.businessCardContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    // @media screen and (max-width:1008px) {
    //     grid-template-columns: 1fr;
    // }
    .banner_top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        @media screen and (max-width:500px) {
            gap: 0.5rem;
            margin-top: 0rem;
        }

        z-index: 1;
        position: relative;

        margin-top: 2rem;
        place-items: center;



        .package_light {
            position: absolute;
            left: 0%;
            top: -50%;
            // translate: transform(-50%, 0%);
        }

        .banner_card {
            background-color: #fff;
            padding: 1rem;
            display: block;
            border-radius: 30px;
            width: 150px;
            border: 4px solid var(--border-color);

            @media screen and (max-width: 1000px) {
                width: 100%;
            }

            position: relative;
            border: 4px solid #D9D9D9;
            cursor: pointer;

            p {
                font-size: 0.8rem !important;

                @media screen and (max-width: 700px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 430px) {
                    font-size: 0.8rem;
                }

                font-weight: 600;
                color: #6F6C90;
                text-align: left;
            }

            h2 {
                font-size: 1.2rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 430px) {
                    font-size: 1rem;

                }

                font-weight: 600;
                color: #000;
                text-align: left;
            }

            h4 {
                font-size: 1.2rem;

                @media screen and (max-width: 700px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 430px) {
                    font-size: 1rem;

                }

                font-weight: 600;
                color: #000;
                text-align: left;
            }
        }


        .active {
            border: 4px solid #2761D0;

        }
    }
    gap: 1rem;
    border-radius: 36px;
    // border-image: linear-gradient(#f6b73c, #4d9f0c) 30;
    // border-width: 4px;
    border-style: solid;
    border: 4px solid #4FAFCB;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
    padding: 2rem;
        

    .both{
        grid-template-areas: 'business-card-part1 business-card-part1 business-card-part2'
        'business-card-part3 business-card-part3 business-card-part4'
        'business-card-part5 business-card-part6 business-card-part6'
        'business-card-part7 business-card-part7 business-card-part7';
    }
    .videoOnly{
        grid-template-areas: 'business-card-part1 business-card-part1 business-card-part2'
        'business-card-part3 business-card-part3 business-card-part4'
        'business-card-part6 business-card-part6 business-card-part6'
        'business-card-part7 business-card-part7 business-card-part7';
    }
    .imageOnly{
        grid-template-areas: 'business-card-part1 business-card-part1 business-card-part2'
        'business-card-part3 business-card-part3 business-card-part4'
        'business-card-part5 business-card-part5 business-card-part5'
        'business-card-part7 business-card-part7 business-card-part7';
    }
    .none{
        grid-template-areas: 'business-card-part1 business-card-part1 business-card-part2'
        'business-card-part3 business-card-part3 business-card-part4'
        'business-card-part7 business-card-part7 business-card-part7';
    }

    
    .part1 {
        grid-column: span 2;

        // @media screen and (max-width:1008px) {
        //     grid-column: span 1;
        // }

        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr auto 1fr;
        // place-content: center;
        grid-auto-rows: min-content;
       

        // @media screen and (max-width:1008px) {
        //     grid-template-areas: 'business-card-part5 business-card-part1 business-card-part1'
        //         'business-card-part5 business-card-part3 business-card-part3'
        //         'business-card-part5 business-card-part2 business-card-part2'
        //         'business-card-part5 business-card-part4 business-card-part4'
        //         'business-card-part6 business-card-part6 business-card-part6'
        //         'business-card-part7 business-card-part7 business-card-part7';
        // }

        .contact_buttons{
            display: grid !important;
            width: 100%;
            grid-template-columns: 1fr 1fr 1fr  1fr;
            color: rgb(50, 112, 226);
            .contact_button{
                display: grid;
                grid-template-columns: 1fr;
                place-items: center;
            }
        }

        .business-card-part1 {
            grid-area: business-card-part1;
            color: #000;
            font-family: "Open Sans";
            font-size: 43px;

            // @media screen and (max-width:1008px) {
            //     font-size: 1.5rem;
            // }

            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.43px;
        }

        .business-card-part2 {
            grid-area: business-card-part2;
            display: flex;
            gap: 1rem;
            align-items: center;
            p{
                text-decoration: underline;
                color: #2761D0;
                cursor: pointer;
                font-weight: 700;
            }
            // align-items: center;
            // justify-content: center;
            // @media screen and (max-width:1008px){
            //     justify-content: flex-start;
            // }
            span{
                font-size: 3rem;
            }
        }

        .business-card-part3 {
            grid-area: business-card-part3;
            color: #9E9EA2;
            font-family: "Open Sans";
            font-size: 28px;

            // @media screen and (max-width:1008px) {
            //     font-size: 1rem;

            // }

            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.28px;
        }

        .business-card-part4 {
            grid-area: business-card-part4;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 1rem;

            img {
                width: 2rem;
                height: 2rem;
            }

            color: #9E9EA2;

            font-family: "Open Sans";
            font-size: 24px;

            // @media screen and (max-width:1008px) {
            //     font-size: 0.875rem;
            //     gap: 0.5rem;
            //     place-items: center;
            // }

            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.24px;
        }

        .business-card-part5 {
            grid-area: business-card-part5;
            overflow: hidden;
            border-radius: 25px;
       
            
            height: 20rem;
            @media screen and (max-width:1585px) {
                height: 14rem;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            // @media screen and (max-width:1008px) {
            //     width: 100%;
            //     height: 100%;

            // }
        }

        .business-card-part6 {
            grid-area: business-card-part6;
            height: 20rem;
            border-radius: 25px;
            overflow: hidden;
            @media screen and (max-width:1585px) {
                height: 14rem;
                
            }
            video {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }

            // @media screen and (max-width:1008px) {
            //     height: 100%;
            //     width: 100%;
            // }
        }

        .business-card-part7 {
            grid-area: business-card-part7;

            .business-card-part7-header {
                display: flex;
                align-items: center;
                gap: 1rem;

                h1 {
                    color: var(--Gray-800, #1E293B);
                    font-family: "Open Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                }

                p {
                    color: var(--Alias-Secondary---On-Theme, #808089);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    /* 27px */
                }

                .viewAll {
                    color: #2761D0;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px;
                    /* 87.5% */
                    letter-spacing: -0.48px;
                }
            }

            .business-card-part7-content {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));

                // @media screen and (max-width:745px) {
                //     // grid-template-columns: 1fr;
                //     display: flex; 
                //     flex-direction: column;
                //     gap: 1rem;
                //     width: 100%;
                //     justify-content: center;
                //     .reviewContainer{
                //         width: 100%;
                //         justify-content: center;
                //     }
                //     .reviewContent{
                //         width: 100%;
                //         justify-content: center;
                //     }

                // }
            }
        }


    }

    .part2 {
        .connect-share{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            // padding-top: 3em;
            @media screen and (max-width:1008px) {
                padding-top: 0em;
                padding-bottom: 1.5em;
            }
            p{
                font-size: 1.5rem;
                text-transform: uppercase;
                text-align: center;
                font-weight: 700;
                // border-bottom: 2px solid #4FAFCB;
            }
            // border: 2px solid #4FAFCB;
            // border-radius: 10px;
            // padding: 1rem;
            // width: 100%;

            .fiber_company_selector {
                width: 100%;
                padding: 0.5rem;
                border-radius: 5px;
                z-index: 9999;
            }
        }

        div {
            display: flex;
            gap: 1rem;


        }

        .qr {
            img {
                width: 8rem;
                height: 8rem;
            }
        }
    }
}

.review_modal{
    overflow: auto;
    height: 80vh;
    width: 80vw;
    outline: none;
}

.review_mobile_modal{
    background-color: #fff;
    overflow-y: auto;
    height: 80vh;
    width: 80vw;
    padding: 1.5rem; /* Add padding for spacing */
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    position: relative; /* Position for close button */
    outline: none;

    /* Adding top and bottom space */
    margin-top: 5vh;
    margin-bottom: 5vh;

    @media screen and (max-width: 768px) {
        height: 85vh; 
        width: 95vw; 
        padding: 1rem;
    }

    @media screen and (max-width: 480px) {
        height: 90vh;
        width: 100vw; 
        padding: 0.8rem;
        border-radius: 0; 
    }

    /* Close button */
    .close-button {
        position: absolute;
        top: 10px; /* Align at the top */
        right: 10px; /* Align to the right */
        background: transparent;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        color: #000;

        &:hover {
            color: #ff0000; /* Highlight on hover */
        }
    }
}

.add_video_bizcard{
    border: 2px solid #4FAFCB;
    height: 100%;
    width: 100%;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    label{
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: 700;
        color: #4FAFCB;
        border: 1px solid black;
        padding: 0.5rem 1rem;
        border-radius: 10px;  
        display: flex;
        align-items: center;
    }
    #biz_card_video{
        display: none;
    }
}