/* Search Box */
.searchBox {
    display: flex;
    align-items: center; /* Ensures vertical alignment */
    justify-content: space-between; /* Push search icon to the right */
    background-color: var(--bg-light);
    padding: 8px 15px;
    border-radius: 12px;
    width: 100%;
    max-width: 500px;
    height: 45px;
    position: relative;
    margin: 0 auto; /* Centered */
  }
  .main_layout_container{
    @media screen and (max-width: 1000px) {
        overflow: none;
        padding: 0rem;
    }
    padding: 0.5rem;
    overflow: auto;
    overscroll-behavior: none;
  }



  /* Search Input */
  .searchInput {
    border: none;
    outline: none;
    background: none;
    width: 100%;
    margin-left: 8px;
    font-size: 14px;
    color: var(--text-color); /* Restore text color */
  }
  
  /* Search Icon */
  .searchIcon {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  
  /* Dropdown */
  .dropdown {
    position: absolute;
    top: 50px; /* Position below the search bar */
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
  }
    /* Loading Message */
    .loading {
        color: gray;
        padding: 10px;
        text-align: center;
        font-size: 14px;
      }
  
  /* Dropdown Items */
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    color: var(--text-color); /* Add text color */
  }
  
  .dropdown-item:hover {
    background-color: #f5f5f5;
  }
  
  /* Icons Container */
  .iconsContainer {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .iconsContainer > * {
    cursor: pointer;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .searchBox {
      width: 90%;
      padding: 8px 12px; /* Adjust padding for smaller screens */
    }
  
    .searchInput {
      font-size: 13px; /* Slightly smaller text */
    }
  
    .searchIcon {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 10px; /* Adjust as per spacing needs */
        top: 14px;
        background-color: #f5f5f5;
      }
  
    .dropdown-item {
      padding: 8px 12px;
      font-size: 13px; /* Slightly smaller text */
    }
  
    .iconsContainer > * {
      width: 16px;
      height: 16px; /* Adjust icon size */
    }
  }

@media screen and (max-width: 1300px) {
    .searchBox {
        width: 35%;
    }
}

@media screen and (max-width: 1100px) {
    .searchBox {
        width: 50%;
    }
}



.FullLogo {

    width: 200px;
    height: 60px;

    @media screen and (max-width: 1100px) {

        display: none;

    }

    @media screen and (max-width: 950px) {
        display: block;
        width: 120px;
        display:none;
        object-fit: contain;
    }

    // @media screen and (max-width: 765px) {
    //     display: none;

    // }



}

.smlogo {
    width: 50px;
    height: 50px;
    display: none;

    @media screen and (max-width: 1100px) {
        display: block;

    }

    @media screen and (max-width: 950px) {

    }

    @media screen and (max-width: 765px) {
        display: block;

    }


}

.BreadCrumbDiv{
    @media screen and (max-width: 950px) {
        display: none !important;
    
    }
}

.showAllModes {

    background-color: var(--bg-light) !important;
    border-radius: 23px !important;
    border: none !important;
    outline: none !important;
    color: var(--text-color) !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    height: 45px !important;
    padding: 2px 8px !important;
    display: flex;
    align-items: center;
    margin-right: 10px;
    // display: none !important;
    // visibility: hidden;

}

.SmSearch {
    display: none;
    background-color: var(--bg-light);
    padding: 8px;
    border-radius: 50%;

   
}
@media screen and (max-width: 650px) {
    .SmSearch {
        display: block;
    }
    .searchBox {
        display: none;
        
    }
  


}
.NewNavMap {

    width: 40px;
    height: 40px;

    @media screen and (max-width: 1200px) {
        display: none;

    }
}




.NameAndRole{
    padding: 2px 10px;
    @media screen and (max-width: 500px) {
        padding: 2px 4px;
    }
    .NewName{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
}
@media screen and (max-width: 900px) {
  .NewName{
    display: none !important;

}
  
}

@media screen and (max-width: 500px) {
    
    .NameAndRole{
        background-color: transparent !important;
    }
    .NewNotify{
        width:40px !important;
        height:40px !important;
    }
    .NewSetting{
        width:40px !important;
        height:40px !important;
    }
    .ProPhoto{
        width:40px !important;
        height:40px !important;
    }
}