.createTeamMember {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
        @media screen and (max-width: 1300px){
            width: 60%;
        }
        @media screen and (max-width: 830px){
            width: 80%;
        }
        @media screen and (max-width: 600px){
            width: 90%;
        }

        background-color: #fff;
        border-radius: 20px;
        border: 1px solid #E5E5E5;
        padding: 2rem;
        gap: 1rem;

        h1 {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }

        input {
            width: 100%;
            height: 3rem;
            border-radius: 9.024px;
            border: 1px solid #C4C4C4;
            padding: 0.5rem;
            font-size: 1rem;
            text-align: center;
            font-weight: 600;
            color: black;
            &:focus {
                outline: none;
            }
        }
        select{
            width: 100%;
            height: 3rem;
            border-radius: 9.024px;
            border: 1px solid #C4C4C4;
            padding: 0.5rem;
            font-size: 1rem;
            text-align: center;
            font-weight: 600;
            color: black;
            &:focus {
                outline: none;
            }
            option{
                font-size: 1rem;
                text-align: center;
                font-weight: 600;
                color: black;
            }

        }

        .selector{
            width: 100%;

        }

        button {
            width:-moz-fit-content;
            width: fit-content;

            border: none;
            border-radius: 9.024px;
            background: #181E4B;
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
            padding: 0.7rem 4rem;

            &:focus {
                outline: none;
            }
        }
    }
}