.manageSellingCardsContainer {
    width: 100%;
    background-color: white;
    padding: 1rem 0rem;
    border-radius: 20px;
    border: 4px solid #4FAFCB;

    .manageSellingCardsSearchFilter {
        border-radius: 10px;
        background: var(--color-foreground);
        padding: 20px;
    
        h1 {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 1rem;
            text-align: center;
        }

        .manageSellingCardsSearchFilterInputs {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin-bottom: 0.5rem;

            @media screen and (max-width: 1040px) {
                grid-template-columns: 1fr;
                width: 100%;
            }

            align-items: center;
            justify-content: space-between;

            input {
                grid-column: span 2/span 2;
                width: 98%;
                height: 3rem;
                border: 1px solid #E5E5E5;
                border-radius: 5px;
                padding: 1rem 2rem;
                font-size: 1rem;
                font-weight: 500;
                margin-right: 0.5rem;
                &:focus {
                    outline: none;
                }
            }

            div {
                height: 3rem;
                align-items: center;

                input::-ms-input-placeholder {
                    margin: auto;
                }
            }
        }

        button {
            width: 100%;
            border: none;
            border-radius: 10px;
            background-color: var(--color-primary);
            color: var(--color-foreground);
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            padding: 1rem 2rem;
            margin-top: 0.5rem;

            &:focus {
                outline: none;
            }
        }
    }

    .sellingCards {
        width: 100%;
        display: grid;
        gap: 1rem;
        margin-top: 2rem;
        grid-template-columns: 1fr;
        place-items: center;

        .notFound {
            grid-column: span 1 / span 1;

            @media screen and (max-width: 1510px) {
                grid-column: span 1 / span 1;
            }

            @media screen and (max-width: 933px) {
                grid-column: span 1 / span 1;
            }
        }
    }

    .swiper-container {
        width: 100%;
        margin: auto;

        @media screen and (max-width:1000px) {
            padding: 0.5rem;
        }
        border: 1px solid #E5E5E5;
        padding: 1rem;
        border-radius: 10px;

        .swiper-wrapper {
            padding: 1rem;

            @media screen and (max-width:1000px) {
                padding: 0rem;
            }
        }

        .swiper-slide {
            display: flex;
            justify-content: center;
        }

        .swiper-button-next,
        .swiper-button-prev {
            width: 40px; /* Adjust width if needed */
            height: 40px; /* Adjust height if needed */
        }
    }

    .spinner {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

    .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
}

.swiper-button-next, .swiper-button-prev {
    background-color: transparent; /* Change the background color */
    width: 50px; /* Change the width */
    height: 50px; /* Change the height */
}
  
.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 20px !important; /* Change the size of the arrow */
    color: #000; /* Change the color of the arrow */
}
