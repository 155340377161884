.FreeMonthForm {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    font-family: 'Open Sans', sans-serif;
    .HeaderDiv {
        h2 {
            text-align: center;
            font-size: 1.5rem;
            font-weight: bold;
        }
    }

    .CurrentInternetProvider {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;

        label {
            font-size: 1.25rem;

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }

            font-weight: bold;
        }

        input {
            margin-top: 1rem;
            padding: 0.5rem 1rem;
            font-size: 1.25rem;

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }

            font-weight: bold;
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            outline: none;
            width: 100%;
        }
    }

    .internetSpeedAndPrice {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 1rem;
        gap: 1rem;

        div {
            display: flex;
            flex-direction: column;

            label {
                font-size: 1.25rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }

                font-weight: bold;
            }

            input {
                margin-top: 1rem;
                padding: 0.5rem 0.5rem;
                width: 80%;
                font-size: 1.25rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }

                font-weight: bold;
                border: 1px solid #C4C4C4;
                border-radius: 10px;
                outline: none;
            }
        }
    }

    .speedTestHeading {
        h3 {
            margin-top: 1rem;
            font-size: 1.25rem;
            font-weight: bold;
        }
    }

    .speedTestImage {

        border: 1px solid #C4C4C4;
        border-radius: 10px;
        padding: 0.5rem 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        input {
            display: none;

        }
    }

    .isBundleContainer{
        margin-top: 1rem;
        display: flex;
        gap: 2rem;
        label{
            font-size: 1.25rem;
            font-weight: bold;
        }

        .IsBundleRadioDiv{
            display: flex;
            gap: 1rem;
            align-items: center;
            div{
                display: flex;
                gap: 1rem;
                align-items: center;
                
                label{
                    font-weight: normal;
                }
                input[type="radio"]{
                    width: 1.5rem;
                    height: 1.5rem;
                }
                input[type="radio"]:checked{
                    accent-color: #2761D0;
                }
            }
        
        }
    }

    .bundleoptions{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 1rem;
    }

    .bundlePrice{
        h3{
            font-size: 1.25rem;
            font-weight: bold;
        }
        .bundlePriceOptions{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            margin-top: 1rem;
        }
    }

    .otherInterestContainer{
        h3{
            font-size: 1.25rem;
            font-weight: bold;
            margin-top: 1rem;
        }
        .otherInterestOptions{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            margin-top: 1rem;
        }
    }

    .survey{
        h3{
            font-size: 1.25rem;
            font-weight: bold;
            margin-top: 1rem;
            text-transform: uppercase;
        }
    }
    .survey_rating
    {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;
        gap: 1rem;
        h4{
            font-size: 1.1rem;
            font-weight: bold;
        }
    }

    
    .recommendation{
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 1rem;
        gap: 1rem;
        h3{
            font-size: 1.25rem;
            font-weight: bold;
            margin-top: 1rem;
            text-transform: uppercase;
        }
        .scale{
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            .scaleItem{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
                padding: 1rem;
                width: 50px;
                height: 50px;
                @media screen and (max-width: 1000px){
                    width: 25px;
                    height: 25px;
                }
                border: 1px solid #C4C4C4;
                border-radius: 10px;
                cursor: pointer;
                input[type="radio"]{
                    display: none;
                }
             
                
            }
            .label{
                grid-column: span 5/ span 5;
            }
            .second{
                justify-self: end;
            }
            .active{
                background-color: #2761D0;
                color: white;
                border: 1px solid #2761D0;
            }
        }
    }

    .buttonGroup{
        margin-top: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        button:nth-child(1){
            border: 2px solid #C4C4C4;
            padding: 0.5rem 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            cursor: pointer;
        }

        button:nth-child(2){
            background-color: #2761D0;
            color: white;
            padding: 1rem 1rem;
            font-size: 1.5rem;
            font-weight: bold;
            border-radius: 20px;
            width: 100%;
        }
    }
}