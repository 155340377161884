.container_for_reschedule{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;

}

.reschedule_form_container_customer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    // height: 100vh;
    @media screen and (max-width: 1000px) {
        padding: 1rem;
        max-width: 90%;
        margin: auto;

    }
    margin: 2rem auto;
    .title {
        font-size: 3rem;
        @media screen and (max-width: 1000px) {
            font-size: 2rem;
        }
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: center;
    }
    .inputs {
        width: 100%;
    }
    .inputLabel {
        font-size: 1rem;
        margin-top: 1rem;
        font-weight: 500;
        text-align: start;
        display: block;
    }
    .datePicker {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        text-align: center;
    }
    .am_pm
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        p{
            text-transform: uppercase;
            font-weight: 500;
            width: 100%;
            text-align: center;
            color: #84A1C8;
            padding: 0.5rem;
            cursor: pointer;
            @media screen and (max-width: 500px){
                // font-size: 0.8rem;
                
            }
        }
        .active{
            color: #2761D0;
            border: 3px solid #2761D0;
            border-radius: 5px;
        }
    }
    .note{
        font-size: 0.8rem;
        color: #84A1C8;
        margin-top: 0.5rem;
    }
    .submitBtn {
        margin-top: 1rem;
        padding: 0.75rem 2rem;
        border: none;
        border-radius: 5px;
        justify-content: center;
        color: white;
        cursor: pointer;
    }
}





