.consultation_container {
    padding: 2rem;
  
    @media (max-width: 600px) {
      padding: 1rem;
    }
  
    .consultation_info, .consultation_form {
      height: 100%;
    }
  
    .consultation_info {
      ul {
        padding-left: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  
    .consultation_form {
      display: flex;
      flex-direction: column;
      height: 100%;
  
      .form_content {
        flex-grow: 1;
        overflow-y: auto;
        padding-bottom: 1rem;
        max-height: calc(100vh - 200px);
      }
  
      .company_logo {
        height: 80px;
        margin: 0 auto 1rem;
        display: block;
      }
  
      .form_fields {
        display: grid;
        gap: 1rem;
      }
  
      .input-group {
        display: flex;
        flex-direction: column;
  
        label {
          margin-bottom: 0.5rem;
          font-weight: 500;
        }
  
        input, textarea, select {
          padding: 0.5rem;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          font-size: 1rem;
  
          &:focus {
            outline: none;
            border-color: #2761D0;
          }
        }
  
        textarea {
          resize: vertical;
        }
      }
  
      .phone_input_container {
        width: 100%;
      }
  
      .phone_input {
        width: 100% !important;
      }
  
      .form_footer {
        padding: 1rem;
        background-color: #f5f5f5;
        border-top: 1px solid #e0e0e0;
      }
  
      .submit_button_container {
        margin-top: 1rem;
      }
  
      .full-width-textarea {
        width: 100%;
      }
  
      .datetime-picker {
        input[type="datetime-local"] {
          padding: 0.5rem;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          font-size: 1rem;
          width: 100%;
          appearance: none;
          background-color: white;
  
          &::-webkit-calendar-picker-indicator {
            cursor: pointer;
            padding: 4px;
            margin-right: 4px;
            opacity: 0.7;
  
            &:hover {
              opacity: 1;
            }
          }
  
          &:focus {
            outline: none;
            border-color: #2761D0;
          }
        }
      }
    }
  }
  
  .thank_you_container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: #f5f5f5;
  
    .MuiCard-root {
      max-width: 600px;
      width: 100%;
      padding: 2rem;
      text-align: center;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  
    .MuiTypography-h4 {
      color: #2761D0;
      margin-bottom: 1.5rem;
    }
  
    .MuiTypography-body1 {
      font-size: 1.1rem;
      line-height: 1.6;
    }
  }
  
  .custom-swal {
    z-index: 9999;
  }
  
  // Improve focus styles for better accessibility
  input:focus,
  textarea:focus,
  select:focus,
  button:focus {
    outline: 2px solid #2761D0;
    outline-offset: 2px;
  }
  
  // Add smooth scrolling to the form
  html {
    scroll-behavior: smooth;
  }
  
  // Mobile responsiveness
  @media (max-width: 600px) {
    .consultation_container {
      .consultation_info, .consultation_form {
        margin-bottom: 1rem;
      }
  
      .consultation_form {
        .form_fields {
          gap: 0.5rem;
        }
  
        .input-group {
          margin-bottom: 0.5rem;
        }
  
        .form_content {
          max-height: calc(100vh - 250px);
        }
      }
    }
  }