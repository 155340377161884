.editFiberCompanyModal {
    z-index: 1050;
}

.input_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    h1 {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
    }

    .inputs_table {
        height: 60vh;
        overflow: auto;
        border: 2px solid #ccc;
        border-radius: 10px;
        padding: 2rem;
        gap: 1rem;
        display: grid;
        grid-template-columns: 1fr;
    }

    .inputs_header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: 2px solid #ccc;
        text-align: center;
    }

    .inputRow {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #eee;
    }

    .inputContainer {
        display: flex;
        align-items: center;
    }

    .checkboxContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .editFiberCompanyFormInputLabel {
        font-size: 16px;
        font-weight: 600;
        margin-right: 5px;
    }

    button {
        width: 100%;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 10px;
        background-color: var(--color-primary);
        color: var(--color-foreground);
        font-size: 1.5rem;
    }
}

.editFiberCompanyContainer {
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    .editFiberCompanyForm {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;

        .editFiberCompanyFormTitle {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .editFiberCompanyFormInput {
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            @media screen and (max-width: 820px) {
                grid-template-columns: 1fr;
            }
            justify-content: center;
            align-items: center;
            gap: 1rem;

            .imageInput {
                position: relative;
                border-radius: 10px;
                overflow: hidden;
                margin: auto;
                input {
                    display: none;
                }

                label {
                    position: absolute;
                    cursor: pointer;
                }
            }

            .inputContainer {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: baseline;
                row-gap: 1rem;
                padding: 1rem;
            }

            .editFiberCompanyFormInputLabel {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .editFiberCompanyFormInputField {
                width: 100%;
                height: 40px;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 0 10px;
                font-size: 16px;

                &:focus {
                    outline: none;
                }
            }
        }

        .editFiberCompanyFormButton {
            width: 100%;
            height: 40px;
            border: none;
            border-radius: 5px;
            background-color: var(--color-primary);
            color: var(--color-foreground);
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }
    }

    .fiberPlansHeading {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
    }

    .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        .MuiPaginationItem-root {
            color: var(--color-primary);
        }
    }
}


.bottom_fiber_company_conatiner{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr;
        
    }
    .add_plan_to_template{
        background-color: white;
        padding: 2rem;
        border-radius: 10px;
        border : 4px solid #4FAFCB;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @media screen and (max-width: 1000px){
            width: min(90vw, 600px);
            margin: auto;
        }
        h1{
            font-size: 1.5rem;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            margin: auto;
        }
        .form{
            padding: 1rem;
            background-color: white;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
            height: fit-content;
            display: grid;
            grid-template-columns: 1fr;
            gap:0.5rem;
            h2{
                font-size: 1.2rem;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
            }
            .async_select__control{
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
                padding: 0.2rem;
            }
            input{
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
            }
            button{
                padding: 0.5rem;
                border: none;
                border-radius: 5px;
                background-color: rgb(24, 30, 75);
                color: white;
                font-weight: bold;
                cursor: pointer;
                outline: none;
                transition: all 0.3s;
                &:hover{
                    background-color: rgb(24, 30, 75);
                }
            }
        }
        .fiber_plans{

            border-radius: 10px;
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;
            
            .fiber_plan{
                display: grid;
                background-color: white;
                box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
                grid-template-columns: 1fr auto;
                // @media screen and (max-width: 530px){
                //     grid-template-columns: 1fr;
                    
                // }
                padding: 1rem;
                border-radius: 10px;
                align-items: center;

                h3{
                    font-size: 1.5rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: center;
                    grid-column: span 2;
                }
                div{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 0.5rem;
                    padding-left: 1rem;
                    p{
                        font-size: 0.9rem;
                        color: #84A1C8;
                        // text-align: center;
                    }
                    p:last-child{
                        grid-column: span 2;
                    }
                }

                button{
                    background-color: #e36363;
                    padding: 0.5rem;
                    border-radius: 10px;
                    width: -moz-fit-content;
                    width: fit-content;
                    @media screen and (max-width: 530px){

                        margin: auto;
                        
                    }
                    &:hover{
                        background-color: red;
                    }
                }
            }
        }
    }
}
.create_offer_template{

    width: 100%;
    // overflow-y: auto;
    border:4px solid #4FAFCB;
    background-color: white;
    padding: 2rem;
    @media screen and (max-width: 500px){
        padding: 1rem;
        
    }
    @media screen and (max-width: 1000px){
        width: min(90vw, 600px);
        margin: auto;
    }
    border-radius: 15px;
    outline: none;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    // box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    h1{
        text-align: center;
        font-weight: bold;
        font-size: 1.4rem;
        text-transform: uppercase;
    }
    .icons_list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon{
            width: 2rem;
            height: 2rem;
        }
    }


    .form{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        overflow-y: auto;
        padding-right: 1rem;
        scrollbar-width: thin;
        
        h2{
            // font-size: revert;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
        }

        .reason_div{
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 0.5rem;
            // align-items: first baseline;
            input{
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
                width: 1.3rem;
                
                // height: 1rem;
            }

        }
        .note{
            color: #84A1C8;
            font-size: 0.8rem;
            text-transform: uppercase;
            margin-left: 0.5rem;
        }
        .create_account_form{
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;
            place-items: center;
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
            padding: 1rem;
            border-radius: 10px;
            margin: 0.5rem;
            .inputs{
                display: grid;
                grid-template-columns: 1fr;
                gap: 0.5rem;
                .input{
                    outline: none;
                    border: 1px solid #C4C4C4;
                    border-radius: 10px;
                    padding: 0.5rem;
                    width: 100%;
                }

                button{
                    padding: 0.5rem;
                    border: none;
                    border-radius: 10px;
                    background-color: #2761D0;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                    outline: none;
                    transition: all 0.3s;
                    &:hover{
                        background-color: #2761D0;
                    }
                }
            }
        }

        .review_textarea{
            color: #84A1C8;
            border: 1px solid #C4C4C4;
            padding: 1rem;
            border-radius: 10px;
        }






        textarea{
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            font-size: revert;
            background-color: white;
        }

        .input_div{
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.2rem;
            label{
                text-transform: uppercase;
                font-weight: 500;
            }


            input,select{
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
                background-color: white;
                font-size: revert;
            } 


        }



        button{
            padding: 0.5rem;
            border: none;
            border-radius: 5px;
            background-color: #2761D0;
            color: white;
            font-weight: bold;
            cursor: pointer;
            outline: none;
            transition: all 0.3s;
            &:hover{
                background-color: #2761D0;
            }
        }
    }

}