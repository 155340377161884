.live-tracking-compact {
    position: fixed;
    left: 20px;
    bottom: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 300px;
    min-height: 60px;
    z-index: 1000;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    border: 1px solid #eee;
  
    &.minimized {
      width: 220px;
      min-height: auto;
      
      .content {
        display: none;
      }
      
      .header {
        padding: 12px;
        
        .name {
          max-width: 140px;
        }
      }
    }
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background: #f8f9fa;
      border-bottom: 1px solid #eee;
  
      .user-info {
        display: flex;
        align-items: center;
        gap: 12px;
        min-width: 0;
  
        .avatar {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          object-fit: cover;
          flex-shrink: 0;
        }
  
        .name {
          font-size: 14px;
          font-weight: 600;
          color: #2d3436;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }
      }
  
      .controls {
        display: flex;
        gap: 8px;
  
        .icon-button {
          background: none;
          border: none;
          padding: 4px;
          color: #666;
          cursor: pointer;
          transition: all 0.2s ease;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
  
          &:hover {
            background: #f0f0f0;
            color: #333;
          }
  
          svg {
            font-size: 14px;
          }
        }
      }
    }
  
    .content {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
  
      .date-section {
        position: relative;
        display: flex;
        align-items: center;
  
        .calendar-icon {
          position: absolute;
          left: 12px;
          color: #666;
          font-size: 14px;
        }
  
        .date-input {
          width: 100%;
          padding: 8px 12px 8px 36px;
          border: 1px solid #ddd;
          border-radius: 6px;
          font-size: 14px;
          color: #333;
          background: white;
          
          &:focus {
            outline: none;
            border-color: #3f51b5;
            box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.1);
          }
        }
      }
  
      .show-path-btn {
        background: #3f51b5;
        color: white;
        padding: 10px 16px;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        cursor: pointer;
        transition: all 0.2s ease;
        width: 100%;
  
        &:hover:not(:disabled) {
          background: #334296;
        }
  
        &:disabled {
          background: #b0bec5;
          cursor: not-allowed;
        }
  
        .btn-icon {
          font-size: 12px;
        }
      }
    }
  }