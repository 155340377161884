.paginated-activity-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1.5rem;

  .search-controls {
    margin-bottom: 2rem;
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
    padding: 1rem 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .activity-list-container {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    position: relative;
    min-height: 400px;
    padding: 1rem;

    .loading-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      border-radius: 12px;
    }

    .activity-list {
      .activity-card {
        transition: transform 0.2s ease, box-shadow 0.2s ease;
        border-radius: 8px;
        margin: 0.5rem 0;
        padding: 1rem;
        background: #f8f9fa;
        
        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
        }
      }

      .activity-header {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        flex-wrap: wrap;
      }

      .activity-content {
        padding-left: 0.5rem;
        border-left: 2px solid #e0e0e0;
      }
    }

    .lead-details {
      background: #ffffff;
      border-radius: 8px;
      padding: 1rem;
      margin: 1rem 0;
      border: 1px solid #e0e0e0;
    }

    .empty-state {
      padding: 3rem;
      text-align: center;
      color: #6c757d;
    }
  }

  .pagination-controls {
    padding: 2rem 0;
    display: flex;
    justify-content: center;

    .MuiPagination-ul {
      flex-wrap: nowrap;
    }

    .MuiPaginationItem-root {
      margin: 0 4px;
      min-width: 32px;
      height: 32px;

      &.Mui-selected {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 1rem;

    .activity-list-container {
      .activity-list {
        .activity-card {
          margin: 0.25rem 0;
          padding: 0.75rem;
        }

        .activity-header {
          flex-direction: column;
          align-items: flex-start;

          .MuiChip-root {
            margin-left: 0 !important;
            margin-top: 0.5rem;
          }
        }
      }

      .pagination-controls {
        padding: 1rem 0;
      }
    }
  }
}
