// Theme Variables
$bg-color: rgba(255, 255, 255, 0.95);
$shadow-color: rgba(0, 0, 0, 0.1);
$shadow-color-hover: rgba(0, 0, 0, 0.2);
$primary-color: #00bcd4;
$secondary-color: #ccc;
$disabled-color: #e0e0e0;
$text-color: #666;
$metric-card-bg: rgba(255, 255, 255, 0.9);
$metric-border: #e0e0e0;

.player {
  background-color: $bg-color;
  border-radius: 10px;
  box-shadow: 0 3px 5px $shadow-color;
  padding: 6px 8px;
  width: 240px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  user-select: none;

  &:hover {
    box-shadow: 0 4px 6px $shadow-color-hover;
    transform: translateY(-2px);
  }

  .metrics-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
    margin: 6px 0;
    
    .metric-card {
      background: $metric-card-bg;
      border: 1px solid $metric-border;
      border-radius: 6px;
      padding: 4px;
      display: flex;
      align-items: center;
      gap: 2px;
      transition: transform 0.2s ease;
      
      &:hover {
        transform: translateY(-1px);
      }
      
      .metric-label {
        font-size: 0.55rem;
        color: #666;
        display: block;
      }
      
      .metric-value {
        font-size: 0.8rem;
        font-weight: 600;
        color: #333;
      }
      
      svg {
        color: #4CAF50;
        font-size: 0.9rem;
      }
    }
  }
  
  .speed-chart {
    height: 100px;
    margin: 8px 0;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 6px;
    padding: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
  }
  .recharts-responsive-container {
    width: 100% !important;
    height: 100% !important;
    
    // Scale down axis labels
    .recharts-text {
        font-size: 0.6rem !important;
    }
    
    // Adjust chart margins
    .recharts-wrapper {
      margin: 2px auto;
      padding: 5px;

    }
}
  .player-handle {
    cursor: move;
    height: 12px;
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .handle-dots {
      width: 25px;
      height: 2px;
      background-color: $secondary-color;
      border-radius: 2px;
      transition: background-color 0.3s ease;
    }
  }
  
  .player-content {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .time-display-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2px;
    
    .time-display {
      font-size: 0.6rem;
      color: $text-color;
    }
  }
  
  .seeker-container {
    position: relative;
    width: 100%;
    margin-bottom: 2px;
    
    .seeker {
      width: 100%;
      -webkit-appearance: none;
      height: 2px;
      background: #ddd;
      outline: none;
      opacity: 0.7;
      transition: opacity 0.2s;
      
      &:hover {
        opacity: 1;
      }
      
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 8px;
        height: 8px;
        background: $primary-color;
        cursor: pointer;
        border-radius: 50%;
        transition: background 0.2s ease;
      }
      
      &::-moz-range-thumb {
        width: 8px;
        height: 8px;
        background: $primary-color;
        cursor: pointer;
        border-radius: 50%;
        transition: background 0.2s ease;
      }
    }
  }
  
  .controls-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    
    .control-btn {
      min-width: 0;
      padding: 2px;
      border: none;
      background: transparent;
      cursor: pointer;
      transition: transform 0.2s ease;
      
      &:hover {
        transform: scale(1.03);
      }
      
      &:focus {
        outline: 2px solid $primary-color;
      }
    }
    
    .speed-btn {
      font-size: 0.6rem;
      padding: 2px 4px;
      color: black;
      min-width: 25px;
      min-height: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s ease;
      
      &:hover {
        background-color: lighten($primary-color, 40%);
      }
      
      &:focus {
        outline: 2px solid $primary-color;
      }
    }
    
    .pan-btn {
      padding: 2px;
      border: none;
      background: transparent;
      cursor: pointer;
      transition: background-color 0.2s ease;
      
      &.active {
        background-color: $disabled-color;
      }
      
      &:hover {
        background-color: lighten($disabled-color, 10%);
      }
      
      &:focus {
        outline: 2px solid $primary-color;
      }
    }
  }
  
  .sections-container {
    display: flex;
    gap: 4px;
    overflow-x: auto;
    padding: 8px 0;
    scrollbar-width: thin;
    scrollbar-color: $primary-color transparent;
  
    &::-webkit-scrollbar {
      height: 4px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: rgba($primary-color, 0.5);
      border-radius: 2px;
    }
  
    .section-btn {
      flex-shrink: 0;
      width: 28px;
      height: 28px;
      min-width: 0;
      padding: 0;
      font-size: 0.7rem;
      font-weight: 500;
      border: 1px solid rgba($primary-color, 0.3);
      border-radius: 8px;
      background-color: rgba(white, 0.9);
      color: $text-color;
      cursor: pointer;
      transition: all 0.2s ease;
  
      &:hover {
        background-color: rgba($primary-color, 0.1);
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
  
      &.active {
        background-color: $primary-color;
        color: white;
        border-color: $primary-color;
        box-shadow: 0 2px 6px rgba($primary-color, 0.3);
      }
  
      &:focus {
        outline: 2px solid rgba($primary-color, 0.5);
      }
    }
  }
  .show-more-btn {
    font-size: 0.6rem !important;
    padding: 2px 4px !important;
    color: $text-color !important;
    min-width: 80px !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    transition: all 0.2s ease !important;
  
    &:hover {
      background-color: rgba($primary-color, 0.1) !important;
    }
  }
}

@media (max-width: 768px) {
  .player {
    width: 90%;
    max-width: 240px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8px;
    padding: 6px;
  }
}
