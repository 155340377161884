.fcc-data-container {
  padding: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
}

.records-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.record-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
}

.record-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.record-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.header-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
}

.geo-name {
  font-weight: 600;
  color: #34495e;
  font-size: 1.1rem;
}

.header-details {
  display: flex;
  gap: 1.5rem;
  color: #7f8c8d;
  font-size: 0.9rem;
}

.toggle-icon {
  font-size: 0.9rem;
  margin-left: 1rem;
  transition: transform 0.2s ease;
}

.record-card.expanded .toggle-icon {
  transform: rotate(90deg);
}

.record-details {
  padding: 1.5rem;
  background-color: #fcfcfc;
  border-top: 1px solid #eee;
  animation: slideDown 0.3s ease-out;
}

.detail-group {
  margin-bottom: 1.5rem;
}

.detail-group h4 {
  margin: 0 0 1rem 0;
  color: #2c3e50;
  font-size: 1rem;
}

.speed-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.speed-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 4px;
}

.speed-item label {
  color: #7f8c8d;
  margin-right: 1rem;
}

.percentage-distribution {
  display: flex;
  gap: 2rem;
}

.percentage-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.percentage-value {
  font-weight: 600;
  color: #27ae60;
}

.no-data {
  text-align: center;
  color: #7f8c8d;
  padding: 2rem;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .header-details {
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .speed-grid {
    grid-template-columns: 1fr 1fr;
  }
  
  .percentage-distribution {
    flex-direction: column;
    gap: 1rem;
  }
}


.SearchFiltersDiv {
  position: relative;
  width: 100%;
  padding: 1rem;
}

/* Loading Overlay */
.LoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  border-radius: 8px;
}

.LoadingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.Spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #000;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.LoadingText {
  color: #000;
  font-size: 1rem;
  font-weight: 500;
}

/* Search Button */
.SearchButton {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 1rem;
  margin-right: 2rem;
  float: right;
  transition: opacity 0.2s;
}

.SearchButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Animation */
@keyframes spin {
  to { transform: rotate(360deg); }
}

.tab-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
}

.tab-button:not(.active) {
  background-color: #e9ecef;
  color: black;
}

.SearchButton {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.SearchButton:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}