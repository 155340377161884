.update_email_form{
    display: grid;
    grid-template-columns: 1fr ;
    gap: 1rem;
    place-items: center;
    h2{
        color: black;
        font-family: "Open Sans";
        font-size: 2rem;
        margin-bottom: 1rem;
        @media screen and (max-width: 910px) {
            font-size: 1.5rem;
        }

        @media screen and (max-width: 500px) {
            font-size: 1rem;
        }

        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    input{
        padding: 0.5rem 1rem;
        border: 1px solid #EAEAEA;
        border-radius: 10px;
        outline: none;
        font-size: 1rem;
        width: 100%;
        text-align: center;
    }
    button{
        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: #2761D0;
        color: white;
        font-size: 1rem;
        width: 100%;
        outline: none;
    
    }
}
.editAccountDetails {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .editAccountDetails__title {
        color: var(--color-icon);
        font-family: "Open Sans";
        font-size: 2rem;

        @media screen and (max-width: 910px) {
            font-size: 1.5rem;

        }

        @media screen and (max-width: 500px) {
            font-size: 1rem;

        }

        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .innerContainer {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        @media screen and (max-width: 1300px) {
            grid-template-columns: 1fr;
        }

        .editAccountpart1 {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;

            .profileData {
                border-radius: 10px;
                border: 1px solid #E0E0E0;
                background: var(--colors-white-100, #FFF);
                padding: 2rem;
                @media screen and (max-width: 500px) {
                    padding: 1rem;
                }
                display: grid;
                grid-template-columns: 1fr;

                @media screen and (max-width: 500px) {
                    gap: 1rem;

                }
                .edit_buttons{
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                    justify-content: end;
                    button{
                        // padding: 0.3rem 0.6rem;
                        border-radius: 10px;
                        color: #2761D0;
                        text-decoration: underline 2px;
                        outline: none;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                    .cancel{
                        color: #df5252
                    }
                }
                .profileDisplayName {
                    color: #2761D0;
                    font-family: "Open Sans";
                    font-size: 2rem;

                    @media screen and (max-width: 910px) {
                        font-size: 1.5rem;
                    }

                    @media screen and (max-width: 500px) {
                        font-size: 1rem;

                    }

                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .profileName {
                    display: flex;

                    align-items: center;
                    gap: 1rem;

                    h1 {
                        color: var(--Gray-800, #1E293B);
                        font-family: "Open Sans";
                        font-size: 3rem;

                        @media screen and (max-width: 910px) {
                            font-size: 2rem;
                        }

                        @media screen and (max-width: 500px) {
                            font-size: 1.5rem;
                        }

                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: -0.48px;
                        text-transform: uppercase;
                    }

                    input{
                        font-size: 1.5rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: -0.48px;
                        text-transform: uppercase;
                        border:1px solid #2761D0;
                        outline: none;
                        border-radius: 10px;
                        padding: 0.5rem 1rem;
                        width: 50%;
                    }

                    button {
                        color: #2761D0;
                        font-family: "Open Sans";
                        font-size: 1rem;

                        @media screen and (max-width: 910px) {
                            font-size: 0.7rem;
                        }

                        @media screen and (max-width: 500px) {
                            font-size: 0.5rem;
                        }

                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-decoration-line: underline;
                        text-transform: uppercase;
                    }
                }

                .profileBio {

                    .bioHeading {
                        color: #2761D0;
                        font-family: "Open Sans";
                        font-size: 1.5rem;

                        @media screen and (max-width: 910px) {
                            font-size: 1rem;
                        }

                        @media screen and (max-width: 500px) {
                            font-size: 0.8rem;
                        }

                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    div {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        gap: 1rem;

                        p {
                            color: #9E9EA2;
                            font-family: "Open Sans";
                            font-size: 1.2rem;

                            @media screen and (max-width: 910px) {
                                font-size: 0.8rem;
                            }

                            @media screen and (max-width: 500px) {
                                font-size: 0.8rem;
                            }

                            font-style: normal;
                            font-weight: 600;
                            line-height: 31px;
                            /* 134.783% */
                            letter-spacing: -0.46px;
                        }
                        textarea{
                            color: #9E9EA2;
                            font-family: "Open Sans";
                            font-size: 1.2rem;
                            border:1px solid #2761D0;
                            border-radius: 10px;
                            padding: 0.5rem 1rem;

                            @media screen and (max-width: 910px) {
                                font-size: 0.8rem;
                            }

                            @media screen and (max-width: 500px) {
                                font-size: 0.8rem;
                            }

                            font-style: normal;
                            font-weight: 600;
                            line-height: 31px;
                            /* 134.783% */
                            letter-spacing: -0.46px;
                            width: 100%;
                            height: 100%;
                            outline: none;
                        }
                        button {
                            color: #2761D0;
                            font-family: "Open Sans";
                            font-size: 1rem;

                            @media screen and (max-width: 910px) {
                                font-size: 0.7rem;
                            }

                            @media screen and (max-width: 500px) {
                                font-size: 0.5rem;
                            }

                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-decoration-line: underline;
                            text-transform: uppercase;
                        }
                    }

                }

            }

            .UserDataSettings {
                border-radius: 10px;
                border: 1px solid #E0E0E0;
                background: var(--colors-white-100, #FFF);
                padding: 2rem;
                @media screen and (max-width: 500px) {
                    padding: 1rem;
                }
                display: grid;
                grid-template-columns: 1fr;
                gap: 1rem;
                height: fit-content;
                .edit_buttons{
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                    justify-content: end;
                    button{
                        // padding: 0.3rem 0.6rem;
                        border-radius: 10px;
                        color: #2761D0;
                        text-decoration: underline 2px;
                        outline: none;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                    .cancel{
                        color: #df5252
                    }
                }
                .userDataHeading {
                    color: #2761D0;
                    font-family: "Open Sans";
                    font-size: 24px;

                    @media screen and (max-width: 910px) {
                        font-size: 20px;
                    }

                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                    }




                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .userDataBody{

                    display: grid;
                    grid-template-columns: auto 1fr;
                    gap: 1rem;
                    @media screen and (max-width: 500px) {
                        gap: 0.5rem;
                    }
                    h3{
                        font-size: 1.1rem;
                        @media screen and (max-width: 910px) {
                            font-size: 1rem;
                          
                        }
                        font-weight: 600;
                        color: #9E9EA2;
                    }
        

                    .hello{
                        display: flex;
                        gap: 1rem;
                        align-items: center;;
                        p{
                            color: #9E9EA2;
                            font-size: 1rem;
                            font-style: normal;
                            // font-weight: 700;
                            line-height: normal;
                            // letter-spacing: -0.48px;
                            // text-transform: uppercase;
                            // border:1px solid #ccc;
                            outline: none;
                            border-radius: 10px;
                            padding: 0.2rem 0.4rem;
                        }
                        
                        input{
                            font-size: 1rem;
                            font-style: normal;
                            // font-weight: 700;
                            line-height: normal;
                            // letter-spacing: -0.48px;
                            // text-transform: uppercase;
                            border:1px solid #ccc;
                            outline: none;
                            border-radius: 10px;
                            padding: 0.2rem 0.4rem;
                        }

                        button{
                            color: #2761D0;
                            font-family: "Open Sans";
                            font-size: 1rem;

                            @media screen and (max-width: 910px) {
                                font-size: 0.7rem;
                            }

                            @media screen and (max-width: 500px) {
                                font-size: 0.5rem;
                            }

                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-decoration-line: underline;
                            text-transform: uppercase;
                        }
                    }

                    .changePassword{
                        color: white;
                        background-color: #181E4B;
                        padding: 0.5rem 1rem;
                        width:-moz-fit-content;
                        width: fit-content;
                        border-radius: 10px;
                    }
                }

            }
        }

        .editAccountpart2 {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;



            .profileImageVideo {
                border-radius: 10px;
                border: 1px solid #E0E0E0;
                background: var(--colors-white-100, #FFF);
                padding: 2rem;
                @media screen and (max-width: 500px) {
                    padding: 1rem;
                }
                display: grid;
                grid-template-columns: 1fr;
                gap: 1rem;

                p {
                    color: #2761D0;

                    font-family: "Open Sans";
                    font-size: 24px;

                    @media screen and (max-width: 910px) {
                        font-size: 20px;
                    }

                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                    }

                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                    /* 116.667% */
                    letter-spacing: -0.48px;
                }

                .image-videoContainer {
                    display: grid;
                    grid-template-columns: 1fr 2fr;
                    gap: 1rem;

                    div {
                        max-height: 335px;

                        input{
                            display: none;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 10px;
                        }

                        video {
                            width: 100%;
                            height: 100%;
                            // object-fit: cover;
                            // object-fit:contain;
                            border-radius: 10px;
                        }

                        label {
                            color: #2761D0;
                            font-family: "Open Sans";
                            font-size: 1rem;

                            @media screen and (max-width: 910px) {
                                font-size: 0.7rem;
                            }

                            @media screen and (max-width: 500px) {
                                font-size: 0.5rem;
                            }

                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-decoration-line: underline;
                            text-transform: uppercase;
                        }
                    }
                }

                .updateImageAndVideoButton{
                        color: #FFF;
                        font-family: "Open Sans";
                        font-size: 1rem;
                        background: #2761D0;
                        padding: 0.5rem 1rem;
                        border: none;
                        border-radius: 10px;

                        @media screen and (max-width: 910px) {
                            font-size: 0.7rem;
                        }

                        @media screen and (max-width: 500px) {
                            font-size: 0.5rem;
                        }

                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-transform: uppercase;
                        width: -moz-fit-content;
                        width: fit-content;
                        margin: auto;
                        margin-top: 1rem;
                    }
            }
            .UserDataSettings {
                border-radius: 10px;
                border: 1px solid #E0E0E0;
                background: var(--colors-white-100, #FFF);
                padding: 2rem;
                @media screen and (max-width: 500px) {
                    padding: 1rem;
                    
                }
                display: grid;
                grid-template-columns: 1fr;
                gap: 1rem;
                height: fit-content;
                .edit_buttons{
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                    justify-content: end;
                    button{
                        // padding: 0.3rem 0.6rem;
                        border-radius: 10px;
                        color: #2761D0;
                        text-decoration: underline 2px;
                        outline: none;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                    .cancel{
                        color: #df5252
                    }
                }
                .userDataHeading {
                    color: #2761D0;
                    font-family: "Open Sans";
                    font-size: 24px;

                    @media screen and (max-width: 910px) {
                        font-size: 20px;
                    }

                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                    }




                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .userDataBody{

                    display: grid;
                    align-items: center;
                    grid-template-columns: 1fr 1fr;
                    @media screen and (max-width: 450px) {
                        grid-template-columns: 1fr;
                        
                    }
                    gap: 1rem;
                    h3{
                        font-size: 1.1rem;
                        font-weight: 600;
                        color: #9E9EA2;
                    }
                    .sound_icon{
                        background-color: #2761D0;
                        color: white;
                        width: -moz-fit-content;
                        width: fit-content;
                        padding: 0.5rem;
                        border-radius: 10px;
                        cursor: pointer;
                        transition: all 0.3s ease;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 0.5rem;
                       
                    }
                    .hello{
                        display: flex;
                        gap: 1rem;
                        align-items: center;
                        p{
                            color: #9E9EA2;
                            font-family: "Open Sans";
                            font-size: 1.2rem;

                            @media screen and (max-width: 910px) {
                                font-size: 1rem;
                            }

                            // @media screen and (max-width: 500px) {
                            //     font-size: 0.8rem;
                            // }

                            font-style: normal;
                            font-weight: 600;
                            line-height: 31px;
                            /* 134.783% */
                            letter-spacing: -0.46px;
                        }
                        .in{
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            letter-spacing: -0.48px;
                            // text-transform: uppercase;
                            border:1px solid #2761D0;
                            outline: none;
                            border-radius: 10px;
                            padding: 0.5rem 1rem;
                        }

                        button{
                            color: #2761D0;
                            font-family: "Open Sans";
                            font-size: 1rem;

                            @media screen and (max-width: 910px) {
                                font-size: 0.7rem;
                            }

                            @media screen and (max-width: 500px) {
                                font-size: 0.5rem;
                            }

                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-decoration-line: underline;
                            text-transform: uppercase;
                        }
                    }

                    .changePassword{
                        color: white;
                        background-color: #2761D0;
                        padding: 0.5rem 1rem;
                        width:-moz-fit-content;
                        width: fit-content;
                        border-radius: 10px;
                    }
                }

            }
        }
    }
}