.NewActivityHistory{
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem;
    .NewActivityHistoryHeader{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin: 1rem 0rem;
        .menu{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            cursor: pointer;
            padding: 1rem;
            width: 100%;
            @media screen and (max-width: 1000px){
                padding: 0.5rem;
                
            }
            @media screen and (max-width: 576px){
                padding: 0.25rem;
                
            }
            p{
                font-size: 1.5rem;
                font-weight: 600;
                color: black;
                margin: 0;
                @media screen and (max-width: 1000px){
                    font-size: 1.25rem;
                }
                @media screen and (max-width: 576px){
                    font-size: 1rem;
                }
               @media screen and (max-width: 450px){
                    font-size: 0.8rem;
               }
            }
        }
        .active{
            border: 2px solid #2761D0;
            border-radius: 10px;
        }
        .delete{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .formsContainer{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        .freeInstallMonth{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            // place-items: center;
            align-items: center;
            justify-content: space-between;
            div{
                border: 1px solid #C4C4C4;
                padding: 1rem;
                @media screen and (max-width: 650px){
                    padding: 0.5rem;
                }
                border-radius: 10px;
                cursor: pointer;
                div{
                    border: none;
                    height: 200px;
                    @media screen and (max-width: 650px){
                        height: 150px;
                        
                    }
                    @media screen and (max-width: 470px){
                        height: 100px;
                    }
                    width: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                h3{
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin: 0;
                    text-align: center;
                    @media screen and (max-width: 1000px){
                        font-size: 1.25rem;
                    }
                    @media screen and (max-width: 576px){
                        font-size: 1rem;
                    }
                
                }
            }
        }

        .hotLead{
            border: 1px solid #C4C4C4;
            width: 100%;
            margin: auto;
            display: grid;
            place-items: center;
            padding: 1rem;
            border-radius: 10px;
            cursor: pointer;
            div{
                background-image: linear-gradient(180deg, #2761D0 0%, #181E4B 100%);
                padding: 2rem 2rem 0rem 2rem;
                width: -moz-fit-content;
                width: fit-content;
                border-radius: 10px;
                
            }
            h3{
                font-size: 1.5rem;
                font-weight: 600;
                margin-top: 1rem;
                @media screen and (max-width: 1000px){
                    font-size: 1.25rem;
                }
                @media screen and (max-width: 576px){
                    font-size: 1rem;
                }
            }
        }
    }

    .infoContainer{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        .customerCard{
            display: flex;
            gap: 1rem;
            .image{
                width: 120px;
                height: 120px;
                border-radius: 10px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .info{
                display: grid;
                grid-template-columns: 1fr;
                h3{
                    font-size: 2rem;
                    font-weight: 600;
                    margin: 0;
                    @media screen and (max-width: 1000px){
                        font-size: 1.5rem;
                    }
                    @media screen and (max-width: 576px){
                        font-size: 1.25rem;
                    }
                    @media screen and (max-width: 400px){
                        font-size: 1rem;
                        
                    }
                }
                .logo{
                    height: 50px;
                    width:-moz-fit-content;
                    width: fit-content;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .location{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 1rem;
                }
            }
        }

        .gridContainer{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            .item{
                padding: 1rem;
                border: 1px solid #CBD3D9;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 200px;
                h3{
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin: 0;
                    text-align: center;
                    @media screen and (max-width: 1000px){
                        font-size: 1.25rem;
                    }
                    @media screen and (max-width: 576px){
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 450px){
                        font-size: 0.8rem;
                        
                    }
                }
                .fiber-card{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 1rem;
                    .image{
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    h3{
                        font-size: 1.3rem;
                        font-weight: 600;
                        margin: 0;
                        @media screen and (max-width: 1000px){
                            font-size: 1rem;
                        }
                        @media screen and (max-width: 576px){
                            font-size: 0.8rem;
                        }
                    
                    }
                }
                .blueText{
                    background-color: #2761D0;
                    color: white;
                    padding: 1rem;
                    border-radius: 10px;
                    p{
                        font-size: 1.5rem;
                        font-weight: 600;
                    }
                }
                .light-container{
                    display: flex;
                    padding: 0.5rem;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    background-color: #84A1C8;
                    width:-moz-fit-content;
                    width: fit-content;
                }
            }
        }
    }
    // .overflow_container{
    //     height: 400px;
    //     overflow: auto;
    //     padding: 1rem;
    //     border: 1px solid #E5E5E5;
    //     border-radius: 10px;
    //     margin: 1rem 0rem;
    //     box-shadow: 0px 0px 10px 0px #b1b1b1;
    //     width: 100%;
    // }
    .NewActivityHistoryBody{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
  
        .NewActivityHistoryCard{
            display: grid;
            grid-template-columns: 1fr;
            .ActivityHistoryCardHeader{
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                .ImgDiv{
                    width: 50px;
                    height: 50px;
                    @media screen and (max-width: 1000px){
                        width: 40px;
                        height: 40px;
                        
                    }
                    @media screen and (max-width: 576px){
                        width: 30px;
                        height: 30px;
                        
                    }
                    border-radius: 50%;
                    margin-right: 10px;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .TitleDiv{
                    align-self: center;
                    width: -moz-fit-content;
                    width: fit-content;
                    margin: auto 0;
                    h3{
                        font-size: 2rem;
                        font-weight: 600;
                        margin: 0;
                        text-transform: uppercase;
                        @media screen and (max-width: 1000px){
                            font-size: 1.5rem;
                        }
                        @media screen and (max-width: 576px){
                            font-size: 1.25rem;
                        }
                    }
                }
            }

            .ActivityHistoryCardBody{
                margin-left: 4rem;
                @media screen and (max-width: 1000px){
                    margin-left: 3rem;
                    
                }
                @media screen and (max-width: 576px){
                    margin-left: 2.5rem;
                    
                }
                color: #5E6282;
            }
        }
    }
}

.feedbackCard{
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 1em;
    justify-content: center;
    align-items: center;
    video{
        width: 100%;
        height: auto;
        max-height: 18em;
        border-radius: 10px;
    }
     
    p{
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
        color: #5E6282;
        @media screen and (max-width: 1000px){
            font-size: 1.25rem;
        }
        @media screen and (max-width: 576px){
            font-size: 1rem;
        }
    }

    .SpeedTestDiv{
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        div{
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            align-items: center;
            justify-content: center;
            img{
                width: 150px;
                height: 150px;
                object-fit: contain;
            }
            span{
                font-size: 1.5rem;
                font-weight: 600;
                margin: 0;
                color: #5E6282;
                @media screen and (max-width: 1000px){
                    font-size: 1.25rem;
                }
                @media screen and (max-width: 576px){
                    font-size: 1rem;
                }
            }
        }
    }
}