
.footer_logo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    display: relative;
    .side_light{
        position: absolute;
        width: 40%;
        left: 0;
        z-index: 0;
    }
    img{
        height: 200px;
        z-index: 1;
        @media screen and (max-width: 1000px){
            height: 180px;   
            
        }
        @media screen and (max-width: 750px){
            height: 150px;   
        }
        @media screen and (max-width: 420px){
            height: 110px;   
            
        }
    }
}
.footer_container{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    row-gap: 2rem;
    @media screen and (max-width: 1620px){
        grid-template-columns: auto auto auto auto;
        
    }
    @media screen and (max-width: 1000px){
        grid-template-columns: auto auto auto;
        
    }
    @media screen and (max-width: 820px){
        grid-template-columns: auto auto;
        
    }
    @media screen and (max-width: 520px){
        grid-template-columns: auto;
        
    }
    color: var(--primary-text);
    padding: 1rem;
    .footer_item{
        display: grid;
        grid-template-columns: 1fr;
        height: fit-content;
        gap: 1rem;
        h2{
            font-size: 1.5rem;
            margin-bottom: 10px;
            font-weight: 600;
        }
        .contact_item{
            display: flex;
            gap: 0.5rem;
            align-items: center;
            cursor: pointer;
            p{
                font-size: 1rem;
            }
        }

        .link{
            color: var(--primary-text);
            text-decoration: none;
            font-size: 1rem;
            font-weight: 500;
            transition: all 0.3s ease;
            &:hover{
                color: #51BAFE;
            }
        }
        .active{
            color: #51BAFE;
        }
        .area_input{
            display: flex;
            // background-color: white;
            // padding: 0.5rem 1rem;
            border-radius: 10px;
            width: -moz-fit-content;
            width: fit-content;
            button{
                padding: 10px 20px;
                border: none;
                background-color: #2761D0;
                color: white;
                font-size: 0.6rem;
                font-weight: 500;
                border-radius: 15px;
                cursor: pointer;
                transition: all 0.3s ease;
                &:hover{
                    // background-color: #51BAFE;
                    color: white;
                }
            }
            input{
                border: none;
                outline: none;
                width: 50%;
                padding: 0.5rem;
                border-radius: 5px;
                margin-right: 0.5rem;
                color: black;
            }
        }
    }
}

.footer_image{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}