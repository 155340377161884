.quiz-header {
    .question {
      position: relative;
      margin: 0;
      line-height: 25px;
      width: 100%;
      outline: none;
  
      &:empty::before {
        content: "Savolingizni bu yerga yozing";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        pointer-events: none;
        color: #999;
      }
    }
  }
  
  .quiz-footer {
    display: flex;
    justify-content: flex-end;
    border-top: 0.5px solid #92929238;
    padding-top: 0.3rem;
  }
  
  .quiz-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
    border-radius: 3px;
  }
  
  .quiz-item__hoverable:hover {
    background-color: rgb(245 245 245);
    cursor: pointer;
  }
  
  .quiz-item__text {
    margin: 0;
    line-height: 25px;
    width: 100%;
    outline: none;
  }
  
  .icon-button {
    all: unset;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100rem;
  
    &.disabled {
      color: #bbbbbb;
    }
  }
  
  .icon-button:hover {
    background-color: rgb(236, 236, 236);
  }
  
  .icon-button__small {
    --size: 1.6rem;
    width: var(--size);
    height: var(--size);
  }
  
  .icon-button__medium {
    --size: 2rem;
    width: var(--size);
    height: var(--size);
    font-size: 1.4rem;
  }
  
  .qt-button {
    all: unset;
    border: 1px solid gray;
    padding: 0.4rem 0.7rem;
    border-radius: 0.3rem;
    cursor: pointer;
  }
  
  .cdx-quiz-error {
    color: #dddd00;
    padding: 0.5rem 0;
  }
  
  .qt-add-btn {
    flex: 1;
    text-align: center;
    border: unset;
  }
  
  .qt-add-btn:hover {
    background-color: #e0e0e0;
  }
  
  /* Custom Styles for radio/checkbox inputs */
  
  .quiz-item {
    &:hover input ~ &__checkmark {
      background-color: #ccc;
    }
  
    & input:checked ~ &__checkmark {
      background-color: #2196f3;
    }
  
    & input:checked ~ &__checkmark::after {
      display: block;
    }
  
    &__label {
      position: relative;
      width: 18px;
      height: 18px;
    }
  
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  
    &__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #eee;
      cursor: pointer;
  
      &--radio {
        border-radius: 50%;
      }
  
      &--radio::after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: white;
      }
  
      &--checkbox::after {
        left: 50%;
        top: 50%;
        translate: -50% -60%;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
  
      &::after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }

  //loader

  .cdx-quiz-lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
  }
  
  .cdx-quiz-lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .cdx-quiz-lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .cdx-quiz-lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .cdx-quiz-lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }