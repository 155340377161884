.review_card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        
    }
    gap: 1rem;
    border: 1px solid #E5E5E5;
    padding: 1rem;
    border-radius: 20px;
    width: 100%;
    
    .review_text {
        .header {
            margin-top: 0rem;
            // display: flex;
            // gap: 1rem;
            // justify-content: flex-start;
            // align-items: center;
            display: grid;
            grid-template-columns: auto 1fr;
            @media screen and (max-width: 1000px) {
                gap: 0.5rem;
            }
            .avatar {
                width: 50px;
                height: 50px;
                @media screen and (max-width: 1000px) {
                    width: 40px;
                    height: 40px;
                }
           
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .info{
                display: flex;
                flex-direction: column;
                justify-content: center;
                h3 {
                    font-size: 1.5rem;
                    text-align: start;
                    @media screen and (max-width: 1000px) {
                        font-size: 1.2rem;
                    }
                    @media screen and (max-width: 500px){
                        font-size: 0.8rem;
                    }
                    font-weight: 600;
                }
            }
            p {
                font-size: 1rem;
                @media screen and (max-width: 1000px) {
                    font-size: 0.8rem;
                }
                @media screen and (max-width: 500px){
                    font-size: 0.6rem;
                }
                color: #A0A0A0;
                grid-column: span 2;
            }
        }

        .company_image {
            width: -moz-fit-content;
            width: fit-content;
            height: 50px;
            overflow: hidden;
            border-radius: 20px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

        }
    }

    .review_image {
        width: 100%;
        height: 200px;
        @media screen and (max-width: 1000px) {
            height: 150px;
        }
        @media screen and (max-width: 500px){
            height: 100px;
        }
        overflow: hidden;
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        video {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

}

.written_review_container {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    border: 1px solid #CBD3D9;
    padding: 2rem;
    border-radius: 20px;
    width: 100%;

    .review_header {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1rem;
        align-items: center;
        .profile {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h3 {
                font-size: 1.5rem;
                @media screen and (max-width: 1000px) {
                    font-size: 1.2rem;
                }
                @media screen and (max-width: 500px){
                    font-size: 1rem;
                }
                font-weight: 600;
                text-align: left;
            }

            p {
                font-size: 1rem;
                @media screen and (max-width: 1000px) {
                    font-size: 0.8rem;
                    
                }
                color: #A0A0A0;
            }
        }
    }

    .body {
        margin-top: 1rem;
        width: 100%;

        p {
            font-size: 1.2rem;
            color: #000000;
            text-align: center;
            @media screen and (max-width: 500px) {
                font-size: 0.75rem;
            }
        }
    }

    .rating {
        display: flex;
        justify-content: center;
        align-items: center;

    }
}
.expanded_image{
    width: 80vw;
    height: 80vh;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.speed_test_review {
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid #C4C4C4;
    width: 100%;
    padding: 2rem;
    @media screen and (max-width: 1000px){
        padding: 1rem;
    }
    border-radius: 10px;

    .top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;
            place-items: start;
        }
        place-items: center;

        .speed_test_header {
            // display: flex;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 1rem;
            @media screen and (max-width: 1000px) {
                gap:0.5rem
            }
            .profile {
                width: 50px;
                height: 50px;
                justify-self: start;
                @media screen and (max-width: 1000px) {
                    width: 40px;
                    height: 40px;
                    
                }
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                h3 {
                    font-size: 1.5rem;
                    @media screen and (max-width: 1000px) {
                        font-size: 1.2rem;
                    }
                    font-weight: 600;
                }

                p {
                    font-size: 1rem;
                    @media screen and (max-width: 1000px) {
                        font-size: 0.8rem;
                        
                    }
                    color: #A0A0A0;
                }
            }
        }
    }

    .before_after_images{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 0.5rem;
        gap: 0rem;
        // @media screen and (max-width: 1000px) {
        //     gap: 0.5rem;
            
        // }
        
        .con{

            display: grid;
            grid-template-columns: 1fr;
            place-items: center;
            .before{
                width: 100%;
                height: 200px;
                @media screen and (max-width: 1000px) {
                    height: 150px;                    
                }
                @media screen and (max-width: 500px){
                    height: 100px;
                }
                overflow: hidden;
                // border-radius: 10px;
                // border: 1px solid #E5E5E5;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
      
            .after{
                width: 100%;
                height: 200px;
                @media screen and (max-width: 1000px) {
                    height: 150px;                    
                }
                @media screen and (max-width: 500px){
                    height: 100px;
                }
                overflow: hidden;
                // border-radius: 10px;
                // border: 1px solid #E5E5E5;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            p{
                font-size: 1.2rem;
                color: #000000;
                text-align: center;
            }
        }
    }
}