 
.SearchTableLayout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 100%;
 
    .SearchCard{
        // width: 90vw;
        width: 100%;
        background-color: white;
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        .SearchDiv{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            .SearchTitle{   
                font-size: 1.5em;
                font-weight: bold;
                margin-bottom: 10px;
                justify-content: left;
                width: 100%;
                margin-bottom: 1.5em;
                // color: #000;
                color: var(--color-primary);
            }
            .SearchInputs{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 1em;
                width: 100%;
                
                @media (max-width: 1000px) {
                    grid-template-columns: 1fr;
                    grid-gap: 1em;
                }
               
                .SearchInput{
                    width: 100%;
                    padding: 1em;
                    border: 1px solid #000;
                    border-radius: 5px;
                    outline: none;
                    //make a light blue shadow when focused
                    &:focus{
                        box-shadow: 0 0 6px #00ccff;
                        border: 1px solid #00ccff;
                    }
                }
                .search_input{
                    grid-column: span 2;
                    @media screen and (max-width: 1000px) {
                        grid-column: span 1;
                    }
                }
                .date_div{
                    display: flex;
                    align-items: center;
                    border: 1px solid #000;
                    border-radius: 5px;
                    padding: 1em;
                    label{
                        width: 100%;
                        padding: 0px;
                    }
                    input[type='date'],input[type='time']{
                        padding: 0em;
                        border: none;
                        outline: none;
                    }
                }
                .expand_icon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 1em;
                    grid-column: span 2;
                    @media screen and (max-width: 1000px) {
                        grid-column: span 1;
                    }
                    .ExpandIcon{
                        cursor: pointer;
                    }
                }
                .checkbox{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 1em;
                    border: 1px solid #000;
                    border-radius: 5px;
                    //make a light blue shadow when focused
                    gap: 1em;
                    &:focus{
                        box-shadow: 0 0 6px #00ccff;
                        border: 1px solid #00ccff;
                        
                    }
                    input{
                        width: 20px;
                        height: 20px;
                    }
                }
                .css-13cymwt-control,
                .css-t3ipsp-control {
                    height: 100%;
                    padding: 1em;
                    border: 1px solid #000;
                    border-radius: 5px;
                    
                }
                
                select{
                    width: 100%;
                    padding: 1em;
                    border: 1px solid #000;
                    border-radius: 5px;
                    //make a light blue shadow when focused
                    &:focus{
                        box-shadow: 0 0 6px #00ccff;
                        border: 1px solid #00ccff;
                        
                    }
                }
                
            }
            .SearchButton{
                cursor: pointer;
                padding: 1em;
                border: 1px solid #000;
                border-radius: 5px;
                background-color: var(--color-primary);;
                color: var(--color-foreground);
                font-weight: bold;
                width: 100%;
                text-align: center;
                margin-top: 1em;

                &:hover{
                    background-color: var(--color-primary);
                    color: var(--color-foreground);
                }
            }
        }
    }
    .TableDiv{
        width: 100%; 
        // @media (max-width: 1000px) {
        //     overflow-x: scroll;
        //     width: 80%;
        // }
 
         .pagination{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 1em;
                margin-bottom: 1em;
                width: 100%;
               
         }
        
    }
}

.Table4 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;

    th, td{
        border: 1px solid #ffcc00;
        padding: 1em;
        text-align: center;
    }

    th{
        background-color: #ffcc00;
        color: var(--color-primary);
    }

    tr:nth-child(even){
        background-color: #fff2cc;
    }
}

.Table5 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;

    th, td{
        border: 1px solid #00ccff;
        padding: 1em;
        text-align: center;
    }

    th{
        background-color: #00ccff;
        color: var(--color-primary);
    }

    tr:nth-child(even){
        background-color: #e6f9ff;
    }
}

.Table2 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;

    th, td{
        border: 1px solid #ccc;
        padding: 1em;
        text-align: center;
    }

    th{
        background-color: #ccc;
        color: var(--color-primary);
    }

    tr:nth-child(even){
        background-color: #f9f9f9;
    }
}

.Table3 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;

    th, td{
        border: 1px solid #eaeaea;
        padding: 1em;
        text-align: center;
    }

    th{
        background-color: #eaeaea;
        color: var(--color-primary);
    }

    tr:nth-child(even){
        background-color: #f5f5f5;
    }
}


.Table6 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;

    th, td{
        border: 1px solid #6c757d;
        padding: 1em;
        text-align: center;
    }

    th{
        background-color: #6c757d;
        color: var(--color-foreground);
    }

    tr:nth-child(even){
        background-color: #e9ecef;
    }
}

.Table7 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;

    th, td{
        border: 1px solid #4d5a6b;
        padding: 1em;
        text-align: center;
    }

    th{
        background-color: #4d5a6b;
        color: var(--color-foreground);
    }

    tr:nth-child(even){
        background-color: #cfd8e3;
    }
}

.Table8 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;

    th, td{
        border: 1px solid #2e3a4d;
        padding: 1em;
        text-align: center;
    }

    th{
        background-color: var(--color-primary);
        color: var(--color-foreground);
    }

    tr:nth-child(even){
        background-color: #aeb9c9;
    }
}

.Table9 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;

    th, td{
        border: 1px solid #1b2636;
        padding: 1em;
        text-align: center;
    }

    th{
        background-color: var(--color-primary);
        color: var(--color-foreground);
    }

    tr:nth-child(even){
        background-color: #7f8e9f;
        color:  var(--color-foreground);
    }
}

.Table10 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;

    th, td{
        border: 1px solid #1b2636;
        padding: 1em;
        text-align: center;
    }

    th{
        background-color: var(--color-primary);
        color:  var(--color-foreground);
    }

    tr:nth-child(even){
        background-color: #4b5f7c;
        color:  var(--color-foreground);
    }
}


.Table11 {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;

    th, td{
        border: 1px solid #1b2636;
        padding: 1em;
        text-align: center;
        font-weight: bold;
    }

    th{
        background-color: #1b2636;
        color:  var(--color-foreground);
    }

    tr:nth-child(even){
        background-color: #cfd8e3;
    }
    tr:nth-child(odd){
        color: var(--color-icon);
    }


}

