.MobileLayoutContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    height: 100vh;

    .MobileLayoutHeader {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        // background-image: linear-gradient(-76.7deg, #022780ff 0%, #000614ff 100%);
        padding: 10px 1rem;

        height: fit-content;

        h1 {
            color: var(--color-icon);
            margin: 0;
            font-size: 1.3rem;
            align-self: center;
            justify-self: center;
            text-align: center;
        }

        .MobileLayoutProfile {
            justify-self: start;
        }
    }

    .MobileLayoutBody {
        display: grid;
        grid-template-columns: 1fr;
        overflow: auto;
        // background-image: linear-gradient(-76.7deg, #022780ff 0%, #000614ff 100%);
    }

    .MobileLayoutNavbar {
        display: grid;
        grid-template-columns: 1fr;
        // background-image: linear-gradient(-76.7deg, #022780ff 0%, #000614ff 100%);
        padding: 20px 10px;
        color: white;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        // position: fixed;
        // bottom: -1px;
        // width: 99vw;
        // left: 50%;
        // transform: translateX(-50%);

        .menuItemsContainer {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
    }
}

.dark {
    .MobileLayoutHeader {
        background-image: linear-gradient(-76.7deg, #022780ff 0%, #000614ff 100%);
    }

    .MobileLayoutBody {
        background-image: linear-gradient(-76.7deg, #022780ff 0%, #000614ff 100%);
    }

    .MobileLayoutNavbar {
        background-image: linear-gradient(-76.7deg, #022780ff 0%, #000614ff 100%);
    }
}

.default {
    .MobileLayoutHeader {
        background-color: var(--color-background);
    }

    .MobileLayoutBody {
        background-color: var(--color-background);
    }

    .MobileLayoutNavbar {
        background-color: var(--color-background);
    }
}

.iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-sidebar);
    cursor: pointer;
    // box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    &:active{
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    }
}

.MobileLayoutHeader{
    // .notification_panel ::before{
    //     left: 2% !important;
    //     right: auto !important;
    // }

}

.notification_icon{
    position: relative;
    .number{
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: #c00;
        color: white;
        border-radius: 50%;
        padding: 0.2rem;
        font-size: 0.7rem;
    }
}
.notification_panel{
    position: absolute;
    top: 60px;
    background-color: white;
    border-radius: 30px;
    // min-height: 20vh;
    max-width: 25rem;

    box-shadow: 0px 0px 8px 0px rgba(117, 116, 116, 0.75);
    margin-top: 1rem;
    padding: .2rem 1rem;

    right: 5%;
    @media screen and (max-width: 500px) {
        width: 20rem;
       
    }
    z-index: 10000;
    // &::before{
    //     content: '';
    //     position: absolute;
    //     top: -10px;
    //     right: 2%;
    //     // @media screen and (max-width: 1000px) {
    //     //     left: 2%;
            
    //     // }
    //     // transform: translateX(50%);
    //     width: 0;
    //     height: 0;
    //     // box-shadow: 0px 0px 8px 0px rgba(117, 116, 116, 0.75);
    //     border-left: 10px solid transparent;
    //     border-right: 10px solid transparent;
    //     border-bottom: 10px solid white;
    // }

    .notification_panel_inner{
        max-height: 60vh;
        overflow-y: auto;
        .notification{
            display: grid;
            grid-template-columns: auto 1fr auto;
            // align-items: start;
            text-align: left;
            
            
            width: 100%;
            gap: 0.5rem;
            padding: 0.5rem 1rem;
           
            border-radius: 20px;
            margin: 8px 0;
            &:last-child{
                border-bottom: none;
            }
            p{
                margin: 0;
                font-size: .5rem;
                color: #000;
            }
            .date{
                font-size: 10px;
                font-weight: 600;
                color: #6c757d;
                grid-column: span 3;
            }
        }
    }
}