.rep-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .rep-card {
    background-color: #ffffff;
    border-radius: 0.75rem;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    &.expanded {
      background-color: #f1f3f5;
    }

    .rep-header {
      display: flex;
      align-items: center;
      padding: 1rem;
      background-color: #3498db;
      color: #ffffff;
      cursor: pointer;

      h2 {
        margin-left: 1rem;
        font-size: 1.25rem;
        font-weight: 600;
      }

      .rep-profile-image {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        object-fit: cover;
        border: 2px solid #ffffff;
      }
      .rep-switch {
        margin-left: auto;
      }
    }

    .rep-content {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .slider-container {
        display: flex;
        align-items: center;
        gap: 1rem;

        input[type="range"] {
          flex-grow: 1;
          min-width: 75%;
          max-width: 85%;
        }

        button {
          color: #000;
        }
      }

      .color-picker-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        margin-top: 0.5rem;

        .zoom-buttons {
          display: flex;
          gap: 0.5rem;
        }

        .color-set {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .panning-switch {
          
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .color-input {
          height: 2.5rem;
          width: 2.5rem;
          border: none;
          padding: 0;
          cursor: pointer;
          border-radius: 50%;
          overflow: hidden;
        }
        button {
          color: #000;
        }
      }

      .toggle-switches {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.9rem;
          color: #4a5568;
        }
      }

      .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
      }

      .mySwiper {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;

        .swiper-pagination {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;

          .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            background: #000;
            opacity: 0.2;
            border-radius: 50%;
            transition: all 0.3s ease;

            &-active {
              opacity: 1;
              background: #3498db;
            }
          }
        }
      }

      .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 300px;
      }

      .activity-card {
        background-color: #ffffff;
        border-radius: 0.5rem;
        padding: 1rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: #f8f9fa;
        }
        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }
      
        .icon {
          width: 32px;
          height: 32px;
        }
      
        .arrow-icon {
          width: 24px;
          height: 24px;
        }

        h3 {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 0.875rem;
          color: #4a5568;
          margin-bottom: 0.25rem;
          text-align: center;
        }

        .go-to-activity {
          text-decoration: underline;
          color: #2761D0;
          margin-top: 1rem;
          cursor: pointer;
        }
      }

      .no-activities {
        text-align: center;
        color: #4a5568;
        font-style: italic;
      }
    }
  }
}

@media (max-width: 600px) {
  .rep-list {
    .rep-card {
      .rep-content {
        padding: 1rem;
        gap: 1rem;
    
        .slider-container {
          flex-direction: column;
          gap: 0.5rem;
    
          input[type="range"] {
            min-width: 100%;
            max-width: 100%;
          }
        }
    
        .color-picker-container {
          flex-direction: column;
          gap: 0.5rem;
    
          .zoom-buttons, .color-set, .panning-switch {
            width: 100%;
            justify-content: center;
          }
    
          .color-input {
            height: 2rem;
            width: 2rem;
          }
        }
    
        .toggle-switches {
          gap: 0.75rem;
    
          div {
            font-size: 0.8rem;
          }
        }
    
        .mySwiper {
          .swiper-slide {
            width: 100%;
          }
        }
    
        .activity-card {
          padding: 0.75rem;
    
          .icon-container {
            gap: 8px;
          }
    
          .icon {
            width: 24px;
            height: 24px;
          }
    
          .arrow-icon {
            width: 20px;
            height: 20px;
          }
    
          h3 {
            font-size: 0.9rem;
          }
    
          p {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}