.sales-report-container {
  width: 100%;
  display: grid;
  flex-direction: 1fr;
  gap: 2rem;
  padding: 2rem 2rem;

  @media screen and (max-width: 1000px) {
    padding: 1rem 1rem;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    // grid-template-columns: 1fr 1fr;
    gap: 1rem;
    background-color: white;
    padding: 1rem 2rem;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.45);

    @media screen and (max-width: 1000px) {
      padding: 0.5rem;
    }

    @media screen and (max-width: 430px) {
      grid-template-columns: 1fr;
      gap: 0.5rem;
    }
    .async_select__control {
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 0.2rem;
    }
    .selector_con {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      label {
        font-weight: bold;
        font-size: 1.2rem;
        @media screen and (max-width: 1000px) {
          font-size: 1rem;
        }
      }
      .date {
        height: 2.5rem;
      }
      select {
        color: #002a8d;
        // padding: 0.5rem;
      }
      .single {
        @media screen and (max-width: 600px) {
          grid-column: span 2;
        }
      }
    }

    .toggle-switch {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      label {
        font-size: 1.2rem;
        margin-right: 1rem;
        font-weight: bold;
      }

      input {
        width: 50px;
        height: 25px;
        -webkit-appearance: none;
        appearance: none;
        background-color: #c6c6c6;
        outline: none;
        border-radius: 50px;
        color: #002a8d;
        position: relative;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:checked {
          background-color: #002a8d;
        }

        &::before {
          content: "";
          position: absolute;
          width: 23px;
          height: 23px;
          background-color: white;
          border-radius: 50%;
          top: 1px;
          left: 1px;
          transition: transform 0.3s ease;
        }

        &:checked::before {
          transform: translateX(25px);
        }
      }
    }

    button {
      background-color: #002a8d;
    }

    .date_range {
      input {
        color: #002a8d;
        background-color: rgb(193 193 193);
      }
    }
  }

  .report-summary {
    display: flex;
    flex-direction: column;
    align-items: center;

    .summary-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
    }

    .summary-card {
      background-color: #fff;
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5rem;
      border-radius: 10px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      padding: 2rem 1rem;
      text-align: center;
      width: 170px;
      @media screen and (max-width: 394px) {
        width: 100%;
      }
      p {
        font-size: 0.8rem;
        color: #002a8d;
        cursor: pointer;
        text-decoration: underline;
      }
      .card-value {
        font-size: 2rem;
        font-weight: bold;
      }

      .card-label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        @media screen and (max-width: 500px) {
          font-size: 0.8rem;
        }
        font-weight: bold;
        color: #666;
        text-align: left;
        gap: 0.5rem;
        .img {
          width: 40px;
          height: 40px;
          margin-right: 3px;
        }
      }
    }
  }
  .btn {
    background-color: #002a8d;
    color: white;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 1rem;
    border-radius: 20px;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }

    &:hover {
      background-color: #002a8d;
    }
  }
}
