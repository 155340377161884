.signup_container{
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    h1{
        color: var(--primary-text);
        font-size: 4rem;
        @media screen and (max-width: 1000px){
            font-size: 3rem;   
        }
        @media screen and (max-width: 750px){
            font-size: 2rem;   
            
        }
        font-weight: 500;
        margin-bottom: 1rem;
        text-align: center;
        z-index: 1;
        position: relative;
    }
    h3{
        color: #51BAFE;
        font-size: 2rem;
        @media screen and (max-width: 1000px){
            font-size: 1.5rem;   
        }
        @media screen and (max-width: 750px){
            font-size: 1.2rem;
        }
        font-weight: 500;
        margin-bottom: 1rem;
        text-align: center;
        z-index: 1;
        position: relative;
    }
    .signup_light{
        position: absolute;
        top: -25%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
    }
    .signup_form{
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        place-items: center;
        background-color: white;
        width: -moz-fit-content;
        width: fit-content;
        padding: 2rem;
        border-radius: 15px;
        margin: auto;
        position: relative;
        z-index: 1;
        border: 4px solid var(--border-color);
        .tabs{
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 1rem;
            @media screen and (max-width: 950px){
                // grid-template-columns: 1fr;
            }
            gap: 1rem;
            .active{
                background-color: #2761D0;
                color: white;
                border-radius: 10px;
                padding: 0.5rem;
                text-align: center;
                cursor: pointer;
                transition: all 0.3s ease;
              
            }

            button{
                background-color: transparent;
                color: #2761D0;
                border: 1px solid #2761D0;
                padding: 0.5rem;
                border-radius: 10px;
                text-align: center;
                cursor: pointer;
                transition: all 0.3s ease;
                @media screen and (max-width: 950px){
                    font-size: 0.6rem;
                }
                &:hover{
                    background-color: #2761D0;
                    color: white;
                }
            }
        }
        h1{
            color: black;
            font-size: 2rem;
            @media screen and (max-width: 1000px){
                font-size: 1.5rem;   
                
            }
            @media screen and (max-width: 750px){
                font-size: 1.2rem;
                
            }
            font-weight: 500;
            margin-bottom: 1rem;
        }

        .userType{
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            label{
                color: black;
                font-size: 1rem;
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 0.5rem;
         
                font-weight: 500;
            }
            input{
                width: 1.5rem;
                height: 1.5rem;
            }
            input[type='radio'] {
                accent-color: green;
            }
        }
        input{
            padding: 0.5rem;
            border: none;
            border-radius: 10px;
            width: 100%;
            margin-bottom: 1rem;
            font-size: 1rem;
            font-weight: 500;
            outline: none;
            border: 1px solid #C4C4C4;
            text-align: center;
        }

        button{
            padding: 0.5rem;
            border: none;
            border-radius: 10px;
            width: 100%;
            margin-bottom: 1rem;
            font-size: 1rem;
            font-weight: 500;
            outline: none;
            background-color: #2761D0;
            color: white;
            cursor: pointer;
            transition: 0.3s;
        }
    }
}