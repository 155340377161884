/* If using separate CSS */
.fcc-legend {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    z-index: 1000;
    border: 1px solid #ccc;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }
  
  .legend-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 8px;
    border: 1px solid #666;
  }
  
  .legend-label {
    font-size: 0.9em;
  }