@tailwind base;
@tailwind components;
@tailwind utilities;

/* create variables for colors, fonts, and spacing */

:root{

    --old-primary-color: #2761D0;
    --primary-color: #0038FF;
    
    --text-color: #000000;
    --bg-light: #FFFFFF;
    --bg-grey: #f6f6f6;
    --icon-color: #022780;
    --border-color: #9595C2;
    --grey-text: #C1C1C5;
    --black-color:#000000;
    --light-border: #E4E4E4;
    --action-bg : #ECECED;

    

}
