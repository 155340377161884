.ManageCustomersDiv {
    width: 100% !important;

    @media screen and (max-width: 1000px) {
        min-width: 98vw !important;
        width: auto !important;
    }   

    .date_range{
        .quick_date{
            width: 100%;
            padding: 1em;
            border: 1px solid black;
            border-radius: 5px;
            input{
                padding: 0;
                border: none;
                outline: none;
                color: black;
                background-color: rgb(193 193 193);
            }
        }
    }

    .button_div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        @media screen and (max-width: 1000px) {
            justify-content: center;
        }
        gap: 1rem;
        margin: 1rem 0;
        width: 100%;
        button{
            padding: 0.5rem 1rem;
            border-radius: 10px;
            background-color: var(--color-primary);
            color: white;
            border: none;
        }
    }
}

.manageCustomersContainer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
    align-items: center;
    justify-content: center;


  

    .manageCustomerHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        border-radius: 10px;
        background: var(--color-foreground);
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        padding: 2rem;

        .manageCustomerTitle {
            font-size: 20px;
            font-weight: 600;
        }

        div {
            display: grid;
            grid-template-columns: 1fr 1fr;

            @media screen and (max-width: 600px) {
                grid-template-columns: 1fr;

            }

            gap: 1rem;
            width: 100%;

            input {
                width: 100%;
                padding: 10px 20px;
                border-radius: 5px;
                border: 1px solid #00000040;
                outline: none;
                font-size: 14px;
                font-weight: 600;
                color: var(--color-primary);

                &::placeholder {
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--color-primary);
                }
            }
        }

        button {
            background-color: var(--color-foreground);
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            color: var(--color-primary);
            padding: 10px 20px;
            border-radius: 5px;
            box-shadow: 0px 0px 5px 0px #00000040;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: var(--color-primary);
                color: var(--color-foreground);
            }

        }
    }

    .spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}