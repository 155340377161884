.digital_tool_belt_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min(90vw,600px);
    // overflow-y: auto;
    max-height: 90vh;
    // overflow:auto;
    background-color: white;
    padding: 2rem;
    @media screen and (max-width: 500px){
        padding: 1rem;
        
    }
    border-radius: 15px;
    outline: none;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    // box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    h1{
        text-align: center;
        font-weight: bold;
        font-size: 1.4rem;
        text-transform: uppercase;
    }
    .icons_list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon{
            width: 2rem;
            height: 2rem;
        }
    }


    .form{
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        overflow-y: auto;
        max-height: 60vh;
        padding-right: 1rem;
        scrollbar-width: thin;
        h2{
            // font-size: revert;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
        }

        .reason_div{
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 0.5rem;
            // align-items: first baseline;
            input{
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
                width: 1.3rem;
                
                // height: 1rem;
            }

        }
        .note{
            color: #84A1C8;
            font-size: 0.8rem;
            text-transform: uppercase;
            margin-left: 0.5rem;
        }
        .create_account_form{
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;
            place-items: center;
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
            padding: 1rem;
            border-radius: 10px;
            margin: 0.5rem;
            .inputs{
                display: grid;
                grid-template-columns: 1fr;
                gap: 0.5rem;
                .input{
                    outline: none;
                    border: 1px solid #C4C4C4;
                    border-radius: 10px;
                    padding: 0.5rem;
                    width: 100%;
                }

                button{
                    padding: 0.5rem;
                    border: none;
                    border-radius: 10px;
                    background-color: #2761D0;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                    outline: none;
                    transition: all 0.3s;
                    &:hover{
                        background-color: #2761D0;
                    }
                }
            }
        }

        .review_textarea{
            color: #000;
            border: 1px solid #C4C4C4;
            padding: 1rem;
            border-radius: 10px;
        }

        .send_review_options{
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            padding: 1rem;
            p{
                color: #84A1C8;
                font-weight: bold;
                text-transform: uppercase;
            }
            .send_options{
                display: grid;
                grid-template-columns: repeat(4,1fr);
                margin-top: 0.5rem;
                place-items: center;
                .icon{
                    &:hover{
                        cursor: pointer;
                        transform: scale(1.1);
                    }
                }
            }
        }

        .customer_info{
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            padding: 1rem;
            h3{
                color: #000;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 1.2rem;
            }
            p{
                color: #84A1C8;
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        .sub_question{
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;
            width: 100%;    
            button{
                background-color: #2761D0;
                color: white;
                border: none;
                border-radius: 5px;
                padding: 0.5rem 1rem;
                width: -moz-fit-content;
                width: fit-content;
                cursor: pointer;
                outline: none;
                font-weight: normal;
            }
        }
        textarea{
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            font-size: revert;
            background-color: white;
        }

        .input_div{
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.2rem;
            label{
                text-transform: uppercase;
                font-weight: 500;
            }

            .time_slot{
                display: grid;
                grid-template-columns: 1fr auto 1fr;
                gap: 0.5rem;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #ccc;
                border-radius: 5px;
                input{

                    border: none;
                }
            }
            .am_pm
            {
                display: flex;
                align-items: center;
                justify-content: space-between;
       
          
                gap: 0.5rem;
                p{
                    text-transform: uppercase;
                    font-weight: 500;
                    width: 100%;
                    border: 3px solid #ccc;
                    border-radius: 5px;
                    text-align: center;
                    color: #84A1C8;
                    padding: 0.5rem;
                    cursor: pointer;
                    @media screen and (max-width: 500px){
                        // font-size: 0.8rem;
                        
                    }
                }
                .active{
                    color: #2761D0;
                    border: 3px solid #2761D0;
                    border-radius: 5px;
                }
            }
            input,select{
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
                background-color: white;
                font-size: revert;
            } 
            input{
                width: 100%;
            }

            .priority
            {
                display: flex;
                align-items: center;
                justify-content: space-between;
                p{
                    text-transform: uppercase;
                    font-weight: 500;
                    width: 100%;
                    text-align: center;
                    color: #84A1C8;
                    padding: 0.5rem;
                    cursor: pointer;
                    @media screen and (max-width: 500px){
                        font-size: 0.8rem;
                        
                    }
                }
                .active{
                    color: #2761D0;
                    border: 3px solid #2761D0;
                    border-radius: 5px;
                }
            }
        }

        .consent{
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 0.5rem;
            
            input{
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
                width: 1.5rem;
                font-size: revert;
                // height: 1rem;
            }
            p{
                font-size: 0.7rem;
            } 
        }

        button{
            padding: 0.5rem;
            border: none;
            border-radius: 5px;
            background-color: #2761D0;
            color: white;
            font-weight: bold;
            cursor: pointer;
            outline: none;
            transition: all 0.3s;
            &:hover{
                background-color: #2761D0;
            }
        }
    }

}