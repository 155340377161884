.job_application_form {

    background-color: white;
    width: min(90vw, 600px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    @media screen and (max-width: 500px) {
        padding: 1rem;
    }
    border-radius: 15px;
    .inner {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        height: 60vh;
        overflow-y: auto;
        padding: 1rem;
        @media screen and (max-width: 500px) {
            padding: 0.5rem;
        }
        h3{
            font-size: 1.2rem;
            font-weight: 500;
            color: black;
            text-align: center;
            text-decoration: underline;
        }
    }

    h1 {
        font-size: 2rem;
        text-transform: uppercase;
        @media screen and (max-width: 1000px) {
            font-size: 2rem;
        }

        font-weight: 700;
        color: black;
        text-align: center;
        // margin-top: 2rem;
    }

    .input {
        padding:  0.5rem 1rem;
        border-radius: 10px;
        border: 1px solid #EAEAEA;
        // font-size: 1.2rem;
        width: 100%;
        outline: none;
    }

    textarea {
        padding: 0.5rem 1rem;
        border-radius: 15px;
        border: 1px solid #EAEAEA;
        // font-size: 1.2rem;
        width: 100%;
        outline: none;
        height: max-content;
    }

    .how {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;

        @media screen and (max-width: 500px) {
            gap: 0.5rem;
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 768px) {
            gap: 0.5rem;

        }

        p {
            grid-column: span 3;
            @media screen and (max-width: 500px) {
                grid-column: span 2;
            }
        }

        div {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 1rem;
            align-items: center;

            @media screen and (max-width: 768px) {
                gap: 0.5rem;
            }

            input {
                width: 20px;
                height: 20px;
            }
        }
    }

    button {
        background-color: #2761D0;
        color: white;
        padding:  0.5rem 1rem;
        border-radius: 15px;
        font-size: 1.2rem;
        width: 100%;
        outline: none;
    }
}

.opportunity_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    place-items: center;
    padding: 0rem 4rem;

    @media screen and (max-width: 1000px) {
        padding: 0rem 2rem;

    }

    h1 {
        font-size: 3rem;

        @media screen and (max-width: 1000px) {
            font-size: 2rem;

        }

        font-weight: 700;
        color: var(--primary-text);
        text-align: center;
        margin-top: 2rem;
    }

    .banner {
        padding: 0rem;
        width: 100%;

        img {
            width: 100%;
        }
    }

    .mission_box {
        padding: 0rem;
        display: grid;
        grid-template-columns: 1fr;

        .top_buttons {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            padding: 0rem 2rem;

            button {
                padding: 1rem 0rem;
                color: #fff;
                font-size: 1.5rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 530px) {
                    font-size: 0.8rem;
                }

                @media screen and (max-width: 475px) {
                    font-size: 0.6rem;

                }
            }

            .active {
                background-color: #2761D0;
                color: white;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
            }
        }

        .mission_box_body {
            background-color: white;
            padding: 4rem 2rem;
            border: 6px solid #2761D0;
            border-radius: 30px;

            h2 {
                font-size: 2rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1.5rem;

                }

                font-weight: 700;
                color: black;
                text-align: center;

            }

            p {
                font-size: 1.2rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }

                color: black;
                text-align: center;
            }
        }
    }

    .container_for_reviews {
        width: 100% !important;
        min-width: 90vw !important;
        padding-top: 0rem !important;
    }

    .expander_button {
        background-color: #2761D0;
        color: white;
        border-radius: 15px;
        padding: 1rem 2rem;
        font-size: 1.5rem;

        @media screen and (max-width: 1000px) {
            font-size: 1rem;
        }

        width: 100%;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .expanded_tab {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        // h1{
        //     font-size: 2rem;
        //     @media screen and (max-width: 1000px) {
        //         font-size: 1.5rem;
        //     }
        //     font-weight: 700;
        //     color: var(--primary-text);
        //     text-align: center;
        // }


        h3 {
            font-size: 1.5rem;

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }

            font-weight: 700;
            color: var(--primary-text);
            text-align: left;
        }

        p {
            font-size: 1.2rem;

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }

            color: var(--primary-text);
            text-align: left;
        }

        .requirements {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;

            .requirement_item {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 1rem;
                align-items: center;
            }
        }

        .location_item {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 1rem;
            align-items: center;
        }

        .button {
            background-color: #2761D0;
            color: white;
            padding: 1rem 2rem;
            width: 70%;
            border-radius: 15px;
            margin: auto;
            font-size: 1.2rem;
        }
    }
}