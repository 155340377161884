.calendly-user-selection {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    .MuiFormControl-root {
      margin-bottom: 20px;
  
      .MuiInputLabel-root {
        color: #333;
      }
  
      .MuiSelect-root {
        background-color: #fff;
      }
  
      .MuiMenuItem-root {
        color: #333;
      }
    }
  
    .MuiButton-root {
      margin-top: 16px;
      padding: 10px 0;
      font-size: 16px;
      font-weight: bold;
      text-transform: none;
  
      &.MuiButton-containedPrimary {
        background-color: #2761CF;
        color: #fff;
  
        &:hover {
          background-color: darken(#2761CF, 10%);
        }
      }
  
      &.MuiButton-containedSecondary {
        background-color: #4CAF50;
        color: #fff;
  
        &:hover {
          background-color: darken(#4CAF50, 10%);
        }
      }
    }
  
    .generated-link {
      margin-top: 20px;
      padding: 15px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
      h3 {
        margin-bottom: 10px;
        font-size: 18px;
        color: #333;
      }
  
      a {
        display: block;
        margin-bottom: 15px;
        word-break: break-all;
        color: #2761CF;
        text-decoration: none;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }