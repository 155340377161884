.modal_heading{
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #000;
    font-weight: bold;
}


.tab_container_modal{
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
    gap: 1rem;
   .active{
        background-color: #2761D0;
        color: #fff;
    }
    
   
    button{
        width: 100%;
        padding: 0.5rem;
        border: none;
        background-color: #181E4B;
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.3s;
        border-radius: 10px;
    
    }
}

.MapStringObjectServicableAreaDiv{
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    align-items: center;
    gap: 0.5rem;
    button{
        padding: 0.5rem 1rem;
        background-color: #2761D0;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 0.6rem;
        width: 100%;
        cursor: pointer;
      

        &:hover{
            background-color: #1A4D9D !important;
            color: white !important;
        }
    }
    .remove{
        background-color: #FF0000;
        &:hover{
            background-color: #FF0000 !important;
            color: white !important;
        }
    }
}

.transparentModal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    backdrop-filter: blur(17px) saturate(147%);
    -webkit-backdrop-filter: blur(17px) saturate(147%);
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    padding: 2rem;
    border-radius: 10px;
    width: 50%;
    @media screen and (max-width : 1000px) {
            width: 80%;
    }
}