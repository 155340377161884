.custom-swal {
    z-index: 2000;
}
.my-feedback {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
    place-items: center;
    border: 4px solid #4FAFCB;
    border-radius: 10px;
    padding: 1rem;
    background-color: white;

    h1 {
        font-size: 2rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
    }



    .feedback-list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        width: 100%;

        .reward_pending {
            border: 1px solid #2761D0;
            border-radius: 10px;
            padding: 0.5rem 1rem;
            color: #2761D0;
        }
        .reward_claimed {
            border: 1px solid #02c022;
            border-radius: 10px;
            padding: 0.5rem 1rem;
            color: #02c022;
        }

        .feedback-item {
            box-shadow: 0px 0px 10px 0px rgba($color: #000000, $alpha: 0.4);
            border-radius: 10px;
            padding: 2rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;

            .feedback_type {
                color: #2761D0;
                font-size: 1.2rem;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
            }

            .feedback_content {
                text-align: center;
                color: #a8a8a8;
            }

            .feedback_video {
                height: 200px;
                border-radius: 10px;
                overflow: hidden;
            }

            .feedback_image {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                img {
                    width: 200px;
                }
            }

            button {
                background-color: #2761D0;
                color: white;
                padding: 0.5rem 1rem;
                font-size: 1rem;
                font-weight: 700;
                border: none;
                border-radius: 10px;
            }
        }
    }

    .no_feedback {
        font-size: 1.5rem;
        text-align: center;
        font-weight: 500;
    }

    .mainRating {
        font-size: 2.8rem;
    }



    h3 {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
        text-align: center;
        text-transform: capitalize;
    }

    .card-container {
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .video-feedback {
        width: 300px;

        p {
            background-color: #2761D0;
            color: white;
            padding: 0.5rem 2rem;
            font-size: 1rem;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            width: -moz-fit-content;
            width: fit-content;
            margin: auto;
        }

        div {
            display: grid;
            grid-template-columns: 1fr;
            box-shadow: 0px 0px 10px 0px rgba($color: #000000, $alpha: 0.4);
            border-radius: 10px;
            padding: 1rem 4rem;

            p {
                font-size: 1rem;
                background-color: transparent;
                color: black;
                display: flex;
                align-items: center;
                gap: 1rem;
                margin: 0;
                margin-left: auto;
            }

            h3 {
                font-size: 1.8rem;
                font-weight: 700;
                margin-bottom: 1rem;
                text-align: left;
            }
        }
    }

    .written-feedback {
        width: 300px;

        p {
            background-color: #2761D0;
            color: white;
            padding: 0.5rem 2rem;
            font-size: 1rem;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            width: -moz-fit-content;
            width: fit-content;
            margin: auto;
        }

        div {
            background-image: linear-gradient(45deg, #000000, #022780);
            padding: 1rem 4rem;
            display: grid;
            grid-template-columns: 1fr;
            place-items: center;
            border-radius: 10px;

            p {
                font-size: 2rem;
                font-weight: 700;
                background-color: transparent;
                color: white;
                display: flex;
                align-items: center;
                margin: 0;
                // margin-left: auto;
            }
        }
    }

    .speedTest-feedback {
        width: 300px;

        p {
            background-color: #2761D0;
            color: white;
            padding: 0.5rem 2rem;
            font-size: 1rem;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            width: -moz-fit-content;
            width: fit-content;
            margin: auto;
        }

        div {
            box-shadow: 0px 0px 10px 0px rgba($color: #000000, $alpha: 0.4);
            border-radius: 10px;
            padding-bottom: 1rem;

            div {
                box-shadow: none;
            }

            p {
                font-size: 1.3rem;
                background-color: transparent;
                color: black;
                text-align: center;
                gap: 1rem;
                margin: auto;
                font-weight: 700;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .time {
                    font-size: 1rem;
                    background-color: transparent;
                    color: #022780;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    padding: 0;
                    margin: 0;
                    margin-left: 1rem;
                }

            }
        }
    }

    .button {
        background-color: #2761D0;
        color: white;
        padding: 1rem 2rem;
        font-size: 1rem;
        width: 100%;
        max-width: 500px;
        font-weight: 700;
    }
}

.FeedbackForm {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 90%;

    position: relative;
    left: 5%;
    top: 5%;

    .AllDiv {
        height: 100%;

        .TitleDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;

            h1 {
                font-size: 2rem;
                font-weight: 700;
                text-transform: uppercase;
            }

            span {
                background-color: #2761D0;
                color: white;
                padding: 0.5rem 1rem;
                font-size: 1rem;
                font-weight: 700;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }

    .BodyDiv {
        display: flex;
        flex-direction: column;
        height: 90%;

        .RatingDiv {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 1rem;
            padding: 1em;

            h3 {
                font-size: 1.5rem;
                font-weight: 700;
                text-transform: uppercase;
            }
        }

        .InputDiv {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 1rem;
            padding: 1em;
            height: 100%;

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                align-items: start;

                label {
                    font-size: 1.5rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 0.5rem;
                }

                input {
                    width: 100%;
                    padding: 1em;
                    border-radius: 10px;
                    border: 1px solid #2761D0;
                    font-size: 1rem;
                    font-weight: 700;
                    margin-bottom: 1rem;
                }

                textarea {
                    width: 100%;
                    padding: 1em;
                    border-radius: 10px;
                    border: 1px solid #2761D0;
                    font-size: 1rem;
                    font-weight: 700;
                    margin-bottom: 1rem;
                    height: 300px;
                }
            }

            button {
                background-color: #2761D0;
                color: white;
                padding: 1rem 2rem;
                font-size: 1rem;
                font-weight: 700;
                border: none;
                border-radius: 10px;
                cursor: pointer;
                margin-top: auto;
            }
        }
    }
}