.sales-report-container {
  // width: 80%;
  display: grid;
  margin: 0 auto;
  flex-direction: 1fr;
  gap: 2rem;
  padding: 2rem 2rem;

  @media screen and (max-width: 1000px) {
    padding: 1rem 1rem;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: white;
    padding: 1rem 2rem;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

    h1 {
      margin-top: 1rem;
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 0.5rem;
    }

    @media screen and (max-width: 430px) {
      grid-template-columns: 1fr;
      gap: 0.5rem;
    }
    .async_select__control {
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 0.2rem;
    }
    .selector {
      display: flex;
      flex-direction: column;

      justify-content: space-between;
      gap: 1rem;
      width: 100%;
      label {
        font-weight: bold;
        font-size: 1.2rem;
        @media screen and (max-width: 1000px) {
          font-size: 1rem;
        }
      }
      select {
        color: #002a8d;
      }
      .single {
        @media screen and (max-width: 600px) {
          grid-column: span 2;
          width: 100%;
        }
        width: 100%;
      }
    }

    .selector_con {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      label {
        font-weight: bold;
        font-size: 1.2rem;
        @media screen and (max-width: 1000px) {
          font-size: 1rem;
        }
      }
      .date {
        height: 2.5rem;
      }
      select {
        color: #002a8d;
      }
    }

    button {
      background-color: #002a8d;
      width: 100%;
      margin-bottom: 1rem;
    }

    .date_range {
      input {
        color: #002a8d;
        background-color: rgb(193 193 193);
      }
    }
  }
}

.swal-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
