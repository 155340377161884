/* Speed category colors */
.speed-bar.low { background: #ff6b6b; }
.speed-bar.medium-low { background: #ffd93d; }
.speed-bar.medium { background: #51cf66; }
.speed-bar.medium-high { background: #4dabf7; }
.speed-bar.high { background: #845ef7; }
.speed-bar.very-high { background: #d6336c; }

.fcc-popup {
  position: relative;
  background: #ffffff;
  border-radius: 8px;  /* slightly smaller radius */
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);  /* lighter shadow */
  width: 300px;   /* reduced width */
  max-width: 90vw;
  max-height: 90vh;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from { transform: translateY(10px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.fcc-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.8rem;  /* reduced padding */
  background: #f8f9fa;
  border-radius: 6px 6px 0 0;  /* slightly smaller radius */
  border-bottom: 1px solid #e9ecef;
}

.fcc-popup-header h4 {
  margin: 0;
  font-size: 0.9rem;  /* reduced title size */
  color: #2d3436;
}

.fcc-popup-close {
  background: none;
  border: none;
  font-size: 1rem;  /* smaller close button */
  cursor: pointer;
  color: #6c757d;
  padding: 0 0.4rem;
  transition: all 0.2s;
}

.fcc-popup-content {
  padding: 0.4rem;   /* reduced padding */
  overflow-y: auto;
  max-height: 70vh;
}

.card-section {
  padding: 0.4rem;  /* reduced padding */
  border-bottom: 1px solid #f1f3f5;
}

.card-section:last-child {
  border-bottom: none;
}

.provider-info, 
.tech-info {
  font-size: 0.7rem;  /* smaller font size */
  color: #495057;
  margin-bottom: 0.2rem;  /* tighter spacing */
}

.stats-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.3rem;  /* reduced gap */
}

.stat-item {
  font-size: 0.75rem;  /* smaller text */
}

.speed-distribution {
  display: grid;
  gap: 0.3rem;  /* reduced gap between speed meters */
}

.speed-item {
  display: grid;
  gap: 0.2rem;  /* reduced gap */
}

.speed-label {
  font-size: 0.7rem;  /* smaller label */
  color: #495057;
}

.speed-bar-container {
  position: relative;
  height: 16px;  /* reduced height */
  background: #f1f3f5;
  border-radius: 4px;
  overflow: hidden;
  width: 15rem;  /* reduced width */
}

.speed-bar {
  height: 100%;
  position: absolute;
  transition: width 0.3s ease;
}

.speed-percent {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.7rem;  /* smaller text */
  color: #fff;
  z-index: 1;
}

.slider-controls {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;  /* reduced padding */
  background: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

.slider-button {
  padding: 0.3rem 0.6rem;  /* smaller button */
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;  /* smaller text */
  transition: background 0.2s;
}