.user_copmmission {
    // display: grid;
    // grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
    padding: 0.5rem;

    h1 {
        font-size: 1.5rem;
        font-weight: bold;
        color: #181E4B;
        text-align: center;
        margin: 1rem 0;
    }

    .commission_stats {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr 1fr;

        }

        height: fit-content;
        gap: 1rem;

        .total {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            padding: 1rem;
            border-radius: 10px;
            display: flex;
            background-color: white;
            flex-direction: column;
            justify-content: space-between;

            h3 {
                font-weight: bold;
            }
        }

        button {
            background-color: #181E4B;
            color: white;
            padding: 0.5rem 1rem;
            border: none;
            border-radius: 10px;
            font-weight: bold;
            width: 100%;
            grid-column: span 4;

            @media screen and (max-width: 600px) {
                grid-column: span 2;
            }
        }
    }

    .commission_plan {
        button {
            background-color: #181E4B;
            color: white;
            padding: 0.5rem 1rem;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            // font-weight: bold;
        }

        .commission_tracker {
            display: flex;
            justify-content: space-between;
            border: 1px solid #E5E5E5;
            border-radius: 10px;
            padding: 1rem;
            align-items: center;
            width: 100%;

            .deduction_reason,.deduction_amount {
                // color: red;
                font-weight: bold;
            }

            .type {
                font-weight: bold;
                font-size: 1.2rem;
                color: #181E4B;
            }

            .amount {
                font-weight: bold;
                font-size: 1.2rem;
                color: #181E4B;
            }
        }

        .paid {
            font-weight: bold;
            font-size: 1.2rem;
            color: #4df54a;
        }
    }
}