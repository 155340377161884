.manage-company-consultation {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  
    .page-title {
      font-size: 1.5rem;
      font-weight: 600;
      color: #2761CF;
      text-align: center;
    }
  
    .filters {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
  
      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }
  
      .search-input {
        width: 100%;
  
        input {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;
          text-align: center;
        }
      }
  
      .industry-select,
      .status-select {
        width: 100%;
      }
    }
  
    .mainTableContainer {
      width: 100%;
      overflow-x: auto;
      background-color: white;
      border-radius: 10px;
      padding: 1rem;
      box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    }
  
    .tableContainer {
      width: 100%;
      min-width: 600px;
    }
  
    .action-buttons {
      display: flex;
      gap: 0.5rem;
      flex-wrap: nowrap;
      white-space: nowrap;
  
      button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.2rem;
        padding: 0.25rem;
        border-radius: 4px;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  
    .loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      width: 100%;
    }
  
    .pagination-container {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      width: 100%;
    }
  }
  
  // Styles for the Pagination component
  .MuiPagination-root {
    .MuiPaginationItem-root {
      color: var(--color-icon);
    }
  
    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
  
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .manage-company-consultation {
      padding: 1rem;
      gap: 1rem;
  
      .filters {
        grid-template-columns: 1fr;
      }
  
      .mainTableContainer {
        padding: 0.5rem;
      }
  
      .pagination-container {
        overflow-x: auto;
      }
    }
  }


  .MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;
  
    .MuiBox-root {
      background-color: white;
      padding: 2rem;
      border-radius: 8px;
      max-width: 90%;
      width: 400px;
      max-height: 90vh;
      overflow-y: auto;
  
      h2 {
        margin-bottom: 1rem;
        color: #2761CF;
      }
    }
  }
  
