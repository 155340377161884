.reviewContainer{
    width: 20rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media screen and (max-width:640px){
        width: 100%;
    }

    .reviewHeader{
        display: flex;
        gap: 0.5rem;

        .reviewImage{
            width: 4rem;
            height: 4rem;
            img{
                width: 100%;
                height: 100%;
            }
        }

        .reviewText{
            h1{
                font-size: 1.25rem;
            
            }
            p{
                color: #9E9EA2;
            }
        }
    }
}

.main_review{
    width: 100%;
    margin: auto;
  
    @media screen and (max-width:1000px) {
    
        padding: 0.5rem;
    }
    border: 1px solid #E5E5E5;
    padding: 1rem;
    border-radius: 10px;
    .slider_review{
        padding: 1rem;
        @media screen and (max-width:1000px){
            padding: 0rem;
        }

        .written_review_container{
            @media screen and (max-width:700px) {
                padding: 1rem;
                
            }
            .review_header{
                @media screen and (max-width:700px) {
                    gap: 0.5rem;
                    
                }
                .profile{
                    @media screen and (max-width:700px) {
                        width: 30px;
                        height: 30px;
                        
                    }
                }
                .info{
                    h3{
                        @media screen and (max-width:700px) {
                            font-size: 0.6rem;
                            
                        }
                    }
                }
            }
            .body{
                p{
                    
                    @media screen and (max-width:700px){
                        font-size: 0.75rem;
                    }
                }
                
            }
        }
    }
    .filter{
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
        h3{
            font-size: 1.5rem;
            font-weight: 700;
            color: #000;
            font-family: "Open Sans";
            text-align: center;
            @media screen and (max-width:1008px) {
                font-size: 1.5rem;
            }
        }

        .top_filter_buttons{
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            align-items: center;
            width: 100%;
            // gap: 1rem;
            border: 1px solid #E5E5E5;
            border-radius: 10px;
            margin-bottom: 1rem;
            overflow: hidden;
            button{
                font-weight: bolder;
                font-size: 1rem;
                width: 100%;
                // background-color: #E5E5E5;
                border: none;
                padding: 0.5rem 1rem;
                // border-radius: 10px;
                // &:hover{
                //     background-color: #2761D0;
                //     color: white;
                // }
            }

            .active{
                background-color: #2761D0;
                color: white;
            }
        }

        .filter_buttons{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            @media screen and (max-width:1000px) {
                gap: 0.5rem;
                margin-bottom: 0.5rem;
            }
            width: 100%;
            margin-bottom: 1rem;
            .active{
                background-color: #2761D0;
            }
            button{
                padding: 0.5rem 1rem;
                border: none;
                border-radius: 8px;
                background-color: #181E4B;
                color: white;
                font-size: 1rem;
                @media screen and (max-width:1008px){
                    font-size: 0.8rem;
                    padding: 0.3rem 0.5rem;
                }
                @media screen and (max-width:630px){
                    font-size: 0.6rem;
                    padding: 0.3rem 0.5rem;
                }
                font-weight: 700;
                cursor: pointer;
                transition: all 0.3s ease;
                width: 100%;
                height: 100%;
                &:hover{
                    background-color: #2761D0;
                }
            }
        }
    }
}

.swiper-button-next, .swiper-button-prev {
    background-color: transparent; /* Change the background color */
    width: 50px; /* Change the width */
    height: 50px; /* Change the height */
  }
  
  .swiper-button-next::after, .swiper-button-prev::after {
    font-size: 20px !important; /* Change the size of the arrow */
    color: #000; /* Change the color of the arrow */
  }