.createShopItemContainer{
    width:100%;
    height:100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 1040px) {
        grid-template-columns: 1fr;
        
    }
    gap: 1rem;
    .createShopItemForm{
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        gap: 1rem;
        background-color: var(--color-foreground);
        border-radius: 9.024px;
        border: 1px solid #E5E5E5;
        h1{
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 1rem;
            text-align: center;
        }
        input{
            width: 100%;
            height: 3rem;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            padding: 0 1rem;
            font-size: 1rem;
            font-weight: 500;
            &:focus{
                outline: none;
            }
        }
        .createShopItemDropzone{
            width: 100%;
            height: 100%;
            margin-bottom: 1rem;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            padding: 0 1rem;
            font-size: 1rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            p {
                font-size: 1rem;
                font-weight: 500;
                text-transform: uppercase;
            }
        }

        .createShopItemImagePreviewContainer{
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                width: 200px;
                height: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                border-radius: 10px;
                border: 4px solid #2761D0;
                overflow: hidden;

                svg {
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .selector{
            width: 100%;
        }
        button{
            width: 100%;
            height: 3rem;
            border: none;
            border-radius: 5px;
            background-color: var(--color-primary);
            color: var(--color-foreground);
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            &:focus{
                outline: none;
            }
        }
    }

    .createShopCategoryForm{
        width:100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        gap: 1rem;
        background-color: var(--color-foreground);
        border-radius: 9.024px;
        border: 1px solid #E5E5E5;
        h1{
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 1rem;
            text-align: center;
        }
        input{
            width: 100%;
            height: 3rem;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            padding: 0 1rem;
            font-size: 1rem;
            font-weight: 500;
            &:focus{
                outline: none;
            }
        }
        button{
            width: 100%;
            height: 3rem;
            border: none;
            border-radius: 5px;
            background-color: var(--color-primary);
            color: var(--color-foreground);
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            &:focus{
                outline: none;
            }
        }
    }
}