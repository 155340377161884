.visibility_container{
    gap: 1rem;
    width: 100%;
    height: 100%;
    .sales_org_list{
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        width: 100%;
        .sales_org{
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            padding: 0.5rem 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}



.sellingCardTutorialContainer {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 1000px) {
        background-color: white;
        border-radius: 36px;
    }

    .visibility_button {
        border-radius: 0.5rem;
        border: none;
        background-color: #181E4B;
        color: white;
        font-size: 1.25rem;
        font-weight: 600;
        cursor: pointer;
        padding: 0.5rem 1rem;
        width: fit-content;
        width: -moz-fit-content;
        margin: auto;
        margin-bottom: 1rem;
    }

    .upAndDownArrows {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-top: 20px;
        padding: 20px;
        width: 100%;
        @media screen and (max-width: 1000px) {
            right: 10px;

        }
    }

    .newStepCardContainer {
        width: -moz-fit-content;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: auto;

        @media screen and (max-width: 1000px) {
            flex-direction: row;
            justify-content: center;
            width: 100%;
            margin-bottom: 1rem;
        }
      }
      
      .newStepCardContainer h2 {
        margin: 0;
      }
    .arrow_buttons{
        position: fixed;
        bottom: 10%;
        right: 15%;
        

        @media screen and (max-width: 1000px) {
            right: 50px;
        }
    }
    .sellingCardTutorialHeading {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 20px;
        justify-content: fl;
        text-align: center;
        color: var(--color-icon);
    }


    .backBtn {
        margin-bottom: 20px;
        justify-content: fl;
        text-align: center;
        color: var(--color-icon);
    }

    .sellingCardHeader{
        display: flex;
        flex-direction: row;
        padding: 1rem;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    }

    .innerContainer {
        width: 100%;
        display: flex;
        gap: 1rem;
        @media screen and (max-width: 1000px) {
            grid-template-columns: 1fr;
            flex-direction: column;
            gap: 0rem;
        }

        align-items: flex-start;

        .stepsNo {
            width: -moz-fit-content;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            // overflow: auto;

            @media screen and (max-width: 1000px) {
                flex-direction: row;
                justify-content: center;
                width: 100%;
                margin-bottom: 1rem;
            }

            .sort_container {
                @media screen and (max-width: 1000px) {
                    display: flex;

                }
            }

            .step_container {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                @media screen and (max-width: 1000px) {
                    flex-direction: row;
                }
            }

            .line {
                width: 0.25rem;
                /* w-1 */
                height: 3rem;
                /* h-12 */
                background-color: #E5E5E5;

                /* bg-[#E5E5E5] */
                @media screen and (max-width: 1000px) {
                    height: 0.25rem;
                    width: 1rem;

                }
            }

            .step {
                height: 4rem;
                width: 4rem;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #71839B;
                color: white;
                font-size: 1.5rem;
                font-weight: 600;
                cursor: pointer;

                @media screen and (max-width: 1000px) {
                    width: 2rem;
                    height: 2rem;
                    font-size: 0.8rem;
                }
            }

            .step:hover {
                background-color: #2761CF;
            }

            .active {
                background-color: #2761CF;
            }

        }

        .stepDetails {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            gap: 1rem;
            padding: 2rem;
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);

            @media screen and (max-width: 1000px) {
                box-shadow: none;
            }

            section {
                width: 100%;
                border: 2px solid #E5E5E5;
                padding: 1rem;
                border-radius: 0.5rem;
                display: flex;
                cursor: pointer;

                div {
                    margin: auto;
                    height: 100%;
                    display: flex;

                    p {
                        color: #5E6282;
                        font-size: 1.25rem;
                        text-transform: uppercase;
                        margin: auto;
                        text-align: center;
                    }
                }

            }

            .imagePreview {
                width: 100%;
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;

                div {
                    width: 200px;
                    height: 100px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    border-radius: 10px;
                    border: 4px solid #2761D0;
                    overflow: hidden;

                    svg {
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer;
                    }

                    img {
                        width: 100%;
                        object-fit: contain;
                        height: 100%;
                    }

                    video {
                        width: 100%;
                        height: 100%;
                    }

                    audio {
                        width: 100%;
                        height: 100%;
                    }

                }
            }

            input {
                border-radius: 0.5rem;
                border: 2px solid #C4C4C4;
                background-color: white;
                text-align: center;
                color: black;
                font-weight: bold;
                padding: 0.5rem;
                outline-color: #181E4B;
                width: 100%;
            }

            textarea {
                width: 100%;
                height: 8rem;
                border: 2px solid #E5E5E5;
                outline: none;
                border-radius: 0.5rem;
                padding: 1rem;
            }

            button {
                width: 100%;
                height: 3rem;
                border-radius: 0.5rem;
                border: none;
                background-color: #181E4B;
                color: white;
                font-size: 1.25rem;
                font-weight: 600;
                cursor: pointer;
            }

            .updateImage {
                width: 100%;
                // height: auto;
                img {
                    width: 100%;
                    // height: 100%;
                    max-height: 500px;
                    @media screen and (max-width: 1000px) {
                        max-height: 300px;
                    }
                    object-fit: contain;
                }

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                audio {
                    width: 100%;
                    height: 100%;
                }

                div {
                    margin: auto;
                    /* m-auto */
                    height: 100%;
                    /* h-full */
                    display: flex;
                    /* flex */
                    justify-content: center;
                    /* justify-center */
                    align-items: center;
                    /* items-center */

                    p {
                        color: #5E6282;
                        /* text-[#5E6282] */
                        font-size: 1.25rem;
                        /* text-xl */
                        text-transform: uppercase;
                        /* uppercase */
                        margin: auto;
                        /* m-auto */
                        text-align: center;
                        /* text-center */
                    }
                }
            }

            h1 {
                font-size: 2.25rem;
            }

            .desc {
                font-size: 1.25rem;
            }

            .action_buttons {
                display: flex;
                gap: 1rem;
                justify-content: center;
                button {
                    width: 2em;
                    height: 2.5em;
                    padding-left: 4px;
                    display: flex;
                    place-items: center;
                    color: #2761D0;
                    font-size: 1rem;
                    font-weight: normal;
                }
            }
        }
    }


}

.swal2-container {
    z-index: 999999;
  }