.public_navbar_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: relative;
    .nav_logo{
        // width: 100px;
        height: 100px;
        @media screen and (max-width: 520px){
            height: 80px;   
        }
        @media screen and (max-width: 420px){
            height: 60px;   
            
        }
        img{

            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .nav_items{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .link{
            color: var(--primary-text);
            text-decoration: none;
            margin: 0 10px;
            font-size: 1rem;
            font-weight: 500;
            transition: all 0.3s ease;
            @media screen and (max-width: 1500px){
                font-size: 0.8rem;
            }
            &:hover{
                color: #51BAFE;
            }
        }
        .active{
            color: #51BAFE;
        }
        button{
            padding: 10px 20px;
            border: none;
            background-color: #2761D0;
            color: white;
            font-size: 1.1rem;
            font-weight: 500;
            border-radius: 15px;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover{
                // background-color: #51BAFE;
                color: white;
            }
        }
    }

    .mobile_nav_items{
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        gap: 1rem;
        z-index: 100;
        background-image: var(--primary-background);
        background-color: var(--primary-background);
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3);
        // transition: all 0.3s ease;
        .link{
            color: var(--primary-text);
            text-decoration: none;
            margin: 0 10px;
            font-size: 1rem;
         
            font-weight: 500;
            transition: all 0.3s ease;
            &:hover{
                color: #51BAFE;
            }
        }
        .active{
            color: #51BAFE;
        }
        button{
            padding: 10px 20px;
            border: none;
            background-color: #2761D0;
            color: white;
            font-size: 1.1rem;
            font-weight: 500;
            border-radius: 15px;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover{
                // background-color: #51BAFE;
                color: white;
            }
        }
    }
 
}

