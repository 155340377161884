table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 7px;
  border: 1px solid #e5d9d9;
  thead {
    background-color: #f3f1f1;
  }
  thead tr th {
    padding: 15px;
    text-align: left;
    white-space: pre;
  }
  thead tr th:first-child {
    display: flex;
    padding-block: 20px;
  }

  tbody {
    border: 1px solid #e5d9d9;
  }

  tbody tr td {
    padding-block: 15px;
    text-align: left;
    border-bottom: 1px solid #cfbdbd;
    padding-block: 18px;
    padding-left: 10px;
  }
  tbody tr td:first-child span {
    width: 22px;
    display: flex;
    height: 22px;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    border: 2px solid green;
  }
  tbody tr td:nth-child(3) span {
       display: block;
  }
  tbody td select {
    border: 1px solid #c5b7b7;
    padding: 8px;
    border-radius: 8px;
    margin-right: 10px;
  }
}
