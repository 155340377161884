.createFiberCompanyContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .createFiberCompanyForm {
        width: -moz-fit-content;
        width: fit-content;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        @media screen and (max-width: 1000px){
            gap: 0rem;
        }
        gap: 1rem;
        align-items: center;
        border-radius: 20px;
        border: 1px solid #E5E5E5;
        padding: 3rem;
        background-color: var(--color-foreground);

        .createFiberCompanyFormTitle {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 2rem;
            @media screen and (max-width: 1000px){
                margin-bottom: 0rem;
            }
            text-align: center;
        }

        .createFiberCompanyFormInput {
            width: 100%;
            height: 3rem;
            margin-bottom: 1rem;
            @media screen and (max-width: 1000px){
                margin-bottom: 0rem;
            }
            border: 1px solid #000000;
            border-radius: 5px;
            padding: 0 1rem;
            font-size: 1rem;
            font-weight: 500;

            &:focus {
                outline: none;
            }
        }

        .createFiberCompanyUpload {
            width: 100%;
            height: 3rem;
            margin-bottom: 1rem;
            @media screen and (max-width: 1000px){
                margin-bottom: 0rem;
            }
            border: 1px solid #000000;
            border-radius: 5px;
            padding: 0 1rem;
            font-size: 1rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .createFiberCompanyUploadText {
                font-size: 1rem;
                font-weight: 500;
            }

            .createFiberCompanyUploadButton {
                width: 3rem;
                height: 3rem;
                border: none;
                border-radius: 5px;
                font-size: 1rem;
                font-weight: 500;
                cursor: pointer;

                &:focus {
                    outline: none;
                }
            }
        }

        .createFiberCompanyFormButton {
            width: 100%;
            height: 3rem;
            margin-top: 1rem;
            @media screen and (max-width: 1000px){
                margin-top: 0rem;
            }
            border: none;
            border-radius: 5px;
            background-color: var(--color-primary);
            color: var(--color-foreground);
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }
    }
}