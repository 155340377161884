.tabbed-stats-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  
    @media (max-width: 768px) {
      padding: 1rem;
    }
  
    .tab-content {
      margin-top: 2rem;
    }
  }