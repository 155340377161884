.job_applicaotion_details{
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr;
    }
    gap: 1rem;
    background-color: white;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
    h1{
        grid-column: span 2 / span 2;
        @media screen and (max-width: 1000px){
            grid-column: span 1 / span 1;
        }
        text-align: center;
        font-size: 3rem;
        font-weight: 600;
        color: #000;
    }
    .details{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        .detail{
             display: grid;
             grid-template-columns: 1fr 1fr;
            //  flex-direction: column;
            border-bottom: 1px solid #ccc;
            padding: 1rem;
             gap: 1rem;
            p{
                font-weight: 600;
            }
            textarea{
                width: 100%;
                height: 100%;
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
            }
            select{
                width: 100%;
                height: 100%;
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
            }
            .blue_text{
                color: #007bff;
                cursor: pointer;
                transition: all 0.3s;
                &:hover{
                    color: #0056b3;
                }
            
            }
        }
    }

    .cv{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        h2{
            font-size: 2rem;
            font-weight: 600;
            color: #000;
            text-align: center;

        }
        a{
            padding: 0.5rem 1rem;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            outline: none;
            transition: all 0.3s;
            width: 100%;
            height: fit-content;
            text-align: center;
            &:hover{
                background-color: #0056b3;
            }
        }
        iframe{
            width: 100%;
            height: 100%;
            @media screen and (max-width: 1000px){
                height: 400px;
            }
        }
    }
}