.leaderboard_container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 2rem 2rem;

    @media screen and (max-width: 1000px) {
        padding: 1rem 1rem;

    }

    .button_group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        @media screen and (max-width: 420px) {
            grid-template-columns: 1fr;
            gap: 0.5rem;

        }

        button {
            background-color: #181E4B;
            border: 1px solid #002A8D;
            color: white;
            border-radius: 10px;
            padding: 1rem 2rem;

            @media screen and (max-width: 1000px) {
                padding: 0.5rem 1rem;
            }

            font-weight: 700;
            text-transform: uppercase;
            height: fit-content;

            &:hover {
                background-color: #002A8D;
            }
        }

        .active {
            background-color: #002A8D;
        }
    }

    .filter_container {
        display: flex;
        flex-direction: column;
        // grid-template-columns: 1fr 1fr;
        gap: 1rem;
        background-color: white;
        padding: 1rem 2rem;
        align-items: center;

        @media screen and (max-width: 1000px) {
            padding: 0.5rem;

        }

        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);

        @media screen and (max-width: 430px) {
            grid-template-columns: 1fr;
            gap: 0.5rem;

        }

        .btn_con {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-width: auto;
            grid-template-rows: 1fr;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            grid-column: 2/3;

        }

        .selector_con {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            label {
                font-weight: bold;
                font-size: 1.2rem;
                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }
            }

            select,
            .date {
                width: 100%;
                height: 3rem;
                @media screen and (max-width: 1000px) {
                    height: 2rem;
                }
                border: 1px solid #E6E6E6 !important;
                border-radius: 5px;
                padding: 0 0.5rem;
                font-size: 1rem;
                color: #41b6e6;
                text-align: left;

                @media screen and (max-width: 1000px) {
                    font-size: 0.75rem;

                }

                font-weight: 500;
                background-color: transparent;
                // color: white;
                padding: 0 1rem;
                outline: none;

                &:focus {
                    outline: none;
                }

                option {
                    font-size: 1rem;
                    text-align: center;
                    font-weight: 600;
                    color: #41b6e6;
                    width: 100%;
                }


            }
        }

        .date_range {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            align-items: center;
            justify-content: space-between;
            grid-column: span 2;
            border: 1px solid #E6E6E6;
            border-radius: 10px;
            padding: 0.5rem;
            @media screen and (max-width: 1000px) {
                padding: 0.5rem;
                gap: 0.5rem;
            }
            width: 100%;
            input {
                color: #41b6e6;
                outline: none;
                background-color: rgb(193 193 193);
                font-size: normal
                ;
            }
        }

        .search_input {
            padding: 0.5rem 1rem;
            border: 1px solid #002A8D;
            border-radius: 10px;
            // grid-column: span 2/ span 2;
            background-color: white;
            outline: none;

            @media screen and (max-width: 430px) {
                grid-column: span 1/ span 1;
            }
        }

        button {
            background-color: #41b6e6;
            // border: 1px solid #002A8D;
            color: white;
            border-radius: 10px;
            padding: 0.5rem;
            font-size: 0.8rem;
            // grid-column: span 2/ span 2;
            width: fit-content;
            width: -moz-fit-content;
            margin-left: auto;

            @media screen and (max-width: 1000px) {
                padding: 0.5rem 1rem;

            }

            @media screen and (max-width: 430px) {
                grid-column: span 1/ span 1;
            }

            font-weight: 700;
            text-transform: uppercase;
            height: fit-content;

            // &:hover {
            //     background-color: #002A8D;
            // }
        }
    }

    .mainTableContainer {
        // @media screen and (max-width: 1000px) {
        //     width: 98vw;

        // }

        width: 100%;
    }

    .rep {
        .sales_rep_leaderboard_item {
            // background: linear-gradient(45deg, #cdebf8 12%,#fff 12%) !important;
            background-color: white;
            gap: 1rem;

            @media screen and (max-width: 1000px) {
                grid-template-columns: repeat(7, 100px) !important;

            }
        }
    }

    .sales_org_leaderboard {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        overflow: auto;
        padding: 0.5rem;
        // gap: 0.5rem;
        gap: 1rem;

        .sales_org_leaderboard_item {
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            gap: 3rem;
            // padding: 1rem;
            @media screen and (max-width: 1400px) {
                gap: 0.5rem;
                grid-template-columns: repeat(9, 85px);
                grid-template-columns: 50px repeat(9, 85px);
                // padding: 0.5rem;
            }
            
   
            .equation {
                grid-column: span 3;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                place-items: center;
                border: 1px solid #41b6e6;
                border-radius: 10px;
                padding: 0.5rem;
            }

            width: 100%;

            // p{
            //     border-right: 1px solid #41b6e6;
            //     &:last-child{
            //         border-right: none;
            //     }
            // }
            .rank {
                font-weight: bold;
                font-size: 1.2rem;
                // box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.45);
                // width: fit-content;
                // padding: 0.5rem;
                // border-radius: 10px;

            }
        }

        .sales_rep_leaderboard_item {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            width: 100%;
            padding: 0.5rem 1rem;
            // border: 2px solid #41b6e6;
            box-shadow: 0px 0px 5px 0px #41b6e6;
            border-radius: 10px;
            margin: 0.5rem 0;
            align-items: center;
            justify-content: start;

            .equation {
                grid-column: span 2;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                place-items: center;
                border: 1px solid #41b6e6;
                border-radius: 10px;
                padding: 0.5rem;
            }

            // background: linear-gradient(45deg, #cdebf8 15%,#fff 15%);
            .profile_image {
                width: 70px;
                height: 70px;

                @media screen and (max-width: 1000px) {
                    width: 50px;
                    height: 50px;

                }

                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

}