.sellingCardDetailsContainer {
  display: flex;
  
.sellingCardTitle {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.centeredImage {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.sellingCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

ul.stepsContainer {
  list-style-type: none;
  padding-left: 0;
  position: relative;
  max-height: 300px;  // Adjust this height as needed
  overflow-y: scroll;  
}

li.stepCardContainer {
  position: relative;
  margin: 0;
  padding-bottom: 1em;
  display: flex;
  align-items: center;
}

li.stepCardContainer:before {
  background-color: #2761D0;
  width: 2px;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px; /* Adjusted to align with the bullet points */
}

.bullet {
  width: 12px;
  height: 12px;
  background-color: #2761D0;
  border-radius: 50%; /* Ensures the shape is a circle */
  // position: relative;
  z-index: 1;
}


.stepCardContainer.fullWidth .stepCard {
  width: calc(100% - 50px); /* Subtract the space taken by the bullet points */
}

.stepCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  margin-left: 30px; /* Adjusted to align the step cards correctly */
}

.newStepCardContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
}

.newStepCardContainer h2 {
  margin: 0;
}

.stepCard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}



.visibility_button {
  margin-bottom: 1rem;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #181E4B;
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
}
}
