.announcement-cards-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;

    h1 {
        margin-bottom: 20px;
        font-size: 28px;
        font-weight: bold;
        color: #181E4B;
        text-align: center;
    }

    .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
    }

    /* Grid container for announcements */
    .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
    }

    .announcement-card {
        background-color: #f9f9f9;
        border-left: 6px solid; /* Dynamic color based on announcement type */
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
        display: grid;
        grid-template-columns: 2fr 1fr; /* Make the first column wider */
        grid-template-rows: auto auto;
        gap: 10px;
        justify-items: start;
        cursor: pointer;

        &:hover {
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
        }
        .announcement-title {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
            font-size: 1.1rem;
            font-weight: bold;
            color: #181e4b;
            text-align: left;
            display: flex;
            align-items: center;
          }
        /* Announcement Header in Row 1 */
        .announcement-header {
            grid-column: 2 / span 2; /* Spans across both columns */
            grid-row: 1;
            display: flex;
            text-align: right;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 10px;

            .announcement-type {
                font-size: 1.1rem;
                color: #181E4B;
                display: flex;
                flex-direction: row;
            }

            .announcement-date {
                font-size: 0.9rem;
                color: #999;
                text-align: right;
            }
        }

        /* Announcement Message in Row 2, Column 1 */
        .announcement-message {
            grid-column: 1 / span 2;
            grid-row: 2;
            font-size: 1rem;
            color: #333;
            line-height: 1.5;
            max-width: 90%; /* Adjust this to increase width */
        }

        /* Announcement Date in Row 2, Column 2 */
        .announcement-date {
            grid-column: 2 / span 2;
            grid-row: 3;
            font-size: 0.85rem;
            color: #999;
            justify-self: flex-end; /* Right-align in the second column */
        }
    }

    .no-announcements {
        text-align: center;
        font-size: 1.2rem;
        color: #666;
    }
}

.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .modal-content {
      background-color: #fff;
      padding: 30px;
      border-radius: 10px;
      width: 400px;
      max-height: 80vh; /* Dynamic height: takes 80% of the viewport height */
      overflow-y: auto;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      z-index: 9999;

      img,video {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 5px;
        max-width: 100%;
      }
      
  
    }
}
