.candidate-main-lead-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f5f5f5;
  
    .top-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 5px;
      //background-color: white;
      padding-left: 3px;

      .see_activity {

        @media screen and (max-width: 1000px) {
            display: none;

        }
    }
    }
  
    .scrollable-content {
      flex: 1;
      overflow-y: auto;
      padding: 16px;
    }
  

    .header-section {
        display: flex;
        align-items: center;
        gap: 16px;

        .map-icon {
            display: flex;
            align-items: center;
            align-self: self-start;
            height: 100%;
            
            img {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
        }

        .lead-info {
            flex: 1;
            min-width: 0;

            .name-container {
                position: relative;
                margin-bottom: 8px;
                display: flex;
               // flex-wrap: wrap; // يسمح بلفّ العناصر
                flex-wrap: nowrap; // إجبار العناصر أن تبقى بجانب بعضها البعض
                align-items: center; // محاذاة رأسية متوسطة
                gap: 0.5rem; // مسافة بين الاسم والـ tag
                //overflow: hidden; // لمنع تجاوز المحتوى خارج الحاوية

                .LeadName {
                    align-items: center;
                    flex: 1; // يجعل العنصر يأخذ المساحة المتبقية
                    font-size: 1.5rem;
                    font-weight: bold;
                    width: 20%;
                    border: none;
                    outline: none;
                    background: transparent;
                    padding-right: 0; // No extra padding needed
                    margin: 0; // Ensure no unwanted margin
                   // flex: 0 1 auto; // يمنع تمدد الاسم إلى كامل المساحة
                    
                }
                .status-label {
                    display: inline-flex;
                    
                    gap:  0.25rem; // Equivalent to gap-1.5 in Tailwind
                    background-color: rgba(34, 197, 94, 0.8); // Greenish color with 80% opacity
                    color: white; // White text for better contrast
                    padding: 0.2rem 0.2rem; // Smaller padding for compact design
                    border-radius:0.0rem; // Equivalent to rounded-md in Tailwind
                    font-weight: 500; // Equivalent to font-medium in Tailwind
                    font-size: 0.7rem; // Equivalent to text-sm in Tailwind
                    line-height: 1;
                    bottom: 0;
                    left: 0;
                    flex-shrink: 0; // يمنع الـ tag من الانكماش
    padding: 0.2rem 0.4rem; // تقليل الحواف الداخلية
                             }
                  

                .door-icon {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    color: rgba(0, 0, 0, 0.54);
                }
            }

            .location {
                display: flex;
                align-items: flex-start;
                gap: 8px;

                textarea {
                    min-height: 20px;
                    flex: 1;
                    resize: none;
                    border: none;
                    outline: none;
                    font-family: inherit;
                    font-size: 0.9rem;
                    background: transparent;
                    min-width: 0;
                }
            }
        }
    }

    .section-divider {
        margin: 16px 0;
    }

    .info-section {
        margin-bottom: 16px;

        .MuiTypography-h6 {
            margin-bottom: 12px;
        }
    }

    .input-field {
        display: flex;
        align-items: center;
        //gap: 8px;
        margin-bottom: 12px;
        //background-color: white;
        padding: 8px;
        border-radius: 4px;

        .MuiSvgIcon-root {
            color: rgba(0, 0, 0, 0.54);
        }

        input {
            flex-grow: 1;
            border: none;
            outline: none;
            font-size: 0.9rem;
        }
    }

    .info-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 16px;
    }

    .checkbox-item {
        margin-top: 8px;
    }

    .appointment-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }

    .customer-status {
        display: flex;
        justify-content: center;

        .status-text {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .status-icons {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    .notes-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }

    textarea {
        width: 100%;
        border: none;
        outline: none;
        resize: vertical;
        min-height: 100px;
        font-family: inherit;
        font-size: 0.9rem;
        padding: 8px;
        background-color: white;
        border-radius: 4px;
    }

    .communication-buttons {
        display: flex;
        justify-content: space-around;
        padding: 16px;
        background-color: white;
        border-top: 1px solid #e0e0e0;

        .MainLeadCard_Button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
            }

            img {
                width: 24px;
                height: 24px;
                margin-bottom: 4px;
            }

            span {
                font-size: 0.75rem;
                color: #5E6282;
            }
        }
    }
}

// Scrollbar styles
.main-content {
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}