.MainLeadCard {
    height: 100%;
    display: flex;
    flex-direction: column;

    .date_label {
        color: #2761D0 !important;
        text-decoration: underline;
        font-size: 1rem !important;

        @media screen and (max-width:1000px) {
            font-size: 0.8rem !important;
        }

        font-weight: 500;
        cursor: pointer;
    }

    .MainLeadCard_LeadData {
        width: 100%;

        .LeadData {
            display: grid;
            grid-template-columns: 1fr;

            .see_activity {
                color: #2761D0;
                text-decoration: underline;
                cursor: pointer;

                @media screen and (max-width: 1000px) {
                    display: none;

                }
            }

            .DataDiv {
                display: grid;
                grid-template-columns: auto 1fr 1fr;
                row-gap: 0.5rem;

                .DataDiv_MapIcon {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    grid-column: span 3;
                    align-items: center;
                    padding-right: 1.5em;

                    @media screen and (max-width: 1000px) {
                        padding-right: 0;

                    }

                    .icons {
                        display: flex;
                        gap: 0.5rem;

                        img {
                            width: 2em;
                            height: 2em;

                            @media screen and (max-width: 1000px) {
                                width: 1.5em;
                                height: 1.5em;
                            }
                        }
                    }

                    .part1 {
                        display: grid;
                        grid-template-columns: auto 1fr;

                        img {
                            max-width: 4em;
                            max-height: 4em;

                            @media screen and (max-width: 1000px) {
                                max-width: 2em;
                                max-height: 2em;
                            }
                        }

                        .LeadName {
                            margin: 0rem;
                            margin-left: 0.5em;
                            font-size: 2em;
                            outline: none;
                            padding: 0rem;
                            width: -moz-fit-content;
                            width: fit-content;

                            @media screen and (max-width: 1000px) {
                                font-size: 1.5em;

                            }

                            @media screen and (max-width: 500px) {
                                font-size: 1em;

                            }

                            font-weight: 600;
                        }

                        span {
                            margin-left: 1em;
                        }
                    }
                }

                .image-wrapper {
                    position: relative;
                    display: inline-block;

                    img {
                        display: block;
                        max-width: 4em;
                        max-height: 4em;

                        @media screen and (max-width: 1000px) {
                            max-width: 2em;
                            max-height: 2em;
                        }

                        @media screen and (max-width: 500px) {
                            max-width: 1.5em;
                            max-height: 1.5em;
                        }
                    }

                    span {
                        position: absolute;
                        bottom: -8px;
                        right: -8px;
                        color: white;
                        padding: 5px;
                        font-size: 16px;
                        font-weight: bolder;
                        background-color: rgba(0, 0, 0, 0.5);
                        border-radius: 15px;

                        @media screen and (max-width: 1000px) {
                            font-size: 14px;
                            bottom: -6px;
                            right: -6px;
                        }

                        @media screen and (max-width: 500px) {
                            font-size: 7px;
                            bottom: -4px;
                            right: -4px;
                        }
                    }
                }

                .ButtonsDivTop {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1rem;
                    justify-content: flex-end;
                    // margin-top: 1em;
                    // margin-bottom: 1em;
                    padding-right: 1em;
                    align-items: center;

                    @media screen and (max-width: 1000px) {
                        padding-right: 0;
                        gap: 0.5rem;

                    }

                    .bot {
                        margin-top: -1em;

                        img {
                            width: 50px;
                            height: 50px;
                            cursor: pointer;
                            align-self: start;
                        }

                        // background-color: white;

                        &:hover {
                            transform: scale(1.1);
                            background-color: #2761D0;
                        }
                    }

                    span {
                        color: #2761D0;
                        text-decoration: underline;
                        cursor: pointer;

                        @media screen and (max-width: 1000px) {
                            font-size: 0.6rem;
                        }

                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                span {
                    color: #5E6282;
                    // margin-top: 0.5em;
                    text-decoration: underline;

                    // margin-top: 1em;
                    @media screen and (max-width: 1000px) {
                        font-size: 0.6rem;

                    }
                }

                .ButtonsDivBottom {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 1em;
                    padding-left: 1em;
                    height: fit-content;

                    @media screen and (max-width: 1000px) {
                        padding-right: 0;
                        padding-left: 0;
                        height: auto;
                        align-items: center;
                    }

                    div {
                        padding: 5px;

                        @media screen and (max-width: 1000px) {
                            padding: 0px;

                        }

                        .icon {
                            width: 2.7em;
                            // max-height: 2em;
                            cursor: pointer;

                            @media screen and (max-width: 1000px) {
                                width: 2em;
                            }

                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }
                }

                p,
                .email_phone_input {
                    color: #5E6282;

                    @media screen and (max-width: 1000px) {
                        font-size: 0.8em;
                    }

                    outline: none;
                }
            }


        }
    }

    .viewCustomerCard {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        justify-content: center;
        border: 1px solid #C4C4C4;
        padding: 1rem;
        width: -moz-fit-content;
        width: fit-content;

        @media screen and (max-width: 1000px) {
            width: 100%;
            padding: 0.5rem;
            border-radius: 10px;
        }

        margin: auto;
        border-radius: 20px;
        gap: 1rem;
        margin-top: 1rem;

        .image {
            width: 100px;
            height: 100px;
            border-radius: 24px;
            overflow: hidden;

            @media screen and (max-width: 1000px) {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .info {
            h2 {
                font-size: 1.5rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;

                }

                font-weight: bold;
            }

            .address {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 1rem;
                align-items: center;

                @media screen and (max-width: 1000px) {
                    gap: 0.5rem;

                }

                p {
                    color: #5E6282;
                    font-size: 1rem;

                    @media screen and (max-width: 1000px) {
                        font-size: 0.6rem;

                    }
                }
            }

            h4 {
                font-size: 1.2rem;

                @media screen and (max-width: 400px) {
                    font-size: 0.6rem;

                }

                color: #2761D0;
                cursor: pointer;
                text-decoration: underline;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
    }

    .appointment_date {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-top: 1rem;

        p {
            color: #5E6282;
            font-weight: bold;
            font-size: 1rem;

            @media screen and (max-width: 1000px) {
                font-size: 0.6rem;

            }
        }

        input {
            width: 100%;
            height: 2.5rem;
            border: 1px solid #C4C4C4;
            border-radius: 0.5rem;
            padding: 0.5rem;
            font-size: 1rem;
            color: #5E6282;
            font-weight: bold;

            @media screen and (max-width: 1000px) {
                font-size: 0.6rem;

            }
        }
    }

    .MapIconDisplay {
        @media screen and (max-width: 1000px) {
            margin-top: 0.5em;
        }
    }

    .MainLeadCard_Notes {
        display: flex;
        flex-direction: column;

        h2 {
            margin-top: 1em;
            font-weight: bold;
        }

        .textArea {
            width: 100%;
            // height: 10em;
            border: 1px solid #C4C4C4;
            border-radius: 0.5em;
            padding: 1em;
            margin-top: 1em;

            @media screen and (max-width: 1000px) {
                padding: 0.5em;
                margin-top: 0.5em;
            }

            font-size: 1em;
            color: #5E6282;
            font-weight: bold;

            textarea {
                width: 100%;
                height: 100%;
                border: none;
                outline: none;

            }


        }

        button {
            background-color: #2761D0;
            color: white;
            padding: 0.5em 1em;
            border: none;
            border-radius: 0.5em;
            cursor: pointer;
            margin-top: 1em;
            width: -moz-fit-content;
            width: fit-content;
            justify-self: center;
            align-self: center;

            &:hover {
                transform: scale(1.1);
            }

        }
    }

    .MainLeadCard_Buttons {
        display: flex;
        bottom: env(safe-area-inset-bottom);
        justify-content: space-evenly;
        margin-top: 1em;
        align-items: flex-start;
        padding-bottom: env(safe-area-inset-bottom);

        @media screen and (max-width: 1000px) {
            margin-top: 0em;
            padding-bottom: 2rem;
        }

        .MainLeadCard_Button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 5em;
            padding: 1em;

            @media screen and (max-width: 1000px) {
                padding: 0.5em;
            }

            cursor: pointer;

            &:hover {
                transform: scale(1.1);
            }

            img {
                width: 3em;

                @media screen and (max-width: 1000px) {
                    width: 2em;

                }

                @media screen and (max-width: 420px) {
                    width: 1.5em;

                }

                // max-height: 2em;

            }

            span {
                color: #5E6282;
                margin-top: 0.5em;
                cursor: pointer;

                @media screen and (max-width: 1000px) {
                    font-size: 0.6rem;

                }

                @media screen and (max-width: 420px) {
                    font-size: 0.5rem;

                }

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    .owner_name {
        background-color: #fff;
        width: 100%;
        text-align: center;
        // padding: 0.5em;
        color: #fff;
        border-radius: 10px;
        grid-column: span 2;

        div {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 0.5rem;
            align-items: center;
            justify-content: flex-start;

            p {
                text-align: left;
                text-transform: uppercase;
                font-weight: 500;
                color: #a0a4ac !important;
            }
        }
    }

}

.CreateFiberHouse {
    .MapIconDisplay {
        .MapIconDiv {
            @media screen and (max-width: 1000px) {
                width: 2.7em;
                height: 2.7em;

                img {
                    width: 2.5em;
                    height: 2.5em;
                }

            }
        }
    }
}

.MapIconDisplay {
    display: flex;
    align-items: center;
    // justify-content: center;
    overflow-x: scroll;
    padding: 1em;
    margin: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    // Hide scrollbar for Firefox
    scrollbar-width: none;

    @media screen and (max-width: 1024px) {
        padding: 0rem;
        // flex-wrap: wrap;
        // overflow-x: unset;
        gap: 0.5rem;
        // width: fit-content;
        // width: -moz-fit-content;
    }

    margin-top: 1.5em;

    .MapIconDiv {
        margin-right: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0.5em;
        height: 6.6em;

        @media screen and (max-width: 1024px) {
            height: 2.5em;
            width: 2.5em;
            padding: 0.2em;
            border-radius: 50%;
            margin-right: 0rem;

        }

        padding: 1em;

        img {
            max-width: 3em;
            max-height: 3em;

            @media screen and (max-width: 1024px) {
                width: 2.3em;
                height: 2.3em;
                margin-bottom: 0rem;
            }

            cursor: pointer;
            margin-bottom: 0.5em;

        }

        div {

            font-size: 0.8em;
            color: #5E6282;
            text-align: center;

            @media screen and (max-width: 1024px) {
                display: none;
            }
        }

        &:hover {
            transform: scale(1.1);

            @media screen and (max-width: 1024px) {
                transform: none;
            }
        }

    }
}

.MapIconSaveButtonDiv {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1em;

    button {
        background-color: #2761D0;
        color: white;
        padding: 0.5em 1em;
        border: none;
        border-radius: 0.5em;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.LeadEditDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .HeaderDiv {
        display: flex;
        width: 100%;
        justify-content: left;

        @media screen and (max-width: 1000px) {
            justify-content: center;
        }

        h2 {
            font-weight: bold;
            font-size: 1.5em;

            @media screen and (max-width: 1000px) {
                font-size: 1em;
                text-align: center;
            }
        }
    }

    .InputsDiv {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1em;

        @media screen and (max-width: 1000px) {
            margin-top: 0.3em;
        }

        .InputDiv {
            display: flex;
            margin-top: 1em;
            align-items: center;
            margin-bottom: 1em;

            @media screen and (max-width: 1000px) {
                margin-top: 0.3em;
                margin-bottom: 0.3em;
            }

            label {
                color: #5E6282;
                font-weight: bold;
                width: 20%;
                margin-left: 1.5em;
            }

            input {
                width: 80%;
                height: 2em;
                border: 1px solid #C4C4C4;
                border-radius: 0.5em;
                padding: 1em;
                margin-top: 0.5em;
                font-size: 1em;
                color: #5E6282;
                font-weight: bold;
            }
        }
    }

    .Buttons {
        display: flex;
        justify-content: space-evenly;
        margin-top: 1em;
        width: 100%;

        @media screen and (max-width: 1000px) {
            margin-top: 0.5em;
        }

        button {
            padding: 0.5em 1em;
            border: none;
            border-radius: 0.5em;
            cursor: pointer;
            min-width: 6em;

            @media screen and (max-width: 1000px) {
                padding: 0.4em 0.8em;
            }

            &:hover {
                transform: scale(1.1);
            }
        }

        .saveButton {
            background-color: #2761D0;
            color: white;
        }

        .cancelButton {
            background-color: #5E6282;
            color: white;
        }
    }

}

.custom_modal {
    width: 50%;

    // height: 60%;
    @media screen and (max-width: 1000px) {
        width: 90%;
    }

    overflow: auto;
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    outline: none;
    gap: 0.5rem;

    .fiber_company_selector {
        width: 100%;
        font-size: 1rem;
    }

    .note {
        color: #84A1C8;
        font-size: 0.8rem;
        text-transform: uppercase;
        margin-left: 0.5rem;
    }

    .promos {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .promo_options {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: start;
            flex-wrap: wrap;

            .promo {
                display: flex;
                gap: 0.2rem;
                width: -moz-fit-content;
                width: fit-content;

                input {
                    padding: 0rem;
                    width: auto;
                }
            }
        }
    }

    .async_select__control {
        border-radius: 0.5rem;
        outline: none;
        border: 1px solid #EAEAEA;
        padding: 0.2rem;
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
    }

    .or {
        font-weight: bold;
    }

    .am_pm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin-top: 1rem;
        width: 100%;
        gap: 0.5rem;

        p {
            text-transform: uppercase;
            font-weight: bold;
            width: 100%;
            font-size: 1.5rem;
            text-align: center;
            color: black;
            padding: 0.5rem;
            // cursor: pointer;
            // border: 3px solid #EAEAEA;
            // border-radius: 5px;

            @media screen and (max-width: 500px) {
                // font-size: 0.8rem;

            }
        }

        .active {
            color: #2761D0;
            border: 3px solid #2761D0;
            border-radius: 5px;
        }
    }

    .time_slot {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 1rem;
        border: 1px solid #EAEAEA;
        border-radius: 0.5rem;

        input {
            background-color: white;
            border: none;
        }
    }

    label {
        width: 100%;
    }

    input,
    select,
    .txt {
        width: 100%;
        padding: 0.5rem;
        border-radius: 0.5rem;
        outline: none;
        border: 1px solid #EAEAEA;
        text-align: center;
        font-size: 1rem;
        font-weight: 400;

        @media screen and (max-width: 1000px) {
            padding: 0.3rem;
            font-size: 1rem;
        }


    }

    .inputs {
        // width: 70%;

        @media screen and (max-width: 1000px) {
            width: 100%;

        }

        .toggle-switch {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;

            label {
                font-size: 1.2rem;
                margin-right: 1rem;
                font-weight: bold;
            }

            input {
                width: 50px;
                height: 25px;
                -webkit-appearance: none;
                appearance: none;
                background-color: #c6c6c6;
                outline: none;
                border-radius: 50px;
                color: #002A8D;
                position: relative;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:checked {
                    background-color: #002A8D;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 23px;
                    height: 23px;
                    background-color: white;
                    border-radius: 50%;
                    top: 1px;
                    left: 1px;
                    transition: transform 0.3s ease;
                }

                &:checked::before {
                    transform: translateX(25px);
                }
            }

        }

        display: flex;
        flex-direction: column;
        gap: 1rem;

        .custom_input {
            width: 100%;
            padding: 0.5rem;
            border-radius: 0.5rem;
            outline: none;
            border: 1px solid #EAEAEA;
            height: auto;

            @media screen and (max-width: 1000px) {
                padding: 0.3rem;
                font-size: 1rem;
            }

            text-align: center;
            font-size: 1.2rem;
            font-weight: 500;
        }

        .consent {
            display: flex;
            align-items: center;
            justify-content: start;

            input {
                width: 18px;
                height: 18px;
                margin-right: .5rem;
            }

            label {
                font-size: 12px;
                color: black;


                @media screen and (max-width: 450px) {
                    font-size: 7px;
                }

                font-weight: 500;
            }

        }
    }

    button {
        padding: 0.5rem 1rem;
        background-color: #2761D0;
        color: white;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
        outline: none;
        width: 100%;
        text-transform: uppercase;
        font-weight: 500;

        @media screen and (max-width: 1000px) {
            // padding: 0.3rem 0.5rem;
            font-size: 0.8rem;

        }
    }


}

.isCustomerMarked {
    margin-top: 1em;

    @media screen and (max-width: 1000px) {
        margin-top: 0.5em;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: large;
    font-weight: bold;
    color: #2761D0;

    .green-checkmark {
        color: green;
    }

    .gray-checkmark {
        color: gray;
    }

    .black-checkmark {
        color: black;
    }

}

.email_phone_input {
    @media screen and (max-width: 1000px) {
        width: 120px;
    }
}