.candidate-stats {
  .title {
    margin-bottom: 24px;
    color: #333;
  }

  .total-leads {
    margin-bottom: 16px;
    color: #666;
  }

  .stats-section {
    background-color: #fff;
    border-radius: 8px;
    // padding: 20px;
    margin-bottom: 24px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .select-actions {
        display: flex;
        gap: 8px;
      }
    }
  }

  .map-icons-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 16px;

    @media (max-width: 600px) {
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      gap: 8px;
    }

    .map-icon-stat {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px;
      border: 2px solid #eee;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease;

      &.active {
        border-color: #2B92D5;
        background-color: rgba(43, 146, 213, 0.1);
      }

      img {
        width: 40px;
        height: 40px;
        margin-bottom: 8px;

        @media (max-width: 600px) {
          width: 30px;
          height: 30px;
        }
      }

      h6 {
        margin-top: 4px;
        color: #2B92D5;
        font-size: 14px;

        @media (max-width: 600px) {
          font-size: 12px;
        }
      }
    }
  }

  .type-stats,
  .invite-stats {
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    display: flex;
    flex-wrap: wrap;

    gap: 16px;

    @media (max-width: 600px) {
      // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 8px;
    }

    .stat-item {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // padding: 16px;
      // background-color: #f5f5f5;
      // border-radius: 8px;
      // text-align: center;
      // cursor: pointer;
      // transition: all 0.3s ease;
      // // width: fit-content;
      // min-width: 90px;


      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
      border: 2px solid #E5E5E5;
      padding: 0.5rem;
      border-radius: 8px;
      // width: 4.5em;
      align-items: center;
      min-width: 80px;
      cursor: pointer;


      &.active {
        // background-color: rgba(43, 146, 213, 0.1);
        background-color: transparent;
        border: 2px solid #2B92D5;
      }

      svg {
        color: #2B92D5;
        margin-bottom: 8px;
        font-size: 24px;

        @media (max-width: 600px) {
          font-size: 20px;
        }
      }

      h6 {
        color: #2B92D5;
        margin-top: 8px;
        font-size: 14px;

        @media (max-width: 600px) {
          font-size: 12px;
        }
      }
    }
  }

  .action-buttons {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;

    .invite-btn {
      flex: 1;
      background-color: #2B92D5;
      text-transform: none;

      &:hover {
        background-color: darken(#2B92D5, 10%);
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  .export-btn {
    width: 100%;
    margin-bottom: 16px;
    background-color: #4CAF50;
    text-transform: none;

    &:hover {
      background-color: darken(#4CAF50, 10%);
    }
  }

  .search-section {
    margin-bottom: 24px;

    .search-input {
      background-color: #fff;
    }
  }

  .candidates-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;

    .candidate-card {
      .candidate-header {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;

        .candidate-info {
          flex: 1;
        }
      }

      .candidate-actions {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        button {
          color: #666;

          &:hover {
            color: #2B92D5;
          }
        }
      }
    }
  }

  .section-divider {
    margin: 24px 0;
  }

  .pagination {
    display: flex;
    justify-content: center;
  }
}