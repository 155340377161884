.calender_container {
    height: 100vh;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    @media screen and (max-width: 1000px) {
        height: 100%;
    }
}


.main_calender_container {
    .filters{
        z-index: 10;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        padding: 2rem;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        h1{
            font-size: 1.5rem;
            font-weight: 600;
            text-align: center;
        }

        // .css-b62m3t-container:nth-child(1){
        //     z-index: 20;
        // }
        // .css-b62m3t-container:nth-child(2){
        //     z-index: 10;
        // }
        .async_select__control{
            border: 1px solid #E5E5E5;
            border-radius: 10px;
            padding: 0.2rem;
        }
        .async_select__menu{
            z-index: 20;
        }
        .toggle-switch {
            display: flex;
            align-items: center;
            justify-content:flex-end;
            width: 100%;

            label {
                font-size: 1.2rem;
                margin-right: 1rem;
                font-weight: bold;
            }

            input {
                width: 50px;
                height: 25px;
                -webkit-appearance: none;
                appearance: none;
                background-color: #c6c6c6;
                outline: none;
                border-radius: 50px;
                color: #002A8D;
                position: relative;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:checked {
                    background-color: #002A8D;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 23px;
                    height: 23px;
                    background-color: white;
                    border-radius: 50%;
                    top: 1px;
                    left: 1px;
                    transition: transform 0.3s ease;
                }

                &:checked::before {
                    transform: translateX(25px);
                }
            }
        }
        .selector_con {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border: 1px solid #E5E5E5;
            border-radius: 10px;
            padding: 0.5rem;
            label {
                font-weight: bold;
                font-size: 1.2rem;
                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }
            }
            .date{
                height: 2.5rem;
            }
            select{
                color: #002A8D;
                outline: none;
                // padding: 0.5rem;
            }
            .single{
                @media  screen and (max-width: 600px) {
                    grid-column: span 2;
                    
                }
            }
        }
    }
}

.rbc-header {
    background-color: #2761D0;
    color: white;
    //     // height: 36px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    padding: 0.5rem;
    text-align: center;
}

.rbc-time-header-cell .rbc-today {
    background-color: #4EADCB;
    color: white;
}

.rbc-event-content,
.rbc-show-more,
.rbc-event-label {
    @media screen and (max-width: 1000px) {
        font-size: 0.6rem !important;
    }
}


.rbc-agenda-date-cell,
.rbc-agenda-event-cell,
.rbc-agenda-time-cell {
    @media screen and (max-width: 1000px) {
        font-size: 0.8rem !important;
    }
}

.rbc-agenda-view {
    table.rbc-agenda-table {
        tbody {
            tr {
                td:first-child {
                    span {
                        border: none !important;
                    }
                }
            }
        }
    }
}

