.MyLightningPlan{
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    width: 100%;
    place-items: center;
    background-color: white;
    padding: 2rem;
    border: 4px solid #4FAFCB;
    border-radius: 10px;
    .plan-body{
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media screen and (max-width: 550px){
            grid-template-columns: 1fr;
        }
        width: 100%;
        gap: 2rem;
        .my-connection{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: 1px solid #CBD3D9;
            border-radius: 10px;
            padding: 3rem ;
            p{
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 1rem;
                text-align: center;
            }
            .company-logo{
                height: 50px;
                display: grid;
                place-items: center;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .my-plan{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: 1px solid #CBD3D9;
            border-radius: 10px;
            padding: 3rem ;
            // width: -moz-fit-content;
            // width: fit-content;
            p{
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 1rem;
            }
           .plan{
                padding: 1rem;
                background-color: #2761D0;
                color: white;
                height: fit-content;
                border-radius: 10px;
                p{
                    padding: 0;
                    margin: 0;
                }
           }

        }
        button{
            background-color: #2761D0;
            color: white;
            padding: 1rem 2rem;
            font-size: 1rem;
            font-weight: 700;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            margin-top: auto;
            grid-column: span 2;
            @media screen and (max-width: 550px) {
                grid-column: span 1;
            }
        }
    }
}