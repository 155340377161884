.ContactCenter {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: 1rem;
    padding-top: 0rem;
    border-radius: 20px;
    border: 4px solid #4EADCB;
    height: 100%;

    @media screen and (max-width: 1200px) {
        width: auto;
        margin: 0.5rem;

    }

    // min-height: 80vh;
    @media screen and (max-width: 400px) {
        padding: 0.5rem;

    }

    .HeaderDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 0 20px;
        margin-bottom: 1rem;

        button {
            color: #2761D0;
            background-color: white;
            padding: 0.5em 1em;
            border: none;

            cursor: pointer;
            margin-top: 0em;
            width: -moz-fit-content;
            width: fit-content;
            justify-self: center;
            align-self: center;
            width: 100%;
            font-weight: 700;
            font-size: 1.2em;
            outline: none;

            &:focus{
                outline: none;
            }

            @media screen and (max-width: 1200px) {
                font-size: 1em;
                padding: 0.2em 0.5em;
            }
        }

        .active {
            color: white;
            background-color: #2761D0;
            outline: none;
        }

        button:nth-child(1) {
            border-radius: 0.5em 0em 0 0.5em;
        }

        button:nth-child(2) {
            border-radius: 0em 0.5em 0.5em 0em;
        }
    }

    .ContentDiv {
        display: flex;
        flex-direction: column;
        width: 100%;

        .Leads {

            .SearchFilters {
                padding: 5px;

                .search-bar {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    width: 100%;
                    align-items: center;
                    border: 1px solid #C4C4C4;
                    padding: 0rem 1rem;
                    border-radius: 10px;

                    @media screen and (max-width: 1200px) {
                        padding: 0rem 0.5rem;

                    }

                    input {
                        width: 100%;
                        padding: 0.1em;
                        margin: 1em 0;
                        outline: none;
                        font-size: 1rem;

                        @media screen and (max-width: 1200px) {
                            font-size: 0.8rem;
                        }

                        font-weight: 600;
                    }

                }

                .single {
                    grid-column: span 2;
                }

                .selectors {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    // @media screen and (max-width: 540px){
                    //     grid-template-columns: 1fr;
                    // }
                    justify-content: space-around;
                    align-items: center;
                    margin: 1rem 0rem;
                    gap: 0.5rem;

                    .async_select__control {
                        border: 1px solid #E5E5E5;
                        border-radius: 10px;
                        padding: 0.2rem;
                    }

                    .selector_con {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 1rem;
                        align-items: center;
                        border: 1px solid #E5E5E5;
                        padding: 0.5rem;
                        border-radius: 10px;

                        @media screen and (max-width: 1200px) {
                            gap: 0.5rem;

                        }

                        label {

                            // font-weight: bold;
                            @media screen and (max-width: 1200px) {
                                font-size: 0.7rem;
                            }
                        }

                        .check {
                            width: 1rem;
                            height: 1rem;
                            cursor: pointer;

                            @media screen and (max-width: 1200px) {
                                width: 0.7rem;
                                height: 0.7rem;
                            }
                        }

                        .toggle-switch {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            width: 100%;

                            label {
                                font-size: 1.2rem;
                                margin-right: 1rem;
                                font-weight: bold;
                            }

                            input {
                                width: 50px;
                                height: 25px;
                                -webkit-appearance: none;
                                appearance: none;
                                background-color: #c6c6c6;
                                outline: none;
                                border-radius: 50px;
                                position: relative;
                                cursor: pointer;
                                transition: background-color 0.3s ease;

                                &:checked {
                                    background-color: #41b6e6;
                                }

                                &::before {
                                    content: '';
                                    position: absolute;
                                    width: 23px;
                                    height: 23px;
                                    background-color: white;
                                    border-radius: 50%;
                                    top: 1px;
                                    left: 1px;
                                    transition: transform 0.3s ease;
                                }

                                &:checked::before {
                                    transform: translateX(25px);
                                }
                            }
                        }

                        .selector {
                            outline: none;
                            color: #2761D0;
                            // width: 100%;
                            // padding: 1rem;
                            font-size: 1rem;
                            border-radius: 10px;
                            // padding: 0.5rem 1rem;
                            width: 100%;

                            @media screen and (max-width: 1200px) {
                                // padding: 0.2rem 0.5rem;
                                font-size: 0.8rem;

                            }

                            // font-weight: bold;

                            option {
                                color: #475569;
                                padding: 1rem;
                                // font-weight: bold;

                                &:hover {
                                    background-color: #2761D0;
                                    color: white;

                                }
                            }
                        }
                    }

                    .date_range {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 0.5rem;
                        align-items: center;
                        justify-content: space-between;
                        grid-column: span 2;
                        border: 1px solid #E6E6E6;
                        border-radius: 10px;
                        padding: 0.5rem;

                        @media screen and (max-width: 1200px) {
                            padding: 0.5rem;
                            gap: 0.5rem;
                        }

                        label {
                            @media screen and (max-width: 1200px) {
                                font-size: 0.7rem;

                            }
                        }

                        width: 100%;

                        input {
                            color: #2761D0;
                            outline: none;
                            background-color: rgb(193 193 193);
                            font-size: normal;
                        }
                    }

                }


                // .filterCategories{
                //     margin-top: 1rem;
                //     margin-bottom: 1rem;
                //     display: flex;
                //     align-items: center;
                //     justify-content: space-between;
                //     gap: 1rem;
                //     @media screen and (max-width: 1200px) {
                //         gap: 0.5rem;
                //     }
                //     grid-column: span 2;
                //     @media screen and (max-width: 1200px) {
                //         grid-column: span 1;
                //     }
                //     .select{
                //         @media screen and (max-width: 1200px) {
                //             // padding: 0.2rem 0.5rem;
                //             font-size: 0.6rem;

                //         }
                //     }
                //     .select.active {

                //         background-color: #41B6E6; 
                //         color: white; 
                //       }
                //     button{
                //         padding: 0.5rem 1rem;
                //         border: none;
                //         background-color: #2761D0;
                //         color: white;
                //         border-radius: 10px;
                //         cursor: pointer;
                //         grid-column: span 2;
                //         @media screen and (max-width: 1200px){
                //             grid-column: span 1;
                //         }
                //         &:hover{
                //             background-color: #1A4F8E;
                //         }
                //     }
                // }
                .more_filters {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 1rem;

                    @media screen and (max-width: 1200px) {
                        grid-template-columns: 1fr;

                    }

                    .date_container {
                        display: flex;
                        align-items: center;
                        border: 1px solid #C4C4C4;
                        border-radius: 10px;
                        padding: 1em;

                        label {
                            width: 100%;
                            padding: 0px;
                        }

                        input[type='date'] {
                            padding: 0em;
                            border: none;
                            outline: none;
                        }
                    }

                    .leadTypesTitle {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        grid-column: span 2;
                        font-weight: bold;

                        @media screen and (max-width: 1200px) {
                            grid-column: span 1;
                        }

                        .deselect {
                            background-color: rgb(231, 98, 98);
                            cursor: pointer;
                        }

                    }

                    // .select_all{
                    //     display: flex;
                    //     align-items: center;
                    //     gap: 1rem;
                    //     @media screen and (max-width: 1200px) {
                    //         gap: 0.5rem;
                    //     }
                    //     grid-column: span 2;
                    //     @media screen and (max-width: 1200px) {
                    //         grid-column: span 1;
                    //     }
                    //     .select{
                    //         @media screen and (max-width: 1200px) {
                    //             // padding: 0.2rem 0.5rem;
                    //             font-size: 0.6rem;

                    //         }
                    //     }
                    //     .deselect{
                    //         background-color: rgb(231, 98, 98);
                    //         cursor: pointer;
                    //         @media screen and (max-width: 1200px) {
                    //             // padding: 0.2rem 0.5rem;
                    //             font-size: 0.6rem;

                    //         }
                    //     }
                    // }
                    .map_icon_selector_display {
                        display: flex;
                        gap: 1rem;
                        flex-wrap: wrap;
                        align-items: center;
                        width: 100%;
                        grid-column: span 2;

                        @media screen and (max-width: 1200px) {
                            grid-column: span 1;
                        }

                        .active {
                            border: 4px solid #2761D0 !important;
                        }

                        .map_icon {
                            width: 50px;
                            height: 50px;
                            border: 4px solid transparent;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0.2rem;

                            @media screen and (max-width: 1200px) {
                                height: 50px;
                                width: 50px;
                            }

                            border-radius: 50%;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .search_input {
                        grid-column: span 2;

                        @media screen and (max-width: 1200px) {
                            grid-column: span 1;
                        }

                        border: 1px solid #C4C4C4;
                        border-radius: 10px;
                        padding: 1em;
                    }

                    .more_btn {
                        padding: 0.5rem 1rem;
                        border: none;
                        background-color: #2761D0;
                        color: white;
                        border-radius: 10px;
                        cursor: pointer;
                        grid-column: span 2;

                        @media screen and (max-width: 1200px) {
                            grid-column: span 1;
                        }

                        &:hover {
                            background-color: #1A4F8E;
                        }
                    }
                }

                .expand_icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 1em;
                    grid-column: span 2;

                    @media screen and (max-width: 1200px) {
                        grid-column: span 1;
                    }

                    .ExpandIcon {
                        cursor: pointer;
                    }
                }

            }

            .LeadsList {
                display: flex;
                flex-direction: column;
                width: 100%;

                .leads_top_button {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    flex-wrap: wrap;
                    margin-top: 1rem;

                    h3 {
                        color: #5E6282;
                        font-weight: bold;
                    }

                    .page_size_container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 0.5rem;
                        color: #2761D0;
                        border-radius: 15px;
                        padding: 0.5rem 1rem;
                        width: -moz-fit-content;
                        width: fit-content;

                        @media screen and (max-width: 1200px) {
                            padding: 0.2rem 0.5rem;
                            font-size: 0.8rem;
                        }


                        // border: 1px solid #2761D0;
                        select {
                            color: #2761D0;
                            // border: 1px solid #2761D0;
                            border-radius: 10px;
                        }
                    }

                    .filter_btn {
                        padding: 0.5rem 1rem;

                        @media screen and (max-width: 1200px) {
                            padding: 0.2rem 0.5rem;
                            font-size: 0.8rem;
                            border-radius: 10px;
                        }

                        border: none;
                        background-color: #2761D0;
                        color: white;
                        border-radius: 15px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                    }

                    .export_button {
                        background-color: #81848A;
                    }

                    // .deselect_all{
                    //     background-color: rgb(231, 98, 98);
                    //     @media screen and (max-width: 1200px){
                    //         // padding: 0.2rem 0.5rem;
                    //         font-size: 0.6rem;
                    //     }
                    // }
                    // .select_all{
                    //     background-color: #2761D0;
                    //     @media screen and (max-width: 1200px){
                    //         // padding: 0.2rem 0.5rem;
                    //         font-size: 0.6rem;
                    //     }
                    // }
                }

                .LeadCard {
                    display: grid;
                    grid-template-columns: auto 1fr 1fr;
                    align-items: center;
                    gap: 1rem;
                    width: 100%;
                    padding: 1em;
                    margin: 1em 0;
                    // border-radius: 0.5em;
                    border-bottom: 1px solid #2761D0;

                    @media screen and (max-width: 800px) {
                        grid-template-columns: auto 1fr auto;
                        // gap: 0.5rem;
                        padding: 1em 0em;
                    }

                    .dbt_1 {
                        display: none;

                        @media screen and (max-width: 800px) {
                            display: flex;

                        }
                    }

                    .dbt_2 {

                        // display: flex;
                        @media screen and (max-width:800px) {
                            display: none;

                        }
                    }

                    .ImagesDiv {
                        position: relative;
                        height: fit-content;
                        width: -moz-fit-content;
                        width: fit-content;
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;

                        .Image {
                            img {
                                width: 100px;
                                height: 100px;
                                object-fit: cover;

                                @media screen and (max-width: 1200px) {
                                    width: 50px;
                                    height: 50px;

                                }

                                border-radius: 50%;
                            }

                            h2 {
                                border-radius: 50%;
                                background-color: #2761D0;
                                color: white;
                                width: 100px;
                                height: 100px;

                                @media screen and (max-width: 1200px) {
                                    width: 50px;
                                    height: 50px;

                                }

                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 2em;
                            }
                        }

                        .MapIcon {
                            // position: relative;
                            // bottom: 2.4em;
                            // left: 3em;
                            position: absolute;
                            bottom: 0;
                            right: 0;

                            img {
                                width: 50px;
                                height: 50px;

                                @media screen and (max-width: 1200px) {
                                    width: 25px;
                                    height: 25px;

                                }

                                border-radius: 50%;
                            }
                        }
                    }

                    .DataDiv {
                        display: flex;
                        flex-direction: column;

                        p {
                            @media screen and (max-width: 1200px) {
                                font-size: 0.8em;
                            }
                        }

                        .Name {
                            font-size: 1.5em;

                            @media screen and (max-width: 1200px) {
                                font-size: 1em;
                            }

                            font-weight: bold;
                        }

                        .Phone {
                            font-size: 1.2em;

                            @media screen and (max-width: 1200px) {
                                font-size: 0.9em;

                            }
                        }

                        .Address {
                            font-size: 1.2em;

                            @media screen and (max-width: 1200px) {
                                font-size: 0.9em;

                            }
                        }
                    }

                    .MainLeadCard_Buttons {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;

                        @media screen and (max-width: 1200px) {
                            gap: 0.2rem;
                        }

                        justify-content: flex-end;

                        @media screen and (max-width: 800px) {
                            grid-column: span 2 /span 2;
                            justify-content: space-between;
                        }

                        .MainLeadCard_Button {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            padding: 0.5em;

                            @media screen and (max-width: 1200px) {
                                padding: 0.1em;
                            }

                            cursor: pointer;
                            border-radius: 0.5em;
                            // background-color: #2761D0;
                            color: black;
                            width: -moz-fit-content;
                            width: fit-content;

                            &:hover {
                                transform: scale(1.1);
                            }

                            img {
                                width: 2rem;

                                @media screen and (max-width: 1200px) {
                                    width: 2rem;

                                }
                            }

                            span {
                                font-size: 0.8em;
                                color: #81848A;

                                @media screen and (max-width: 1200px) {
                                    font-size: 0.5em;

                                }
                            }
                        }
                    }

                    // .additional_info{
                    //     display: flex;
                    //     align-items: flex-start;
                    //     justify-content: flex-start;
                    //     flex-direction: column;
                    //     p{
                    //         color: #2761D0;
                    //         font-weight: bold;
                    //     }
                    // }
                    //you can use this just as a starting point if you like. but i suggest start from scratch
                    // .MainLeadCard_Buttons {
                    //     display: flex;
                    //     justify-content: space-evenly;
                    //     margin-top: 1em;


                    //     .MainLeadCard_Button {
                    //         display: flex;
                    //         flex-direction: column;
                    //         align-items: center;
                    //         justify-content: center;
                    //         width: 5em;
                    //         padding: 1em;
                    //         cursor: pointer;

                    //         &:hover {
                    //             transform: scale(1.1);
                    //         }

                    //         img {
                    //             width: 3em;
                    //             @media screen and (max-width: 1200px){
                    //                 width: 2em;

                    //             }
                    //             @media screen and (max-width: 420px){
                    //                 width: 1.5em;

                    //             }
                    //             // max-height: 2em;

                    //         }

                    //         span {
                    //             color: #5E6282;
                    //             margin-top: 0.5em;
                    //             cursor: pointer;
                    //             @media screen and (max-width: 1200px){
                    //                 font-size: 0.6rem;

                    //             }
                    //             @media screen and (max-width: 420px){
                    //                 font-size: 0.5rem;

                    //             }
                    //             &:hover {
                    //                 transform: scale(1.1);
                    //             }
                    //         }
                    //     }
                    // }
                }
            }
        }
    }
}

.NewCandidateCard {
    // display: "flex",
    //     gap: "1rem",
    //     alignItems: "center",
    //     justifyContent: "space-between",
    //     borderBottom: "1px solid var(--primary-color)",
    //     marginTop: "2rem",
    //     padding: "1rem 0",

    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #2761D0;
    margin: 2rem 0;
    padding: 1rem 0;
     @media screen and (max-width: 750px) {
        flex-direction: column;
        gap: 0.5rem;
        align-items: start;;
     }

     .NewCandidateButtons{
            display: flex;
            align-items: center;
            gap: 1rem;
            
            @media screen and (max-width: 750px) {
                
                justify-content: space-evenly;
                width: 100%;
            }
     }


}

.mapicon_con {
    display: flex;
    overflow: scroll;
    gap: 0.5rem; 
    grid-column: span 2;
    width: 100%;
    align-items: center;
    padding: 1rem 0rem;

    @media screen and (max-width: 1200px) {
        gap: 0.5rem;
        padding: 0.5rem 0rem;
        scrollbar-width: none;
    }

    .map_icon {
        width: 3.5rem;  
        height: 3.5rem; 
        border: 2px solid transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.3rem;
        border-radius: 50%;

        img {
            width: 3.5rem; 
            height: 3.5rem;
            max-width: unset;
            border-radius: 50%;
        }
    }

    .active {
        border: 2px solid #2761D0;
    }
}

.reset_all_fitlers {
    background-color: red;
    color: white;
    font-size: 0.9rem;
    padding: 0.2rem 0.5rem;
    border-radius: 10px;
    margin-top: 1rem;
    outline: none;

    &:focus{
        outline: none;
    }
}


.customm-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        text-align: center;
    }

    .modal-content {
        background-color: #fff;
        padding: 2rem;
        border-radius: 10px;
        width: 50vh;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

        button {
            padding: 0.5rem;
            border: none;
            border-radius: 5px;
            margin-top: 1rem;
            background-color: rgb(24, 30, 75);
            color: white;
            font-weight: bold;
            cursor: pointer;
            outline: none;
            transition: all 0.3s;

            &:hover {
                background-color: rgb(24, 30, 75);
            }
        }

        .form {
            padding: 1rem;
            background-color: white;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            height: fit-content;
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;

            h2 {
                font-size: 1.2rem;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
            }

            .async_select__control {
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
                padding: 0.2rem;
            }

            input {
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
            }

            button {
                padding: 0.5rem;
                border: none;
                border-radius: 5px;
                background-color: rgb(24, 30, 75);
                color: white;
                font-weight: bold;
                cursor: pointer;
                outline: none;
                transition: all 0.3s;

                &:hover {
                    background-color: rgb(24, 30, 75);
                }
            }
        }

        .fiber_plans {

            border-radius: 10px;
            display: grid;
            grid-template-columns: 1fr;
            margin-top: 1rem;
            gap: 0.5rem;

            .fiber_plan {
                display: grid;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                background-color: white;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                grid-template-columns: 1fr auto;
                // @media screen and (max-width: 530px){
                //     grid-template-columns: 1fr;

                // }
                padding: 1rem;
                border-radius: 10px;
                align-items: center;

                h3 {
                    font-size: 1.5rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: center;
                    grid-column: span 2;
                }

                div {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 0.5rem;
                    padding-left: 1rem;

                    p {
                        font-size: 0.9rem;
                        color: #84A1C8;
                        // text-align: center;
                    }

                    p:last-child {
                        grid-column: span 2;
                    }
                }

                button {
                    background-color: #e36363;
                    padding: 0.5rem;
                    border-radius: 10px;
                    width: -moz-fit-content;
                    width: fit-content;

                    @media screen and (max-width: 530px) {

                        margin: auto;

                    }

                    &:hover {
                        background-color: red;
                    }
                }
            }
        }
    }
}