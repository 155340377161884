.invitation-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  max-height: 90vh;
  overflow: auto;

  &.mobile {
    padding: 16px;
  }

  .scrollable-content {
    max-height: 60vh; /* Adjust this height based on how much content you want to display before scrolling */
    overflow-y: auto; /* Make this section scroll vertically when necessary */
    padding-right: 8px; /* Add padding for better scroll visibility */
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &.mobile {
      gap: 16px;
    }
  }

  .template-select {
    width: 100%;
  }

  .preview-image {
    align-self: center;
    img {

      max-width: 300px;
      max-height: 200px;
      border-radius: 4px;
    }
  }

  .message-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    .action-button {
      flex: 1;
      max-width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #1976d2;

      &:hover {
        background-color: rgba(25, 118, 210, 0.04);
      }

      .MuiTypography-caption {
        margin-top: 4px;
      }
    }
  }
}

// Mobile styles
@media (max-width: 600px) {
  .invitation-modal {
    .preview-image {
      img {
        height: 150px;
      }
    }

    .action-buttons {
      flex-direction: row;
      gap: 8px;

      .action-button {
        width: auto;
      }
    }
  }
}