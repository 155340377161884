.createTrainingModuleContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 1210px) {
        background-color: white;
        border-radius: 20px;
        border: 4px solid #4FAFCB;
        padding-top: 1rem;
    }

    h1 {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: center;
        color: var(--color-icon);
    }

    .button_tab {
        display: flex;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        margin-bottom: 1rem;
        background-color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 0.5rem 1rem;
        border-radius: 10px;

        p {
            padding: 0.5rem 1rem;
            border: none;
            border-radius: 10px;
            color: #2761D0;
            font-size: 1rem;
        }

        p.active {
            background-color: #2761D0;
            color: white;
        }
    }
}

.createTrainingModuleInnerContainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    @media screen and (max-width: 1210px) {
        grid-template-columns: 1fr;
    }

    .createTrainingModuleForm {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        @media screen and (max-width: 1000px) {
            padding: 0rem;
        }
        gap: 1rem;
        background-color: var(--color-foreground);
        border-radius: 20px;
        border: 4px solid #4FAFCB;

        @media screen and (max-width: 1210px) {
            border: none;
        }

        input {
            width: 100%;
            height: 3rem;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            padding: 0 1rem;
            font-size: 1rem;
            font-weight: 500;

            &:focus {
                outline: none;
            }
        }

        .createTrainingModuleDropzone {
            width: 100%;
            height: 100%;
            margin-bottom: 1rem;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            padding: 0 1rem;
            font-size: 1rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            p {
                font-size: 1rem;
                font-weight: 500;
                text-transform: uppercase;
            }
        }

        .createTrainingModuleImagePreviewContainer {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                width: 200px;
                height: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                border-radius: 10px;
                border: 4px solid #2761D0;
                overflow: hidden;

                svg {
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        textarea {
            width: 100%;
            height: 10rem;
            margin-bottom: 1rem;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            padding: 1rem;
            font-size: 1rem;
            font-weight: 500;
            resize: none;

            &:focus {
                outline: none;
            }
        }
    }

    .thumbnailPreviewContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;

        @media screen and (max-width: 430px) {
            padding: 1rem;
        }

        gap: 1rem;

        h1 {
            font-size: 1.5rem;
            font-weight: 600;
        }

        .thumbnailPreview {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            .thumbnailPreviewImage {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                border: 1px solid #E5E5E5;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .thumbnailPreviewButton {
                width: 100%;
                height: 3rem;
                border: none;
                border-radius: 5px;
                background-color: #000000;
                color: var(--color-foreground);
                font-size: 1rem;
                font-weight: 500;
                cursor: pointer;

                &:focus {
                    outline: none;
                }
            }
        }
    }
}