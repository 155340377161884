.edit_module {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    border-radius: 10px;
    background-color: white;
    padding: 1rem;
    width: 50%;
    @media screen and (max-width: 1000px) {
        width: 90%;
    }
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    h1 {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
        @media screen and (max-width: 447px) {
            font-size: 1.2rem;
        }
    }

    .edit_module_image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            width: 100%;
            height: 200px !important;
            object-fit: contain;
            border-radius: 5px;
        }
        video {
            width: 100%;
            height: 200px !important;
            object-fit: cover;
            border-radius: 5px;
        }
    }
    input {
        width: 100%;
        padding: 0.5rem;
        margin: 0.5rem 0;
        border: 1px solid rgb(209, 208, 208);
        border-radius: 5px;
    }
    textarea {
        width: 100%;
        padding: 0.5rem;
        margin: 0.5rem 0;
        border: 1px solid rgb(209, 208, 208);
        border-radius: 5px;
    }
    .button_div {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        gap: 0.5rem;
        button {
            padding: 0.5rem 1rem;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-weight: 700;
            background-color: #2761D0;
        }
    }
}

.trainingModule {
    width: 391px;
    @media screen and (max-width: 447px) {

    }

    background-color: var(--color-foreground);
    border-radius: 10px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(209, 208, 208);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .trainingModuleImage {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        overflow: hidden;
        background-color: #f0f0f0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .headingAndDownload {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        h3 {
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 1rem;
        }
    }

    p {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 1rem;
        text-align: left;
        width: 100%;
        color: rgb(170, 170, 170);
    }
}