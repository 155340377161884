.ce-block__content,
.ce-toolbar__content {
    max-width: calc(100% - 100px) !important;
}

.cdx-block {
    max-width: 100% !important;
}

.codex-editor__loader {
    height: 38px !important;
}

.codex-editor__redactor {
    padding-bottom: 38px !important;
}

#editorjs {
  // border: 1px solid #ccc; // Add a border for visibility
}

// Add styles for different heading levels
.ce-block__content h1 {
  font-size: 2em !important;
  color: #333;
}

.ce-block__content h2 {
  font-size: 1.75em !important;
  color: #555;
}

.ce-block__content h3 {
  font-size: 1.5em !important;
  color: #777;
}

.ce-block__content h4 {
  font-size: 1.25em !important;
  color: #999;
}

.ce-block__content h5 {
  font-size: 1em !important;
  color: #bbb;
}

.ce-block__content h6 {
  font-size: 0.75em !important;
  color: #ddd;
}

// Add styles for text alignment
.ce-block__content--align-left {
  text-align: left;
}

.ce-block__content--align-center {
  text-align: center;
}

.ce-block__content--align-right {
  text-align: right;
}

.ce-code__textarea {
    min-height: 100px;
}