.manage-recruit-templates {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px); // Assuming a 64px header in the Layout component
    overflow: hidden;
  
    .page-header {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      padding: 20px;
    }
  
    .template-content {
      display: flex;
      flex: 1;
      overflow: hidden;
    }
  
    .template-list-container {
      flex: 0 0 300px;
      display: flex;
      flex-direction: column;
      background-color: #f5f5f5;
      padding: 20px;
    }
  
    .template-list {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 20px;
    }
  
    .template-item {
      padding: 15px;
      margin-bottom: 10px;
      background-color: #ffffff;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease;
  
      &:hover {
        background-color: #e0e0e0;
      }
  
      &.active {
        background-color: #2196f3;
        color: #ffffff;
      }
  
      &.add-template {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4caf50;
        color: #ffffff;
  
        &:hover {
          background-color: #45a049;
        }
  
        svg {
          margin-right: 10px;
        }
      }
    }
  
    .template-details {
      flex: 1;
      padding: 0px 20px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;


      .requirement-input {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
  
        input {
          flex-grow: 1;
          margin-right: 10px;
          margin-bottom: 0;
        }
  
        .delete-requirement-btn {
          background-color: transparent;
          border: none;
          color: #f44336;
          cursor: pointer;
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: color 0.3s ease;
  
          &:hover {
            color: #d32f2f;
          }
  
          svg {
            font-size: 20px;
          }
        }
      }
  
      .add-requirement-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4caf50;
        color: white;
        border: none;
        padding: 10px 15px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-bottom: 20px;
  
        &:hover {
          background-color: #45a049;
        }
  
        svg {
          margin-right: 5px;
        }
      }
  
      .template-actions {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
  
        svg {
          cursor: pointer;
          margin-left: 10px;
          font-size: 24px;
          color: #757575;
  
          &:hover {
            color: #2196f3;
          }
        }
      }
  
      .template-name-input {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        font-size: 16px;
      }
      label {
        font-size: 16px;
        font-weight: bold;
      }

      input {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        font-size: 16px;
        height: 50px;
  
        &:disabled {
          background-color: #f5f5f5;
          cursor: not-allowed;
        }
      }
  
      textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        font-size: 16px;
        min-height: 300px;
        max-height: 300px;
        resize: vertical;
  
        &:disabled {
          background-color: #f5f5f5;
          cursor: not-allowed;
        }
      }
  
      .image-upload {
        margin-bottom: 20px;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        height: 300px;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
  
        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          object-fit: contain;
        }
  
        .upload-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 15px 30px;
          background-color: rgba(33, 150, 243, 0.8);
          color: #ffffff;
          border: none;
          border-radius: 50px;
          cursor: pointer;
          font-size: 16px;
          transition: all 0.3s ease;
          z-index: 10;
  
          &:hover {
            background-color: rgba(25, 118, 210, 0.9);
          }
  
          svg {
            font-size: 24px;
            margin-bottom: 5px;
          }
  
          span {
            font-weight: bold;
          }
        }
      }
  
      button {
        padding: 10px 20px;
        background-color: #2196f3;
        color: #ffffff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #1976d2;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .manage-recruit-templates {
      .template-content {
        flex-direction: column;
      }
  
      .template-list-container {
        flex: 0 0 auto;
        max-height: 40vh;
      }
  
      .template-list {
        max-height: calc(40vh - 60px); // Subtracting height of add button
      }
  
      .template-details {
        flex: 1;
        margin-left: 0;
        margin-top: 15px;
        display: flex;
        flex-direction: column;

        input {
            flex-grow: 1;
            min-height: 50px;
            height: auto;
            margin-bottom: 15px;
          }
  
        textarea {
          flex-grow: 1;
          min-height: 200px;
          height: auto;
        }
  
        .image-upload {
          height: 200px;
  
          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .manage-recruit-templates {
      .template-details {
        textarea {
          min-height: 150px;
        }
        input {
            min-height: 50px;
        }
        .image-upload {
          height: 150px;
        }
      }
    }
  }