.createFiberPlanContainer{
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    div{
        width: -moz-fit-content;
        width:fit-content;
        height:100%;
        display:grid;
        grid-template-columns:1fr;
        gap:1rem;
        align-items:center;
        border-radius:20px;
        border:1px solid #E5E5E5;
        padding:3rem;
        background-color:var(--color-foreground);
        h3{
            font-size:2rem;
            font-weight:600;
            margin-bottom:2rem;
        }
        input{
            width:100%;
            height:3rem;
            margin-bottom:1rem;
            border:1px solid #000000;
            border-radius:5px;
            padding:0 1rem;
            font-size:1rem;
            font-weight:500;
            &:focus{
                outline:none;
            }
        }

        button{
            width:100%;
            height:3rem;
            border:none;
            border-radius:5px;
            background-color:#000000;
            color:var(--color-foreground);
            font-size:1rem;
            font-weight:500;
            cursor:pointer;
            &:focus{
                outline:none;
            }
        }
    }
}