.our_services_container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 2rem;

    h1 {
        font-size: 4rem;

        @media screen and (max-width: 700px) {
            font-size: 2rem;
        }

        text-align: center;
        margin-bottom: 2rem;
        color: var(--primary-text);
        font-weight: 600;
    }

    .services_banner {
        width: -moz-fit-content;
        width: fit-content;
        display: grid;
        grid-template-columns: 1fr;
        margin: auto;
        position: relative;
        padding: 1rem;
        .banner_top {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            z-index: 1;
            position: relative;
            @media screen and (max-width:1000px) {
                place-items: center;
            }
            .package_light{
                position: absolute;
                left: 0%;
                top: -50%;
                // translate: transform(-50%, 0%);
            }
            .banner_card {
                background-color: #fff;
                padding: 1rem;
                border-radius: 30px;
                width: 180px;
                border: 4px solid var(--border-color);
                @media screen and (max-width: 700px) {
                    width: 150px;
                }

                @media screen and (max-width: 430px) {
                    width: 120px;

                }

                position: relative;
                border: 4px solid #D9D9D9;
                cursor: pointer;

                p {
                    font-size: 1.2rem;

                    @media screen and (max-width: 700px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 430px) {
                        font-size: 0.8rem;
                    }

                    font-weight: 600;
                    color: #6F6C90;
                    text-align: left;
                }

                h2 {
                    font-size: 3rem;

                    @media screen and (max-width: 700px) {
                        font-size: 2rem;
                    }

                    @media screen and (max-width: 430px) {
                        font-size: 1.5rem;

                    }

                    font-weight: 600;
                    color: #000;
                    text-align: left;
                }

                h4 {
                    font-size: 1.5rem;

                    @media screen and (max-width: 700px) {
                        font-size: 1.2rem;
                    }

                    @media screen and (max-width: 430px) {
                        font-size: 1rem;

                    }

                    font-weight: 600;
                    color: #000;
                    text-align: left;
                }
            }


            .active {
                border: 4px solid #2761D0;

            }
        }

        .custom_border {
            width: 500px;
            height: 250px;

            @media screen and (max-width: 700px) {
                width: 100%;
                height: 200px;
                top: 40%;
            }

            @media screen and (max-width: 430px) {
                width: 100%;
                height: 200px;

            }

            border: 4px solid #fff;
            border-radius: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 0;
            transform: translate(-50%, -50%);
        }

        .banner_bottom {
            z-index: 1;
            background-color: #fff;
            padding: 2rem;
            border-radius: 10px;
            margin-top: 1rem;
            border: 4px solid var(--border-color);
            .service_card {
                display: flex;
                align-items: center;
                gap: 1rem;
                justify-content: flex-start;
                margin-bottom: 1rem;

                p {
                    font-size: 1.2rem;

                    @media screen and (max-width: 700px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 430px) {
                        font-size: 0.8rem;

                    }

                    color: #6F6C90;
                }
            }
        }
    }

    .availability_form {
        width: 80%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin: auto;
        padding: 8rem;

        @media screen and (max-width: 1050px) {
            padding: 4rem;
        }

        @media screen and (max-width: 700px) {
            padding: 1rem;

        }

        border-radius: 30px;
        overflow: hidden;

        .form {
            display: grid;
            grid-template-columns: 1fr;
            background-color: #fff;
            margin: auto;
            width: -moz-fit-content;
            width: fit-content;
            padding: 1rem 4rem;

            @media screen and (max-width: 700px) {
                padding: 1rem 2rem;
            }

            border-radius: 30px;

            h1 {
                font-size: 3rem;

                @media screen and (max-width: 1050px) {
                    font-size: 2rem;
                }

                @media screen and (max-width: 700px) {
                    font-size: 1.5rem;

                }

                text-align: center;
                margin-bottom: 2rem;
                color: #000;
                font-weight: 600;
            }

            .tabs {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1rem;
                margin-bottom: 1rem;

                button {
                    padding: 0.5rem 1rem;
                    border: none;
                    border-radius: 10px;
                    outline: none;
                    font-size: 1.2rem;
                    background-color: #fff;
                    color: #000;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    @media screen and (max-width: 920px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 620px) {
                        font-size: 0.8rem;

                    }

                    &:hover {
                        background-color: #2761D0;
                        color: #fff;
                    }

                }

                .active {
                    background-color: #2761D0;
                    color: #fff;

                }
            }

            .input_container {
                display: grid;
                grid-template-columns: 1fr;
                gap: 1rem;
                margin-bottom: 2rem;
                .consent{
                    display: grid;
                    grid-template-columns: auto 1fr;
                    gap: 2rem;
                    @media screen and (max-width:1000px) {
                        gap: 0.5rem;
                    }
                    place-items: center;
                    input{
                        width: 20px;
                        height: 20px;
                        border-radius: 40px;
                    }
                }
                input {
                    padding: 0.5rem 1rem;
                    border: none;
                    border-radius: 10px;
                    outline: none;
                    font-size: 1.2rem;
                    border: 1px solid #EAEAEA;
                    text-align: center;

                    @media screen and (max-width: 700px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 430px) {
                        font-size: 0.8rem;

                    }

                }
                .custom_address_selector{
                    position: relative;
                    input {
                        padding: 0.5rem 1rem;
                        border: none;
                        border-radius: 10px;
                        outline: none;
                        font-size: 1.2rem;
                        border: 1px solid #EAEAEA;
                        text-align: center;
                        width: 100%;
                        @media screen and (max-width: 700px) {
                            font-size: 1rem;
                        }
    
                        @media screen and (max-width: 430px) {
                            font-size: 0.8rem;
    
                        }
    
                    }
                    .location_options{
                        position: absolute;
                        top: 100%;
                        background-color: white;
                        width: 100%;
                        box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
                        border-radius: 10px;
                        padding: 1rem;
                        .option{
                            padding: 0.5rem 1rem;
                            border-radius: 10px;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out;
                            &:hover{
                                background-color: #EAEAEA;
                            }
                        }
                    }
                }
                button {
                    padding: 0.5rem 1rem;
                    border: none;
                    border-radius: 10px;
                    outline: none;
                    font-size: 1.2rem;
                    background-color: #2761D0;
                    color: #fff;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    @media screen and (max-width: 700px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 430px) {
                        font-size: 0.8rem;

                    }

                    &:hover {
                        background-color: #1E4E9B;
                    }
                }
            }
        }
    }

    .tv_streaming {
        width: 80%;
        margin: auto;
        margin-top: 2rem;
    }

    .channel_list {
        display: grid;
        grid-template-columns: 1fr;
        background-color: #fff;
        padding: 2rem 4rem;
        border: 4px solid var(--border-color);
        @media screen and (max-width:700px) {
            padding: 2rem;
        }

        border-radius: 30px;
        width: 60%;

        @media screen and (max-width: 500px) {
            width: 80%;
        }

        margin: auto;
        margin-bottom: 15rem;

        h1 {
            font-size: 3rem;

            @media screen and (max-width:700px) {
                font-size: 2rem;
            }

            text-align: center;
            margin-bottom: 1rem;
            margin-top: 4rem;
            color: #000;
            font-weight: 600;

            span {
                color: #2761D0;
            }
        }

        .channel_services {
            display: grid;
            grid-template-columns: 1fr;
            place-items: center;
            gap: 1rem;

            @media screen and (max-width: 700px) {
                gap: 0.5rem;

            }

            .channel_service_item {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                justify-content: center;
            }
        }

        .channel_icons {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            place-items: center;

        }

        .more {
            color: #2761D0;
            font-size: 1.3rem;

            @media screen and (max-width: 700px) {
                font-size: 1rem;

            }

            font-weight: 500;
            text-align: center;
        }

        .form {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
            border: 4px solid #022780;
            padding: 1rem 2rem;
            border-radius: 30px;
            margin-bottom: -25%;

            @media screen and (max-width: 700px) {
                margin-bottom: -50%;
            }

            background-color: #fff;
            place-items: center;

            h2 {
                font-size: 2rem;

                @media screen and (max-width: 700px) {
                    font-size: 1.5rem;

                }

                text-align: center;
                color: #000;
                font-weight: 600;
            }

            input {
                padding: 0.5rem 1rem;
                border: none;
                border-radius: 10px;
                outline: none;
                font-size: 1.2rem;

                @media screen and (max-width: 700px) {
                    font-size: 1rem;

                }

                border: 1px solid #EAEAEA;
                text-align: center;
                width: 80%;

                @media screen and (max-width: 700px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 430px) {
                    font-size: 0.8rem;

                }
            }

            button {
                padding: 0.5rem 1rem;
                border: none;
                width: 80%;
                border-radius: 10px;
                outline: none;
                font-size: 1.2rem;
                background-color: #2761D0;
                color: #fff;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                @media screen and (max-width: 700px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 430px) {
                    font-size: 0.8rem;

                }

                &:hover {
                    background-color: #1E4E9B;
                }
            }

        }
    }

    .phone_service {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;

        .phone_banner {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2rem;
            place-items: center;
            position: relative;
            z-index: 1;

            .pic {
                width: -moz-fit-content;
                width: fit-content;
                margin: auto;
                z-index: 1;

                img {
                    width: 70%;
                    height: 70%;

                    @media screen and (max-width: 1000px) {
                        width: 50%;
                        height: 50%;

                    }

                    margin: auto;
                }
            }

            .custom_border {
                z-index: 0;
                width: 45%;

                @media screen and (max-width: 1000px) {
                    width: 50%;
                    height: 68%;
                }

                @media screen and (max-width: 700px) {
                    width: 60%;
                    height: 65%;
                }

                @media screen and (max-width: 500px) {
                    width: 65%;
                    height: 67%;

                }

                height: 60%;
                border: 4px solid #fff;
                border-radius: 30px;
                position: absolute;
                top: 60%;
                left: 50%;
                z-index: 0;
                transform: translate(-50%, -60%);
            }

            .text {
                z-index: 1;

                h3 {
                    font-size: 2rem;

                    @media screen and (max-width: 700px) {
                        font-size: 1.2rem;
                    }

                    text-align: center;
                    margin-bottom: 1rem;
                    color: #fff;
                    font-weight: 600;

                    span {
                        color: #51BAFE;
                    }
                }
            }

            .bottom {
                z-index: 1;
                background-color: #fff;
                padding: 2rem;
                width: 60%;
                border: 4px solid var(--border-color);
                @media screen and (max-width: 700px) {
                    width: 80%;

                }

                border-radius: 30px;
                display: grid;
                grid-template-columns: 1fr;
                gap: 1rem;

                div {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    gap: 1rem;
                    align-items: center;

                    @media screen and (max-width: 480px) {
                        gap: 0.5rem;
                    }

                    p {
                        font-size: 1.2rem;

                        @media screen and (max-width: 1170px) {
                            font-size: 1rem;
                        }

                        @media screen and (max-width: 570px) {
                            font-size: 0.8rem;

                        }

                        @media screen and (max-width: 480px) {
                            font-size: 0.7rem;

                        }

                        @media screen and (max-width: 43v0px) {}

                        color: #6F6C90;
                    }
                }
            }
        }

    }

    .ask_lightning_os {
        position: relative;
        margin-bottom: 8rem;
        margin-top: 8rem;

        .right_bot {
            position: absolute;
            right: 0;
            top: 0;


            @media screen and (max-width: 1600px) {
                width: 45%;
            }
            @media screen and (max-width: 480px) {
                opacity: 0.5;                
            }
      
        }

        .left_bot {
            position: absolute;
            left: 0;
            top: 0;

            @media screen and (max-width: 1600px) {
                width: 45%;
            }
            @media screen and (max-width: 480px) {
                opacity: 0.5;                
            }

        }

        .text {
            display: grid;
            grid-template-columns: 1fr;
            place-items: center;
            h1{
                font-size: 3rem;

                @media screen and (max-width: 700px) {
                    font-size: 2rem;
                }

                text-align: center;
                margin-bottom: 1rem;
                color: var(--primary-text);
                font-weight: 600;
            }
            p {
                font-size: 1.2rem;

                @media screen and (max-width: 1170px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 570px) {
                    font-size: 0.8rem;

                }

                @media screen and (max-width: 480px) {
                    font-size: 0.7rem;

                }

                @media screen and (max-width: 430px) {
                    font-size: 0.6rem;

                }

                color: #fff;
                text-align: center;
            }

            button {
                background-color: #022780;
                color: #fff;
                padding: 0.5rem 3rem;
                margin: auto;
                font-weight: 600;
                font-size: 1.2rem;
                border-radius: 15px;
            }
        }
    }
}