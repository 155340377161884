.audioPlayer {
    --primary: #2761D0;
    --secondary: #fff;
  
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    // gap: ;
    // border: 1px solid #C4C4C4;
    // padding: 0.5rem 1rem;
    // border-radius: 10px;
  }
  
  .forwardBackward {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    font-family: monospace;
    font-size: 16px;
    cursor: pointer;
  }
  
  .forwardBackward:hover {
    color: var(--primary);
  }
  
  .playPause {
    background: var(--primary);
    border: none;
    border-radius: 50%;
    // width: 50px;
    // height: 50px;
    padding: 1rem;
    @media screen and (max-width: 600px){
        // padding: 0.5rem;
    }
    font-size: 16px;
    color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items:center;
  }
  
  .play {
    // position: relative;
    // left: 5px;
  }
  
  .currentTime,
  .duration {
    // font-family: monospace;
    font-size: 16px;
  }
  
  .currentTime {
    margin-left: 10px;
    margin-right: 10px;
  }

  .duration{
    padding-left: 10px;
  }
  
  .progressBar {
    --bar-bg: #E8E7FF;
    --seek-before-width: 0;
    --seek-before-color: #2761D0;
    --knobby: #181E4B;
    --selectedKnobby: #26c9c3;
  
    appearance: none;
    background: var(--bar-bg);;
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 5px;
    outline: none;
  }
  
  /* progress bar - safari */
  .progressBar::-webkit-slider-runnable-track {
    background: var(--bar-bg);;
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 5px;
    outline: none;
  }
  
  /* progress bar - firefox */
  .progressBar::-moz-range-track {
    background: var(--bar-bg);;
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 5px;
    outline: none;
  }
  
  .progressBar::-moz-focus-outer {
    border: 0;
  }
  
  /* progress bar - chrome and safari */
  .progressBar::before {
    content: '';
    height: 5px;
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }
  
  /* progress bar - firefox */
  .progressBar::-moz-range-progress {
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 5px;
  }
  
  /* knobby - chrome and safari */
  .progressBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: none;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    margin: -5px 0 0 0;
    z-index: 3;
    box-sizing: border-box;
  }
  
  /* knobby while dragging - chrome and safari */
  .progressBar:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }
  
  /* knobby - firefox */
  .progressBar::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: transparent;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
  }
  
  /* knobby while dragging - firefox */
  .progressBar:active::-moz-range-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }