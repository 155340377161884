.container_for_reviews{
    // max-width: 124rem;
    // width: 80%;
    // padding: 4rem 1rem;
    // margin: 0 auto;
    min-width: 100%;
    padding: 4rem 10%;
    padding-bottom: 0rem;
    @media screen and (max-width: 800px) {
        padding: 4rem 0%;
        padding-bottom: 0rem;
    }
    webkit-overflow-scrolling: touch;
    .swiper-button-next::after, .swiper-button-prev::after {
        color: white;
      }

      .swiper-slide {
        /* Styles for all slides */
        opacity: 0.7;
      }
      
      .swiper-slide-active {
        /* Override styles for the active slide */
        opacity: 1;
      }
}

.review_preview{
    width: 100%;
    padding: 4rem 10%;
    padding-top: 0rem;
    overflow: hidden;
    @media screen and (max-width: 800px) {
        padding: 4rem 0%;
        padding-top: 0rem;
    }
    position: relative;
    .review_buttons{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        place-items: center;
        gap: 2rem;
        z-index: 1;
        position: relative;
        button{
            background-color: #022780;
            border: none;
            padding: 0.5rem 2rem;
            border-radius: 10px;
            font-size: 1rem;
            height: 100%;
            @media screen and (max-width: 1000px){
                font-size: 0.7rem;
            }
            @media screen and (max-width: 500px){
                font-size: 0.5rem;
            }
            font-weight: 700;
            color: #fff;
            cursor: pointer;
            transition: all 0.3s ease;
            width: 100%;
            position: relative;
            img{
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
            }

        }

        .active{
            background-color: #2761D0;
            transition : all 0.3s ease;
        }
    }
    .blue_background{
        position: absolute;
        width: 70%;
        height: 70%;
        background-color: #022780;
        border-radius: 30px;
        bottom: 8%;
        left: 50%;
        transform: skewX(-30deg) translateX(-50%);
        z-index: 0;
    }
    .preview{
        width: 100%;
        padding: 4rem 10%;
        @media screen and (max-width: 800px) {
            padding: 4rem 0%;
            
        }
        background-color: #fff;
        border-radius: 30px;
        margin-top: 2rem;
        position: relative;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.43);

        .written_review{
            z-index: 1;
            position: relative;
            .text{
                padding: 1rem 2rem;
                text-align: center;
                p{
                    color: black;
                }
            }
            .review_name
            {
                font-size: 1.5rem;
                font-weight: 700;
                color: #000;
                text-align: center;
            }
            .quote{
                display: block;

            }
            .rightQuote{
                display: flex;
                justify-content: flex-end;
                width: 100%;
            }
            p{
                text-align: center;
            }
        }

        .before_after_images{
            z-index: 1;
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top: 0.5rem;
            gap: 1rem;
            @media screen and (max-width: 1000px) {
                gap: 0.5rem;
                
            }
            .con{
    
                display: grid;
                grid-template-columns: 1fr;
                place-items: center;
                .before{
                    width: 100%;
                    height: 200px;
                    @media screen and (max-width: 1000px) {
                        height: 150px;                    
                    }
                    @media screen and (max-width: 500px){
                        height: 100px;
                    }
                    overflow: hidden;
                    border-radius: 10px;
                    border: 1px solid #E5E5E5;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        @media screen and (max-width: 1000px) {
                            object-fit: none;
                        }
                    }
                }
                .after{
                    width: 100%;
                    height: 200px;
                    @media screen and (max-width: 1000px) {
                        height: 150px;                    
                    }
                    @media screen and (max-width: 500px){
                        height: 100px;
                    }
                    overflow: hidden;
                    border-radius: 10px;
                    border: 1px solid #E5E5E5;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        @media screen and (max-width: 1000px) {
                            object-fit: none;
                        }
                    }
                }
                p{
                    font-size: 1.2rem;
                    color: #000000;
                    text-align: center;
                }
            }
        }

        .video_review{
            position: relative;
            z-index: 1;
            height: 400px;
            @media screen and (max-width: 1000px) {
                height: 300px;
                
            }
            @media screen and (max-width: 500px){
                height: 200px;
            }
            width: 100%;
            video{
                width: 100%;
                height: 100%;
                border-radius: 10px;
                object-fit: contain;
            }
        }
    }
}
.swiper-button-next::after, .swiper-button-prev::after{
    color: var(--primary-text) !important;
}