.file_upload_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: 2px solid #c4c4c4;
    border-radius: 10px;
    height: 250px;
    cursor: pointer;
    position: relative;

    .close{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    input{
        display: none;
    }
    span{
        color: #5E6282;
        font-size: 1.25rem;
        text-transform: uppercase;
        margin: auto;
        text-align: center;
        font-weight: bold;
    }

.icon{

    .fiv-cla,
    .fiv-viv,
    .fiv-sqo {
        font-size: 6em;
    }

}

}