.offer_services_container{
    background-color: white;
    width: fit-content;
    padding: 2rem;
    border-radius: 20px;
    position: relative;
    z-index: 10;
    color: #1E376D;
    margin-left: auto;
    margin-right: auto;
    .inner_container{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        .services_heading{
            position: absolute;
            top: -8%;
            left: 50%;
            transform: translateX(-50%);
            padding: 0.5rem 1rem;
            border-radius: 20px;
            background-color: #01AB52;
            color: white;
            font-size: 2rem;
            width: max-content;
        }
        h1{
            font-size: 2rem;
            font-weight: 700;
            // color: #01A7E1;
            text-align: center;
            text-transform: uppercase;
            span{
                color: #01AB52;
            }
        }
        .services{
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            justify-content: center;
            align-items: center;
            h3{
                font-size: 2rem;
                font-weight: 700;
            }
            p{
                font-size: 1rem;
                color: #01AB52;
                font-weight: 700;
            }
        }
    }
}