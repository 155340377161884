.availability_check_details_container{
    width: 100%;
    height: 100%;
    display: grid;
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    grid-template-columns: 1fr;
    box-shadow:  0 0 10px 0 rgba(0,0,0,0.4);
    .details{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr ;
        @media screen and (max-width: 786px){
            grid-template-columns: 1fr 1fr;

        }
        @media screen and (max-width: 420px){
            grid-template-columns: 1fr;
            
        }
        gap: 2rem;
        #map{
            grid-row: span 3/span 3;
            @media screen and (max-width: 786px){
                grid-column: span 2 / span 2;
            }
            @media screen and (max-width: 420px){
                grid-column: span 1/span 1;
            }

            grid-column: span 3/span 3;
        }

        .no_coordinates_found{
            grid-column: span 3/span 3;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            font-weight: 500;
            color: red;
            @media screen and (max-width: 786px){
                grid-column: span 2 / span 2;
                
            }
            @media screen and (max-width: 420px){
                grid-column: span 1/span 1;
                
            }
        }
        .detail_item{
            display: flex;
            flex-direction: column;
            label{
                font-size: 1.2rem;
                font-weight: 500;
                margin-bottom: 0.5rem;
            }
            
            input{
                width: 100%;
                height: 2.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 0 1rem;
                font-size: 1rem;
                &:focus{
                    outline: none;
                }
            }

           input[type="checkbox"]{
            width: 20px;
            height: 20px;
           }

        }
    }
    .button_group{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        button{
            padding: 0.5rem 1rem;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            outline: none;
            transition: all 0.3s;
            margin-top: 1rem;
            &:hover{
                background-color: #0056b3;
            }
        
        }

        button:nth-child(2){
            background-color: #dc3545;
            &:hover{
                background-color: #c82333;
            }
        }
    }
    button{
        padding: 0.5rem 1rem;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
        transition: all 0.3s;
        margin-top: 1rem;
        &:hover{
            background-color: #0056b3;
        }
    }
}