.referral-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border: 4px solid #4FAFCB;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 14px 0px #2B92D5;
    .referral-container-button{

    
        padding: 0.5rem 1rem;
        background-color: var(--color-primary);
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        // width: 100%;
        margin-bottom: 1rem;
        font-weight: 600;
       // hover 
        &:hover{
            background-color: #2761d0;
        }
    }
    .qr-code-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 14px 0px #ccc;
        margin-bottom: 2rem;
        
        h3 {
            font-size: 1.2rem;
            font-weight: 550;
            text-align: center;
            margin-top: 0.5rem;
        }
    }

    .copy-link-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 0.5rem 1rem;
        background-color: var(--color-primary);
        color: white;
        border: none;
        border-radius: 5px;
        margin-bottom: 2rem;
        cursor: pointer;

        h5 {
            font-size: 1.2rem;
            font-weight: 550;
            margin-right: 1rem;
        }

        button {
            background-color: transparent;
            border: none;
            color: white;
            cursor: pointer;
        }
    }

    .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start; // Aligns checkbox and inputs to the left
        gap: 1rem;
        width: 100%;
        max-width: 500px;

        h3 {
            font-size: 1.2rem;
            font-weight: 550;
            text-align: left;
            margin-bottom: 0.5rem;
        }

        .checkbox_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            .checkbox {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                input {
                    width: 1rem;
                    height: 1rem;
                }

                label {
                    font-size: 1rem;
                    font-weight: bold;
                    color: #333;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                }
            }

            .details {
                color: #C4C4C4;
                font-size: 0.8rem;
                font-weight: 500;
                margin-left: 1.5rem;
            }
        }

        .fiber_company_selector {
            width: 100%;
            padding: 0.5rem;
            border-radius: 5px;
        }

        .generate-link-button {
            padding: 0.5rem 1rem;
            background-color: var(--color-primary);
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            width: 100%;
            margin-top: 1rem;
            text-align: center;
            font-size: 1rem;
            font-weight: 600;

            &:hover {
                background-color: #2761d0;
            }
        }

        button {
            padding: 0.5rem 1rem;
            background-color: var(--color-primary);
            color: #fff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            width: 100%;
            height: 100%;
            margin-top: 1rem;
            text-align: center;
            font-size: 1rem;
            font-weight: 600;

            &:hover {
                background-color: #2761d0;
            }
        }
    }
}
