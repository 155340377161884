.customerDashboard {

    width: 100%;
    height: 100%;
    padding: 1rem;

    .dashboardImage {
        width: 100%;
        display: grid;
        place-items: center;
        @media screen and (max-width: 1000px){
            display: none;
        }
    }

    .dashboardSearch {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border: 1px solid #E8E9E9;
        background: var(--color-foreground);
        // width: -moz-fit-content;
        // width: fit-content;
        width: 100%;
        padding: 0.5rem 1rem;
        margin: auto;
        margin-top: 2rem;

        input {
            border: none;
            outline: none;
            background: transparent;
            width: 100%;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            font-weight: 500;
        }
    }

    .dashboardBody {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));

        @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @media screen and (max-width: 820px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));

        }


        gap: 1rem;
        margin-top: 2rem;

        .dashboardCard {
            width: 100%;
            height: 100%;
            color: var(--color-primary);
            border-radius: 25px;
            background-color: var(--color-dashboard-card);
            box-shadow: 0px 0px 14px 0px #2B92D5;
            padding: 1rem;
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 1rem;
            align-items: center;
            justify-items: center;
            @media screen and (max-width: 1000px){
                height: 160px;
            }
            // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            // on hover
            &:hover{
                // make it pop up
                
            }

            .dashboardCardImage {
                width: 100%;
                height: 100%;
                display: grid;
                place-items: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .dashboardCardTitle {
                p {
                    color: var(--color-icon);
                    text-align: center;
                    font-size: 20px;
                    @media screen and (max-width: 1000px){
                        font-size: 1rem;
                    }
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.72px;
                    text-transform: uppercase;
                }
            }
        }
    }

}