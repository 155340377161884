.bug_ticket_form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 2rem auto;

    h1 {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: center;
    }
    .title {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: center;
    }

    .bug_ticket_form {
        width: 100%;

        .input_group {
            margin-bottom: 1rem;

            label {
                font-weight: 600;
                display: block;
                margin-bottom: 0.5rem;
            }

            input[type="text"],
            input[type="file"],
            textarea {
                width: 100%;
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                font-size: 1rem;
                box-sizing: border-box;
            }

            textarea {
                resize: vertical;
            }
        }

        .submit_button {
            background-color: #2761D0;
            color: white;
            border: none;
            padding: 0.75rem 2rem;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            width: 100%;

            &:hover {
                background-color: #1d4f9b;
            }
        }
    }
}

.loading_spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh; // Full height loading screen
}
