.searchUserContainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;

    .searchFilters {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
        border-radius: 10px;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        background-color: var(--color-foreground);
        padding: 2rem;
        .searchFiltersInput {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;

            input {
                width: 100%;
                height: 100%;
                border-radius: 9.024px;
                border: 1px solid #E5E5E5;
                padding: 0.5rem;
                font-size: 1rem;
                font-weight: bold;
                text-align: center;

                &:focus {
                    outline: none;
                }

            }
        }

        button{
            width: -moz-fit-content;
            width: fit-content;
            padding-left: 3rem;
            padding-right: 3rem;
            height: 3rem;
            border-radius: 9.024px;
            font-size: 1rem;
            text-align: center;
            font-weight: 600;
            color: var(--color-foreground);
            background-color: var(--color-primary);
            &:focus {
                outline: none;
            }
        }
    }




    .searchUserResult {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .searchUserResultTitle {
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            font-weight: bold;
        }
    }
}