@keyframes blinking {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }


  .audioRecorderContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    place-items: center;
    padding: 0.5rem;
    gap: 1rem;
    button{
        padding: 10px;
        border-radius: 50%;
        background-color: #2761D0;
        width: -moz-fit-content;
        width: fit-content;
        height: fit-content;
        margin: auto;
        outline: none;
    }
  }