.signin_container{
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    .opt-container {
        display: flex;
        justify-content: space-between;
        gap:2px;
      }
      .opt-container input {
        // @apply !mr-0 flex-1 py-5 outline-none;
        margin-right: 0px;
        flex: 1;
        padding: 1rem 0rem;
        outline: none;
      }
    h1{
        color: var(--primary-text);
        font-size: 4rem;
        @media screen and (max-width: 1000px){
            font-size: 3rem;   
        }
        @media screen and (max-width: 750px){
            font-size: 2rem;   
            
        }
        font-weight: 500;
        margin-bottom: 1rem;
        text-align: center;
    }
    h3{
        color: #51BAFE;
        font-size: 2rem;
        @media screen and (max-width: 1000px){
            font-size: 1.5rem;   
        }
        @media screen and (max-width: 750px){
            font-size: 1.2rem;
        }
        font-weight: 500;
        margin-bottom: 1rem;
        text-align: center;
    }
    p{
        color: var(--primary-text);
        font-size: 2rem;
        @media screen and (max-width: 1000px){
            font-size: 1.5rem;   
            
        }
        @media screen and (max-width: 750px){
            font-size: 1.2rem;
            
        }
        font-weight: 500;
        margin-bottom: 2rem;
        text-align: center;
        span{
            color: #51BAFE;
            text-decoration: underline;
            
        }
    }

    .signin_form{
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        place-items: center;
        background-color: white;
        width: 40%;
        border: 4px solid var(--border-color);
        @media screen and (max-width: 1100px){
            width: 60%;   
        }
        @media screen and (max-width: 750px){
            width: 80%;   
        }
        margin: auto;
        padding: 2rem 4rem;
        border-radius: 20px;
        .tabs{
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 1rem;
            @media screen and (max-width: 950px){
                // grid-template-columns: 1fr;
            }
            gap: 1rem;
            .active{
                background-color: #2761D0;
                color: white;
                border-radius: 10px;
                padding: 0.5rem;
                text-align: center;
                cursor: pointer;
                transition: all 0.3s ease;
              
            }

            button{
                background-color: transparent;
                color: #2761D0;
                border: 1px solid #2761D0;
                padding: 0.5rem;
                border-radius: 10px;
                text-align: center;
                cursor: pointer;
                transition: all 0.3s ease;
                @media screen and (max-width: 950px){
                    font-size: 0.6rem;
                }
                &:hover{
                    background-color: #2761D0;
                    color: white;
                }
            }
        }
        h1{
            color: black;
            font-size: 1.8rem;
            font-weight: bold;
            @media screen and (max-width: 1000px){
                font-size: 1.5rem;   
            }
            @media screen and (max-width: 750px){
                font-size: 1.2rem;
                
            }
        }
        input{
            width: 100%;
            padding: 0.5rem;
            border: 1px solid #C4C4C4;
            margin-bottom: 1rem;
            font-size: 1rem;
            border-radius: 10px;
            text-align: center;
            font-weight: bold;
            &:focus{
                outline: none;
            }
        }
        button{
            width: 100%;
            padding: 0.5rem;
            border: none;
            background-color: #2761D0;
            color: white;
            font-size: 1.1rem;
            font-weight: 500;
            border-radius: 15px;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover{
                // background-color: #51BAFE;
                color: white;
            }
        }
    }
}