.public_feedback_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .digital_tool_belt_container {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        .not_found{
            font-weight: bold;
            font-size: 1.5rem;
            color: #84A1C8;
            text-align: center;
            span{
                color: #002A8D;
                font-size: 2rem;
            }
        }
        .form {
            overflow: auto;
            height: auto;
            max-height: 100%;
            .image{
                display: flex;
                justify-content: center;
                margin: 1rem;
                img{
                    // width: 100%;
                    max-height: 200px;
                    height: auto;
                    object-fit: contain;
                }
            }
        }
    }
}