.file-upload {
  margin: 10px;
  overflow: overlay;
}

.file-upload-wizard {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 10px;
  .error-message {
    color: red;
    font-size: 12px;
    margin-left: 5px;
  }
  .steps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px;

    .step {
      display: flex;
      gap: 10px;
      align-items: center;
      .step span {
        margin-top: 5px;
        font-size: 14px;
        color: black;
        font-weight: bold;
      }

      &.active {
        color: #000;
        // border-color: #007bff;
        background-color: #259e6a; /* Active background color */
        color: white;
        border-radius: 4px;
      }
    }
  }

  .step-content {
    margin-bottom: 20px;

    .upload-step {
      text-align: center;

      h2 {
        margin-bottom: 10px;
      }

      .upload-box {
        border: 2px dashed #ccc;
        padding: 20px;
        text-align: center;
        cursor: pointer;
        border-radius: 8px;

        label {
          cursor: pointer;
          span {
            font-weight: bold;
            color: #007bff;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    button {
      padding: 10px 20px !important;
      border: none;
      width: auto !important;
      border-radius: 4px;
      cursor: pointer;

      &:disabled {
        background: #ccc !important;
        cursor: not-allowed;
      }

      &:not(:disabled) {
        background: green !important;
        color: #fff !important;
      }
    }
  }
}

// swal
/* SweetAlert Custom Styles */
.swal-custom-popup {
  font-family: Arial, sans-serif; /* Adjust font if needed */
}

.swal-error-message {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  text-align: left;
}
.swal-error-list {
  text-align: left;
  margin-top: 10px;
  color: #d9534f;
  font-size: 14px;

  li {
    margin-bottom: 5px;
  }
}


