.manage-company-consultation {
    padding: 2rem;
    max-width: 100%;
    margin: 0 auto;
  
    .page-title {
      margin-bottom: 2rem;
      color: #2761CF;
    }
  
    .filters {
      display: flex;
      gap: 1rem;
      margin-bottom: 2rem;
      flex-wrap: wrap;
  
      .search-input-container {
        position: relative;
        width: 100%;
  
        .search-icon {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          color: #757575;
        }
  
        .search-input {
          width: 100%;
          padding: 0.5rem 0.5rem 0.5rem 2.5rem;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;
          font-size: 1rem;
  
          &:focus {
            border-color: #2761CF;
          }
        }
      }
  
      .industry-select,
      .status-select {
        flex: 1;
        min-width: 200px;
      }
    }
  
    .mainTableContainer {
      width: 100%;
      overflow-x: auto;
    }
  
    .tableContainer {
      min-width: 100%;
    }
  
    .action-buttons {
      display: flex;
      gap: 0.5rem;
      flex-wrap: nowrap;
      white-space: nowrap;
  
      button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.2rem;
        padding: 0.25rem;
        border-radius: 4px;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  
    .loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      width: 100%;
    }
  
    .pagination-container {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      width: 100%;
    }
  }
  
  // Styles for the Pagination component
  .MuiPagination-root {
    .MuiPaginationItem-root {
      color: var(--color-icon);
    }
  
    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
  
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
  
  @media (max-width: 768px) {
    .manage-company-consultation {
      padding: 1rem;
  
      .filters {
        flex-direction: column;
  
        .search-input,
        .industry-select,
        .status-select {
          width: 100%;
        }
      }
  
      .pagination-container {
        overflow-x: auto;
      }
    }
  }