.fiber_technology_container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;

    .fiber_banner {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        position: relative;
        @media screen and (max-width: 1100px) {
            position: relative;
            place-items: center;
        }

        .text {
            align-self: center;
            // justify-self: center;
            padding-left: 8rem;
            
            @media screen and (max-width: 1100px) {
                grid-column: span 2/ span 2;
                justify-self: start;
                padding-left: 2rem;
                z-index: 1;
                display: grid;
                place-items: center;
                grid-template-columns: 1fr;
                width: 100%;
            }

            h1 {
                font-size: 4rem;

                @media screen and (max-width: 1450px) {
                    font-size: 3rem;
                }
                @media screen and (max-width: 550px) {
                    br{
                        display: none;
                    }
                    margin-top: 2rem;
                    text-align: center;
                }
                @media screen and (max-width: 1100px) {
                    font-size: 2rem;
                    align-self: center;
                    br{
                        display: none;
                    }
                }

                font-weight: 600;
                color: var(--primary-text);
                margin-bottom: 1rem;
            }

            .point {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-bottom: 1rem;

                p {
                    font-size: 1rem;
                    color: var(--primary-text);
                }
            }
        }

        .lightningBot {
            align-self: center;
            justify-self: center;
            position: absolute;
            right: 0;
            top: 0;
            height: 600px;
            @media screen and (max-width: 1100px) {
                position: static;
                // right: 0;
                height: 250px;
                width: auto;
                // z-index: 0;
            }

            @media screen and (max-width: 550px) {
                // opacity: 0.2;
            }

            img {
                // width: 100%;
                height: 100%;
            }
        }
    }

    .at_light_container {
        position: relative;
        width: 100%;
        margin-top: 2rem;

        img {
            position: absolute;
            left: -20%;
            top: 0;
            z-index: 0;
            height: fit-content;
            @media screen and (max-width: 550px) {
                opacity: 0.2;
        
            }
        }

        .at_lightning {
            position: relative;
            font-size: 4rem;
            z-index: 1;
            padding: 2rem;
          
            @media screen and (max-width: 1450px) {
                font-size: 3rem;
            }
            
            @media screen and (max-width: 1100px) {
                font-size: 2rem;
            }

            font-weight: 600;
            color: var(--primary-text);
            margin-bottom: 1rem;
            text-align: center;
        }
    }

    .key_benefits_container {
        width: 100%;
        padding: 2rem;
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        margin-top: 2rem;
        padding: 0 10rem;

        @media screen and (max-width: 1100px) {
            padding: 0rem 2rem;

        }

        .key_benefits_header {
            display: flex;
            align-items: center;
            gap: 1rem;

            h1 {
                font-size: 2rem;
                font-weight: 600;
                color: var(--primary-text);
            }
        }

        .key_benefits {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            @media screen and (max-width: 940px) {
                grid-template-columns: 1fr 1fr;
            }

            @media screen and (max-width: 490px) {
                grid-template-columns: 1fr;

            }

            gap: 1rem;

            .benefit {
                display: flex;
                align-items: center;
                gap: 1rem;

                p {
                    font-size: 1rem;
                    color: var(--primary-text);
                }
            }
        }
    }

    .fiber_family {
        width: 100%;
        padding: 0 8rem;
        margin-top: 2rem;

        @media screen and (max-width: 1100px) {
            padding: 2rem;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .speed_comparison {
        width: 100%;
        padding: 0 8rem;
        margin-top: 2rem;
        position: relative;
        margin-bottom: 2rem;
        .comparison_light{
            position: absolute;
            right: 0;
            top: 50%;
            z-index: 0;

        }
        @media screen and (max-width: 1100px) {
            padding: 2rem;
        }

        h1 {
            font-size: 4rem;

            @media screen and (max-width: 1450px) {
                font-size: 3rem;

            }

            @media screen and (max-width: 1100px) {
                font-size: 2rem;

            }
            br{
                @media screen and (max-width: 450px){
                    display: none;
                }
            }
            font-weight: 600;
            color: var(--primary-text);
            margin-bottom: 1rem;
            text-align: center;
        }

        .image_vs {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            gap: 1rem;
            place-items: center;
            h2{
                font-size: 2rem;
                font-weight: 600;
                color: var(--primary-text);
            }
            .internet {
                z-index: 1;
                display: grid;
                grid-template-columns: 1fr;
                place-items: center;
                p{
                    font-size: 1rem;
                    color:  var(--primary-text);
                }
            }
        }
    }
}