.orderHistory{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    h1{
        font-size: 2rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .pagination{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}