.referral_program_container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 2rem 10rem;

    @media screen and (max-width: 1100px) {
        padding: 2rem;

    }

    .referral_program_title {

        font-size: 3rem;

        @media screen and (max-width: 1100px) {
            font-size: 2rem;
        }

        font-weight: 600;
        color: var(--primary-text);
        margin-bottom: 1rem;
        text-align: center;

    }

    .referral_program_banner {
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
        position: relative;
        .banner_top_light{
            position: absolute;
            top: -20%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
        }
        .banner_bottom_light{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
        }
        .white_container {
            background-color: #fff;
            padding: 2rem;
            border-radius: 30px;
            margin: auto;
            z-index: 1;
            position: relative;
            border: 4px solid var(--border-color);
            .white_container_top {
                display: grid;
                grid-template-columns: 1fr auto 1fr;

                @media screen and (max-width: 530px) {
                    grid-template-columns: 1fr 1fr 1fr;
                }

                // @media screen and (max-width: 430px) {
                //     grid-template-columns: 1fr 1fr 1fr;

                // }
                gap: 1rem;
                place-items: center;
                // .equal{

                //     img{
                //         height: 50px;
                //         // width: 100%;
                //         object-fit: contain;
                //     }
                // }
                .blue_container {

                    width: 210px;
                    height: 100%;
                    border-radius: 10px;

                    @media screen and (max-width: 830px) {
                        width: 150px;
                    }

                    @media screen and (max-width: 700px) {
                        width: 100px;
                    }

                    p {
                        border: 4px solid #2761D0;
                        border-bottom: none;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        color: black;
                        background-color: #fff;
                        text-align: center;
                        padding: 0.5rem 3rem;
                        font-weight: 600;
                        font-size: 1.5rem;

                        @media screen and (max-width: 830px) {
                            padding: 0.5rem 2rem;
                            font-size: 1rem;
                        }

                        @media screen and (max-width: 700px) {
                            padding: 0.5rem 1rem;
                            font-size: 0.8rem;
                        }
                    }

                    .bottom {
                        height: 180px;

                        @media screen and (max-width: 830px) {
                            height: 130px;
                            padding: 0 1rem;
                        }

                        @media screen and (max-width: 700px) {
                            height: 80px;
                            padding: 0 0.5rem;

                        }

                        background: linear-gradient(-45deg, #000000 0%, #022780 100%);
                        padding:0 2rem;
                        display: grid;
                        place-items: center;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        position: relative;

                        .price {
                            position: absolute;
                            top: 20%;
                        }


                        h3 {
                            // margin: auto;
                            font-size: 2rem;

                            @media screen and (max-width: 830px) {
                                font-size: 1.5rem;
                            }

                            @media screen and (max-width: 700px) {
                                font-size: 1rem;

                            }

                            font-weight: 600;
                            color: #fff;
                            margin-bottom: 1rem;
                            text-align: center;
                        }

                        img {
                            align-self: end;

                            @media screen and (max-width: 830px) {
                                height: 50px;

                            }

                            @media screen and (max-width: 700px) {
                                height: 30px;

                            }
                        }
                    }
                }
            }

            .white_container_text {
                margin-top: 2rem;

                h1 {
                    font-size: 2rem;

                    @media screen and (max-width: 830px) {
                        font-size: 1.5rem;
                    }

                    @media screen and (max-width: 700px) {
                        font-size: 1.2rem;
                    }

                    font-weight: 600;
                    color: #000;
                    text-align: center;
                }

                p {
                    font-size: 1.5rem;

                    @media screen and (max-width: 830px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 700px) {
                        font-size: 0.8rem;
                    }

                    color: #000;
                    text-align: center;
                    font-weight: 600;

                    span {
                        color: #2761D0;
                    }
                }
            }
        }

        .border_container {
            width: 100%;
            padding: 0rem 4rem;

            .custom_border {
                border: 4px solid var(--border-color);
                height: 170px;

                @media screen and (max-width: 830px) {
                    height: 120px;

                }

                @media screen and (max-width: 700px) {
                    height: 100px;
                }

                border-top: none;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                position: relative;

                img {
                    height: 200px;

                    @media screen and (max-width: 830px) {
                        height: 150px;
                    }

                    @media screen and (max-width: 700px) {
                        height: 100px;

                    }

                    position: absolute;
                    left: 50%;
                    bottom: -50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .donation_card_container {
        display: grid;
        grid-template-columns: 1fr;
        margin: auto;
        place-items: center;
        margin-top: 6rem;
        position: relative;

        .donation_light_left{
            position: absolute;
            top: 50%;
            left: -50%;
            transform: translateY(-50%);
            z-index: 0;
        }
        .donation_card_top {
            border: 8px solid var(--border-color);
            border-bottom: none;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            background: linear-gradient(0deg, #000B23 0%, #002B89 100%);
            // width: -moz-fit-content;
            // width: fit-content;
            width: 90%;
            padding: 1rem 4rem;
            z-index: 1;
            h1 {
                font-size: 3rem;

                @media screen and (max-width: 830px) {
                    font-size: 2rem;
                }

                @media screen and (max-width: 700px) {
                    font-size: 1rem;
                }

                font-weight: 600;
                color: #fff;
                text-align: center;
            }
        }
        .donation_card_middle{
            z-index: 1;
            border: 8px solid var(--border-color);
            border-radius: 40px;
            background-color: var(--border-color);
        }
        .donation_card_bottom {
              border: 8px solid var(--border-color);
            border-top: none;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            background-color: white;
            width: 90%;
            padding: 2rem 4rem;
            z-index: 1;
            @media screen and (max-width: 830px) {
                padding: 2rem 2rem;
            }

            @media screen and (max-width: 700px) {
                padding: 2rem 1rem;
            }

            h1 {
                font-size: 3rem;

                @media screen and (max-width: 830px) {
                    font-size: 2rem;
                }

                @media screen and (max-width: 700px) {
                    font-size: 1rem;
                }

                font-weight: 600;
                color: #0074FF;
                text-align: center;
            }

            h2 {
                font-size: 2rem;

                @media screen and (max-width: 830px) {
                    font-size: 1.5rem;
                }

                @media screen and (max-width: 700px) {
                    font-size: 1rem;
                }

                font-weight: 600;
                color: #000;
                text-align: center;
                margin-top: 1rem;
            }
        }
    }

    .form_wrapper {
        display: grid;
        grid-template-columns: 1fr;
        margin: auto;
        place-items: center;
        margin-top: 4rem;
        width: 100%;
        margin-bottom: 2rem;
        position: relative;
        .become_partner_light{
            position: absolute;
            top: -30%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
        }
        .referral_form {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2rem;
            margin: auto;
            place-items: center;
            z-index: 1;
            background: linear-gradient(0deg, #000B23 0%, #002B89 100%);
            border-radius: 30px;
            box-shadow: 0px 0px 20px 0px #000000;
            padding: 1rem 4rem;
            width: 90%;

            @media screen and (max-width: 830px) {
                padding: 2rem 2rem;
            }

            @media screen and (max-width: 700px) {
                padding: 2rem 1rem;
            }

            h1 {
                font-size: 3rem;

                @media screen and (max-width: 830px) {
                    font-size: 2rem;
                }

                @media screen and (max-width: 700px) {
                    font-size: 1rem;
                }

                font-weight: 600;
                color: #fff;
                text-align: center;
            }

            .in_container {
                display: grid;
                grid-template-columns: 1fr;
                gap: 1rem;
                width: 50%;

                .in {
                    width: 100%;
                    padding: 0.5rem 1rem;
                    border-radius: 10px;
                    border: none;
                    text-align: center;
                    font-size: 1.5rem;

                    @media screen and (max-width: 830px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 700px) {
                        font-size: 0.8rem;
                    }

                    background-color: #fff;
                    color: #000;
                    border-radius: 10px;
                    outline: none;
                }

                button {
                    margin-top: 1rem;
                    width: 100%;
                    padding: 0.5rem 1rem;
                    border-radius: 10px;
                    border: none;
                    text-align: center;
                    font-size: 1.5rem;

                    @media screen and (max-width: 830px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 700px) {
                        font-size: 0.8rem;
                    }

                    background-color: #022780;
                    color: #fff;
                    border-radius: 10px;
                    outline: none;
                    cursor: pointer;
                }
            }
        }
    }
}