.userDetailsContainer{
    display: flex; /* flex */
    flex-direction: column; /* flex-col */
    gap: 1.25rem; /* gap-5 */
    justify-content: center; /* justify-center */
    align-items: center; /* items-center */
    border: 1px solid #E5E5E5; /* border-1 border-solid border-[#E5E5E5] */
    background-color: var(--color-foreground); /* bg-white */
    padding: 2rem; /* py-5 */
    border-radius: 1.5rem; /* rounded-2xl */
    margin: auto; /* mx-auto */

    .form{
        display: flex; /* flex */
        flex-direction: column; /* flex-col */
        gap: 0.75rem; /* gap-3 */
        width: 75%; /* w-3/4 */
        justify-content: center; /* justify-center */
        align-items: center; /* items-center */

        h1{
            font-size: 1.875rem; /* text-3xl */
            font-weight: 500; /* font-medium */
        }

        .inputs{
            display: grid; /* grid */
            grid-template-columns: repeat(2, minmax(0, 1fr)); /* grid-cols-2 */
            gap: 1rem; /* gap-4 */

            label{
                font-size: 1rem; /* text-base */
                font-weight: 500; /* font-medium */
            }

            input{
                height: 3rem; /* h-12 */
                border: 1px solid #E5E5E5; /* border-1 border-solid border-[#E5E5E5] */
                border-radius: 0.5rem; /* rounded-md */
                padding: 1rem; /* px-4 py-3 */
                font-size: 1rem; /* text-base */
                font-weight: 400; /* font-normal */
                color: var(--color-primary); /* text-[#181E4B] */
                outline: none; /* focus:outline-none */
                transition: all 0.2s ease-in-out; /* transition-all duration-200 ease-in-out */
                &:focus{
                    border: 1px solid #71839B; /* focus:border-[#71839B] */
                }
            }

            select{
                border: 1px solid #E5E5E5; /* border-1 border-solid border-[#E5E5E5] */
                border-radius: 0.375rem; /* rounded-md */
                padding: 0.5rem; /* p-2 */
            }
        }
        .spinner{
            display: flex; /* flex */
            align-items: center; /* items-center */
            justify-content: center; /* justify-center */
        }

        button{
            width: 100%; /* w-full */
            height: 3rem; /* h-12 */
            border: 1px solid #E5E5E5; /* border-1 border-solid border-[#E5E5E5] */
            border-radius: 0.5rem; /* rounded-md */
            padding: 1rem; /* px-4 py-3 */
            font-size: 1rem; /* text-base */
            font-weight: 400; /* font-normal */
            color: var(--color-primary); /* text-[#181E4B] */
            outline: none; /* focus:outline-none */
            transition: all 0.2s ease-in-out; /* transition-all duration-200 ease-in-out */
            &:hover{
                background-color: var(--color-primary); /* hover:bg-[#181E4B] */
                color: var(--color-foreground); /* hover:text-white */
            }
        }
    }
    
}