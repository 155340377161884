.custom_modal {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 80vh;
    /* Limit modal height */

    /* Modal header (fixed at top) */
    h1,
    h3 {
        color: #333;
        text-align: center;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    h1 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1rem;
    }

    .speed_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        width: 100%;

        h1 {
            font-size: 3rem;
            font-weight: 700;
            // color: #01A7E1;
            text-align: center;
            text-transform: uppercase;

        }

        .speed {
            border: 2px solid #01A7E1;
            border-radius: 10px;
            padding: 1rem;
            p {
                font-size: 1rem;
                color: #000000;
                font-weight: 700;

                span {
                    font-size: 1.2rem !important;
                    color: #01A7E1 !important;
                }
            }
        }
    }

    /* Scrollable content */
    .modal-content {
        overflow-y: auto;
        flex-grow: 1;
        width: 100%;
    }

    .address {
        position: relative;

        .address_list {
            position: absolute;
            background-color: white;
            width: 100%;
            z-index: 99;
            border-radius: 10px;
            box-shadow: 1px 13px 12px 5px rgba(0, 0, 0, 0.45);
            // dont apply the box--shadow at the top
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;

            // padding: 1rem;
            div {
                font-size: 0.9rem;
                border-bottom: 1px solid #E6E6E6;
                padding-bottom: 0.5rem;
                margin-bottom: 0.5rem;
                padding: 0.5rem;
                max-height: 400px;
                overflow-y: auto;

                &:hover {
                    cursor: pointer;
                    background-color: #2761d0;
                    color: white;

                }
            }

        }
    }

    .image-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        .image-box {
            position: relative;
            width: 100%;
            height: auto;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            background-color: #f5f5f5;
            display: flex;
            align-items: center;
            justify-content: center;

            .image-wrapper {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%;
                    height: auto;
                    border-radius: 10px;
                }

                .edit-button {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    background-color: #2761d0;
                    border: none;
                    border-radius: 25px;
                    padding: 0.75rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: white;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

                    &:hover {
                        background-color: #1f4fa0;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .upload-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 200px;
                border: 2px dashed #ccc;
                border-radius: 10px;
                cursor: pointer;

                .upload-label {
                    color: #2761d0;
                    cursor: pointer;
                    font-weight: bold;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                input[type='file'] {
                    display: none;
                }
            }
        }
    }

    /* Buttons */
    button {
        background-color: #2761d0;
        color: white;
        border: none;
        padding: 0.8rem 2rem;
        border-radius: 8px;
        cursor: pointer;
        margin-top: 1.5rem;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        width: 100%;
        text-align: center;

        &:hover {
            background-color: #1f4fa0;
        }
    }

    /* SweetAlert2 z-index */
    .swal2-container {
        z-index: 9999 !important;
    }
}