.customer_feedback_container {
    width: 100%;
    background-color: white;
    border: 4px solid #4FAFCB;
    border-radius: 36px;
    padding: 2rem;
    @media screen and (max-width:1000px) {
        padding: 1rem;
        width: auto;
        margin: 0.5rem;
    }
    h3{
        font-size:  1.5rem;
        font-weight: 700;
        color: #000;
        font-family: "Open Sans";
        text-align: center;
        @media screen and (max-width:1008px) {
            font-size: 1.3rem;
        }
    }

    .header{
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 2rem;
        place-items: center;
        @media screen and (max-width:1008px) {
            margin-top: 1rem;
            
        }
        h1{
            font-size: 4rem;
            font-weight: 700;
            color: #1E293B;
            font-family: "Open Sans";
            text-align: center;
            @media screen and (max-width:1008px) {
                font-size: 4rem;
            }
        }
        p{
            color: rgb(170, 170, 170);
            span{
                color: #2761D0;
                text-decoration: underline;
            }
        }
    }

    .rating_metrics{
        display: grid;
        grid-template-columns: 1fr 1fr;
        // place-items: end;

        width: -moz-fit-content;
        width: fit-content;
        @media screen and (max-width:470px) {
            width: 100%;
        }
        margin: auto;
        margin-top: 2rem;
        @media screen and (max-width:1008px) {
            margin-top: 1rem;
        }
        h3{
            font-size: 1.5rem;
            // font-weight: 700;
            color: #000;
            font-family: "Open Sans";
            text-align: start;
            @media screen and (max-width:1008px) {
                font-size: 1.5rem;
            }
            @media screen and (max-width: 1000px) {
                font-size: 1.2rem;
            }
            // align-self: start;
        }
        h2{
            font-size: 2rem;
            // font-weight: 700;
            color: #000;
            font-family: "Open Sans";
            text-align: center;
            @media screen and (max-width:1008px) {
                font-size: 1.5rem;
            }
            @media screen and (max-width: 1000px) {
                font-size: 1.2rem;
            }
            // align-self: end;
        }
    }

 


}

.review_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    place-items: center;
    margin-top: 2rem;
    @media screen and (max-width:1008px) {
        margin-top: 1rem;
        
    }
    @media screen and (max-width:1150px) {
        grid-template-columns: 1fr;
    }
    .slider_review{
        padding: 1rem;
        @media screen and (max-width:1000px){
            padding: 0rem;
        }

        .written_review_container{
            @media screen and (max-width:700px) {
                padding: 1rem;
                
            }
            .review_header{
                @media screen and (max-width:700px) {
                    gap: 0.5rem;
                    
                }
                .profile{
                    @media screen and (max-width:700px) {
                        width: 30px;
                        height: 30px;
                        
                    }
                }
                .info{
                    h3{
                        @media screen and (max-width:700px) {
                            font-size: 0.6rem;
                            
                        }
                    }
                }
            }
            .body{
                p{
                    
                    @media screen and (max-width:700px){
                        font-size: 0.75rem;
                    }
                }
                
            }
        }
    }

    .container_header{
        display: flex;
        justify-content: center;
        align-items: center;
        // grid-template-columns: 1fr 1fr;
        grid-column: span 2/ span 2;
        gap: 1rem;
        width: 100%;
        @media screen and (max-width:1150px) {
            grid-column: span 1/ span 1;
            
        }
        h3{
            font-size: 2rem;
            font-weight: 700;
            color: #000;
            font-family: "Open Sans";
            text-align: start;
            justify-self: flex-start;
            @media screen and (max-width:1008px) {
                font-size: 1.5rem;
            }
        }
        p{
            color: #2761D0;
            font-size: 1rem;
            text-decoration: underline;
            justify-self: flex-end;
            cursor: pointer;
        }

        .toggle-switch {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            label {
                font-size: 1.2rem;
                margin-right: 1rem;
                font-weight: bold;
            }

            input {
                width: 50px;
                height: 25px;
                -webkit-appearance: none;
                appearance: none;
                background-color: #c6c6c6;
                outline: none;
                border-radius: 50px;
                position: relative;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:checked {
                    background-color: #41b6e6;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 23px;
                    height: 23px;
                    background-color: white;
                    border-radius: 50%;
                    top: 1px;
                    left: 1px;
                    transition: transform 0.3s ease;
                }

                &:checked::before {
                    transform: translateX(25px);
                }
            }
        }
    }
    .container_filter{
        // display: flex;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 100%;
        grid-column: span 2/ span 2;
        @media screen and (max-width:1150px) {
            grid-column: span 1/ span 1;
        }
     
        button{
            padding: 0.5rem 1rem;
            border: none;
            border-radius: 8px;
            background-color: #181E4B;
            color: white;
            font-size: 1rem;
            font-weight: 700;
            cursor: pointer;
            transition: all 0.3s ease;
            width: 100%;
            height: 100%;
            @media screen and (max-width:1008px) {
                font-size: 0.8rem;
                padding: 0.5rem;
                font-weight: normal;
            }
            &:hover{
                background-color: #2761D0;
            }
        }
        .active{
            background-color: #2761D0;

        }
    }

    .noReviews{
        font-size: 1.5rem;
        font-weight: 700;
        color: #000;
        font-family: "Open Sans";
        text-align: center;
        @media screen and (max-width:1008px) {
            font-size: 1.5rem;
        }
        grid-column: span 2/ span 2;
        @media screen and (max-width:1150px) {
            grid-column: span 1/ span 1;
        }
    }

    .spinner{
        grid-column: span 2/ span 2;
        @media screen and (max-width:1150px) {
            grid-column: span 1/ span 1;
        }
    }

}