.manageLeadsContainer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
    align-items: center;
    justify-content: center;


    .action-buttons {
        display: flex;
        gap: 0.5rem;
        flex-wrap: nowrap;
        white-space: nowrap;
    
        button {
          background: none;
          border: none;
          cursor: pointer;
          font-size: 1.2rem;
          padding: 0.25rem;
          border-radius: 4px;
          transition: background-color 0.3s ease;
    
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }

    .manageLeadsHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        border-radius: 10px;
        background: var(--color-foreground);
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        padding: 2rem;

        .manageLeadsTitle {
            font-size: 20px;
            font-weight: 600;
        }

        .searchFiltersInput {
            display: grid;
            grid-template-columns: 1fr 1fr;

            @media screen and (max-width: 600px) {
                grid-template-columns: 1fr;

            }

            gap: 1rem;
            width: 100%;

            .single {
                grid-column: span 2;

                @media screen and (max-width: 600px) {
                    grid-column: span 1;
                }
            }

            .date_range {
                .quick_date {
                    width: 100%;
                    padding: 0.5rem 1rem;
                    border-radius: 5px;
                    border: 1px solid #00000040;
                    outline: none;
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--color-primary);
                    input{
                        border: none;
                        outline: none;
                        padding: 0;
                        background-color: rgb(193 193 193);
                    }
                }
            }

            select {
                width: 100%;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                border: 1px solid #00000040;
                outline: none;
                font-size: 14px;
                font-weight: 600;
                color: var(--color-primary);
            }

            input {
                width: 100%;
                padding: 10px 20px;
                border-radius: 5px;
                border: 1px solid #00000040;
                outline: none;
                font-size: 14px;
                font-weight: 600;
                color: var(--color-primary);

                &::placeholder {
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--color-primary);
                }
            }
        }

        button {
            background-color: var(--color-foreground);
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            color: var(--color-primary);
            padding: 10px 20px;
            border-radius: 5px;
            box-shadow: 0px 0px 5px 0px #00000040;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: var(--color-primary);
                color: var(--color-foreground);
            }

        }
    }

    .spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}