.comissionPlanMakerContainer {
    width: 100%;

    h1 {
        text-transform: uppercase;
        /* uppercase */
        font-size: 1.875rem;
        /* text-3xl */
        font-weight: 700;
        /* font-bold */
    }

    .inputContainer {
        display: grid;
        /* grid */
        grid-template-columns: repeat(1, minmax(0, 1fr));
        /* grid-cols-1 */
        gap: 2rem;
        /* gap-8 */
        margin-top: 1rem;
        /* mt-4 */
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        /* shadow-lg */
        padding: 1.5rem;
        /* p-6 */
        background-color: white;
        /* bg-white */
        border-radius: 0.5rem;
        /* rounded-lg */

        h1 {
            text-transform: uppercase;
            /* uppercase */
            font-size: 1.25rem;
            /* text-xl */
            font-weight: 600;
            /* font-semibold */
        }

        .inputs1 {
            display: grid;
            /* grid */
            grid-template-columns: repeat(3, minmax(0, 1fr));

            /* grid-cols-2 */
            @media screen and (max-width: 1000px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            gap: 1rem;
            /* gap-4 */

            div {
                display: grid;
                /* grid */
                grid-template-columns: repeat(1, minmax(0, 1fr));
                /* grid-cols-1 */

                label {
                    text-transform: uppercase;
                    /* uppercase */
                    font-weight: 700;
                    /* font-bold */
                }

                input {
                    border: 1px solid #E5E7EB;
                    /* border */
                    border-radius: 0.375rem;
                    /* rounded-md */
                    padding: 0.5rem;
                    /* py-2 */
                    font-size: 1rem;
                    /* text-base */
                    width: 100%;
                }
            }
        }

        h1 {
            text-transform: uppercase;
            /* uppercase */
            font-size: 1.25rem;
            /* text-xl */
            font-weight: 600;
            /* font-semibold */
        }

        .inputs2 {
            display: grid;
            /* grid */
            grid-template-columns: repeat(3, minmax(0, 1fr));

            @media screen and (max-width: 1000px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            gap: 2rem;
            /* gap-8 */

            div {
                display: grid;
                /* grid */
                grid-template-columns: repeat(1, minmax(0, 1fr));
                /* grid-cols-1 */
                gap: 1rem;
                /* gap-4 */

                h1 {
                    text-transform: uppercase;
                    /* uppercase */
                    font-size: 1.125rem;
                    /* text-lg */
                    font-weight: 700;
                    /* font-bold */
                }

                div {
                    display: grid;
                    /* grid */
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                    /* grid-cols-1 */

                    label {
                        text-transform: uppercase;
                        /* uppercase */
                        font-weight: 700;
                        /* font-bold */
                    }
    
                    input {
                        border: 1px solid #E5E7EB;
                        /* border */
                        border-radius: 0.375rem;
                        /* rounded-md */
                        padding: 0.5rem;
                        /* py-2 */
                        font-size: 1rem;
                        /* text-base */
                        width: 100%;
                    }
                }
            }
        }

        .button-container{
            margin-top: 1rem;
            button{
                width: 100%;
                padding: 0.5rem;
                background-color: #1F2937;
                color: white;
                border-radius: 0.375rem;
                /* rounded-md */
                font-weight: 700;
                /* font-bold */
                text-transform: uppercase;
                /* uppercase */
                font-size: 1rem;
                /* text-base */
            }
        }
    }
}