.customer_info_model{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min(90vw,600px);
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    outline: none;
    @media screen and (max-width: 500px){
        padding: 1rem;
        
    }

    h2{
        text-align: center;
        font-weight: bold;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: black;
    }
    .customer_info{
        display: grid;
        grid-template-columns: 1fr; 
        gap: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 1rem;
        max-height: 70vh;
        overflow-y: auto;
        @media screen and (max-width: 500px){
            padding: 0.5rem;
            
        }
        h3{
            font-size: 1rem;
            font-weight: bold;
            text-transform: uppercase;
            // text-decoration: 2px underline ;
            color: black;
        }
        .info{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            gap: 0.5rem;
            h4{
                font-size: 1rem;
                color: black;
                @media screen and (max-width: 500px){
                    font-size: 0.9rem;
                    
                }
            }
            P{
                color: #84A1C8;
                grid-column: span 2;
                @media screen and (max-width: 500px){
                    font-size: 0.9rem;
                    
                }
            }
        }
        // .single{
        //     grid-column: span 2;
        // }
    }
    .view_more{
        color: #2761D0;
        text-align: center;
        cursor: pointer;
        text-decoration: 2px underline;
    }
}