.candidate-filters {
    .MuiAccordion-root {
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin-bottom: 16px;
  
      .MuiAccordionSummary-root {
        background-color: #f5f5f5;
        
        &:hover {
          background-color: #e0e0e0;
        }
      }
  
      .MuiAccordionDetails-root {
        padding: 24px;
      }
    }
  
    .InputsDiv {
      display: flex;
      flex-direction: column;
      gap: 16px;
  
      .InputDiv {
        display: flex;
        flex-direction: column;
        gap: 8px;
  
        label {
          font-weight: 600;
          margin-bottom: 4px;
        }
  
        input, .css-1s2u09g-control {
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          padding: 8px;
          font-size: 14px;
  
          &:focus {
            outline: none;
            border-color: #2196f3;
          }
        }
      }
  
      .DateRangeSection {
        margin-bottom: 16px;
        padding-bottom: 16px;
        position: relative;
  
        .selector_con {
          margin-bottom: 8px;
        }
  
        .date_range {
          display: flex;
          gap: 16px;
          align-items: center;
  
          label {
            font-weight: 600;
            margin-right: 8px;
          }
  
          .quick_date {
            display: flex;
            gap: 8px;
            align-items: center;
  
            input {
              padding: 8px;
              border: 1px solid #e0e0e0;
              border-radius: 4px;
              font-size: 14px;
            }
          }
        }
  
        .separator {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          background-color: #e0e0e0;
        }
      }
    }
  
    .MapIconsSection {
      margin-top: 24px;
  
      .lead_type_title {
        font-weight: 600;
        margin-bottom: 12px;
      }
  
      .select_deselect {
        display: flex;
        gap: 12px;
        margin-bottom: 16px;
  
        button {
          padding: 8px 16px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-weight: 600;
          transition: background-color 0.3s;
  
          &.select {
            background-color: #2196f3;
            color: white;
  
            &:hover {
              background-color: #1976d2;
            }
          }
  
          &.deselect {
            background-color: #f44336;
            color: white;
  
            &:hover {
              background-color: #d32f2f;
            }
          }
        }
      }
  
      .MapIconStats {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
  
        .MapIconStat {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 8px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.3s;
  
          &.active {
            border-color: #2196f3;
            background-color: #e3f2fd;
          }
  
          h3 {
            margin: 0;
            img {
              width: 32px;
              height: 32px;
            }
          }
  
          p {
            margin: 4px 0 0;
            font-size: 12px;
            text-align: center;
          }
        }
      }
    }
  
    @media (max-width: 600px) {
      .MuiBox-root {
        flex-direction: column;
      }
  
      .InputsDiv {
        .DateRangeSection {
          .date_range {
            flex-direction: column;
            align-items: stretch;
  
            .quick_date {
              flex-direction: column;
  
              input {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }