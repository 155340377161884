.manage_customer_company_user{
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 2rem;
    width: 100%;
    @media screen and (max-width: 1000px){
        height: fit-content; 
    }
    h1{
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .filters{
        display: grid;
        padding: 1.5rem;
        gap: 1rem;
        grid-template-columns: 1fr 1fr;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        @media screen and (max-width: 570px) {
            grid-template-columns: 1fr;
        }
        .filter_input{
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;
            label{
                font-weight: 500;
                color: black;
            }
            input,select{
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                background-color: white;
            }
        }
        .single{
            grid-column: span 2;
            @media screen and (max-width: 570px) {
                grid-column: span 1;
            }
        }
        button{
            background-color: #2761D0;
            color: white;
            border: none;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            margin: auto;
            grid-column: span 2;

            @media screen and (max-width: 570px)  {
                grid-column: span 1;
            }
        }

    }
}