.HotLeadForm {
    font-family: 'Open Sans', sans-serif;

    .HeaderDiv {
        display: flex;
        width: 100%;
        place-items: center;
        flex-direction: column;
        gap: 3rem;

        h2 {
            font-weight: bold;
            font-size: 1.5em;
        }

        .slider {
            width: 80%;

            @media screen and (max-width: 1000px) {
                width: 100%;

            }
        }
    }


    .Objection {
        margin-top: 2rem;

        h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: var(--color-icon);
        }

        .objection_options {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            @media screen and (max-width: 500px) {
                grid-template-columns: 1fr 1fr;
            }

            margin-top: 1rem;

        }
    }

    .ObjectionNotes {
        margin-top: 1rem;

        label {
            font-size: 1.25rem;
            font-weight: 600;
            color: var(--color-icon);
        }

        input {
            width: 100%;
            padding: 1rem;
            margin-top: 1rem;
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            font-size: 1rem;
            outline: none;
        }
    }

    .LeadData {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        label {
            font-size: 1.25rem;
            font-weight: 600;
            color: var(--color-icon);
        }

        textarea {
            width: 100%;
            padding: 1rem;
            margin-top: 1rem;
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            font-size: 1rem;
            outline: none;
        }
    }

    .SubtitleDiv {
        margin-top: 1rem;

        h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: var(--color-icon);
        }
    }

    .AppointmentType {
        display: flex;
        margin-top: 1rem;
        flex-wrap: wrap;
        gap: 2rem;

        label {
            font-size: 1rem;
            font-weight: 600;
            color: var(--color-icon);
        }
    }

    .AppointmentDate {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 1rem;
            font-weight: 600;
            color: var(--color-icon);
        }

        div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top: 1rem;
        }
    }

    .AppointmentTime {
        margin-top: 1rem;
        display: flex;
        gap: 2rem;
        justify-content: flex-start;
        flex-wrap: wrap;

        // align-items: center;
        h3 {
            font-size: 1rem;
            font-weight: 600;
            color: var(--color-icon);
        }

    }

    .appointmentDateTime {
        margin-top: 1rem;
        display: flex;
        gap: 2rem;
        justify-content: flex-start;
        align-items: center;

        label {
            font-size: 1rem;
            font-weight: 600;
            color: var(--color-icon);
            width: max-content;
        }

        input {
            width: 90%;
            padding: 1rem;
            margin-top: 1rem;
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            font-size: 1rem;
            outline: none;
        }

    }

    .ButtonDiv {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        width: 100%;

        button {
            padding: 1rem 2rem;
            border: none;
            border-radius: 10px;
            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
            outline: none;
            background-color: #2761D0;
            color: white;
            width: 100%;
        }

    }
}

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: -moz-fit-content !important;
    width: fit-content !important;
    // font-weight: bold;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: #2761D0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}