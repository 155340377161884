.plan_container {
    background-color: white;
    width: fit-content;
    color: #6F6C90;
    padding: 2rem;
    border-radius: 20px;
    margin-top: -20%;
    position: relative;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-width: 470px;
    .plan_name {
        font-size: 1.8rem;
        font-weight: 700;
        @media screen and (max-width: 500px) {
            font-size: 1.5rem;
        }
        // margin-bottom: 1rem;
        text-align: center;
        color: white;
        position: absolute;
        top: -5%;
        left: 50%;
        transform: translateX(-50%);
        padding: 0.5rem 1rem;
        border-radius: 20px;
    }

    .plan_price {
        text-align: center;
        color: #170F49;
        margin-top: 1rem;
        span {
            font-size: 2.5rem;
            font-weight: 700;
        }
    }

    .plan_speedupto {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        justify-content: center;
        color: #170F49;
        p {
            color: #01A7E1;
            font-size: 2.5rem;
        }
        .gig_speed{
            color: #170F49;
            font-weight: bold;
        }
        span{
            text-align: left;
        }
    }

    .plan_desc1000{
        text-align: center;
        color: #170F49;
        font-size: 1rem;
        @media screen and (max-width: 459px) {
            // margin-left: 4rem;
            
        }
    }
     .plan_desc500{
        text-align: center;
        color: #170F49;
        font-size: 1rem;
        @media screen and (max-width: 459px) {
            // margin-left: 0.5rem;
            
        }
    }
    .plan_bullets{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 1rem;
        li{
            display: flex;
            gap: 0.5rem;
            align-items: center;
            color: #6F6C90;
            font-size: 1rem;
            font-weight: 500;
        
        }
    }

    .plan_button{
        background-color: #01A7E1;
        color: white;
        padding: 0.5rem 2rem;
        width: 100%;
        border-radius: 20px;
        margin: auto;
        margin-top: 1rem;
        font-size: 1.2rem;
    }
}

