.manageTeamMembers{
    display: grid; /* grid */
    grid-template-columns: repeat(1, minmax(0, 1fr)); /* grid-cols-1 */
    gap: 3rem; /* gap-12 */
    width: 100%; /* w-full */

    .filters{
        display: flex; /* flex */
        flex-direction: column; /* flex-col */
        gap: 3rem; /* gap-12 */
        background-color: white; /* bg-white */
        padding: 2rem; /* p-2 */
        border-radius: 1.5rem; /* rounded-3xl */
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3); /* shadow-md */
        div{
            display: grid; /* grid */
            grid-template-columns: repeat(2, minmax(0, 1fr)); /* grid-cols-1 */
            @media screen and (max-width: 1024px){
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
            gap: 3rem; /* gap-12 */

            input,select{
                width: 100%;
                height: 100%;
                border-radius: 9.024px;
                border: 1px solid #E5E5E5;
                padding: 0.5rem;
                font-size: 1rem;
                font-weight: bold;
                text-align: center;

                &:focus {
                    outline: none;
                }
            }
        }
        button{
            margin: auto;
            width: -moz-fit-content;
            width: fit-content;
            padding-left: 3rem;
            padding-right: 3rem;
            height: 3rem;
            border-radius: 9.024px;
            font-size: 1rem;
            text-align: center;
            font-weight: 600;
            color: white;
            background-color: #181E4B;
            &:focus {
                outline: none;
            }
        }
    }

    .spinner{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .pagination{
        display:flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}