.SearchTableLayout {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ManageCustomersDiv {
    width: 100% !important;
    .button_div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 1rem 0;
        width: 100%;
        button {
            padding: 0.5rem 1rem;
            border-radius: 10px;
            background-color: var(--color-primary);
            color: white;
            border: none;
        }
    }
}

.SearchCard {
    background-color: white;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    padding: 2rem;
    border-radius: 10px;
}

.SearchDiv {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.SearchTitle {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    color: var(--color-primary);
}

.SearchInputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
}

.expand_icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.TableDiv {
    width: 100%;

    .top_button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px;
        gap: 1rem;
        flex-wrap: wrap;
        margin-top: 1rem;
        .filter_btn {
            padding: 0.5rem 1rem;
    
            @media screen and (max-width: 1000px) {
                padding: 0.2rem 0.5rem;
                font-size: 0.8rem;
                border-radius: 10px;
            }
    
            border: none;
            background-color: #2761D0;
            color: white;
            border-radius: 15px;
            cursor: pointer;
            display: flex;
            align-items: center;
        }
    
        .export_button {
            background-color: #81848A;
        }
    }


}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.issue_details_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    max-width: 500px;
    margin: 2rem auto;
}

.title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
}

.button_group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.confirm_button {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: #2761D0;
    color: white;
    border: none;
    cursor: pointer;
}
