/* SelfieUploadPopup.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 12px;
    max-width: 450px;
    width: 90%;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    transform: scale(1);
    animation: scaleUp 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  @keyframes scaleUp {
    from { transform: scale(0.95); }
    to { transform: scale(1); }
  }
  
  .upload-header {
    margin-bottom: 1.5rem;
  }
  
  .upload-header h2 {
    font-size: 1.5rem;
    color: #2d3748;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .upload-instructions {
    color: #718096;
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
  
  .file-input-wrapper {
    position: relative;
    margin: 1.5rem 0;
  }
  
  .file-input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .upload-area {
    border: 2px dashed #cbd5e0;
    border-radius: 8px;
    padding: 2rem;
    transition: all 0.2s ease;
    background: #f7fafc;
  }
  
  .upload-area:hover {
    border-color: #4299e1;
    background: #ebf8ff;
  }
  
  .upload-area.dragover {
    border-color: #4299e1;
    background: #ebf8ff;
    transform: scale(1.02);
  }
  
  .upload-preview {
    margin-top: 1rem;
    max-width: 150px;
    max-height: 150px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .button-group {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .upload-button {
    background: #4299e1;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .upload-button:hover {
    background: #3182ce;
    transform: translateY(-1px);
  }
  
  .close-button {
    background: #e2e8f0;
    color: #2d3748;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .close-button:hover {
    background: #cbd5e0;
  }
  
  .selected-file {
    margin-top: 1rem;
    color: #4299e1;
    font-size: 0.9rem;
  }