.offer_container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;

    @media screen and (max-width:1370px) {
        grid-template-columns: 1fr;
    }

    padding-top: 2rem;
    background-color: white;
    gap: 2rem;

    @media screen and (max-width: 1000px) {
        padding: 2rem 1rem;
    }

    position: relative;

    .share_container {
        position: fixed;
        z-index: 1000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
        gap: 1rem;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        padding: 2rem;

        .link_input {
            outline: none;
            border: 1px solid #2761D0;
            border-radius: 10px;
            padding: 0.5rem;
            width: 100%;
            display: flex;
            gap: 0.5rem;
            cursor: pointer;
        }
    }

    .BizCard {
        width: 100%;
        margin: auto;
        margin-top: 1rem;

        // @media screen and (max-width:1000px) {
        //     display: none;
        // }
        @media screen and (max-width: 1370px) {
            width: 70%;
        }

        @media screen and (max-width:1000px) {
            width: 100%;
        }
    }

    .offer {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        padding: 2rem;
        border-radius: 10px;

        @media screen and (max-width:500px) {
            padding: 1rem;
        }

        .free_install {
            font-weight: bold;
            font-size: 2rem;
        }

        .two_free {
            font-size: 3rem;
            font-weight: bold;
            margin-bottom: 0rem;
        }

        .month {
            font-size: 3.5rem;
            font-weight: bold;
            text-align: center;
            margin-top: 0rem;
        }

        h2 {
            font-size: 1.5rem;

            @media screen and (max-width: 1000px) {
                font-size: 1.5rem;
            }

            font-weight: 500;
            margin-bottom: 1rem;
            color: var(--primary-text);
            text-align: center;

            span {
                color: #2761D0;

            }
        }

        h3 {
            font-size: 1.5rem;

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }

            font-weight: 500;
            color: var(--primary-text);
            text-align: center;
        }

        .banner {
            width: 540px;
            margin: auto;

            @media screen and (max-width: 1000px) {
                width: 100%;
            }

            .accept_offer_button {
                background-color: #2761D0;
                color: white;
                padding: 0.5rem 1rem;
                border-radius: 10px;
                cursor: pointer;
                width: 540px;
                margin: auto;

                @media screen and (max-width: 1000px) {
                    width: 100%;

                }

                align-self: center;
            }

            .banner_top {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 2rem;

                @media screen and (max-width:500px) {
                    gap: 0.5rem;
                    margin-top: 0rem;
                }

                z-index: 1;
                position: relative;

                margin-top: 2rem;
                place-items: center;



                .package_light {
                    position: absolute;
                    left: 0%;
                    top: -50%;
                    // translate: transform(-50%, 0%);
                }

                .banner_card {
                    background-color: #fff;
                    padding: 1rem;
                    border-radius: 30px;
                    width: 250px;
                    border: 4px solid var(--border-color);

                    @media screen and (max-width: 1000px) {
                        width: 100%;
                    }

                    position: relative;
                    border: 4px solid #D9D9D9;
                    cursor: pointer;

                    p {
                        font-size: 1.2rem;

                        @media screen and (max-width: 700px) {
                            font-size: 1rem;
                        }

                        @media screen and (max-width: 430px) {
                            font-size: 0.8rem;
                        }

                        font-weight: 600;
                        color: #6F6C90;
                        text-align: left;
                    }

                    h2 {
                        font-size: 2.5rem;

                        @media screen and (max-width: 1000px) {
                            font-size: 1.5rem;
                        }

                        @media screen and (max-width: 430px) {
                            font-size: 1.2rem;

                        }

                        font-weight: 600;
                        color: #000;
                        text-align: left;
                    }

                    h4 {
                        font-size: 1.5rem;

                        @media screen and (max-width: 700px) {
                            font-size: 1.2rem;
                        }

                        @media screen and (max-width: 430px) {
                            font-size: 1rem;

                        }

                        font-weight: 600;
                        color: #000;
                        text-align: left;
                    }
                }


                .active {
                    border: 4px solid #2761D0;

                }
            }

            .banner_bottom {
                z-index: 1;
                background-color: #fff;
                padding: 1rem;
                padding-left: 0rem;
                border-radius: 10px;
                margin-top: 1rem;

                @media screen and (max-width: 1000px) {
                    margin-top: 0rem;

                }

                border: 4px solid var(--border-color);

                .service_card {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    justify-content: flex-start;
                    margin-bottom: 1rem;

                    @media screen and (max-width: 1000px) {
                        gap: 0.5rem;
                        margin-bottom: 0.5rem;
                    }

                    p {
                        font-size: 1.2rem;

                        @media screen and (max-width: 700px) {
                            font-size: 1rem;
                        }

                        @media screen and (max-width: 430px) {
                            font-size: 0.8rem;

                        }

                        color: #6F6C90;
                    }
                }
            }
        }
    }

    .offer_card {
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        width: -moz-fit-content;
        width: fit-content;
        padding: 2rem;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        margin: auto;
        margin-top: 2rem;

        h2 {
            font-size: 2rem;

            @media screen and (max-width: 1000px) {
                font-size: 1.2rem;
            }

            font-weight: 700;
            color: var(--primary-text);
            text-align: center;
        }

        .offer_type {
            border: 4px solid #2761D0;
            border-radius: 30px;
            padding: 2rem;

            p {
                font-size: 2rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }

                color: #2761D0;
                font-weight: 700;
                text-align: center;
            }
        }

        .free {
            h3 {
                font-size: 1.5rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }

                font-weight: 500;
                color: var(--primary-text);
                text-align: left;
            }
        }

        .include {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 0.5rem;
            align-items: center;

            p {
                color: rgb(139, 135, 135);
            }
        }

        .action_buttons {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;

            .accept {
                background-color: transparent;
                border: 2px solid green;
                color: green;
                padding: 0.5rem 1rem;
                border-radius: 10px;
            }

            .accept:hover {
                transition: all 0.5s ease;
                background-color: green;
                color: white;
            }

            .reject {
                background-color: transparent;
                border: 2px solid red;
                color: red;
                padding: 0.5rem 1rem;
                border-radius: 10px;
            }

            .reject:hover {
                transition: all 0.5s ease;
                background-color: red;
                color: white;
            }
        }
    }
}

.customer_model {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    h1 {
        font-size: 2rem;

        @media screen and (max-width: 1000px) {
            font-size: 1.5rem;
        }

        font-weight: 700;
        color: var(--primary-text);
        text-align: center;
    }

    .fiber_type_container {
        border: 8px solid #2761D0;
        border-radius: 30px;
        padding: 3rem;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;

        p {
            font-size: 3rem;
            font-weight: 900;
            color: #2761D0;
        }
    }

    form {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;

        .DesiredInstallDiv {
            display: flex;
            flex-direction: column;

            h3 {
                margin-bottom: 0.5em;
                font-size: 1.2em;
                font-weight: bold;
                color: #333;
                text-transform: uppercase;
                // text-decoration: underline;
                letter-spacing: 1px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
            }

            .OptionsDiv {
                margin-top: 1em;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 1em;

                div {
                    display: flex;
                    flex-direction: row;
                    justify-content: left;
                    align-items: center;
                    margin-bottom: 1em;
                    // border-bottom: 1px solid #E5E5E5;
                    padding-bottom: 1em;

                    input {
                        // check box input
                        width: 1.5em;
                        height: 1.5em;
                        margin-right: 0.5em;

                    }

                    label {
                        // check box label
                        font-size: 1em;
                        font-weight: 600;
                        margin-bottom: 0em;
                    }
                }

                .OtherDiv {
                    grid-column: span 3;
                    display: flex;
                    flex-direction: row;
                    justify-content: left;
                    align-items: center;
                    margin-bottom: 1em;
                    // border-bottom: 1px solid #E5E5E5;
                    padding-bottom: 1em;

                    input {
                        // check box input
                        width: 100%;
                        height: 3em;
                        margin-right: 0.5em;

                    }

                    label {
                        // check box label
                        font-size: 1em;
                        font-weight: 600;
                        margin-bottom: 0em;
                    }
                }
            }
        }

        .form_group {
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;

            label {
                font-size: 1.2rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }

                color: var(--primary-text);
                // margin-bottom: 0.5em;
                // font-size: 1.2em;
                font-weight: bold;
                color: #333;
                text-transform: uppercase;
                // text-decoration: underline;
                letter-spacing: 1px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
            }

            input,select {
                padding: 0.5rem;
                outline: none;
                border: 1px solid #C4C4C4;
                border-radius: 5px;
                font-size: 1rem;
                color: black;

                @media screen and (max-width: 1000px) {
                    font-size: 0.8rem;
                }
            }

            textarea {
                padding: 0.5rem;
                outline: none;
                border: 1px solid #C4C4C4;
                border-radius: 5px;
                font-size: 1rem;
                color: black;

                @media screen and (max-width: 1000px) {
                    font-size: 0.8rem;
                }
            }

        }

        .checkbox_container {
            display: flex;
            flex-direction: column;
            .checkbox {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                input {
                    width: 1rem;
                    height: 1rem;
                }

                label {
                    font-size: 1rem;
                    font-weight: bold;
                    color: #333;
                    text-transform: uppercase;
                    // text-decoration: underline;
                    letter-spacing: 1px;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                }
            }

            .details{
                color: #C4C4C4;
                font-size: 0.8rem;
                font-weight: 500;
            }
        }

        button {
            padding: 0.5rem 1rem;
            background-color: #2761D0;
            color: white;
            border: none;
            border-radius: 5px;
            font-size: 1rem;
            cursor: pointer;

            @media screen and (max-width: 1000px) {
                font-size: 0.8rem;
            }
        }
    }

}