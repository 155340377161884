.announcement-banner-container {
    display: grid;
    gap: 1rem;
    margin: 20px 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    padding-right: 10px;
  }
  
  /* Scrollbar customization */
  .announcement-banner-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .announcement-banner-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
  
  .announcement-banner-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .announcement-banner {
    background-color: #f5f5f5;
    border-left: 4px solid #2761CF;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 0.5rem;
    align-items: center;
  
    &:hover {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }
  
    /* Title in Row 1, Column 1 */
    .announcement-title {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
      font-size: 1.1rem;
      font-weight: bold;
      color: #181e4b;
      text-align: left;
      display: flex;
      align-items: center;
    }
  
    /* Announcement Type in Row 1, Column 2 (right-aligned) */
    .announcement-type {
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
      font-size: 1rem;
      font-weight: bold;
      color: #696969;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
  
      /* Optional media icon */
      .media-icon {
        margin-left: 10px;
      }
    }
  
    /* Message in Row 2, Column 1 */
    .announcement-message {
      grid-column: 1 / span 2;
      grid-row: 2 / span 1;
      font-size: 0.9rem;
      color: #333;
      line-height: 1.4;
      text-align: left;
    }
  
    /* Announcement Date in Row 2, Column 2 (right-aligned) */
    .announcement-date {
      grid-column: 2 / span 1;
      grid-row: 2 / span 1;
      font-size: 0.85rem;
      color: #999;
      text-align: right;
      justify-self: flex-end; /* Ensure it aligns to the right */
    }
  }
.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}


.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .modal-content {
      background-color: #fff;
      padding: 30px;
      border-radius: 10px;
      width: 400px;
      max-height: 80vh; /* Dynamic height: takes 80% of the viewport height */
      overflow-y: auto;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      z-index: 9999;

      img,video {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 5px;
        max-width: 100%;
      }
      
  
    }
}