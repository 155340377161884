.MainBizCardContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: min(100%, 600px);
    margin: 0 auto;
    h1 {
        font-size: 2rem;
        font-weight: 600;
        @media screen and (max-width: 1000px) {
            font-size: 1.5rem;
            // margin-bottom: 1rem;
        }
        // margin-bottom: 1rem;
        color: black;
        text-align: center;
    }

    .input_div {
        display: flex;
        flex-direction: column;
        label {
            font-size: 1rem;
            font-weight: 500;
        }

        input {
            padding: 0.5rem;
            font-size: 1rem;
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            outline: none;
            width: 100%;
        }
        .note{
            font-size: 0.8rem;
            color: rgb(132, 161, 200);
        }
        .async_select__control{
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            outline: none;
            width: 100%;
            // padding: 0.1rem;
        }
    }



    .button {
        // display: flex;
        // flex-direction: column;
        // gap: 1rem;
        // margin-top: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
        place-items: center;

        button {
            width: -moz-fit-content;
            width: fit-content;
            .icon {
                color: #2761D0;
                // @media screen and (max-width: 500px) {
                //     font-size: 1rem !important;
                    
                // }
    
                &:hover {
                    color: #2761D0;
                    transform: scale(1.2);
                }

            }
        }
    
        .disabled {
            background-color: #C4C4C4;
            cursor: not-allowed;
        }
    }

    .userType {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        // @media screen and (max-width: 1000px) {
        //     gap: 0.5rem;
        // }
        align-items: center;
        justify-content: flex-start;
        label {
            color: black;
            font-size: 1rem;
            // @media screen and (max-width: 1000px) {
            //     font-size: 0.6rem;
            // }

            display: grid;
            grid-template-columns: auto 1fr;
            gap: 0.5rem;
            align-items: center;
            // font-weight: 500;
        }

        input {
            width: 1.5rem;
            height: 1.5rem;
            @media screen and (max-width:1000px) {
                width: 1rem;
                height: 1rem;
            }
        }

        // input[type='radio'] {
        //     accent-color: blue;
        // }
    }
}

.create_offer_form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    h1 {
        font-size: 2rem;
        font-weight: 600;
        color: black;
        text-align: center;

        @media screen and (max-width: 1000px) {
            font-size: 1.5rem;
        }

        @media screen and (max-width: 520px) {
            font-size: 1.3rem;
        }
    }



    .input_container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;

        select {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            font-weight: 500;
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            outline: none;
            width: 100%;
        }

        span {
            color: black;
            font-size: 1rem;
            font-weight: 500;
        }

        input {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            font-weight: 500;
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            outline: none;
            width: 100%;
        }
    }

    button {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: 500;
        border: none;
        border-radius: 10px;
        outline: none;
        cursor: pointer;
        background-color: #2761D0;
        color: white;
        width: 100%;
    }
}
.send_offer_button{
    background-color: #2761D0;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    width: 100%;
    border: none;
    border-radius: 10px;
}
.biz_card_tab{
    padding: 1rem;
    @media screen and (max-width: 1000px) {
        padding: 0.1rem;
    }
}
.BizCard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1rem;
    @media screen and (max-width:1000px) {
        gap: 0.5rem;
        padding: 0.5rem;
    }
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    padding: 2rem;

    @media screen and (max-width: 1000px) {
        padding: 1rem;
    }

    border-radius: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .main_review {
        border: none;
    }

    .part1 {
        grid-column: span 3 / span 3;
        // @media screen and (max-width: 1000px) {
        //     grid-column: span 5 / span 5;
        // }

        h1 {
            font-size: 3rem;
            @media screen and (max-width: 1100px) {
                font-size: 2rem;
                
            }
            @media screen and (max-width: 600px) {
                font-size: 1rem;
                margin-bottom: 0.5rem;
            }

            font-weight: 600;
            color: black;
            text-align: left;
        }

        h3 {
            font-size: 1.25rem;

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }

            font-weight: 600;
            color: #b6b6b6;
            text-align: left;
        }

        .rating {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            margin-bottom: 1rem;

            .css-ryrseu-MuiRating-root {
                @media screen and (max-width: 1000px) {
                    font-size: 1rem !important;
                }

            }

            p {
                color: #2761D0;
                font-size: 1rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }

                text-decoration: underline;
            }
        }

        .imageAndVideo {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            height: 200px;
            @media screen and (max-width:1250px) {
                
            }
            @media screen and (max-width: 1000px) {
                height: 140px;
                gap: 0.5rem;
            }
            // @media screen and (max-width: 520px){
            //     height: 70px;
            // }
            .image {
                grid-column: span 1 / span 1;
                border-radius: 20px;

                @media screen and (max-width: 1000px) {
                    border-radius: 10px;
                }

                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .video {
                grid-column: span 2 / span 2;
                border-radius: 20px;
                background-color: black;

                @media screen and (max-width: 1000px) {
                    border-radius: 10px;
                }

                overflow: hidden;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;

                }
            }
        }

        .MyReviews {
            display: grid;
            grid-template-columns: 1fr;
            margin-top: 1rem;
            place-items: center;
            border: 1px solid #C4C4C4;
            padding: 1rem;

            @media screen and (max-width: 1000px) {
                padding: 0.5rem;
                border-radius: 10px;
            }

            border-radius: 20px;

            h3 {
                color: black;
                font-size: 1.25rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }
            }

            .filters {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 0.5rem;
                @media screen and (max-width: 1000px) {
                    gap: 0.2rem;
                }

                button {
                    padding: 0.5rem 1rem;

                    @media screen and (max-width: 1000px) {
                        padding: 0.2rem 0.5rem;
                    }

                    font-size: 1rem;

                    @media screen and (max-width: 1000px) {
                        font-size: 0.5rem;
                        border-radius: 5px;
                    }

                    font-weight: 600;
                    border: none;
                    border-radius: 10px;
                    outline: none;
                    cursor: pointer;
                    background-color: #040F3C;
                    color: white;
                }
            }

            .review {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                @media screen and (max-width: 1000px) {
                    gap: 0.5rem;
                }

                margin-top: 1rem;
                justify-content: center;
                align-items: center;

                .reviewHeader {
                    display: flex;
                    gap: 1rem;

                    @media screen and (max-width: 1000px) {
                        gap: 0.5rem;
                    }

                    .image {
                        width: 50px;
                        height: 50px;

                        @media screen and (max-width: 1000px) {
                            width: 30px;
                            height: 30px;
                        }

                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                    }

                    .info {
                        h5 {
                            font-size: 1rem;

                            @media screen and (max-width: 1000px) {
                                font-size: 0.6rem;
                            }

                            font-weight: 600;
                            color: black;
                        }

                        p {
                            font-size: 1rem;

                            @media screen and (max-width: 1000px) {
                                font-size: 0.5rem;
                            }

                            color: #b6b6b6;

                        }
                    }
                }

                .reviewBody {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    @media screen and (max-width: 1000px) {
                        gap: 0.5rem;
                    }

                    justify-content: center;
                    align-items: center;

                    p {
                        font-size: 1rem;

                        @media screen and (max-width: 1000px) {
                            font-size: 0.6rem;
                        }

                        color: black;
                        text-align: center;
                    }
                }
            }
        }
    }

    .part2 {
        grid-column: span 2 / span 2;
        // @media screen and (max-width: 1000px) {
        //     grid-column: span 5 / span 5;
            
        // }
        .fiber-logo {
            width: 100%;
            max-width: 300px;
            margin-bottom: 1rem;
            @media screen and (max-width:1000px) {
                margin-bottom: 0.5rem;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

            }
        }
    }

    .userInfo {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        @media screen and (max-width: 1000px) {
            gap: 0.5rem;
            
        }
        .element {
            display: grid;
            gap: 1rem;
            grid-template-columns: auto 1fr;
            align-items: center;

            @media screen and (max-width: 1000px) {
                gap: 0.2rem;

            }

            p {
                color: #b6b6b6;
                font-size: 1rem;

                @media screen and (max-width: 1000px) {
                    font-size:0.8rem;

                }
            }
        }
    }

    .trustedPartner {
        margin-top: 1rem;
        grid-column: span 5 / span 5;

        h3 {
            font-size: 1.25rem;

            @media screen and (max-width: 1000px) {
                font-size: 0.7rem;
            }

            font-weight: 600;
            margin-bottom: 1rem;
            color: black;
            text-align: left;
        }

        div {
            // display: flex;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 1rem;
            // flex-direction: column;
            flex-wrap: wrap;
            align-items: baseline;

            img {
                height: 30px;
                width: auto;
                object-fit: contain;
            }
        }
    }
}

.mobile_biz_card{
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    padding: 2rem;
    @media screen and (max-width:500px) {
        padding: 1rem;
    }
    border-radius: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    
    .nameimage{
        display: flex;
        flex-direction: row;
        gap: 1rem;
        .name{

            // width: 100%;
            h1{
                font-size: 1.5rem;
                @media screen and (max-width: 1000px){
                    font-size: 1.3rem;
                }
                font-weight: 600;
                color: black;
                text-align: center;
            }
            h2{
                font-size: 1rem;
                @media screen and (max-width: 1000px){
                    font-size: 0.8rem;
                    
                }
                font-weight: 600;
                color: black;
                text-align: center;
            }
        }

        .image{
            border-radius: 50%;
            overflow: hidden;
            width: 90px;
            height: 90px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .contact_details{
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        .element{
            display: flex;
            gap: 0.5rem;
            align-items: center;
            p{
                color: #b6b6b6;
                font-size: 1rem;
            }
        
        }
    }
    .video{
        width: 80vw;
        video{
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 10px;
            overflow: hidden;
        }
    }
    .reviews{
        width: 80vw;
    }
}

.dissappearOnMobile{
    @media screen and (max-width: 1000px) {
        display: none;
        
    }
}