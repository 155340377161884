.commission_plan_details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;

    }

    .title {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: center;
        grid-column: span 2/span 2;

        @media screen and (max-width: 1000px) {
            grid-column: span 1/span 1;
        }
    }

    .spinner {
        display: grid;
        place-items: center;
        height: 100%;
        grid-column: span 2/span 2;

        @media screen and (max-width: 1000px) {
            grid-column: span 1/span 1;
        }
    }

    .details1 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;


        .commission_name_desc {

            border: 2px solid #4FAFCB;
            border-radius: 10px;
            height: fit-content;
            height: -moz-fit-content;
            padding: 2rem;
            background-color: white;
            text-align: center;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);

            .commission_name {
                font-size: 1.8rem;
                font-weight: 600;
            }

            .commission_desc {
                font-size: 1.2rem;
                font-weight: 400;
            }
        }

        .commission_table {
            display: grid;
            grid-template-columns: 1fr 1fr;
            // gap: 0.5rem;
            border: 4px solid #4FAFCB;
            border-radius: 10px;
            background-color: white;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
            padding: 2rem;
            overflow: auto;
            height: 500px;

            h3 {
                font-size: 1.8rem;
                font-weight: 600;
                text-align: center;
                grid-column: span 2/span 2;

                @media screen and (max-width: 1000px) {
                    grid-column: span 1/span 1;
                }
            }

            .commission_table_item {
                border-bottom: 1px solid #4FAFCB;
                padding: 0.5rem 1rem;
                font-weight: 500;
                text-transform: capitalize;
            }

            input {
                border: none;
                border-bottom: 1px solid #4FAFCB;
                padding: 0.5rem 1rem;
                font-weight: 500;
                outline: none;
                color: green;
            }

            .commission_save_button {
                width: fit-content;
                width: -moz-fit-content;
                padding: 0.5rem 1rem;
                border-radius: 9.024px;
                border: none;
                background-color: var(--color-primary);
                color: white;
                font-size: 1rem;
                font-weight: 600;
                cursor: pointer;

                &:focus {
                    outline: none;
                }
            }

            .commission_cancel_button {
                width: fit-content;
                width: -moz-fit-content;
                padding: 0.5rem 1rem;
                border-radius: 9.024px;
                border: none;
                background-color: red;
                color: white;
                font-size: 1rem;
                font-weight: 600;
                cursor: pointer;

                &:focus {
                    outline: none;
                }
            }

            .commission_edit_button {
                width: fit-content;
                width: -moz-fit-content;
                padding: 0.5rem 1rem;
                border-radius: 9.024px;
                border: none;
                background-color: #4FAFCB;
                color: white;
                font-size: 1rem;
                font-weight: 600;
                cursor: pointer;

                &:focus {
                    outline: none;
                }
            }

            .commission_table_item:nth-child(odd) {
                color: #4FAFCB;
            }
        }
    }

    .details2 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        height: fit-content;

        .add_users {
            height: fit-content;
            border: 2px solid #4FAFCB;
            border-radius: 10px;
            background-color: white;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
            padding: 2rem;
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;

            .add_users_title {
                font-size: 1.8rem;
                font-weight: 600;
                text-align: center;
            }

            .add_user_btn {
                width: 100%;
                padding: 0.5rem 1rem;
                border-radius: 9.024px;
                border: none;
                background-color: var(--color-primary);
                color: white;
                font-size: 1rem;
                font-weight: 600;
                cursor: pointer;

                &:focus {
                    outline: none;
                }

            }
            .fiber_plan_form{
                display: grid;
                grid-template-columns: 1fr;
                gap: 0.5rem;
                .fiber_plan_input{
                    border: 1px solid #E6E6E6;
                    border-radius: 9.024px;
                    padding: 0.5rem 1rem;
                    font-weight: 500;
                    outline: none;
                }
            }
            .fiber_commision_plans_list{
                display: grid;
                grid-template-columns: 1fr;
                gap: 0.5rem;
                .fiber_commission_plan{
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 0.5rem;
                    background-color: white;
                    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
                    padding: 1rem;
                    border-radius: 10px;
                    .fiber_plan_title{
                        font-size: 1.2rem;
                        font-weight: 600;
                        border-bottom: 1px solid #E6E6E6;
                    }
                    .fiber_plan_details{
                        display: grid;
                        grid-template-columns: 1fr ;

                        h1{
                            font-size: 1.1rem;
                            font-weight:500;
                        }
                        div{
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 0.2rem;
                            input{
                                border: 1px solid #E6E6E6;
                                border-radius: 9.024px;
                                padding: 0.5rem 1rem;
                                font-weight: 500;
                                outline: none;
                            }
                            .single{
                                grid-column: span 2;
                                span{
                                    font-weight: normal
                                    ;
                                }
                            }
                            p{
                                border: 1px solid #E6E6E6;
                                padding: 0.5rem;
                                border-radius: 10px;
                                span{
                                    font-weight: 600;

                                }
                            }
                        }
                        .buttons{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            margin-top: 0.5rem;
                            button{
                                width: fit-content;
                                width: -moz-fit-content;
                                margin: auto;
                                padding: 0.5rem 1rem;
                                border-radius: 9.024px;
                                border: none;
                                background-color: var(--color-primary);
                                color: white;
                                font-size: 1rem;
                                font-weight: 600;
                                cursor: pointer;
                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .current_users {
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;
            border: 2px solid #4FAFCB;
            border-radius: 10px;
            background-color: white;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
            // height: fit-content;
            padding: 2rem;
            height: 100%;

            .current_users_title {
                font-size: 1.8rem;
                font-weight: 600;
                text-align: center;
            }

            .current_users_list {
                display: grid;
                grid-template-columns: 1fr;
                gap: 0.5rem;
                .no_users{
                    text-align: center;
                    font-size: 1.2rem;
                }
                .current_users_item {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    place-items: start;


                    .info {
                        display: grid;
                        grid-template-columns: auto 1fr;
                        border: 2px solid #4FAFCB;
                        // padding: 1rem;
                        background-color: white;
                        border-radius: 10px;
                        gap: 1rem;
                        padding: 1rem;
                        width: 100%;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        border-right: none;
                        cursor: pointer;
                        .avatar {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .user_info {
                            display: grid;
                            grid-template-columns: 1fr;

                            .user_name {
                                font-size: 1rem;
                                font-weight: 600;
                            }

                            .user_email {
                                font-size: 1rem;
                                font-weight: 400;
                            }
                        }
                    }

                    .minus {
                        background-color: red;
                        color: white;
                        height: 100%;
                        width: 100%;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                        padding: 1rem 0.5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

        }
    }
}