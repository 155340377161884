.CustomerDetailsPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .TitleDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        h1 {
            font-size: 24px;
            font-weight: bold;
            color: var(--color-primary);
            margin-bottom: 20px;
            width: 90%;
            text-align: center;
        }
        .EditButton {
            background-color: var(--color-primary);
            color: var(--color-foreground);
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .SatelliteButton{
        background-color: #0056b3;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 10px;
    }
    .DetailsCard {
        background-color: var(--color-foreground);
        padding: 20px;
        border-radius: 5px;
        border: 1px solid #ddd;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: 100%;
        margin-bottom: 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .DetailsCardTitle {
            display: flex;
            justify-content: left;
            width: 100%;
            gap: 1rem;
            // background-color: #ddd;

            h2 {
                font-size: 21px;
                font-weight: bold;
                color: var(--color-primary);
                margin-bottom: 10px;
            }
            border-bottom: 1px solid #ddd;
            margin-bottom: 1.5em;
        }

        .DetailsDiv {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 1em;
            width: 100%;
            margin-bottom: 1.5em;

            @media (max-width: 1000px) {
                grid-template-columns: 1fr 1fr;
                grid-gap: 1em;
            }

            .DetailsDivElement {
                display: flex;
                // align-items: center;
                flex-direction: column;
                gap: 10px;
                margin-bottom: 10px;
                justify-content: left;

                p {
                    font-weight: bold;
                    color: var(--color-primary);
                    // margin-right: 10px;
                    // max-width: 6em;
                }

                span {
                    // font-weight: bold;
                    color: var(--color-primary);
                }
            }
        }
    }
    
}

.CustomerEditDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .TitleDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        
        h1 {
            font-size: 24px;
            font-weight: bold;
            color: var(--color-primary);
            margin-bottom: 20px;
            width: 90%;
            text-align: center;
        }

        .EditButton {
            background-color: var(--color-primary);
            color: var(--color-foreground);
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .CustomerEditInputsDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 1.5em;

        .CustomerEditInputDiv {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            justify-content: space-between;
            width: 100%;
            // min-width: 70%;

            label {
                font-weight: bold;
                color: var(--color-primary);
                margin-right: 10px;
                width: 30%;                
            }

            input {
                font-weight: bold;
                color: var(--color-primary);
                padding: 5px;
                border: 1px solid #ddd;
                border-radius: 5px;
                width: 70%;
                padding: 1em;
            }

            select {
                font-weight: bold;
                color: var(--color-primary);
                padding: 5px;
                border: 1px solid #ddd;
                border-radius: 5px;
                width: 70%;
                padding: 1em;
            }
        }
    }

    .SaveButton {
        background-color: var(--color-primary);
        color: var(--color-foreground);
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        width: 80%;
        height: 4.5em;

        &:hover {
            background-color: #0056b3;
            transform: translateY(-2px);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        }
    }
}