.cancel_form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: min(90vw,500px);
    margin: 2rem auto;
    @media screen and (max-width: 1000px) {
        padding: 1rem !important;
        margin: 1rem auto !important;
    }
    .subtitle {
        margin-top: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: center; 
        @media screen and (max-width: 1000px) {
            font-size: 1.2rem;
        }
    }
    
    
    .inputs {
        width: 100%;
    }
    

    .inputDiv{
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 0.5rem;
        align-items: center;
        input{
            width: 1rem;
            height: 1rem;
        }
    }

    .inputDiv,
    .subInput,
    .subSubInput {
        margin-bottom: 1rem;
    }
    
    .inputWrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-wrap: wrap;
        margin-top: 0.5rem;
    }
    
    
    .preferBtn {
        background-color: #2761D0;
        color: white;
        border: 1px solid #ccc;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        cursor: pointer;
    }
       
    
    .submitBtn {
        margin-top: 1rem;
        padding: 0.75rem 2rem;
        border: none;
        border-radius: 5px;
        color: white;
        cursor: pointer;
    }
    
}


