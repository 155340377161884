.customerFeedbackAnalysis {
    display: grid;
    /* grid */
    grid-template-columns: repeat(1, minmax(0, 1fr));
    /* grid-cols-1 */
    gap: 1rem;
    /* gap-4 */
    width: 100%;
    /* w-full */
    margin: auto;
    /* m-auto */

    h1 {
        font-size: 1.875rem;
        /* text-3xl */
        font-weight: 700;
        /* font-bold */
    }

    .innerContainer {
        display: grid;
        /* grid */
        grid-template-columns: repeat(1, minmax(0, 1fr));
        /* grid-cols-1 */
        gap: 1rem;
        /* gap-4 */

        .analysisPart1 {
            display: grid;
            /* grid */
            grid-template-columns: repeat(5, minmax(0, 1fr));

            /* grid-cols-3 */
            @media screen and (max-width: 1024px) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
                /* grid-cols-2 */

            }

            gap: 1rem;
            /* gap-4 */

            .boldP {
                font-weight: 700;
                /* font-bold */
                font-size: 1.125rem;
                /* text-lg */
            }
        }

        .analysisPart2 {
            .innerPart2 {
                display: grid;
                /* grid */
                grid-template-columns: repeat(2, minmax(0, 1fr));

                @media screen and (max-width: 1024px) {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }

                /* grid-cols-1 */
                gap: 1rem;

                /* gap-4 */
                .customerSentiment {
                    h1 {
                        font-weight: 700;
                        /* font-bold */
                        font-size: 1.125rem;
                        /* text-lg */
                        text-transform: uppercase;
                        /* uppercase */
                    }

                    div {
                        display: grid;
                        /* grid */
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                        /* grid-cols-2 */
                        div{
                            display: flex; /* flex */
                            gap: 1rem; /* gap-4 */
                            align-items: center; /* items-center */

                            .boldP{
                                font-weight: 700;
                            }
                        }
                    }
                }

                .overview{
                    width: -moz-fit-content;
                    width:fit-content;
                    h1{
                        font-weight: 700;
                        /* font-bold */
                        font-size: 1.125rem;
                        /* text-lg */
                        text-transform: uppercase;
                    }
                    div{
                        display: grid; /* grid */
                        grid-template-columns: repeat(2, minmax(0, 1fr)); /* grid-cols-2 */

                        div{
                            display: grid; /* grid */
                            grid-template-columns: repeat(1, minmax(0, 1fr)); /* grid-cols-1 */

                            p{
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }

        .analysisPart3{
            h1{
                font-weight: 700; /* font-bold */
                font-size: 1.125rem; /* text-lg */
                text-transform: uppercase; /* uppercase */
            }

            .feedBackContainer{
                display: grid; /* grid */
                grid-template-columns: repeat(2, minmax(0, 1fr)); /* grid-cols-1 */
                gap: 1rem; /* gap-4 */
                @media  screen and (max-width: 1024px) {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }
            }
        }
    }
}

.card {
    background-color: white;
    /* bg-white */
    border: 2px solid #C4C4C4;
    /* border-2 border-[#C4C4C4] */
    padding: 2rem;
    /* p-8 */
    border-radius: 1.5rem;
    /* rounded-2xl */
}