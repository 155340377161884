.live-tracking-tab {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);


    .selector_con {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      label {
          font-weight: bold;
          font-size: 1.2rem;
          @media screen and (max-width: 1000px) {
              font-size: 1rem;
          }
      }

      select,
      .date {
          width: 100%;
          height: 3rem;
          @media screen and (max-width: 1000px) {
              height: 2rem;
          }
          border: 1px solid #E6E6E6 !important;
          border-radius: 5px;
          padding: 0 0.5rem;
          font-size: 1rem;
          color: #41b6e6;
          text-align: left;

          @media screen and (max-width: 1000px) {
              font-size: 0.75rem;

          }

          font-weight: 500;
          background-color: transparent;
          // color: white;
          padding: 0 1rem;
          outline: none;

          &:focus {
              outline: none;
          }

          option {
              font-size: 1rem;
              text-align: center;
              font-weight: 600;
              color: #41b6e6;
              width: 100%;
          }


      }
  }
  .input {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    .searchBtn {

      padding: 0.5rem;
      border: none;
      border-radius: 10px;
      background-color: #2761D0;
      color: white;
      font-weight: bold;
      cursor: pointer;
      outline: none;
      transition: all 0.3s;
      &:hover{
          background-color: #2761D0;
      }
    }
  }


  
    .title {
      text-align: center;
      font-size: 2.5rem;
      font-weight: 700;
      color: #2c3e50;
      margin-bottom: 1rem;
    }
  
    .date-picker-container {
      display: flex;
      justify-content: center;
      gap: 1.5rem;

      .selector_con {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        label {
            font-weight: bold;
            font-size: 1.2rem;
            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }
        }
        .date{
            height: 2.5rem;
        }
        select{
            color: #002A8D;
            // padding: 0.5rem;
        }
        .single{
            @media  screen and (max-width: 600px) {
                grid-column: span 2;
                
            }
        }
    }
    .date_range {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      grid-column: span 2;
      border: 1px solid #E6E6E6;
      border-radius: 10px;
      padding: 0.5rem;
      width: 100%;

      .quick_date {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-radius: 10px;
          gap: 0.5rem;
        }
    }



      // input[type='date'] {
      //   padding: 0.8rem 1.2rem;
      //   font-size: 1.1rem;
      //   border: 1px solid #ced4da;
      //   border-radius: 8px;
      //   background-color: #fff;
      //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      //   transition: all 0.3s ease;
  
      //   &:hover {
      //     border-color: #3498db;
      //   }
  
      //   &:focus {
      //     outline: none;
      //     border-color: #3498db;
      //     box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
      //   }
  
      //   &::-webkit-calendar-picker-indicator {
      //     cursor: pointer;
      //     filter: invert(0.5);
      //   }
      // }
  
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
  
    .rep-list-container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }