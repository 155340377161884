.map_page_container {
    overscroll-behavior: none;
    @media screen and (max-width: 1000px) {
        position: absolute;
        top: 0px;
        height: 100dvh;
        width: 100vw;
        touch-action: none;
    }
    position: static;
}

.NewNavMap {

    width: 40px;
    height: 40px;

    @media screen and (max-width: 1200px) {
        display: none;

    }
}


.transparent_loader {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.5);
    padding: 1em;
    border-radius: 1em;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

    .loader_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            font-size: 1.5em;

            @media screen and (max-width: 1000px) {
                font-size: 1em;
            }

            font-weight: 600;
            color: #333;
            text-transform: uppercase;
            text-align: center;
        }
    }
}

.assigned_total {
    box-shadow: 0px 0px 10px 0px #078fff;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0rem;
}

.assigned_leads {
    // border: 1px solid #078fff;
    padding: 0.5rem;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    div {
        box-shadow: 0px 0px 10px 0px #078fff;
        width: -moz-fit-content;
        width: fit-content;
        padding: 0.5rem 1rem;
        border-radius: 10px;

    }
}

.not_on_desktop {
    display: none;

    @media screen and (max-width: 1000px) {
        display: flex;
    }
}

.SearchLocation {
    display: flex;
    position: absolute;
    z-index: 1;
    background-color: white;
    align-items: center;
    padding: 0rem 1rem;
    border-radius: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 595.5px;
    top: 2%;
    @media screen and (max-width: 1000px) {
        width: 234.082px;
        top: calc(2% + 65px);
        // left: 50%;
        // transform: translate(-30%, 0%);
    }

    // box-shadow: 0px 0px 14px 0px #2B92D5;

    .rotating {
        animation: rotation 2s infinite linear;
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }

    }

    input {
        outline: none;
        border: none !important;
        padding: 0.5rem 1rem;

        margin-right: 1rem;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
}

.options {
    position: absolute;
    z-index: 1000;
    background-color: white;
    align-items: center;
    left: 50%;
    transform: translate(-50%, 0%);
    top: 10%;

    @media screen and (max-width: 1000px) {
        top: calc(11% + 65px);

    }

    width: 595.5px;

    @media screen and (max-width: 1000px) {
        width: 234.082px;

    }

    padding: 0rem 1rem;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;

    div {
        padding: 0.5rem 1rem;
        border-radius: 10px;
        margin: 0.5rem 0;
        cursor: pointer;

        &:hover {
            background-color: #2B92D5;
            color: white;
        }

    }
}

.TrackLocationButton {
    // margin-top: 1em;
    background-color: #fff !important;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 10px;
}

.TitleDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    // font-size: 1.5em;
    h1,
    h2,
    h3 {
        font-size: 2.4em;
        font-weight: 600;
        text-align: center;

        @media screen and (max-width: 1000px) {
            font-size: 1.5em;

        }

    }

    @media only screen and (max-width: 1249px) {
        justify-content: center;
        align-items: center;

    }
}

.PopupContainer {
    position: absolute;
    // top: 70%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    width: 96%;
    height: 50%;
    overflow: auto;

    .main_review {
        border: none;
    }

    .PopupHeader {
        display: flex;

        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 0.5em;
    }
}

.ServicableAreaFormDiv {
    width: 100%;

    .selector {
        width: 100% !important;
    }

    @media only screen and (max-width: 1249px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        margin-bottom: 1em;
        // gap: 1em;
        // width: 100%;

        .css-13cymwt-control,
        .css-t3ipsp-control,
        .css-1nmdiq5-control {
            width: 100% !important;
        }

    }

    button {
        align-self: center;
    }

    .selector {
        width: 80%;
        margin: auto;
    }

    .ServicableAreaFormInputs {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;
        width: 100%;

        .react-geo-digitize-button-wrapper {
            width: 100%;
            justify-content: center;
            align-self: center;
            text-align: center;

            button {
                width: 80% !important;

                &:hover {
                    // background-color: #00A36F;
                    background-color: #078fff;
                }
            }

        }

        @media only screen and (max-width: 1249px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 1em;
            gap: 1em;

            input {
                width: 80%;
            }

            div {
                width: 80%;
            }

            button {
                width: 80% !important;
            }
        }
    }

    button {
        width: 80%;
    }

}

.CloseButton {
    background-color: #F2F2F2;
    border: 1px solid #E5E5E5;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.GreenCreateButton {
    background-color: #00B87C;
    color: white;
    border: none;
    border-radius: 0.5em;
    padding: 0.5em 1em;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;

    &:hover {
        background-color: #00A36F;
    }

}

.MapPageBottom {
    display: flex;
    flex-direction: row;
    margin-top: 3em;

    .MapPageSideMenu {
        width: 20%;
        margin-right: 1em;
        border: 1px solid #E5E5E5;
    }

    .MapFormDiv {
        width: 80%;
        border: 1px solid black;

        // .css-13cymwt-control,
        // .css-t3ipsp-control {}

        // .css-1nmdiq5-control {}

        // .css-1nmdiq5-menu {}

    }

    @media only screen and (max-width: 1249px) {
        display: flex;
        flex-direction: column;

        .MapPageSideMenu {
            width: 100%;
            margin-right: 0em;
            margin-bottom: 1.5em;
        }

        .MapFormDiv {
            width: 100%;
        }
    }

}

.create-map-icon-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    max-width: 800px;
    margin: auto;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .title-div {
        h1 {
            font-size: 1.5rem;
            color: #333;
            margin-bottom: 1rem;
        }
    }

    .create-map-icon-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .form-row {
            display: flex;
            gap: 1rem;
            align-items: center;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: stretch;
            }

            input,
            .file-upload-label {
                flex: 1;
            }
        }

        .file-upload-label {
            cursor: pointer;
            overflow: hidden;
            border: 2px dashed #C4C4C4;
            border-radius: 8px;
            background-color: white;
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: all 0.3s ease;

            &:hover {
                border-color: #2761D0;
            }

            .file-upload-content {
                display: flex;
                align-items: center;
                gap: 1rem;

                p {
                    margin: 0;
                    color: #333;
                }

                .image-preview {
                    width: 40px;
                    height: 40px;
                    border-radius: 4px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            svg {
                flex-shrink: 0;
            }
        }

        .checkbox-row {
            .MuiFormGroup-root {
                justify-content: flex-start;
                gap: 1rem;
            }

            .MuiFormControlLabel-root {
                margin-right: 0;
            }

            .MuiCheckbox-root {
                color: #2761D0;
            }
        }
    }

    .create-button {
        background-color: #2761D0;
        color: white;
        border: none;
        border-radius: 8px;
        padding: 0.75rem 1.5rem;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 600;
        transition: background-color 0.3s ease;
        align-self: flex-start;

        &:hover {
            background-color: #1e4ba3;
        }

        @media screen and (max-width: 768px) {
            align-self: stretch;
        }
    }
}

.MapIconsParent {
    max-width: 60em;
}

.MapIconsDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    overflow-x: scroll;
    // width: 100%;
    max-width: 100%;
    border: 1px solid #E5E5E5;
    padding: 1em;

    .MapIconDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 1em;
        min-width: 15em;

        @media screen and (max-width: 1000px) {
            min-width: 10em;

        }

        border: 1px solid #E5E5E5;
        padding: 1em;

        @media screen and (max-width: 1000px) {
            padding: 0.5em;

        }

        img {
            //small round image
            width: 6em;
            height: 6em;

            @media screen and (max-width: 1000px) {
                width: 3em;
                height: 3em;

            }

            border-radius: 50%;
            margin-bottom: 1em;
        }

        .MapIconName {
            font-size: 1.2em;
            font-weight: 600;
            margin-bottom: 1em;

            @media screen and (max-width: 1000px) {
                font-size: 0.8em;
                text-align: center;
            }
        }

        .MapIconButtonsDiv {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        button {
            background-color: #00B87C;
            color: white;
            border: none;
            border-radius: 0.5em;
            padding: 0.5em 1em;
            cursor: pointer;
            font-size: 1em;

            @media screen and (max-width: 1000px) {
                font-size: 0.8em;

            }

            font-weight: 600;
            margin-top: 1em;
            // margin-bottom: 1em;
            width: 100%;

            &:hover {
                background-color: #00A36F;
            }
        }



        .MapIconEditButton {
            background-color: #078fff; // change to a more colorful background color
            color: white; // change text color to white
            border: none;
            border-radius: 0.5em;
            padding: 0.5em 1em;
            cursor: pointer;
            font-size: 1em;

            @media screen and (max-width: 1000px) {
                font-size: 0.8em;

            }

            font-weight: 600;
            margin-top: 1em;
            // margin-bottom: 1em;
            width: 100%;

            &:hover {
                background-color: #FFA000; // change hover background color
            }
        }

        .MapIconDeleteButton {
            background-color: #d42115; // change to a more colorful background color
            color: white; // change text color to white
            border: none;
            border-radius: 0.5em;
            padding: 0.5em 1em;
            cursor: pointer;
            font-size: 1em;

            @media screen and (max-width: 1000px) {
                font-size: 0.8em;

            }

            font-weight: 600;
            margin-top: 1em;

            &:hover {
                background-color: #D32F2F; // change hover background color
            }
        }
    }
}

.SnapPositionButton {
    border-radius: 0.5em;
    padding: 0.5em 1em;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    margin-top: 1em;
    margin-bottom: 1em;
    // background-color: black;
    // color: white;
    background-color: #181E4B;
    color: white;

    &:hover {
        // background-color: whitesmoke;
        // color: black;
        background-color: #00458B;


    }
}

.CreateFiberHouse {
    @media screen and (max-width: 1000px) {
        padding: 0em;
    }

    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .TitleDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;
        width: 100%;

        @media only screen and (max-width: 1249px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 1em;
            gap: 1em;
        }

    }

    .CreateSingleFiberHouseForm {
        max-width: 60em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;

        input {
            width: 100%;
            padding: 0.5rem;
            border: 1px solid #E5E5E5;
            border-radius: 4px;
            font-size: 1rem;
            transition: border 0.3s;

            &:focus {
                border: 1px solid #2761D0;
                outline: none;
            }
        }

        .phone-input-container {
            width: 100% !important;
            border: 1px solid #E5E5E5;
            border-radius: 4px;
            transition: border 0.3s;

            &:focus-within {
                border: 1px solid #2761D0;
            }
        }


        .css-13cymwt-control,
        .css-t3ipsp-control,
        .css-1nmdiq5-control {
            width: 30em !important;

            @media screen and (max-width:1000px) {
                width: 100% !important;
            }
        }

        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        @media only screen and (max-width: 1249px) {
            input {
                width: 80%;

                @media screen and (max-width: 1000px) {
                    width: 100%;

                }
            }

            select {
                width: 80% !important;

                @media screen and (max-width: 1000px) {
                    width: 100%;

                }
            }

            .phone-input-container {
                width: 100% !important;

                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }

            .selector {
                width: 80% !important;
                align-content: center;
                justify-content: center;

                @media screen and (max-width: 1000px) {
                    width: 100% !important;

                }
            }

            .css-13cymwt-control,
            .css-1nmdiq5-menu,
            .css-1nmdiq5-control {
                width: 100% !important;
            }


            max-width: 30em;
        }

        .MapIconsDiv {

            // max-width: 20em;
            // @media only screen and (max-width: 1249px) {
            //     max-width: 15em;
            // }
            @media screen and (max-width: 1000px) {
                padding: 0.3rem;
                padding-bottom: 0rem;
                border: none;
                display: flex;
                flex-direction: row;
                // gap: 0.1rem;
                flex-wrap: wrap;
            }

            .MapIconDiv {
                min-width: 12em;

                @media screen and (max-width: 1000px) {
                    min-width: 3em;
                    padding: 0rem;
                }

                border: 1px solid #E5E5E5;
                padding: 1em;

                img {
                    //small round image
                    width: 3em;
                    height: 3em;
                    border-radius: 50%;
                    margin-bottom: 0em;
                }
            }
        }


    }
}

.FiberHousefileDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5em;

    .TitleDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1em;
    }

    .FormDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
        margin-top: 1em;

        .selector {
            width: 80%;

            @media screen and (max-width: 1000px) {
                width: 100%;

            }
        }

        .css-13cymwt-control,
        .css-t3ipsp-control {
            margin-top: 1em;
            width: 100% !important;
            justify-self: center;
        }

        // .css-1nmdiq5-control {}

        // .css-1nmdiq5-menu {}

        label {
            width: 80%;

            @media screen and (max-width: 1000px) {
                width: 100%;

            }
        }

        .MapIconsDiv {
            // max-width: 20em;
            width: 80%;
            margin-bottom: 1em;
            margin-top: 1em;

            @media only screen and (max-width: 1249px) {
                // max-width: 15em;
            }

            @media screen and (max-width: 1000px) {
                width: 100%;

            }

            .MapIconDiv {
                min-width: 12em;

                @media screen and (max-width: 1000px) {
                    min-width: 3em;
                    padding: 0rem;
                }

                border: 1px solid #E5E5E5;
                padding: 1em;

                img {
                    //small round image
                    width: 3em;
                    height: 3em;
                    border-radius: 50%;
                    margin-bottom: 1em;
                }
            }

        }

        button {
            width: 80%;

            @media screen and (max-width: 1000px) {
                width: 100%;

            }

            //on hover
            &:hover {
                background-color: whitesmoke;
                color: black;
            }
        }

        .StatusDiv {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 1em;
            margin-top: 2em;
            width: 100%;
            overflow-x: auto;

            .MapStringObjectDiv {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-bottom: 1em;

                p {
                    font-size: 1em;
                    font-weight: 600;
                    margin-bottom: 0em;

                }

                border-bottom: 1px solid #E5E5E5;
                padding-bottom: 1em;


            }
        }
    }


}

.ServicableAreaDetailsDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 1.5em;

    .tabs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
        margin-bottom: 1rem;

        div {
            padding: 0.5rem 1rem;
            border-radius: 1rem;
            box-shadow: 0px 0px 10px 0px #2B92D5;
            cursor: pointer;
            color: #2B92D5;
            display: flex;
            justify-content: center;
            align-items: center;

            h3 {
                text-align: center;
            }

            &:hover {
                background-color: #2B92D5;
                color: white;
            }

        }

        .active {
            background-color: #2B92D5;
            color: white;
        }
    }

    .FormsDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // align-items: center;
        width: 100%;

        div {
            text-align: center;
        }

    }

    .ButtonDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;


        button {
            margin-top: 1.5em;
            width: 100%;
        }
    }
}

.EditLeadDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 1.5em;

    .LeadInfoDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;

        .LeadInfoTitles {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-right: 1px solid #E5E5E5;
            padding-right: 1em;

            h3 {
                margin-bottom: 0.5em;
                font-size: 1.2em;
                font-weight: bold;
                color: #333;
                text-transform: uppercase;
                // text-decoration: underline;
                letter-spacing: 1px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
            }
        }

        .LeadInfoData {
            display: flex;
            flex-direction: column;
            justify-content: left;
            align-items: left;
            text-align: left;
            width: 100%;

            h3 {
                margin-bottom: 0.5em;
                font-size: 1.2em;
                font-weight: bold;
                color: #333;
                text-transform: uppercase;
                // text-decoration: underline;
                letter-spacing: 1px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                border-bottom: 1px solid #E5E5E5;
                padding-left: 1em;
            }
        }

        .LeadInfoInputs {
            display: flex;
            flex-direction: column;
            justify-content: left;
            align-items: left;
            text-align: left;
            width: 80%;

            div {
                margin-bottom: 0.5em;
            }

        }
    }

    .LeadInfoButtons {
        display: flex;
        align-items: right;
        justify-content: right;
        width: 100%;
        text-align: right;
        padding-left: auto;
    }

    .LeadStatusDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;
        margin-top: 1em;

        .DoorKnockingStatusDiv {
            width: 50%;
            display: flex;
            flex-direction: column;
            padding-right: 1em;

            label {
                margin-bottom: 0.5em;
                border-bottom: 1px solid #E5E5E5;
                padding-bottom: 1em;
                font-size: 1.2em;
                font-weight: bold;
                color: #333;
                text-transform: uppercase;
                // text-decoration: underline;
                letter-spacing: 1px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

            }

            div {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                select {
                    width: 100%;
                    margin-right: 1em;
                }
            }
        }

        .CustomerInterestDiv {
            width: 50%;
            display: flex;
            flex-direction: column;
            padding-left: 1em;

            label {
                margin-bottom: 0.5em;
                border-bottom: 1px solid #E5E5E5;
                padding-bottom: 1em;
                font-size: 1.2em;
                font-weight: bold;
                color: #333;
                text-transform: uppercase;
                // text-decoration: underline;
                letter-spacing: 1px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
            }

            .CustomerInterestParent {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .CustomerInterestPlusButton {
                    //make a cool plus button
                    background-color: #00B87C;
                    color: white;
                    border: none;
                    border-radius: 50%;
                    width: 1.5em;
                    height: 1.5em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    //bigger font size
                    font-size: 1.5em;
                    font-weight: 600;

                    //on hover
                    &:hover {
                        background-color: #00A36F;
                    }
                }

                .CustomerInterestMinusButton {
                    //make a cool minus button
                    background-color: #d42115;
                    color: white;
                    border: none;
                    border-radius: 50%;
                    width: 1.5em;
                    height: 1.5em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    //bigger font size
                    font-size: 1.5em;
                    font-weight: 600;

                    //on hover
                    &:hover {
                        background-color: #D32F2F;
                    }
                }

                .CustomerInterestProgressBar {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: left;
                    justify-content: left;
                    text-align: left;
                    border: 1px solid #E5E5E5;
                    padding: 0.3em;
                    margin-left: 0.5em;
                    margin-right: 0.5em;
                    height: 3.6em;

                    .CustomerInterestSegment {
                        width: 10%;
                        height: 100%;
                        background-color: #00B87C;
                        margin-right: 0.5em;
                    }
                }
            }
        }

    }
}

.EditFiberHouseDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1.5em;

}

.EditLeadParentDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

}

.SearchFiltersDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;

    @media screen and (max-width: 1000px) {
        padding: 0.5em;
    }

    .TitleDiv {
        width: 100%;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;
    }

    .company_selector {
        width: 100%;

        .async_select__control {
            width: 100%;
            border: 1px solid #E5E5E5;
            border-radius: 10px;
        }
    }

    .toggle-switch {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        label {
            // font-size: 1.2rem;
            margin-right: 1rem;
            // font-weight: bold;
        }

        input {
            width: 50px;
            height: 25px;
            -webkit-appearance: none;
            appearance: none;
            background-color: #c6c6c6;
            outline: none;
            border-radius: 50px;
            position: relative;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:checked {
                background-color: #41b6e6;
            }

            &::before {
                content: '';
                position: absolute;
                width: 23px;
                height: 23px;
                background-color: white;
                border-radius: 50%;
                top: 1px;
                left: 1px;
                transition: transform 0.3s ease;
            }

            &:checked::before {
                transform: translateX(25px);
            }
        }
    }

    .InputsDiv {
        width: 100%;
        grid-column-gap: 1em;
        grid-row-gap: 1em;
        margin-bottom: 1em;
        margin-top: 1em;
        //at media less than 1400px
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .InputDiv,
        .selector_con {
            width: 100%;
        }

        .SearchButtonDiv {
            width: 100%;
        }

        margin-bottom: 1em;
        gap: 1em;

        // @media only screen and (max-width: 1249px) {}

        .InputDiv,
        .selector_con {
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            align-items: center;

            label {
                margin-bottom: 0.5em;
            }

            input {
                width: 100%;
                outline: none;
                // border: 1px solid #E5E5E5;
                border: none;
                color: #2761D0;
                border-radius: 10px;
                padding: 0.5rem 1rem;
                align-self: self-end;

            }

            select {
                width: 100%;
                outline: none;
                // border: 1px solid #E5E5E5;
                border: none;
                color: #2761D0;
                border-radius: 10px;
                padding: 0.5rem 1rem;
            }

            // .css-13cymwt-control,
            // .css-t3ipsp-control {}

            // .css-1nmdiq5-control {}
        }

        .date_range {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;

            .quick_date {
                display: grid;
                grid-template-columns: 1fr auto 1fr;

                input {
                    @media screen and (max-width: 1000px) {
                        width: 100px;
                        background-color: rgb(193 193 193);
                    }

                    @media screen and (max-width: 500px) {
                        width: 60px;
                        background-color: rgb(193 193 193);
                    }
                }

            }
        }

        .SearchButtonDiv {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            span {
                width: 100%;
            }

            button {
                width: 100%;
                margin-top: auto;
            }
        }
    }

    .TableDiv {
        width: 100%;
        // max-width: 100em;
        overflow-x: scroll !important;

        table {
            // width: 150em;
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            border: 1px solid #E5E5E5;

            thead {
                background-color: #F2F2F2;

                tr {
                    th {
                        padding: 1em;
                        text-align: center;
                        font-size: 1em;
                        font-weight: 600;
                        color: #333;
                        text-transform: uppercase;
                        // text-decoration: underline;
                        letter-spacing: 1px;
                        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid #E5E5E5;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 1em;
                        text-align: center;
                        font-size: 1em;
                        font-weight: 600;
                        color: #333;
                        text-transform: uppercase;
                        // text-decoration: underline;
                        letter-spacing: 1px;
                        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid #E5E5E5;

                        button {
                            border: none;
                            border-radius: 0.5em;
                            padding: 0.5em 1em;
                            cursor: pointer;
                            font-size: 1em;
                            font-weight: 600;
                            margin-top: 1em;
                            // margin-bottom: 1em;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.StatsDiv {
    .TitleDiv {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;
    }

    @media only screen and (max-width: 1249px) {
        .react-geo-digitize-button-wrapper {
            width: 100% !important;
            justify-content: center;
            align-self: center;
            text-align: center;

            button {
                width: 100% !important;

                &:hover {
                    // background-color: #00A36F;
                    background-color: #078fff;
                }
            }
        }
    }

    .react-geo-digitize-button-wrapper {
        button {
            &:hover {
                // background-color: #00A36F;
                background-color: #078fff;
            }
        }
    }

    .Stats {
        .Stat {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // margin-bottom: 1em;
            margin-top: 1em;

            @media screen and (max-width: 1000px) {
                margin: 0.2em 0em;
                padding: 0em;
            }

            padding: 0.5em;
            // border: 1px solid #E5E5E5;
            width: 100%;

            h3 {
                margin-bottom: 0.5em;
                font-size: 1.2em;

                @media screen and (max-width: 1000px) {
                    font-size: 1em;
                    font-weight: 400;
                }

                font-weight: bold;
                color: #333;
                text-transform: uppercase;
                // text-decoration: underline;
                letter-spacing: 1px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
            }

            p {
                margin-bottom: 0.5em;
                font-size: 1.2em;

                @media screen and (max-width: 1000px) {
                    font-size: 1em;
                    font-weight: 400;

                }

                font-weight: bold;
                color: #333;
                text-transform: uppercase;
                // text-decoration: underline;
                letter-spacing: 1px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
            }
        }

        .assign_leads {
            background-color: #2761D0;
            color: white;
            border: none;
            outline: none;
            border-radius: 10px;

            @media screen and (max-width: 1000px) {
                font-size: 0.7rem;
            }
        }

        .select_all {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: -moz-fit-content;
            width: fit-content;
            gap: 0.5em;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;

            .select {
                // padding: 0.5rem 1rem;
                border-radius: 10px;
                cursor: pointer;
                color: #2B92D5;
                text-decoration: underline;

                // &:hover {
                //     background-color: #2B92D5;
                //     color: white;
                // }
            }

            .deselect {
                text-decoration: underline;
                // padding: 0.5rem 1rem;
                border-radius: 10px;
                cursor: pointer;
                color: #d42115;

                // &:hover {
                //     background-color: #d42115;
                //     color: white;
                // }
            }
        }

        .lead_owners_title {
            font-size: 1.2rem;
            font-weight: medium;
            margin: 0.5rem 0rem;

            @media screen and (max-width: 1000px) {
                // font-size: 1rem;
                margin: 0rem;
            }
        }

        .lead_owners {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // margin-top: 1rem;
            gap: 0.5em;

            .lead_owner {
                // border: 2px solid #00458B;
                color: black;
                // border: none;
                padding: 0.2rem;
                border-radius: 10px;
                border: 2px solid #ccc;
                display: grid;
                grid-template-columns: 1fr;
                min-width: 5rem;
                place-items: center;
                cursor: pointer;

                img {
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                }

                p {
                    // padding: 0.2rem 0.4rem;
                    font-size: 0.8rem;
                    text-align: center;

                    span {
                        background-color: #2761D0;
                        color: white;
                        padding: 0.2rem 0.4rem;
                        margin-right: 0.3rem;
                        border-radius: 10px;

                        .icon {
                            cursor: pointer;

                            &:hover {
                                color: red !important;
                            }
                        }
                    }
                }

                div {
                    // background-color: red;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    /* padding: 0.5rem 1rem; */
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

            }

            .active {
                border: 2px solid #078fff;
                // color: white;
            }
        }

        .stats_review_container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1em;
        }

        .ratio_container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.3em;

            .label {
                font-size: 1.2em;
                font-weight: bold;
                color: #333;
                // text-transform: uppercase;
                // text-decoration: underline;
                letter-spacing: 1px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

                @media screen and (max-width: 600px) {
                    font-size: 0.8em;
                }
            }

            .ratio {
                font-size: 1.5em;
                font-weight: bold;
                color: #333;
                text-transform: uppercase;
                // text-decoration: underline;
                letter-spacing: 1px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

                @media screen and (max-width: 600px) {
                    font-size: 1em;
                }
            }
        }

        .stats_icon_reviews {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1em;

            .icon {
                cursor: pointer;
                border: 2px solid #ccc;
                padding: 0.3rem;
                border-radius: 10px;

                &:hover {
                    background-color: #ccc;
                    transform: scale(1.1);
                }
            }
        }

        .StatusStats {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            grid-column-gap: 1em;
            grid-row-gap: 1em;

            @media only screen and (max-width: 1500px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 1em;
                gap: 1em;
            }

            margin-bottom: 1em;
            margin-top: 1em;
            align-items: center;
            margin-bottom: 1em;
            margin-top: 1em;
            padding: 1.5em;
            border: 1px solid #E5E5E5;
            width: 100%;

            @media screen and (max-width: 1000px) {
                .MapIconStat {
                    flex-direction: column;
                }

            }

            .StatusStat {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-bottom: 1em;
                margin-top: 1em;
                padding: 1.5em;
                border: 1px solid #E5E5E5;
                width: 100%;

                h3 {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    margin-bottom: 0.5em;
                    font-size: 1.2em;
                    font-weight: bold;
                    color: #333;
                    text-transform: uppercase;

                    span {
                        @media screen and (max-width: 1000px) {
                            display: none;
                        }
                    }

                    // text-decoration: underline;
                    letter-spacing: 1px;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

                    @media screen and (max-width: 1000px) {
                        font-size: 1em;
                    }

                    @media screen and (max-width: 400px) {
                        font-size: 0.8em;

                    }
                }

                p {
                    margin-bottom: 0.5em;
                    font-size: 1.2em;

                    @media screen and (max-width: 1000px) {
                        font-size: 1em;
                    }

                    @media screen and (max-width: 400px) {
                        font-size: 0.8em;

                    }

                    font-weight: bold;
                    color: #333;
                    text-transform: uppercase;
                    // text-decoration: underline;
                    letter-spacing: 1px;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                }

                img {
                    width: 3em;
                    height: 3em;
                    margin-bottom: 1em;
                    border-radius: 50%;
                    margin-right: 1.5em;
                }
            }
        }

    }

    .stats_input_container {
        padding: 1rem;

        border: 1px solid #E5E5E5;
        border-radius: 10px;
        margin-top: 1rem;

        .stats_input {
            width: 100%;
            padding: 0.2rem 0.4rem;
            // box-shadow: 0px 0px 10px 0px #2B92D5;
            border: none;
            outline: none;
        }
    }

    .assign_button_container {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: 1rem;
        gap: 1rem;

        .assign_button {
            padding: 0.5rem 1rem;
            background-color: #2B92D5;
            color: white;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            font-size: 1em;
            font-weight: 600;
            margin-top: 1em;
            width: 100%;

            &:hover {
                background-color: #00458B;
            }
        }
    }


}

.css-15v22id-MuiAccordionDetails-root {
    padding: 0px !important;
}

.css-6bxxch-MuiPaper-root-MuiAccordion-root:before {
    height: 0px !important;
}

.switch {
    grid-column: 1 / 2;
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-gap: 3rem;
    justify-self: center;

    input {
        display: none;
    }

    &__1,
    &__2 {
        width: 2rem;

        label {
            display: flex;
            align-items: center;
            width: 100%;
            height: 1rem;
            box-shadow: .3rem .3rem .6rem #c8d0e7,
                -.2rem -.2rem .5rem white;
            background: rgba(255, 255, 255, 0);
            position: relative;
            cursor: pointer;
            border-radius: 1.6rem;

            &::after {
                content: "";
                position: absolute;
                left: .4rem;
                width: 0.9rem;
                height: 0.9rem;
                border-radius: 50%;
                background: #9baacf;
                transition: all .4s ease;
            }

            &::before {
                content: '';
                width: 100%;
                height: 100%;
                border-radius: inherit;
                background: linear-gradient(330deg, #5b0eeb 0%, #6d5dfc 50%, #8abdff 100%);
                opacity: 0;
                transition: all .4s ease;
            }
        }
    }

    & input:checked {
        &~label {
            &::before {
                opacity: 1;
            }

            &::after {
                left: 57%;
                background: #E4EBF5;
            }
        }
    }
}


.MapIconStats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    width: 100%;

    @media screen and (max-width:1000px) {
        gap: 0.2em;
    }

    .MapIconStat {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
        border: 2px solid #E5E5E5;
        padding: 0.5rem;
        border-radius: 50%;
        width: 4.5em;
        align-items: center;

        img {
            width: 2em;
            height: 2em;
            border-radius: 50%;
        }
    }

    .active {
        border: 2px solid #2761D0;

    }
}

.fiberHouse_list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    padding: 1rem 0rem;

    .LeadCard {
        display: grid;
        grid-template-columns: auto 1fr 1fr;
        align-items: center;
        gap: 1rem;
        width: 100%;
        padding: 1em;
        margin: 1em 0;
        // border-radius: 0.5em;
        border-bottom: 1px solid #2761D0;

        @media screen and (max-width: 600px) {
            grid-template-columns: auto 1fr auto;
            // gap: 0.5rem;
            padding: 1em 0em;
        }

        .dbt_1 {
            display: none;

            @media screen and (max-width: 600px) {
                display: flex;

            }
        }

        .dbt_2 {

            // display: flex;
            @media screen and (max-width: 600px) {
                display: none;

            }
        }

        .ImagesDiv {
            position: relative;
            height: fit-content;
            width: -moz-fit-content;
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .Image {
                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;

                    @media screen and (max-width: 1000px) {
                        width: 50px;
                        height: 50px;

                    }

                    border-radius: 50%;
                }

                h2 {
                    border-radius: 50%;
                    background-color: #2761D0;
                    color: white;
                    width: 100px;
                    height: 100px;

                    @media screen and (max-width: 1000px) {
                        width: 50px;
                        height: 50px;

                    }

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 2em;
                }
            }

            .MapIcon {
                // position: relative;
                // bottom: 2.4em;
                // left: 3em;
                position: absolute;
                bottom: 0;
                right: 0;

                img {
                    width: 50px;
                    height: 50px;

                    @media screen and (max-width: 1000px) {
                        width: 25px;
                        height: 25px;

                    }

                    border-radius: 50%;
                }
            }
        }

        .DataDiv {
            display: flex;
            flex-direction: column;

            p {
                @media screen and (max-width: 1000px) {
                    font-size: 0.8em;
                }
            }

            .Name {
                font-size: 1.5em;

                @media screen and (max-width: 1000px) {
                    font-size: 1em;
                }

                font-weight: bold;
            }

            .Phone {
                font-size: 1.2em;

                @media screen and (max-width: 1000px) {
                    font-size: 0.9em;

                }
            }

            .Address {
                font-size: 1.2em;

                @media screen and (max-width: 1000px) {
                    font-size: 0.9em;

                }
            }
        }

        .MainLeadCard_Buttons {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            @media screen and (max-width: 1000px) {
                gap: 0.2rem;
            }

            justify-content: flex-end;

            @media screen and (max-width: 600px) {
                grid-column: span 2 /span 2;
                justify-content: space-between;
            }

            .MainLeadCard_Button {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0.5em;

                @media screen and (max-width: 1000px) {
                    padding: 0.1em;
                }

                cursor: pointer;
                border-radius: 0.5em;
                // background-color: #2761D0;
                color: black;
                width: -moz-fit-content;
                width: fit-content;

                &:hover {
                    transform: scale(1.1);
                }

                img {
                    width: 2rem;

                    @media screen and (max-width: 1000px) {
                        width: 2rem;

                    }
                }

                span {
                    font-size: 0.8em;
                    color: #81848A;

                    @media screen and (max-width: 1000px) {
                        font-size: 0.5em;

                    }
                }
            }
        }

        // .additional_info{
        //     display: flex;
        //     align-items: flex-start;
        //     justify-content: flex-start;
        //     flex-direction: column;
        //     p{
        //         color: #2761D0;
        //         font-weight: bold;
        //     }
        // }
        //you can use this just as a starting point if you like. but i suggest start from scratch
        // .MainLeadCard_Buttons {
        //     display: flex;
        //     justify-content: space-evenly;
        //     margin-top: 1em;


        //     .MainLeadCard_Button {
        //         display: flex;
        //         flex-direction: column;
        //         align-items: center;
        //         justify-content: center;
        //         width: 5em;
        //         padding: 1em;
        //         cursor: pointer;

        //         &:hover {
        //             transform: scale(1.1);
        //         }

        //         img {
        //             width: 3em;
        //             @media screen and (max-width: 1000px){
        //                 width: 2em;

        //             }
        //             @media screen and (max-width: 420px){
        //                 width: 1.5em;

        //             }
        //             // max-height: 2em;

        //         }

        //         span {
        //             color: #5E6282;
        //             margin-top: 0.5em;
        //             cursor: pointer;
        //             @media screen and (max-width: 1000px){
        //                 font-size: 0.6rem;

        //             }
        //             @media screen and (max-width: 420px){
        //                 font-size: 0.5rem;

        //             }
        //             &:hover {
        //                 transform: scale(1.1);
        //             }
        //         }
        //     }
        // }
    }

    .fiberHouse {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 0.5rem;
        border: 1px solid #E5E5E5;
        border-radius: 10px;
        padding: 1rem;

        img {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
        }

        div {
            display: grid;
            grid-template-columns: 1fr;

            button {
                outline: none;
                text-decoration: underline;
                color: #078fff;
                text-align: end;
            }
        }

        hr {
            grid-column: span 2 / span 2;
        }

        p {
            grid-column: span 2 / span 2;
            font-size: 0.8rem;
        }
    }
}

.CreateCustomerDiv {
    width: 80%;

    @media only screen and (max-width: 1249px) {
        width: 100%;
    }

    label {
        font-size: 1em;
        font-weight: 600;
        margin-bottom: 0em;
    }

    //make all input place holders bolder and bigger font size and darker color 
    input::placeholder {
        font-weight: 600;
        font-size: 1em;
        color: #333 !important;
    }

    .DesiredInstallDiv {
        display: flex;
        flex-direction: column;

        // flex-wrap: wrap;
        h3 {
            margin-bottom: 0.5em;
            font-size: 1.2em;
            font-weight: bold;
            color: #333;
            text-transform: uppercase;
            // text-decoration: underline;
            letter-spacing: 1px;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        }

        .OptionsDiv {
            margin-top: 1em;
            // display: flex;
            // flex-direction: row;
            // flex-wrap: wrap;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            gap: 1rem;

            div {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                margin-bottom: 1em;
                // border-bottom: 1px solid #E5E5E5;
                padding-bottom: 1em;

                input {
                    // check box input
                    width: 1.5em;
                    height: 1.5em;
                    margin-right: 0.5em;

                }

                label {
                    // check box label
                    font-size: 1em;
                    font-weight: 600;
                    margin-bottom: 0em;
                }
            }

            .OtherDiv {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                margin-bottom: 1em;
                grid-column: span 3;
                // border-bottom: 1px solid #E5E5E5;
                padding-bottom: 1em;

                input {
                    // check box input
                    width: 100%;
                    height: 3em;
                    margin-right: 0.5em;

                }

                label {
                    // check box label
                    font-size: 1em;
                    font-weight: 600;
                    margin-bottom: 0em;
                    // width:100%;
                }
            }
        }
    }

    .CreateButton {
        // background-color: #00B87C;
        color: white;
        border: none;
        border-radius: 0.5em;
        padding: 0.5em 1em;
        cursor: pointer;
        font-size: 1em;
        font-weight: 600;
        margin-top: 1em;
        margin-bottom: 1em;
        width: 100%;

        &:hover {
            background-color: #00A36F;
        }
    }
}

.ConvertedToCustomerSpan {
    background-color: #00458B;
    color: white;
    border: none;
    border-radius: 0.5em;
    padding: 0.5em 1em;
    font-size: 1em;
    font-weight: 600;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
    text-align: center;
    cursor: not-allowed;
}

.ActivityHistory {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 1.5em;
    border: 1px solid #E5E5E5;
    border-radius: 0.5em;
    width: 100%;

    .TitleDiv {
        display: flex;
        flex-direction: row;
        justify-content: left;
        margin-bottom: 1em;
        width: 100%;

        h2 {
            margin-bottom: 0.5em;
            font-size: 1.2em;
            font-weight: bold;
            color: #333;
            text-transform: uppercase;
            // text-decoration: underline;
            letter-spacing: 1px;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
            cursor: pointer;

            &:hover {
                color: #00458B;
            }
        }
    }

    .ActivityHistoryBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
        margin-top: 1em;
        padding: 1.5em;
        width: 100%;


        .ActivityHistoryCard {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 1em;
            padding: 1em;
            border: 3px solid #E5E5E5;
            border-radius: 0.5em;

            .ActivityHistoryCardHeader {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1em;
                width: 100%;
                border-bottom: 1px solid #E5E5E5;
                padding-bottom: 1em;

                h3 {
                    margin-bottom: 0.5em;
                    font-size: 1.2em;
                    font-weight: bold;
                    color: #333;
                    text-transform: uppercase;
                    // text-decoration: underline;
                    letter-spacing: 1px;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                }

                h4 {
                    //this is the date or timestamp of the activity 
                    margin-bottom: 0.5em;
                    font-size: 1em;
                    font-weight: bold;
                    color: #333;
                    text-transform: uppercase;
                    // text-decoration: underline;
                    letter-spacing: 1px;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

                }
            }

            .ActivityHistoryCardBody {
                display: flex;
                flex-direction: column;

                h3 {
                    margin-bottom: 0.5em;
                    font-size: 1.2em;
                    font-weight: bold;
                    color: #333;
                    text-transform: uppercase;
                    // text-decoration: underline;
                    letter-spacing: 1px;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                }

                p {
                    margin-bottom: 0.5em;
                    font-size: 1em;
                    font-weight: bold;
                    color: #333;
                    text-transform: uppercase;
                    // text-decoration: underline;
                    letter-spacing: 1px;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                }

                .MapIconChangeDiv {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .MapIconChange {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        align-items: center;
                        text-align: center;
                        width: 100%;

                        h3 {
                            margin-bottom: 0.5em;
                            font-size: 1.2em;
                            font-weight: bold;
                            color: #333;
                            text-transform: uppercase;
                            // text-decoration: underline;
                            letter-spacing: 1px;
                            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                        }

                        img {
                            width: 6em;
                            height: 6em;
                            margin-bottom: 1em;
                            border-radius: 50%;
                        }
                    }
                }
            }

            .ActivityHistoryCardFooter {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1em;
                width: 100%;
                border-top: 1px solid #E5E5E5;
                padding-top: 1em;

                h4 {
                    //this is the date or timestamp of the activity 
                    margin-bottom: 0.5em;
                    font-size: 1em;
                    font-weight: bold;
                    color: #333;
                    text-transform: uppercase;
                    // text-decoration: underline;
                    letter-spacing: 1px;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                }

                div {

                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    img {
                        width: 3em;
                        height: 3em;
                        margin-bottom: 1em;
                        border-radius: 50%;
                        margin-right: 1.5em;
                    }

                    h3 {
                        margin-bottom: 0.5em;
                        font-size: 1.2em;
                        font-weight: bold;
                        color: #333;
                        text-transform: uppercase;
                        // text-decoration: underline;
                        letter-spacing: 1px;
                        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                    }
                }

            }
        }

    }
}

.ManageLeadStatuses {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .TitleDiv {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        margin-bottom: 1em;
        width: 100%;

        h2 {
            margin-bottom: 0.5em;
            font-size: 1.6em;
            font-weight: bold;
            color: #333;
            text-transform: uppercase;
            // text-decoration: underline;
            letter-spacing: 1px;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        }
    }

    .CreateLeadStatus {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 1em;
        width: 100%;
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 1em;

        input {
            width: 50%;
            border: 1px solid #2b2a2a;
            padding: 1em;
            border-radius: 0.5em;
            margin-right: 1em;

            &:focus {
                // border: 10px solid #00B87C;
                //make it glow light blue
                box-shadow: 0 0 10px #2cdcf3;
            }
        }

        button {
            background-color: #00B87C;
            color: white;
            border: none;
            border-radius: 0.5em;
            padding: 1em 2em;
            cursor: pointer;
            font-size: 1em;
            font-weight: 600;
            margin-top: 1em;
            margin-bottom: 1em;
            width: 30%;

            &:hover {
                background-color: #00A36F;
            }
        }

    }

    .LeadStatuses {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
        margin-top: 1em;
        padding: 1.5em;
        width: 100%;

        .LeadStatus {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1em;
            width: 100%;
            border-bottom: 1px solid #E5E5E5;
            padding-bottom: 1em;

            .LeadStatusText {
                width: 45%;

                p {
                    margin-bottom: 0.5em;
                    font-size: 1.2em;
                    font-weight: bold;
                    color: #333;
                    text-transform: uppercase;
                    // text-decoration: underline;
                    letter-spacing: 1px;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                }

                input {
                    width: 100%;
                    border: 1px solid #2b2a2a;
                    padding: 1em;
                    border-radius: 0.5em;
                    margin-right: 1em;

                    &:focus {
                        // border: 10px solid #00B87C;
                        //make it glow light blue
                        box-shadow: 0 0 10px #2cdcf3;
                    }
                }
            }

            .LeadStatusButtons {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                width: 45%;

                button {
                    width: 40%;
                }
            }
        }

    }
}

.CreateCustomerBottomDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        gap: 1rem;
    }

    width: 100%;
    margin-top: 1em;

    .Left {
        width: 50%;
        padding-right: 3em;

        @media screen and (max-width: 1000px) {
            width: auto;
            padding-right: 0;
        }

        img {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }

    .Right {
        width: 50%;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1000px) {
            width: auto;
        }

        label {
            font-size: 0.8em;
            font-weight: 600;
        }

        input {
            margin-bottom: auto;
            margin-top: 0.3em;
        }
    }
}

.custom-swal {
    z-index: 9999;
    /* Set your desired z-index value */
}

.CodeInputsHeader {
    font-weight: bold;
}

.CodeInputs {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 1em;

    input {
        width: 3.6em;
        padding: 1em;
        border-radius: 0.5em;
        border: 1px solid #E5E5E5;
        margin-bottom: 1em;
        border: 1px solid #E5E5E5;
        height: 4.5em;
        text-align: center;
    }
}

.MapIconTags {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;

    .MapIconTag {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1em;
        border: 1px solid #E5E5E5;
        border-radius: 0.5em;
        width: 100%;

        p {
            font-size: 1.2em;

            @media screen and (max-width: 1000px) {
                font-size: 1em;
            }

            @media screen and (max-width: 400px) {
                font-size: 0.8em;

            }

            font-weight: bold;
            color: #333;
            text-transform: uppercase;
            // text-decoration: underline;
            letter-spacing: 1px;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        }

        // &:hover{
        //     background-color: #00458B;
        //     border: 1px solid #00458B;
        //     p{
        //         color: white;
        //     }
        // }
        cursor: pointer;
    }


}

.legend_container {
    h1 {
        font-size: 1.5em;
        font-weight: bold;
        color: #333;
        text-transform: uppercase;
        // text-decoration: underline;
        letter-spacing: 1px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }

    .map_icons {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media screen and (max-width: 1000px) {
            grid-template-columns: 1fr;
        }

        margin-top: 1rem;
        gap: 1rem;

        .icon {
            display: flex;
            align-items: center;
            gap: 1rem;

            p {
                font-size: 1.2em;
                color: #333;

                // text-decoration: underline;

            }
        }
    }
}

.ol-zoom {
    @media screen and (max-width: 1000px) {
        bottom: 14% !important;
    }
    bottom: 15% !important;
    left: auto !important;
    right: 1% !important;
    top: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    background-color: transparent !important;
    width: 50px !important;
    button {
        border-radius: 5.491px !important;
        font-size: 1em !important;
    }


}

.ol-rotate {

    width: 50px !important;
    height: 50px !important;
    bottom: 5% !important;
    left: auto !important;
    right: 1% !important;
    top: auto !important;
    background-color: transparent !important;
    button{
        width: 50px !important;
        height: 50px !important;
        background-color: transparent !important;
    }
    @media screen and (max-width: 1000px) {
        // bottom: 1em !important;
        // right: 1em !important;
        // top: auto !important;
        bottom: 5% !important;
    }
}

.overflow_container {
    height: 400px;
    overflow: auto;
    padding: 1rem;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    margin: 1rem 0rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
}

.servicablearea_member_list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 1rem;
    height: fit-content;

    .MemberDiv {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        gap: 1rem;
        align-items: center;
        border: 1px solid #078fff;
        padding: 1rem;
        border-radius: 10px;
        height: fit-content;

        @media screen and (max-width: 1000px) {
            grid-template-columns: 1fr 1fr;
        }

        .MemberName {
            font-size: 1em;
            color: #333;

        }

        .MemberPhone {
            font-size: 1em;
            color: #333;
        }

        .assign_house {

            border-radius: 10px;
            padding: 0.5rem 1rem;
            color: #078fff;
            cursor: pointer;
            border: 1px solid #078fff;

            @media screen and (max-width: 1000px) {
                grid-column: span 2 / span 2;
            }

            text-align: center;

            &:hover {
                box-shadow: 0px 0px 10px 0px #078fff;
                border: none;
            }
        }

    }

    .UserDiv {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        gap: 1rem;
        align-items: center;
        border: 1px solid #E5E5E5;
        padding: 1rem;
        border-radius: 10px;

        .MemberName {
            font-size: 1em;
            color: #333;
        }

        .MemberPhone {
            font-size: 1em;
            color: #333;
        }
    }

}

.FiiberHousesTable {
    @media screen and (max-width:1000px) {
        width: max-content !important;

        tbody {
            width: max-content !important;

            tr {
                width: max-content !important;

                td {
                    width: max-content !important;
                }
            }
        }
    }

}

.lead_type_title {
    font-size: 1.2rem;
    font-weight: medium;
    // margin: 0.5rem 0rem;
    width: 100%;
    text-align: left;

}

.select_deselect {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;

    // gap: 1em;
    .select {
        // padding: 0.5rem 0.5rem;
        // border-radius: 10px;
        cursor: pointer;
        // background-color: #2B92D5;
        color: #868686;
        font-weight: 500;
        text-decoration: underline;
        border-right: 2px solid #868686;
        padding-right: 0.5rem;

        @media screen and (max-width: 1000px) {
            font-size: 0.8rem;
        }
    }

    .deselect {
        // padding: 0.5rem 0.5rem;
        // border-radius: 10px;
        cursor: pointer;
        color: #868686;
        padding-left: 0.5rem;
        // color: white;
        font-weight: 500;
        text-decoration: underline;

        @media screen and (max-width: 1000px) {
            font-size: 0.8rem;
        }
    }
}

.action_title {
    font-size: 1.2rem;
    font-weight: medium;
    // margin: 0.5rem 0rem;
    width: 100%;
    text-align: left;
    margin-top: 1rem;
}

.action_buttons {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    // overflow: auto;
    gap: 0.5em;
    scrollbar-width: none;

    @media screen and (max-width: 1000px) {
        gap: 0.2em;

    }

    .active {
        color: #fff !important;
        background-color: #2761D0;
    }

    .action_button {
        cursor: pointer;
        color: #000;
        border: 1px solid #2761D0;
        border-radius: 10px;
        padding-left: 0.5rem;
        // color: white;
        padding: 0.2rem 0.4rem;
        font-weight: 500;
        // text-decoration: underline;
        // width: 100%;
        font-size: 1rem;
        min-width: 127px;

        @media screen and (max-width: 1000px) {
            font-size: 0.8rem;
        }
    }
}

.action_date {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    @media screen and (max-width: 1000px) {
        display: flex !important;
        gap: 0.5rem !important;
    }

    label,
    select {
        background-color: white;

        @media screen and (max-width: 500px) {
            font-size: 0.8rem
        }
    }

    // .InputDiv{
    //     grid-template-columns: 1fr !important;
    //     border: 1px solid #E5E5E5;
    //     border-radius: 10px;
    //     padding: 0.5rem;
    // }
    .date_range {
        border: 1px solid #E5E5E5;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 0.5rem;
        width: 100%;
        grid-column: span 2;

        @media screen and (max-width: 1000px) {
            grid-column: span 1;
        }

        label {
            @media screen and (max-width: 1000px) {
                font-size: 0.8rem;

            }
        }
    }
}

.quick_date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // width: 100%;
    // border: 1px solid #E5E5E5;
    border-radius: 10px;
    // padding: 0.5rem;
    gap: 0.5rem;

    @media screen and (max-width: 1000px) {
        // flex-wrap:wrap;
        // gap:0rem;
    }

    label {
        // width: 100%;
        white-space: nowrap;

        @media screen and (max-width: 1000px) {
            font-size: 0.8rem;

        }
    }

    p {
        @media screen and (max-width: 500px) {
            font-size: 0.8rem;
        }
    }

    input {
        font-size: 0.8rem;
        color: #2761D0;
        outline: none;

        @media screen and (max-width: 500px) {
            width: 60px;
        }

        // width: 30%;
    }
}

.assign_leads_container {
    display: flex;
    flex-direction: column;
    width: auto;
    min-width: 50%;
    max-height: 80vh;
    overflow: auto;
    height: auto;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;

    @media screen and (max-width: 1000px) {
        width: 90vw;
        height: auto;

    }

    h1 {
        font-size: 1.5rem;
        font-weight: bold;
        color: #333;
        text-transform: uppercase;
        // text-decoration: underline;
        letter-spacing: 1px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
        height: fit-content;
    }

    .assign_leads_content {
        margin-top: 1rem;

        h3 {
            font-size: 1.2rem;
            font-weight: medium;
            margin: 0.5rem 0rem;

            @media screen and (max-width: 1000px) {
                // font-size: 1rem;
                margin: 0rem;
            }
        }

        .sales_orgs {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            // align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;

            .note {
                color: #84A1C8;
                font-size: 0.8rem
            }

            .sales_org {
                // border: 2px solid #00458B;
                color: black;
                // border: none;
                padding: 0.2rem 0.5rem;
                border-radius: 10px;
                border: 2px solid #ccc;
                display: grid;
                grid-template-columns: auto 1fr 1fr;
                // min-width: 5rem;
                height: 5rem;
                justify-content: flex-start;
                align-items: center;
                // cursor: pointer;
                gap: 0.5rem;

                .image {
                    border-radius: 50%;
                    // border: 1px solid #ccc;
                    // padding: 0.5rem;

                    img {
                        width: 3rem;
                        height: 3rem;

                        @media screen and (max-width:1000px) {
                            width: 2rem;
                            height: 2rem;

                        }

                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                p {
                    // padding: 0.2rem 0.4rem;
                    font-size: 1rem;
                    font-weight: bold;
                    display: flex;
                    align-items: center;

                    @media screen and (max-width:1000px) {
                        font-size: 0.8rem;
                    }

                    // text-align: center;

                }

                // for round checkbox
                // .input {
                //     justify-self: flex-end;
                //     border: 1px solid #ccc;
                //     padding: 0.2rem;
                //     border-radius: 50%;

                //     div {
                //         // border: 1px solid #ccc;
                //         width: 1.5rem;
                //         height: 1.5rem;
                //         border-radius: 50%;
                //         @media screen and (max-width:1000px) {
                //             width: 1rem;
                //             height: 1rem;

                //         }
                //     }

                //     .active {
                //         background-color: #2761D0;
                //     }
                // }

                .input {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: end;

                    button {
                        width: -moz-fit-content;
                        width: fit-content;
                        background-color: transparent;
                        padding: 0rem;
                        font-size: 0.8rem;

                        @media screen and (max-width:1000px) {
                            font-size: 0.6rem;
                        }

                        color: black;
                        margin: 0rem;
                        text-decoration: underline;
                        font-size: bold;
                    }

                    .assign {
                        color: #00458B;
                    }

                    .remove {
                        color: #FF0000;
                    }
                }

            }

            .active {
                border: 2px solid #2B92D5;
                // color: white;
            }
        }

        button {
            background-color: #2B92D5;
            color: white;
            border: none;
            border-radius: 10px;
            padding: 0.5rem 1rem;
            cursor: pointer;
            font-size: 1em;
            font-weight: 600;
            margin-top: 1em;
            margin-bottom: 1em;
            width: 100%;

            &:hover {
                background-color: #00458B;
            }
        }
    }
}

.reset_all_filters {
    background-color: #f12222;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    margin-left: auto;

}

.sales_rep_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .remove_rep {
        background-color: #f12222;
        color: white;
        border: none;
        border-radius: 10px;
        padding: 0.2rem 0.5rem;
        font-size: 0.8rem;
        margin-top: 0.3rem;
    }
}

.search_filter_container {
    box-shadow: 0px 0px 10px 0px #b1b1b1;
    padding: 1rem;
    border-radius: 10px;
}



.ol-popup {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 12px;
    left: -50%;
    transform: translate(-50%, 0);
    white-space: nowrap;
}


/* Styling for the popup */
/* Styling for the popup container */
#image-overlay {
    display: none;
    position: absolute;
    width: 120px;
    /* Adjust as needed */
    height: 120px;
    /* Ensure height matches width */
    border-radius: 50%;
    /* Makes the container circular */
    overflow: hidden;
    /* Ensure the image fits inside the circle */
    border: 2px solid white;
    /* Optional: Add border for aesthetics */
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    /* Optional shadow */
    background-color: transparent;
    /* Transparent background */
}

/* Image inside the circular container */
#overlay-image {
    width: 100%;
    /* Full width of the container */
    height: 100%;
    /* Full height of the container */
    object-fit: cover;
    /* Ensures the image covers the circular area */
    border-radius: 50%;
    /* Ensure the image stays circular */
}