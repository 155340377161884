*{
    margin: 0;
    padding: 0;
}
html, body {
 overscroll-behavior: none;
}
.default {
  --color-background: rgb(248, 249, 250);
  --color-foreground: white;
  --color-dashboard-card: #fff;
  --color-primary:#181E4B;
  --color-icon:#181E4B;
  --color-sidebar:rgb(248, 249, 250);
  --color-icon-hover:#181E4B;
}

.MuiModal-root .MuiBox-root {
  width: min(500px,90%) !important;
  padding: 1rem !important;
}
.css-1hoorc-MuiFormGroup-root{
  flex-direction: row !important;

}


.dark{
  --color-background: transparent;
  --color-foreground: #fff;
  --color-dashboard-card:#040F3C;
  background-image: linear-gradient(-76.7deg, #022780ff 0%, #000614ff 100%);
  --color-primary: #2761D0;
  --color-icon: #fff;
  --color-sidebar: #040F3C;
  --color-icon-hover: #2761D0;
}

/* @media (prefers-color-scheme: dark) {
  body {
  --color-background: #1f2023;
  --color-foreground: #efefef;
  }
} */

html{
  height: -webkit-fill-available;
}

body{
    font-family: 'Poppins', sans-serif;
}

body{
  background-color: var(--color-background);
}

/* Side bar background color on Mobile */
.css-dip3t8{
  background-color: var(--bg-light) !important;
  border-top-right-radius: 25px !important;
  
}


.link-text{
  color: var(--color-text) !important;
}

.list-button:hover .link-text{
  color: inherit !important;
}




.App {
  overflow: hidden;
  color: var(--black);
  background: #f3f3f3;
  padding: 1rem 1rem;
}

/* blur */
.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #a6ddf0;
  filter: blur(72px);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: var(--buttonBg);
  transition: all 100ms ease-out;
  padding: 1rem;
}

.button:hover {
  color: var(--orange);
  border: 2px solid var(--orange);
  cursor: pointer;
  background: transparent;
}

.button:disabled{
  background: silver;
  pointer-events: none;
}


/* ::-webkit-scrollbar {
  display: none;
} */



/* Form Styling */
.infoForm{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.infoInput{
  border: none;
  outline: none;
  background-color: var(--inputColor);
  border-radius: 8px;
  padding: 20px;
  flex:1;
}

.infoForm>div{
  display: flex;
  gap: 1rem;
  height: 2rem;
  width: 90%;
}

.infoButton{
  width: 6rem;
  height: 2rem;
  align-self: flex-end;
}

 
iframe img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 10px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.audio-recorder{
  background-color: transparent !important;
  box-shadow: none !important;
}
input[type='radio'] {
  accent-color: blue;
}

.MuiSwipeableDrawer-paperAnchorBottom	{
  bottom: 10% !important;
}
.css-1k87yq5-MuiPaper-root-MuiDrawer-paper{
  width: auto !important;
}

.css-188by2p-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root .MuiMenuItem-root:active{
  background-color:  white !important;
}