.fiberFeedBackContainer{
    width: 100%;
    height: 100%;
    .fiberFeedBackHeading{
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .fiberFeedbackBody{
        .bodyHeader{
            width:-moz-fit-content;
            width: fit-content;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            h3{
                font-size: 1.5rem;
                font-weight: 600;
                text-transform: uppercase;
            }
            .viewAll{
                color: #2761D0;
                text-decoration: underline;
            }

        }

        .body{
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            @media screen and (max-width: 860px){
                grid-template-columns: 1fr;
            }
            gap: 2rem;
            .card{
                display: grid;
                grid-template-columns: 1fr;
                gap: 1rem;
                .cardHeader{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1rem;
                    img{
                        width: 70px;
                        height: 70px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                    h4{
                        font-size: 1.5rem;
                        font-weight: 600;
                        text-transform: uppercase;
                    }

                    p{
                        font-size: 0.8rem;
                        font-weight: 600;
                        text-transform: uppercase;
                        color: #6c757d;
                    }
                }
            }
        }
    }
}