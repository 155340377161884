.issue-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 2rem auto;

}

.issue-details-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
}

.issue-details-content {
    width: 100%;
    margin-bottom: 1rem;
}

.issue-details-content p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.issue-details-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.reschedule_form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 2rem auto;
}

.title {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
}

.inputs {
    width: 100%;
    .am_pm
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin-top: 1rem;
        gap: 0.5rem;
        p{
            text-transform: uppercase;
            font-weight: 500;
            width: 100%;
            text-align: center;
            color: #84A1C8;
            padding: 0.5rem;
            border: 3px solid #ccc;
            border-radius: 5px;
            cursor: pointer;
            @media screen and (max-width: 500px){
                // font-size: 0.8rem;
                
            }
        }
        .active{
            color: #2761D0;
            border: 3px solid #2761D0;
            border-radius: 5px;
        }
    }
}

.inputLabel {
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 500;
    text-align: start;
    display: block;
}

.datePicker {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
}

.submitBtn {
    margin-top: 1rem;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 5px;
    justify-content: center;
    color: white;
    cursor: pointer;
}
