.manageOrganizations {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    .filters {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        border-radius: 10px;
        border: 1px solid #E5E5E5;
        background: var(--color-foreground);
        padding: 2rem;

        h1 {
            font-size: 20px;
            font-weight: 600;
            color: var(--color-primary);
        }

        .inputs {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            @media screen and (max-width: 910px) {
                grid-template-columns: 1fr;
            }

            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 1rem;

            input {
                width: 100%;
                padding: 10px 20px;
                border-radius: 5px;
                border: 1px solid #00000040;
                outline: none;
                font-size: 14px;
                font-weight: 600;
                color: var(--color-primary);

                &::placeholder {
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--color-primary);
                }
            }
            .selector{
                width: 100%;
            }
            button {
                background-color: var(--color-foreground);
                border: none;
                outline: none;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
                color: var(--color-primary);
                padding: 10px 20px;
                border-radius: 5px;
                box-shadow: 0px 0px 5px 0px var(--color-primary);
                transition: all 0.3s ease-in-out;
                width: 100%;
                &:hover {
                    background-color: var(--color-primary);
                    color: var(--color-foreground);
                }
            }
        }

       
    }
    .orgGraphs{
        display: flex;
        overflow: auto;
        background-color: var(--color-foreground);
        width: 100%;
        border-radius: 10px;
    }

    .spinner{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}