.shopItemDetailsContainer {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    justify-content: center;
    align-items: center;
    margin: auto;
    border: 1px solid #E5E5E5;
    background-color: var(--color-foreground);
    padding: 2rem;
    border-radius: 1.5rem;
    width: 40%;
    @media screen and (max-width: 1120px) {
        width: 80%;
    }

    @media screen and (max-width: 640px) {
        width: 100%;  
    }

    h3 {
        font-size: 2.25rem;
        margin-bottom: 1.25rem;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
        text-transform: uppercase;
    }

    .inputs{
        display: grid; 
        grid-template-columns: 1fr; 
        gap: 1rem; 
        margin: auto;
        width: 100%;

        input{
            width: 100%;

            height: 3rem;
            border: 1px solid #E5E5E5;
            border-radius: 0.5rem;
            padding: 1rem;
            font-size: 1rem;
            font-weight: 400;
            color: #181E4B;
            outline: none;
            transition: all 0.2s ease-in-out;
            &:focus{
                border: 1px solid #71839B;
            }
        }

        button{
            width: 100%;
            height: 3rem;
            border: 1px solid #E5E5E5;
            border-radius: 0.5rem;
            padding: 1rem;
            font-size: 1rem;
            font-weight: 400;
            color: var(--color-primary);
            outline: none;
            transition: all 0.2s ease-in-out;
            &:hover{
                background-color: var(--color-primary);
                color: var(--color-foreground);
            }
        }
    }
}