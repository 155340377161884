.new_offer_page {
    background: linear-gradient(to right, #022780 0%, #000614 100%);
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    color: white;
    .video{
        width: min(80vw, 500px) !important;
    }
    .inner_container {
        max-width: 600px;
        border: 2px solid white;

        @media screen and (max-width: 615px) {
            border: none;

        }

        border-radius: 20px;

        .banner {
            .banner_image {
                width: 100%;
                height: 400px;
                object-fit: cover;
                // border-radius: 20px;

            }

            .schedule_button {
                background-color: #2761D0;
                color: white;
                padding: 1rem 4rem;
                border-radius: 20px;
                font-weight: 700;
                font-size: 1.5rem;
                text-transform: uppercase;
                margin: auto;
                margin-top: -50%;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }

            .customer_service {
                color: #170F49;
                text-align: center;
                font-size: 2rem;
                font-weight: 700;
                text-transform: uppercase;
                margin: 2rem 0rem;
            }

            .customer_service_p {
                color: #170F49;
                text-align: center;
                font-size: 1.2rem;
                font-weight: 700;
                text-transform: uppercase;
                margin: 2rem 0rem;
            }

            .quick_h1 {
                color: #170F49;
                font-size: 3rem;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                margin: 2rem 0rem;
            }
            .quick_h3{
                color: #170F49;
                font-size: 2rem;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
            }
            
            .quick_p{
                color: #170F49;
                font-size: 1.5rem;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
            }
            .quick_copy{
                color: #170F49;
                font-size: 1.1rem;
                font-weight: 500;
                text-align: center;
            }
            .first {
                border-radius: 20px;

                @media screen and (max-width: 615px) {
                    border-radius: 0px;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }

            .top_accept_offer {
                padding: 2rem;

                .inner_div {
                    color: black;
                    background-color: white;
                    padding: 2rem;
                    border-radius: 10px;
                    margin-top: -20%;

                    @media screen and (max-width: 500px) {
                        margin-top: -40%;
                    }

                    z-index: 10;
                    position: relative;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 1rem;

                    .backpacks_ready {
                        text-align: center;
                        color: #1E376D;
                        font-size: 1.8rem;
                        // @media screen and (max-width: 500px){
                        //     font-size: 1.5rem;
                        // }
                        text-transform: uppercase;
                        font-weight: 600;

                        span {
                            color: #01AB52;
                        }
                    }

                    .accept_offer_button {
                        background-color: #2761D0;
                        color: white;
                        padding: 1rem 2rem;
                        border-radius: 10px;
                        font-size: 1.3rem;
                    }
                }
            }
        }

        .company_details {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 1rem;

            .company_name {
                font-weight: 700;
                text-transform: uppercase;
            }

            .limited_offer {
                font-size: 2rem;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
            }

            .promo_offer_border{
                border-radius: 20px;
                padding: 0rem 2rem;
                // margin: 1rem;
                text-align: center;
                width: 100%;
                // margin: 0rem 2rem;
                .promo_offer{
                    width: 100%;
                    border: 4px solid  #4DD4FF;
                    border-radius:   20px;
                    padding: 1rem;
                    font-size: 2.5rem;
                    span{
                        font-size: 1.5rem;
                    }
                }
            }

            .available {
                text-transform: uppercase;
            }
        }

        .header {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            justify-content: space-between;

            .image {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                overflow: hidden;

                @media screen and (max-width: 410px) {
                    height: 150px;
                    width: auto;
                    object-fit: cover;

                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .info {

                grid-column: span 2/span 2;

                .rating-section {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    // Align rating and average rating on the same row
                    .rating-row {
                        display: flex;
                        align-items: center;

                        .avg-rating {
                            margin-left: 10px;
                            font-size: 1.0rem;
                            font-weight: bold;
                            color: #2761D0;
                        }
                    }

                    // Styling for reviews count under the rating
                    .reviews-count {
                        font-size: 1rem;
                        color: #2761D0;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }

                h3 {
                    font-size: 2rem;

                    @media screen and (max-width: 1400px) {
                        font-size: 2rem;

                    }

                    @media screen and (max-width: 1000px) {
                        font-size: 2rem;

                    }

                    @media screen and (max-width: 882px) {
                        font-size: 1.5rem;
                    }

                    @media screen and (max-width: 410px) {
                        font-size: 1.2rem;
                    }

                    text-transform: uppercase;

                }

                p {
                    font-size: 3rem;

                    @media screen and (max-width: 1400px) {
                        font-size: 2rem;

                    }

                    @media screen and (max-width: 1000px) {
                        font-size: 1.5rem;

                    }

                    @media screen and (max-width: 882px) {
                        font-size: 1.25rem;

                    }

                    @media screen and (max-width: 410px) {
                        font-size: 1rem;

                    }

                    color: #9E9EA2;

                }

                .logo {
                    height: 50px;
                    width: -moz-fit-content;
                    width: fit-content;

                    @media screen and (max-width: 410px) {
                        height: 30px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;

                    }
                }

                .rating {
                    display: flex;

                    p {



                        font-size: 1rem;

                        @media screen and (max-width: 882px) {
                            font-size: 0.8rem;

                        }

                        color: #2761D0;
                        text-decoration: underline;
                    }
                }

            }

        }

        .user-contact {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
            margin-top: 1rem;
            place-items: baseline;
            grid-auto-rows: max-content;

            .contact-item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 1rem;

                p {
                 

                    font-size: 1.2rem;
                    color: #9e9ea2;
          
                    @media screen and (max-width: 882px) {
                      font-size: 1rem;
                    }
          
                    @media screen and (max-width: 760px) {
                      font-size: 0.8rem;
                    }
                }


            }
        }

        .payitForwardContainer {
            display: grid;
            place-items: center;

            @media screen and (max-width: 410px) {
                grid-template-columns: 1fr;
                place-items: center;
            }

            gap: 1rem;
            margin-top: 1rem;

            div {
                display: flex;
                align-items: center;
                gap: 1rem;
                flex-direction: column;
                border: 1px solid #9E9EA2;
                padding: 1rem;
                border-radius: 10px;
                width: -moz-fit-content;
                width: fit-content;
                height: 100%;
                justify-content: space-between;

                @media screen and (max-width: 882px) {
                    padding: 1.5rem;

                }

                h3 {
                    font-size: 1.5rem;
                    font-weight: 700;

                    @media screen and (max-width: 882px) {
                        font-size: 1.25rem;

                    }

                    @media screen and (max-width: 560px) {
                        font-size: 0.8rem;
                    }

                    text-transform: uppercase;
                    text-align: center;
                }

                --qr-code-size: 180px;

                .image {
                    border: none;
                    width: 320px;
                    height: 320px;

                    @media screen and (max-width: 1400px) {
                        width: 270px;
                        height: 270px;

                    }

                    @media screen and (max-width: 1000px) {
                        width: 200px;
                        height: 200px;

                    }

                    @media screen and (max-width: 490px) {
                        width: 130px;
                        height: 130px;

                    }

                    padding: 0;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                p {
                    color: #2761D0;
                    text-decoration: underline;
                }
            }
        }

        .offerAndQr {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @media screen and (max-width: 630px) {
                justify-content: center;

            }

            align-items: center;

            .offer_con {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1rem;
            }
        }

        .mobile_biz_card_review {
            width: min(80vw, 500px);
            margin: auto;
        }

        .main_review {
            border: none;
        }

        .contact_buttons {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            width: 100%;
            place-items: center;

            .contact_button {
                // width: 50px;
                // height: 50px;
                margin-top: 1rem;

                // display: grid;
                // grid-template-columns: 1fr;
                // place-items: center;
                .icon {
                    font-size: 4rem;

                    @media screen and (max-width: 1430px) {
                        font-size: 3rem;
                    }

                    @media screen and (max-width: 1000px) {
                        font-size: 2rem;
                    }

                    // @media screen and (max-width: 882px) {
                    //     font-size: 1.5rem;

                    // }
                }

                p {
                    // display: none;
                    text-align: center;
                    font-size: 1.2rem;

                    @media screen and (max-width: 1430px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 1000px) {
                        font-size: 0.8rem;

                    }

                    margin: auto;
                    color: #9E9EA2;
                }

                // @media screen and (max-width: 500px) {
                //     width: 40px;
                //     height: 40px;
                // }
                // @media screen and (max-width: 410px) {
                //     width: 30px;
                //     height: 30px;

                // }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                &:hover {
                    transform: scale(1.1);
                    cursor: pointer;
                }
            }
        }

        .edit_card_details_button {
            background-color: #2761D0;
            color: white;
            padding: 0.5rem 1rem;
            border-radius: 10px;
            font-size: 1.5rem;
            text-transform: uppercase;
            margin: auto;
            margin-top: 1rem;
            cursor: pointer;

            &:hover {
                background-color: #1A3D8D;
            }
        }
    }

    .here_there {
        margin-top: 2rem;

        h1 {
            color: white;
            font-size: 1.8rem;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
        }
    }
}