.editFiberPlanContainer {
    width: 100%;

    .form {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        padding: 4rem;
        border-radius: 50px;
        border: 1px solid #E5E5E5;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        background-color: var(--color-foreground);

        .formHeading {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
            text-align: center;
        }

        input {
            width: 100%;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 0 10px;
            font-size: 16px;

            &:focus {
                outline: none;
            }
        }

        button {
            width: 100%;
            height: 40px;
            border: none;
            border-radius: 5px;
            background-color: var(--color-primary);
            color: var(--color-foreground);
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }
    }
}