.issue-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 2rem auto;
}

.issue-details-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
}

.issue-details-content {
    width: 100%;
    margin-bottom: 1rem;
}

.issue-details-content p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.issue-details-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.confirm-btn, .resolve-btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    background-color: #2761D0;
    transition: background-color 0.3s;
}

.confirm-btn:hover, .resolve-btn:hover {
    background-color: #1c4ba6;
}


.cancel_form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 2rem auto;
    .title {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: center;
    }
    input[type="text"] {
        flex: 1;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    input[type="checkbox"] {
        margin-right: 0.5rem; /* Adjust the margin as needed */
        width: 1.0rem; /* Set a fixed width for checkboxes */
        height: 1.0rem;
    } 
}




.subtitle {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;

}


.inputs {
    width: 100%;
}

.inputDiv,
.subInput,
.subSubInput {
    margin-bottom: 1rem;
}

.inputWrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
}


.preferBtn {
    background-color: #2761D0;
    color: white;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
}

  

.submitBtn {
    margin-top: 1rem;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
