.customer_card{
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    border: 4px solid #4FAFCB;
    border-radius: 20px;
    padding: 1rem;
    @media screen and (max-width: 410px){
        padding: 0.3rem;
        
    }
    .card_top{
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0.5rem;
        .profile_image{
            border: 1px solid #CACACA;
            border-radius: 20px;
            padding: 0.2rem;
            height: 150px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 20px;
            }
        }
        .profile_info{
            display: flex;
            flex-direction: column;
            justify-content: start;
            gap: 0.5rem;
            padding: 0.2rem;
            h2{
                text-align: left;
                font-size: 1.1rem;
            }
            p{
                color: #9E9EA2;
            }
        }
    }

    .logo{
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .company_plan{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
        .company,.plan{
            border: 1px solid #C4C4C4;
            border-radius: 10px;
            padding: 0.5rem;
            h3{
                margin-bottom: 0.5rem;
                color: #000;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 1.1rem;
                text-align: center;
            }


        }
        .company_logo{
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;
            height: 100px;
            place-items: center;
            img{
                width: 100%;
                height: 100px;
                object-fit: contain;
            }
        }
        .plan_name{
            color: white;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            height: 100px;
            p{
                padding: 1rem;
                background-color: #2761D0;
                font-size: 1.2rem;
                font-weight: bold;
                text-transform: uppercase;
                border-radius: 10px;
            }
        }
    }

    .coming_soon{
        border: 10px solid #B5C8D7;
        border-radius: 10px;
        padding: 0.5rem;
        font-weight: bold;
        color: #1E293B;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        p{
        }
    }

    .my_specialist{
        border: 1px solid #C4C4C4;
        border-radius: 10px;
        padding: 1rem;
        h3{
            color: #000;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 1.2rem;
        }
        .specialist_info{
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 0.5rem;
            margin-top: 0.5rem;
            .profile_image{
                width: 70px;
                height: 70px;
                border-radius: 50%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
            .specialist_name{
                h3{
                    color: #000;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.1rem;
                }
                p{
                    color: rgba(0,0,0,0.4);
                }
            }
        }
    }

}