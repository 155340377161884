// MiniSalesRepCard.scss
.tracking-indicator {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 16px;
    height: 16px;
    background: #4CAF50;
    border-radius: 50%;
    animation: pulse 1.5s infinite;
  }

  @keyframes pulse {
    0% { box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4); }
    70% { box-shadow: 0 0 0 10px rgba(76, 175, 80, 0); }
    100% { box-shadow: 0 0 0 0 rgba(76, 175, 80, 0); }
  }