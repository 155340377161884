.digitalBusinessCardPage {
  display: grid;

  background-color: white;
  // @media screen and (max-width: 1210px){
  border: 4px solid #4fafcb;
  // }

  width: min(90%, 900px);
  border-radius: 20px;
  margin: auto;
  padding: 2rem;
  @media screen and (max-width: 410px) {
    padding: 1rem;
  }

  .main_review {
    border: none;
  }
  .rating-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    // Align rating and average rating on the same row
    .rating-row {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .avg-rating {
        margin-left: 10px;
        font-size: 1rem;
        font-weight: bold;
        color: #2761d0;
      }
    }

    // Styling for reviews count under the rating
    .reviews-count {
      font-size: 1rem;
      color: #2761d0;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;
    justify-content: space-between;
    .image {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      @media screen and (max-width: 410px) {
        height: 150px;
        width: auto;
        object-fit: cover;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user-contact {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
      margin-top: 1rem;
      place-items: baseline;
      grid-auto-rows: max-content;

      .contact-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;

        p {
          font-size: 1.2rem;
          color: #9e9ea2;

          @media screen and (max-width: 882px) {
            font-size: 1rem;
          }

          @media screen and (max-width: 760px) {
            font-size: 0.8rem;
          }
        }
      }
    }
    .info {
      grid-column: span 2 / span 2;

      h3 {
        font-size: 4rem;

        @media screen and (max-width: 1400px) {
          font-size: 2rem;
        }

        @media screen and (max-width: 1000px) {
          font-size: 1.8rem;
        }

        @media screen and (max-width: 882px) {
          font-size: 1.2rem;
        }
        @media screen and (max-width: 410px) {
          font-size: 1.2rem;
        }
        text-transform: uppercase;
      }

      p {
        font-size: 2rem;

        @media screen and (max-width: 1400px) {
          font-size: 1.8rem;
        }

        @media screen and (max-width: 1000px) {
          font-size: 1.3rem;
        }

        @media screen and (max-width: 882px) {
          font-size: 1.05rem;
        }
        @media screen and (max-width: 410px) {
          font-size: 0.8rem;
        }
        // color: #9E9EA2;
      }

      .logo {
        height: 50px;
        width: -moz-fit-content;
        width: fit-content;
        @media screen and (max-width: 410px) {
          height: 30px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .rating {
        display: flex;

        p {
          font-size: 1rem;

          @media screen and (max-width: 882px) {
            font-size: 0.8rem;
          }

          color: #2761d0;
          text-decoration: underline;
        }
      }
    }
  }

  .company-logo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 1rem;
    gap: 1rem;

    div {
      height: 40px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .video {
    width: 100%;
    height: 400px;
    @media screen and (max-width: 1000px) {
      height: 300px;
    }

    @media screen and (max-width: 560px) {
      height: 200px;
    }

    border-radius: 10px;
    overflow: hidden;
    margin-top: 1rem;
    // background-color: #9E9EA2;

    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .offerAndQr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 630px) {
      justify-content: center;
    }
    align-items: center;
    .offer_con {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }
  .banner_top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media screen and (max-width: 1000px) {
      gap: 0.5rem;
      margin-top: 0rem;
    }

    z-index: 1;
    position: relative;

    margin-top: 2rem;
    place-items: center;

    .package_light {
      position: absolute;
      left: 0%;
      top: -50%;
      // translate: transform(-50%, 0%);
    }

    .banner_card {
      background-color: #fff;
      padding: 1rem;
      display: block;
      border-radius: 30px;
      width: 160px !important;
      border: 4px solid var(--border-color);

      @media screen and (max-width: 1000px) {
        width: 100%;
      }

      position: relative;
      border: 4px solid #d9d9d9;
      cursor: pointer;

      p {
        font-size: 1rem !important;

        @media screen and (max-width: 1000px) {
          font-size: 0.8rem !important;
        }

        // @media screen and (max-width: 430px) {
        //     font-size: 0.8rem;
        // }

        font-weight: 600;
        color: #6f6c90;
        text-align: left;
      }

      h2 {
        font-size: 1.2rem;

        @media screen and (max-width: 1000px) {
          font-size: 1rem;
        }

        @media screen and (max-width: 430px) {
          font-size: 1rem;
        }

        font-weight: 600;
        color: #000;
        text-align: left;
      }

      h4 {
        font-size: 1.2rem;

        @media screen and (max-width: 700px) {
          font-size: 1rem;
        }

        @media screen and (max-width: 430px) {
          font-size: 1rem;
        }

        font-weight: 600;
        color: #000;
        text-align: left;
      }
    }

    .active {
      border: 4px solid #2761d0;
    }
  }
  .payitForwardContainer {
    display: grid;
    place-items: center;

    @media screen and (max-width: 410px) {
      grid-template-columns: 1fr;
      place-items: center;
    }

    gap: 1rem;
    margin-top: 1rem;

    div {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-direction: column;
      border: 1px solid #9e9ea2;
      padding: 1rem;
      border-radius: 10px;
      width: -moz-fit-content;
      width: fit-content;
      height: 100%;
      justify-content: space-between;

      @media screen and (max-width: 882px) {
        padding: 1.5rem;
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 700;

        @media screen and (max-width: 882px) {
          font-size: 1.25rem;
        }

        @media screen and (max-width: 560px) {
          font-size: 0.8rem;
        }

        text-transform: uppercase;
        text-align: center;
      }

      --qr-code-size: 180px;

      .image {
        border: none;
        width: 320px;
        height: 320px;

        @media screen and (max-width: 1400px) {
          width: 270px;
          height: 270px;
        }

        @media screen and (max-width: 1000px) {
          width: 200px;
          height: 200px;
        }

        @media screen and (max-width: 490px) {
          width: 130px;
          height: 130px;
        }

        padding: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      p {
        color: #2761d0;
        text-decoration: underline;
      }
    }
  }

  .mobile_biz_card_review {
    width: min(80vw, 700px);
    margin: auto;
  }

  // OLD CSS

  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: start;
  // width: 100%;
  @media screen and (max-width: 1210px) {
    width: auto;
    margin: 1rem;
  }
  @media screen and (max-width: 560px) {
    margin: 0rem;
  }
  gap: 0.5rem;
  // padding: 0.5rem;
  font-weight: 500;
  // @media screen and (max-width: 1430px) {
  grid-template-columns: 1fr;

  // }

  .cardContainer {
    grid-column: span 4 / span 4;

    @media screen and (max-width: 1430px) {
      grid-column: span 1 / span 1;
    }

    .cardHeading {
      font-size: 1.875rem;
      text-transform: uppercase;
      color: var(--color-icon);
      text-align: center;
    }
  }

  .statsContainer {
    h1 {
      font-size: 1.5rem;
      text-transform: uppercase;
      text-align: center;
      color: var(--color-icon);
    }

    .p1 {
      display: grid;
      grid-template-columns: 1fr;
      background-color: white;
      padding: 1rem;
      border-radius: 10px;

      @media screen and (max-width: 1430px) {
        grid-template-columns: 1fr 1fr;
      }

      justify-content: center;
      align-items: center;
      gap: 1rem;

      .block {
        border-radius: 1rem;
        padding-left: 1rem;
        padding-top: 1rem;
        border-width: 4px;
        padding-bottom: 1rem;
        border-style: solid;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p:nth-child(1) {
          color: #9e9ea2;
        }

        p:nth-child(2) {
          font-weight: 700;
          font-size: 1.25rem;
        }
      }

      .share {
        display: grid;
        justify-content: center;
        align-items: center;

        p {
          text-transform: uppercase;
          font-weight: 700;
        }

        div {
          display: flex;
          gap: 1rem;

          .repCardIcon {
            cursor: pointer;
          }
        }
      }
    }
  }

  .contact_buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    place-items: center;

    .contact_button {
      // width: 50px;
      // height: 50px;
      margin-top: 1rem;

      // display: grid;
      // grid-template-columns: 1fr;
      // place-items: center;
      .icon {
        font-size: 4rem;

        @media screen and (max-width: 1430px) {
          font-size: 3rem;
        }

        @media screen and (max-width: 1000px) {
          font-size: 2rem;
        }

        // @media screen and (max-width: 882px) {
        //     font-size: 1.5rem;

        // }
      }

      p {
        // display: none;
        text-align: center;
        font-size: 1.2rem;

        @media screen and (max-width: 1430px) {
          font-size: 1rem;
        }

        @media screen and (max-width: 1000px) {
          font-size: 0.8rem;
        }

        margin: auto;
        color: #9e9ea2;
      }

      // @media screen and (max-width: 500px) {
      //     width: 40px;
      //     height: 40px;
      // }
      // @media screen and (max-width: 410px) {
      //     width: 30px;
      //     height: 30px;

      // }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
  }

  .edit_card_details_button {
    background-color: #2761d0;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-top: 1rem;
    cursor: pointer;

    &:hover {
      background-color: #1a3d8d;
    }
  }
}

.infoAndQrContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  .user-contact {
    width: auto;
    margin: 1rem 0rem;
  }
  .payitForwardContainer {
    align-self: center;
    margin: auto;
  }
}
