.disabled_container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    width: 100vw;
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
        padding: 1rem;
        border-radius: 10px;
        h1{
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        p{
            font-size: 1rem;
            margin-bottom: 1rem;
        }
        button{
            padding: 0.5rem 1rem;
            border: none;
            background-color: #007bff;
            color: white;
            border-radius: 5px;
            cursor: pointer;
            transition: 0.3s;
            &:hover{
                background-color: #0056b3;
            }
        }
    }
}