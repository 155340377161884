.light-theme{
    --primary-background: #fff;
    --secondary-background: linear-gradient(-90deg, #000614 0%, #031E60 100%);
    --review-background: #fff;
    --primary-text: #000;
    --border-color: #2761D0;
    --check-color: #2761D0;
    --primary-color: #2761D0;
}

.dark-theme{
    --primary-background: linear-gradient(-90deg, #000614 0%, #031E60 100%);
    --secondary-background: #fff;
    --primary-text: #fff;
    --review-background: #fff;
    --border-color: #fff;
    --check-color: #fff;
    --primary-color: #fff;
}

body{
    background-color: var(--primary-background);
}
.publicLayoutContainer{
    background-image:var(--primary-background);
    width: 100%;
    height: 100%;
    position: relative;

    .floating_icon{
        position: fixed;
        bottom: 10%;
        right: 5%;
        padding: 1rem;
        background-color: #2761D0;
        border-radius: 50%;
        z-index: 1000;
        &:hover{
            cursor: pointer;
        }
    }
}