.my-referrals-container {
    padding: 1rem;
    border: 4px solid #4FAFCB;
    border-radius: 10px;
    background-color: white;
    height: 70vh;
    h1 {
        font-size: 2rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
    }

    .my-referrals {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 2rem;
        width: 100%;
        overflow: auto;
        padding: 1rem;
        @media screen and (max-width: 445px) {
            // gap: 0.5rem;

        }

      

        .referral-card {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: white;
            box-shadow: 0px 0px 14px 0px #2B92D5;
            padding: 1rem;
            border-radius: 10px;
            @media screen and (max-width: 445px) {
                flex-direction: column;
                align-items: baseline;
                justify-content: flex-start;
                gap: 0.3rem;
                height: fit-content;
            }

            .header {
                display: flex;
                align-items: center;
                gap: 1rem;

                .image {
                    width: 70px;
                    height: 70px;

                    @media screen and (max-width: 890px) {
                        width: 70px;
                        height: 70px;
                    }

                    @media screen and (max-width: 600px) {
                        width: 50px;
                        height: 50px;
                    }

                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .info {
                    h1 {
                        font-size: 1.5rem;

                        @media screen and (max-width: 890px) {
                            font-size: 1.2rem;
                        }

                        @media screen and (max-width: 600px) {
                            font-size: 1rem;
                        }

                        text-transform: uppercase;
                        text-align: left;
                    }

                    p {
                        font-size: 1.2rem;

                        @media screen and (max-width: 890px) {
                            font-size: 1rem;
                        }

                        @media screen and (max-width: 600px) {
                            font-size: 0.8rem;
                        }

                        color: #9E9EA2;
                        text-align: left;
                    }
                }
            }

            .status {
                margin-right: 1rem;

                p {
                    font-size: 1.2rem;

                    @media screen and (max-width: 890px) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: 600px) {
                        font-size: 0.8rem;
                    }

                    font-weight: 600;
                    color: #4FAFCB;
                }
            }
        }
    }   
}

.updateReferralCode{
    display: flex;
    // width: 100%;
    flex-direction: column;
    padding: 2rem;
    margin: 1rem;
    background-color: white;
    border: 4px solid #4FAFCB;
    border-radius: 10px;
    label{
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 0.5rem;
        text-align: center;
    }

    #referralCodeInput{
        width: 100%;
        padding: 1rem;
        border: 1px solid #D9D9D9;
        border-radius: 10px;
        font-size: 1.3rem;
        font-weight: 500;
        margin-bottom: 1rem;
        outline: none;
        text-align: center;
    }

    button{
        width: 100%;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 10px;
        font-size: 1.3rem;
        text-transform: uppercase;
        font-weight: 700;
        color: white;
        background-color: var(--color-primary);
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover{
            background-color: #040F3C;
        }
    }
}

.referral-heading{
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
    
}
.stat_header_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
    .stat_header{
        display: grid;
        grid-template-columns: auto 1fr;
        // width: 100%;
        place-items: center;
        background-color: white;
        padding: 1rem;
        box-shadow: 0px 0px 14px 0px #2B92D5;
        border-radius: 10px;
        h1{
            font-size: 1.3rem;
            text-transform: uppercase;
            font-weight: 500;
            text-align: center;
        }
        p{
            font-size: 1.5rem;
            font-weight: 700;
            text-align: center;
            margin-top: 1rem;
        }
    }
}

.stats_body{
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem;
    gap: 1rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 14px 0px #2B92D5;
    margin-top: 1rem;
    .stat_item{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        padding: 1rem;
        border-bottom: 1px solid #ccc;
        h1{
            font-size: 1.2rem;
            font-weight: 500;
            text-transform: uppercase;
        
        }
        p{
            font-size: 1.3rem;
            font-weight: 700;
            text-align: right;
        }
    }
}

.balance-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media screen and (max-width: 1100px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
        
    }
    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        
    }
    gap: 1rem;
    .balance-card{
        padding: 1rem;
        box-shadow: 0px 0px 14px 0px #2B92D5;
        border-radius: 10px;
        background-color: white;
        width: 100%;
        h1{
            font-size: 1.3rem;
            text-transform: uppercase;
            font-weight: 500;
            text-align: center;
        }
        p{
            font-size: 1.5rem;
            font-weight: 700;
            text-align: center;
            margin-top: 1rem;
        }
    }
}