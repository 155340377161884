.sales-report-container {
    width: 100%;
    display: grid;
    flex-direction: 1fr;
    gap: 2rem;
    padding: 2rem 2rem;

    @media screen and (max-width: 1000px) {
        padding: 1rem 1rem;
        ;
    }

    .filter-container {
        display: flex;
        flex-direction: column;
        // grid-template-columns: 1fr 1fr;
        gap: 1rem;
        background-color: white;
        padding: 1rem 2rem;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.45);

        @media screen and (max-width: 1000px) {
            padding: 0.5rem;

        }

        @media screen and (max-width: 430px) {
            grid-template-columns: 1fr;
            gap: 0.5rem;

        }

        .selector_con_border{
            border: 1px solid #E6E6E6;
            border-radius: 10px;
            padding: 0.5rem;
        }
        .selector_con {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 0.5rem;


            label {
                font-weight: bold;
                font-size: 1rem;
                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }
            }

            select,
            .date {
                // height: 3rem;
                // border: 1px solid #E6E6E6;
                // border-radius: 10px;
                // padding:  0.5rem;
                font-size: 1rem;
                color: #41b6e6;
                background-color: transparent;
                outline: none;

                @media screen and (max-width: 1000px) {
                    font-size: 0.85rem;
                }
            }

            .creatable-select {
                .css-yk16xz-control {
                    border-color: #E6E6E6;
                    border-radius: 5px;
                }
            }
        }

        .date_range {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            align-items: center;
            justify-content: space-between;
            grid-column: span 2;
            border: 1px solid #E6E6E6;
            border-radius: 10px;
            padding: 0.5rem;

            @media screen and (max-width: 1000px) {
                padding: 0.5rem;
                gap: 0.5rem;
            }

            width: 100%;

            input {
                color: #41b6e6;
                outline: none;
                background-color: rgb(193 193 193);
                font-size: normal;
            }
        }

        .btn_con {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            align-items: end;

            .toggle-switchh {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;

                label {
                    font-size: 1rem;
                    margin-right: 1rem;
                    font-weight: bold;
                }

                input {
                    width: 50px;
                    height: 25px;
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: #c6c6c6;
                    outline: none;
                    border-radius: 50px;
                    position: relative;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

                    &:checked {
                        background-color: #41b6e6;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        width: 23px;
                        height: 23px;
                        background-color: white;
                        border-radius: 50%;
                        top: 1px;
                        left: 1px;
                        transition: transform 0.3s ease;
                    }

                    &:checked::before {
                        transform: translateX(25px);
                    }
                }
            }
        }

        button {
            background-color: #41b6e6;
            color: white;
            border-radius: 5px;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            font-weight: bold;
            text-transform: uppercase;
            cursor: pointer;
            align-self: flex-end;
            margin-top: 1rem;

            @media screen and (max-width: 1000px) {
                width: 100%;
            }

            &:hover {
                background-color: #002A8D;
            }
        }
    }

    .table-container {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        overflow: auto;
        padding: 0.5rem;
        // gap: 0.5rem;
        gap: 1rem;

        table {
            width: 100%;
            border-collapse: collapse;
            text-align: left;

            thead {
                tr {
                    background-color: #41b6e6;
                    color: white;
                    font-weight: bold;

                    th {
                        padding: 1rem;
                    }
                }
            }

            tbody {
                tr {
                    &:nth-child(even) {
                        background-color: #f2f2f2;
                    }

                    td {
                        padding: 1rem;
                        border-bottom: 1px solid #ddd;
                        align-items: center;

                        .tableBtn {
                            border-bottom: 1px solid #41b6e6;
                            color: #41b6e6;
                            padding: 0.5rem 1rem;
                            font-size: 1rem;
                            font-weight: bold;
                            cursor: pointer;
                            margin-top: 1rem;
                        }

                        .tableBtn:hover {
                            border-bottom: 1px solid #002A8D;
                            color: #002A8D;
                        }
                    }
                }
            }
        }
    }
}