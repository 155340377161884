.feedBackCard {
    display: grid;
    /* grid */
    grid-template-columns: repeat(2, minmax(0, 1fr));
    /* grid-cols-2 */
    border: 2px solid #C4C4C4;
    /* border-2 border-[#C4C4C4] */
    border-radius: 0.5rem;
    /* rounded-lg */
    padding: 1rem;
    /* p-4 */

    .details {
        display: grid;
        /* grid */
        grid-template-columns: repeat(1, minmax(0, 1fr));

        /* grid-cols-1 */
        .detailsPart1 {
            display: grid;
            /* grid */
            grid-template-columns: auto auto;
            /* grid-cols-[auto,auto] */
            gap: 0.5rem;
            /* gap-2 */
            width: -moz-fit-content;
            width: fit-content;
            /* w-fit */
            align-items: center;

            /* items-center */
            div:nth-child(1) {
                border-radius: 9999px;
                /* rounded-full */
                overflow: hidden;
                /* overflow-hidden */
                width: 4rem;
                /* w-16 */
                height: 4rem;
                /* h-16 */

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            div:nth-child(2) {
                p:nth-child(1) {
                    font-weight: 700;
                    /* font-bold */
                    font-size: 1rem;
                    /* text-base */
                }
                p:nth-child(2) {
                    font-size: 0.75rem;
                }
            }
        }
    }
    div:nth-child(2){
        border-radius: 0.5rem;
        overflow: hidden;
    }
}