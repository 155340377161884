.summer-sales-program {
    display: grid;
    grid-template-columns: 1fr;

    .summer_sales_banner {
        padding: 8rem 4rem;
        // margin: auto;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media screen and (max-width: 850px) {
            background-image: url('./components/mobile.png') !important;
            // background-image: image-url('./components/mobile.png');
        }
        .text {
            display: flex;
            // justify-content: center;
            flex-direction: column;
            gap: 1rem;

            h1 {
                color: white;
                font-size: 3rem;
                font-weight: bold;

                @media screen and (max-width: 1000px) {
                    font-size: 2rem;
                    // br{
                    //     display: none;
                    // }
                }

                @media screen and (max-width: 380px) {
                    font-size: 1.5rem;

                    br {
                        display: none;
                    }
                }
            }

            p {
                color: white;
                font-size: 1.5rem;

                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 380px) {
                    font-size: 0.8rem;

                }
            }

            button {
                background-color: white;
                color: black;
                border-radius: 10px;
                padding: 0.5rem 1rem;
                width: fit-content;
                width: -moz-fit-content;
            }
        }
    }

    .welcome_summer {
        display: flex;
        flex-direction: column;
        padding: 4rem 8rem;
        gap: 1rem;
        @media screen and (max-width: 1000px) {
            padding: 4rem 4rem;

        }

        position: relative;

        .left {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            @media screen and (max-width: 950px) {
                width: 50px;
            }
        }

        .right {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @media screen and (max-width: 950px) {
                width: 50px;
            }

        }

        h1 {
            color: var(--primary-text);
            font-size: 3rem;
            font-weight: bold;
            text-align: center;
            text-transform: capitalize;

            @media screen and (max-width: 1000px) {
                font-size: 2rem;
                // br{
                //     display: none;
                // }
            }

            @media screen and (max-width: 940px) {
                font-size: 1.5rem;

                br {
                    display: none;
                }
            }
        }

        p {
            color: var(--primary-text);
            font-size: 1rem;
            font-weight: 500;

            @media screen and (max-width: 1000px) {
                font-size: 0.8rem;
            }

            @media screen and (max-width: 380px) {
                font-size: 0.6rem;
            }

            text-align: center;
            text-transform: capitalize;

            @media screen and (max-width: 940px) {
                br {
                    display: none;
                }
            }
        }
    }

    .large_image {

        padding: 1rem 8rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        flex-direction: column;
        @media screen and (max-width: 1000px) {
            padding: 1rem 4rem;
        }

        .con {
            position: relative;

            .large {
                z-index: 2;
                position: relative;
            }

            .left {
                position: absolute;
                left: -100px;
                top: 50%;
                z-index: 0;
                transform: translateY(-50%);
                width: 200px;
                @media screen and (max-width: 950px) {
                    width: 100px;
                    left: -50px;
                }
            }

            .right {
                position: absolute;
                right: -100px;
                bottom: 0;
                z-index: 0;
                width: 200px;
                // transform: translateY(-50%);
                @media screen and (max-width: 950px) {
                    width: 100px;
                    right: -50px;
                }
            }
        }
        button{
            background-color: #2761D0;
            color: white;
            border-radius: 10px;
            padding: 0.5rem 4rem;
            width: fit-content;
            font-size: 1.5rem;
            width: -moz-fit-content;
            @media screen and (max-width: 1000px){
                padding: 0.5rem 2rem;
                font-size: 1rem;
            }
        }
    }

    .fresh_graduates{
        display: flex;
        flex-direction: column;
        padding: 6rem 8rem;
        // gap: 1rem;
        overflow: hidden;
        @media screen and (max-width: 1000px) {
            padding: 6rem 4rem;
        }
        h1{
            color: var(--primary-text);
            text-align: center;
            font-size: 3rem;
            font-weight: bold;
            text-transform: capitalize;
            @media screen and (max-width: 1000px) {
                font-size: 2rem;
            }
            @media screen and (max-width: 940px) {
                br{
                    display: none;
                }
            }
        }
        p{
            text-align: center;
            font-size: 1rem;
            font-weight: 500;
            color: var(--primary-text);
        }
        .main_image{
            margin-top: 4rem;
            position: relative;
            .left{
                position: absolute;
                left: -100px;
                // top: 50%;
                z-index: 0;
                // transform: translateY(-50%);
                bottom: -100px;
                width: 200px;
                @media screen and (max-width: 950px) {
                    width: 100px;
                    left: -50px;
                    bottom: -50px;
                }
            }

            .right{
                position: absolute;
                right: -200px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 0;
                width: 200px;
                @media screen and (max-width: 950px) {
                    width: 100px;
                    right: -100px;
                }
            }

            .main{
                z-index: 2;
                position: relative;
            }
        }

        .what_you_learn{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 4rem 8rem;
            @media screen and (max-width: 1200px) {
                padding: 4rem 4rem;
            }
            @media screen and (max-width: 1000px) {
                padding: 4rem 2rem;
                
            }
            @media screen and (max-width: 500px) {
                padding: 4rem 0rem;
                
            }
            .bullets{
                display: grid;
                grid-template-columns: 1fr auto;
                @media screen and (max-width: 900px){
                    grid-template-columns: 1fr;
                }
                gap: 1rem;
                .bullet{
                    display: grid;
                    grid-template-columns: auto 1fr;
                    align-items: start;
                    gap: 1rem;
                    p{
                        color: var(--primary-text);
                        text-align: start;
                        font-weight: 500;
                    }
                }
            }
            button{
                background-color: #2761D0;
                color: white;
                border-radius: 10px;
                padding: 0.5rem 4rem;
                @media screen and (max-width: 1000px){
                    padding: 0.5rem 2rem;
                    font-size: 1rem;
                }
                width: fit-content;
                font-size: 1.5rem;
                width: -moz-fit-content;
                margin: auto;
            }
        }
    }

    .success_stories{
        min-width: 90vw;
        width: 100%;
        padding: 0rem 4rem;
        // background-position: center;
        // background-size: cover;
        // background-repeat: no-repeat;
        position: relative;
        .back{
            position: absolute;
            right: 0px;
            top: 0px;
            height: 80%;
            @media screen and (max-width: 1020px) {
                display: none;
            }
        }
        h1{
            font-size: 3rem;
            font-weight: bold;
            color: var(--primary-text);
        }
        .story{
            display: grid;
            grid-template-columns: 1fr 1fr;
            // padding: 0rem 4rem;
            @media screen and (max-width: 1020px) {
                grid-template-columns: 1fr;
                // padding: 0rem 2rem;
                
            }
            margin-top: 2rem;
            p{
                font-size: 1.2rem;
                color: var(--primary-text);
                font-weight: 500;
                @media screen and (max-width: 1000px) {
                    br{
                        display: none;
                    }
                    
                }
            }
            .text{
                display: grid;
                grid-template-columns: 1fr;
                gap: 2rem;
                text-transform: capitalize;
            }
            .salesrep{
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                margin-top: 1rem;
                gap: 1rem;
            }
        }
    }
}