.sample-canvas{
    width:150px;
    overflow:hidden;
    height: 300px;

    .canvas-container{
        height: 300px !important;
        canvas{
            height: 300px !important;
        }
    }
}