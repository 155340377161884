.manage_commission_plan{
    @media screen and (max-width: 1000px){
        width: 98vw !important;
    }

    .search_filters{
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media screen and (max-width: 1000px){
            grid-template-columns: 1fr;
        }
        gap: 1rem;
        background-color: white;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        padding: 2rem;
        margin-bottom: 2rem;
        h1{
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 1rem;
            grid-column: span 2/span 2;
            text-align: center;
            @media screen and (max-width: 1000px){
                grid-column: span 1/span 1;
            }
        }
        input{
            width: 100%;
            height: 3rem;
            border-radius: 9.024px;
            border: 1px solid #C4C4C4;
            padding: 0.5rem;
            font-size: 1rem;
            text-align: left;
            font-weight: 600;
            color: var(--color-primary);
            &:focus {
                outline: none;
            }
        }

        button{
            width: 100%;
            height: 3rem;
            border-radius: 9.024px;
            border: none;
            background-color: var(--color-primary);
            color: white;
            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
            &:focus {
                outline: none;
            }
            grid-column: span 2/span 2;
            @media screen and (max-width: 1000px){
                grid-column: span 1/span 1;
            }
        }
    }
}