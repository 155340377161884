.chat_container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    border: 4px solid #4FAFCB;
    min-height: 80vh;

    .chat_conversation_header_edit_image {

        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        z-index: 20;
        width: 90%;
        // max-height: 80vh;
        // overflow: auto;


        // padding: 1rem;
        .current_image {
            width: 300px;
            height: 100%;
            margin: auto;
            position: relative;

            .edit_button {
                position: absolute;
                bottom: 10px;
                right: 10px;
                background-color: #2761D0;
                border-radius: 50%;
                padding: 0.5rem;
                cursor: pointer;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .conversation_files {
            display: grid;
            grid-template-columns: 1fr;
            padding: 2rem;

            .tabs {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                place-items: center;
                border-bottom: 1px solid #4FAFCB;
                padding: 1rem 0;
                row-gap: 1rem;

                .tab {
                    padding: 0.5rem 1rem;
                    border-radius: 10px;
                    cursor: pointer;
                    text-align: center;
                    background-color: #F5F5F5;
                    color: #4FAFCB;
                    font-weight: 600;


                }

                .active {
                    background-color: #4FAFCB;
                    color: white;
                }
            }

            .list {
                display: grid;
                grid-template-columns: 1fr;
                gap: 1rem;
                max-height: 30vh;
                overflow: auto;
                margin-top: 1rem;
            }
        }
    }

    // margin-top: 2rem;
    .chat_nav_icons {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 2rem;

        @media screen and (max-width: 1000px) {
            // display: grid;
            // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            // place-items: center;
            padding: 2rem 0rem;
        }

        .nav-text {
            font-size: 1rem;
            font-weight: 600;
            color: #4FAFCB;

            @media screen and (max-width: 1000px) {
                font-size: 0.8rem;
            }

            @media screen and (max-width: 400px) {
                font-size: 0.6rem;

            }
        }
    }

    .search_input {
        padding: 2rem;

        @media screen and (max-width: 1000px) {
            padding: 1rem;
        }

        @media screen and (max-width: 400px) {
            padding: 0.5rem;
        }

        input {
            width: 100%;
            padding: 0.5rem;
            border: 1px solid #4FAFCB;
            border-radius: 6px;
            // margin-right: 1rem;
            outline: none;
        }
    }

    .chat_list_container {
        height: 60vh;
        overflow: auto;
        padding: 2rem;

        .floating_icon {
            position: fixed;
            bottom: 6rem;
            right: 2rem;
            background-color: #2761D0;
            color: white;
            padding: 1rem;
            border-radius: 50%;
            cursor: pointer;
        }

        @media screen and (max-width: 1000px) {
            padding: 1rem;
        }

        @media screen and (max-width: 400px) {
            padding: 0.5rem;
            height: 60vh;

        }
        .line{
            width: 100%;
            height: 1px;
            background-color: #e8e8e8;
            margin: 0.5rem 0;
        }
        .chat_list_item {
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: center;
            // margin-bottom: 2rem;
            cursor: pointer;
            padding: 0.5rem 1rem;
            width: 100%;
            // border-bottom: 1px solid #c5c5c5;
            @media screen and (max-width: 400px) {
                padding: 0.2rem 0.5rem;
            }
          
            border-radius: 10px;

            &:hover {
                background-color: #F5F5F5;

            }

            .chat_list_item_avatar {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 1rem;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .chat_list_item_info {
                display: flex;
                flex-direction: column;

                .chat_list_item_info_name {
                    font-size: 1.5rem;

                    @media screen and (max-width: 1000px) {
                        font-size: 1rem;
                    }

                    font-weight: 600;
                }

                .chat_list_item_info_message {
                    font-size: 1.2rem;

                    @media screen and (max-width: 1000px) {
                        font-size: 1rem;
                    }

                    color: #4FAFCB;
                }
            }

            .chat_list_date {
                font-size: 1rem;

                @media screen and (max-width: 1000px) {
                    font-size: 0.8rem;
                }

                color: #4FAFCB;
                margin-left: auto;
                text-align: right;
            }
        }
    }

    .chat_conversation_container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 8vh 60vh 10vh;

        .chat_conversation_header {
            display: grid;
            grid-template-columns: auto auto 1fr;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            padding-left: 1rem;
            border-bottom: 1px solid #F5F5F5;
            position: relative;

            .pinned_messages_container {
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: white;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                z-index: 10;
                width: 70%;
                padding: 2rem;

                @media screen and (max-width: 1000px) {
                    width: 90%;
                }

                .pinned_messages_header {
                    display: flex;
                    align-items: center;
                    margin: auto;
                    width: fit-content;
                    width: -moz-fit-content;
                    gap: 0.5rem;

                    p {
                        font-weight: bold;
                        font-size: 1.5rem;
                        color: #2761D0;
                        text-align: center;
                    }
                }

                .pinned_messages_body {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 1rem;
                    margin-top: 1rem;
                    max-height: 50vh;
                    overflow: auto;

                    .pinned_message {
                        display: grid;
                        grid-template-columns: auto 1fr auto;
                        align-items: center;
                        padding: 0.5rem 1rem;
                        border-radius: 10px;
                        gap: 1rem;
                        background-color: #F5F5F5;

                        .chat_list_item_avatar {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            margin-right: 1rem;
                            overflow: hidden;

                            @media screen and (max-width: 1000px) {
                                width: 30px;
                                height: 30px;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .chat_list_item_info {
                            display: flex;
                            flex-direction: column;

                            .chat_list_item_info_name {
                                font-size: 0.6rem;
                                font-weight: 600;
                                color: #4FAFCB;
                            }

                            .chat_list_item_info_message {
                                font-size: 1rem;
                                // color: #4FAFCB;
                            }
                        }

                        .chat_list_date {
                            font-size: 0.6rem;
                            color: #4FAFCB;
                            margin-left: auto;
                            text-align: right;
                        }
                    }
                }

            }

            .pinned_messages {
                display: flex;
                align-items: center;
                background-color: white;
                width: 100%;
                position: absolute;
                top: 100%;
                padding: 0.5rem 1rem;
                font-weight: bold;
                cursor: pointer;
                border-bottom: 1px solid #ccc;
                gap: 0.5rem;
                z-index: 10;

                &:hover {
                    color: #2761D0;
                }
            }

            .chat_conversation_header_avatar {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 1rem;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .chat_conversation_header_info {
                display: flex;
                align-items: center;

                .chat_conversation_header_info_avatar {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-right: 1rem;
                    overflow: hidden;
                }

                .chat_conversation_header_info_name {
                    font-size: 1.5rem;

                    @media screen and (max-width: 1000px) {
                        font-size: 1rem;
                    }

                    font-weight: 600;
                    color: #2761D0;
                }

                .customer_service {
                    font-size: 1.5rem;

                    @media screen and (max-width: 1000px) {
                        font-size: 1rem;
                    }

                    font-weight: 600;
                    color: #2761D0;

                    @media screen and (max-width: 1000px) {
                        font-size: 1.2rem;
                    }

                    @media screen and (max-width: 590px) {
                        font-size: 1rem;

                    }
                }
            }
        }

        .chat_conversation_body {
            overflow: auto;
            padding: 1rem;

            .day-divider {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                margin-bottom: 1rem;

                .divider {
                    width: 100%;
                    height: 1px;
                    background-color: #4FAFCB;
                }

                .date {
                    font-size: 1.2rem;
                    color: #4FAFCB;
                }


            }

            .chat_message_right {
                display: flex;
                justify-content: flex-start;
                flex-direction: row-reverse;
                margin-bottom: 1rem;
                max-width: 50%;

                @media screen and (max-width: 1000px) {
                    max-width: 90%;
                    gap: 0.5rem;
                }

                text-align: right;
                margin-left: auto;
                gap: 1rem;

                .avatarAndTime {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 1rem;

                    .avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 1rem;
                        overflow: hidden;
                    }

                    .time {
                        font-size: 0.8rem;
                        color: #4FAFCB;
                        width: max-content;
                    }
                }

                .chat_message {
                    padding: 1rem;
                    position: relative;

                    @media screen and (max-width: 1000px) {
                        padding: 0.5rem;
                    }

                    border-radius: 10px;
                    border: 1px solid #C4C4C4;
                    max-width: 100%;
                    min-width: 20%;
                    width: -moz-fit-content;
                    width: fit-content;

                    p {
                        word-break: break-all;
                    }

                    iframe {
                        width: 100%;

                    }

                    audio {
                        background-color: transparent;
                    }

                    audio::-webkit-media-controls-panel {
                        background-color: transparent;
                    }
                }

                .download_icon {
                    cursor: pointer;
                    background-color: #2761D0;
                    border-radius: 50%;
                    padding: 0.5rem;
                    height: fit-content;
                    margin: auto 0;
                }

                .edit_msg_input {
                    width: 100%;
                    padding: 0.5rem;
                    border: 1px solid #4FAFCB;
                    border-radius: 6px;
                    margin-right: 1rem;
                    display: flex;
                    align-items: center;

                    input {
                        outline: none;
                        width: 100%;
                    }
                }
            }

            .chat_message_left {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 1rem;
                max-width: 50%;

                @media screen and (max-width: 1000px) {
                    max-width: 90%;
                    gap: 0.5rem;
                }

                text-align: left;
                margin-right: auto;
                gap: 1rem;

                .avatarAndTime {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1rem;
                    flex-direction: column;

                    .avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 1rem;
                        overflow: hidden;
                    }

                    .time {
                        font-size: 0.8rem;
                        color: #4FAFCB;
                        width: max-content;
                    }
                }

                .chat_message {
                    padding: 1rem;

                    @media screen and (max-width: 1000px) {
                        padding: 0.5rem;
                    }

                    height: fit-content;
                    border-radius: 10px;
                    border: 1px solid #C4C4C4;
                    max-width: 100%;
                    width: -moz-fit-content;
                    width: fit-content;

                    iframe {
                        width: 100%;

                    }

                    p {
                        word-break: break-all;
                    }

                    audio {
                        background-color: transparent;
                    }
                }

                .download_icon {
                    cursor: pointer;
                    background-color: #2761D0;
                    border-radius: 50%;
                    padding: 0.5rem;
                    height: fit-content;
                    margin: auto 0;
                }
            }
        }

        .sendButton {
            background-color: #181E4B;
            color: white;
            padding: 0.5rem 1rem;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
}

$rhap_theme-color: #2761D0;

.rhap_button-clear {
    color: #2761D0 !important;
}

.rhap_volume-indicator {
    background-color: #2761D0 !important;
}

.rhap_progress-indicator {
    background-color: #2761D0 !important;
}

.rhap_progress-filled {
    background-color: #2761D0 !important;
}

.rhap_container {
    box-shadow: none !important;
}

.createConversationForm {
    // display: flex;
    // padding: 0rem 1rem;
    // align-items: center;
    // justify-content: flex-start;
    // @media screen and (max-width: 1000px){
    //     gap: 0.5rem;
    //     flex-wrap: wrap;
    //     justify-content: center;
    // }
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;

    h1 {
        text-align: center;
    }

    .conversationNameInput,
    select {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #4FAFCB;
        border-radius: 6px;
        // margin-right: 1rem;
        outline: none;
    }

    .selector {
        width: 100%;
        // padding: 0.5rem;
        border-radius: 6px;
        // margin-right: 1rem;

    }

    button {
        background-color: #2761D0;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 6px;
        cursor: pointer;

        @media screen and (max-width: 1000px) {
            width: 100%;

        }
    }
}

.css-1nmdiq5-menu {
    position: absolute !important;
}

.fiv-cla,
.fiv-viv,
.fiv-sqo {
    font-size: 3em;
}


.chat_conversation_header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #ccc;
    animation: borderAnimation 2s infinite;
    animation-timing-function: linear;

    .chat_conversation_header_avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 1rem;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .chat_conversation_header_info {
        display: flex;
        align-items: center;

        .chat_conversation_header_info_avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 1rem;
            overflow: hidden;
        }

        .chat_conversation_header_info_name {
            font-size: 1.5rem;

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }

            font-weight: 600;
            color: #2761D0;
        }

        .customer_service {
            font-size: 1.5rem;

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }

            font-weight: 600;
            color: #2761D0;

            @media screen and (max-width: 1000px) {
                font-size: 1.2rem;
            }

            @media screen and (max-width: 590px) {
                font-size: 1rem;
            }
        }
    }
}

.progress {
    // width: 60vmin;
    // height: 50vmin;
    display: grid;
    place-content: center;
    color: white;
    text-shadow: 0 1px 0 #000;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    width: 50%;
    --border-angle: 0turn; // For animation.
    --main-bg: conic-gradient(
        from var(--border-angle),
        #213,
        #112 5%,
        #112 60%,
        #213 95%
      );
    
    border: solid 5px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #f03 99%, transparent);
    
    background: 
      // padding-box clip this background in to the overall element except the border.
      var(--main-bg) padding-box,
      // border-box extends this background to the border space
      var(--gradient-border) border-box, 
      // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
      var(--main-bg) border-box;
    
    background-position: center center;
  
    animation: bg-spin 3s linear infinite;
    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
  