.manageFiberCompanies {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    .searchFilters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        flex-direction: column;
        gap: 1rem;
        border-radius: 10px;
        background: var(--color-foreground);
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        padding: 2rem;

        h1 {
            font-size: 20px;
            font-weight: 600;
            color: var(--color-primary);
        }

        .inputs {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            @media screen and (max-width: 910px) {
                grid-template-columns: 1fr;
            }

            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 1rem;

            input {
                width: 100%;
                padding: 10px 20px;
                border-radius: 5px;
                border: 1px solid #00000040;
                outline: none;
                font-size: 14px;
                font-weight: 600;
                color: var(--color-primary);

                &::placeholder {
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--color-primary);
                }
            }
        }

        button {
            background-color: var(--color-foreground);
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            color: var(--color-primary);
            padding: 10px 20px;
            border-radius: 5px;
            box-shadow: 0px 0px 5px 0px #00000040;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: var(--color-primary);
                color: var(--color-foreground);
            }
        }
    }

    .companiesContainer {
        overflow: auto;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #E0E0E0;
        background: var(--color-foreground);
        padding: 1rem;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        .tableHeaders {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            place-items: center;
            gap: 1rem;

            // margin-bottom: 1rem;

            h3 {
                color: var(--color-primary);
                font-family: "Open Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }


        }

        .tableBody {
            .fiberCompany {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                place-items: center;
                gap: 1rem;
                border-radius: 10px;
                border: 1px solid #E0E0E0;
                background: var(--color-foreground);
                padding: 1rem;

                div {
                    img {
                        width: 5rem;
                        object-fit: cover;

                    }
                }

                p {
                    color: var(--color-primary);
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .fiberCompanyEdit {
                    width: -moz-fit-content;
                    width: fit-content;
                    color: var(--color-primary);
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration-line: underline;
                }

                .fiberCompanyDelete {
                    width: -moz-fit-content;
                    width: fit-content;
                    color: var(--Colors-Action-Primary, #d63838);
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration-line: underline;
                }
            }
        }
    }

    .spinner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}