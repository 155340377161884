.mainTableContainer{
    width: 100%;
    overflow-x: auto;
    border:4px solid #4FAFCB;
    border-radius: 10px;
    background-color: white;
    height: fit-content;
    .tableContainer{
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        padding: 1rem 2rem;
        
        width: 100%;
        @media screen and (max-width: 1150px){
            // width: max-content;
            
        }

    }
}