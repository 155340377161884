.edit_check_availability_form{
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    h1{
        font-size: 1.5rem;
        font-weight: 600;
        color: #333;
        text-align: center;
    }
    .inputs{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        input{
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            text-align: center;

        }
        .checkbox{
            display: flex;
            align-items: center;
            gap: 0.5rem;
            input{
                width: 1rem;
                height: 1rem;
            }
            label{
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 0.5rem;
                align-items: center;
            }
        }
    }

    button{
        padding: 0.5rem 1rem;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
        transition: all 0.3s;
        &:hover{
            background-color: #0056b3;
        }
    
    }
}

.manage_availability_checks{
    display: gird;
    grid-template-columns: 1fr;
    gap: 2;
    @media screen and (max-width: 1000px){
        width: 98vw;
        
    }
    width: 100%;
    height: 100%;

    .top_part{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        background-color: white;
        border-radius: 10px;
        padding: 1rem;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
        h1{
            font-size: 1.5rem;
            font-weight: 600;
            color: #333;
            text-align: center;
        }
        .search_filters{
            display: grid;
            grid-template-columns: 1fr 1fr ;
            @media screen and (max-width: 504px){
                grid-template-columns: 1fr;
            }
            gap: 1rem;
            input{
                padding: 0.5rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                outline: none;
                text-align: center;

            }
        }
        button{
            padding: 0.5rem 1rem;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            outline: none;
            transition: all 0.3s;
            &:hover{
                background-color: #0056b3;
            }
        }
    }
}
