.review{

    position: relative;
    margin-top: 5rem;
    background-color: transparent;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 4rem;
    // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    .profile {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        
        position: absolute;
        top: -10%;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 120;
        object-fit: cover;
    }
    .profile_h4{
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 120;
        color: white;
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 1rem;
        // font-size: 2rem;
        // background-color: #2761D0;
    }
    #bbg_0{
        clip-path: polygon(0% 0%, 100% 5%, 100% 100%, 0 100%);
    }
    #bbg_1{
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 95%);
        top: 51%;
        transform: skew(-8deg, 0deg) translate(-56%, -50%);
    }
    #bbg_2{
        transform: skew(10deg, 10deg) translate(-30%, -44%);
    }
    .blue_background{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
        width: 75%;
        height: 97%;
        border-radius: 30px;
        background-color: #2761D0;
       
    }
    .testimonial_card {
        filter: url('#flt_tag') drop-shadow(2px 4px 6px black);
        // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        position: relative;
    
        #test_0{
            clip-path: polygon(8% 5%, 100% 0, 100% 80%, 0 100%);
        }

        #test_1{
            clip-path: polygon(0% 23%, 100% 0%, 90% 83%, 9% 99%);
        }

        #test_2{
            clip-path: polygon(0% 0%, 100% 20%, 90% 100%, 5% 80%);
        }
        .home_testmonail {
            position: relative;
            width: 100%;
            z-index: 1;
            background-color: var(--review-background);
            width: -moz-fit-content;
            width: fit-content;
            padding:4rem 6rem;
            width: max-content;
            //  clip-path: circle(45% at 50% 50%);
            border-radius: 30px;
            margin: auto;
    
            .review_background {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 0;
                transform: translate(-50%, -50%);
    
            }
    
      
    
            h1 {
                z-index: 1;
                position: relative;
                font-size: 1.5rem;
                @media screen and (max-width: 1000px) {
                    font-size: 1.3rem;
                    
                }
                @media screen and (max-width: 500px) {
                    font-size: 1.2rem;
                    
                }
                font-weight: 700;
                color: #000;
                text-align: center;
                margin-top: 2rem;
            }
            p{
                color: #525252;
                text-align: center;
            }
        }
    
        .flt_svg {
            visibility: hidden; 
            position: absolute;
            width: 0px;
            height: 0px;
        }
    }
}