.offers-report-container {
    width: 100%;
    display: grid;
    flex-direction: 1fr;
    gap: 2rem;
    padding: 2rem 2rem;

    @media screen and (max-width: 1000px) {
        padding: 1rem 1rem;;
    }

    .filter-container {
        display: flex;
        flex-direction: column;
        // grid-template-columns: 1fr 1fr;
        gap: 1rem;
        background-color: white;
        padding: 1rem 2rem;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.45);

        @media screen and (max-width: 1000px) {
            padding: 0.5rem;

        }

        @media screen and (max-width: 430px) {
            grid-template-columns: 1fr;
            gap: 0.5rem;

        }

        .selector_con {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            gap: 0.5rem;
            justify-content: space-between;
            width: 100%;
            label {
                font-weight: bold;
                font-size: 1.2rem;
                @media screen and (max-width: 1000px) {
                    font-size: 1rem;
                }
            }
        }

        .toggle-switch {
            display: flex;
            align-items: center;
            justify-content:flex-end;
            width: 100%;

            label {
                font-size: 1.2rem;
                margin-right: 1rem;
                font-weight: bold;
            }

            input {
                width: 50px;
                height: 25px;
                -webkit-appearance: none;
                appearance: none;
                background-color: #c6c6c6;
                outline: none;
                border-radius: 50px;
                position: relative;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:checked {
                    background-color: #41b6e6;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 23px;
                    height: 23px;
                    background-color: white;
                    border-radius: 50%;
                    top: 1px;
                    left: 1px;
                    transition: transform 0.3s ease;
                }

                &:checked::before {
                    transform: translateX(25px);
                }
            }
        }

        .date_range {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            align-items: center;
            justify-content: space-between;
            grid-column: span 2;
            border: 1px solid #E6E6E6;
            border-radius: 10px;
            padding: 0.5rem;
            @media screen and (max-width: 1000px) {
                padding: 0.5rem;
                gap: 0.5rem;
            }
            width: 100%;
            input {
                color: #41b6e6;
                outline: none;
                background-color: rgb(193 193 193);
                font-size: normal
                ;
            }
        }

        button {
            background-color: #002A8D;
            color: white;
            border-radius: 5px;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            font-weight: bold;
            text-transform: uppercase;
            cursor: pointer;
            align-self: flex-end;
            margin-top: 1rem;

            @media screen and (max-width: 1000px) {
                width: 100%;
            }

            &:hover {
                background-color: #002A8D;
            }
        }
    }

    .report-summary {
        display: flex;
        flex-direction: column;
        align-items: center;

        .summary-cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
        }

        .summary-card {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            padding: 1rem 2rem;
            text-align: center;
            width: 150px;

            .img {
                width: 50px;
                height: 50px;
                margin-bottom: 0.5rem;
                display: inline;
            }

            .card-value {
                font-size: 2rem;
                font-weight: bold;
            }

            .card-label {
                font-size: 1rem;
                color: #666;
            }
        }
    }

}
