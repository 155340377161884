.create_customer_container{
    display: grid;
    grid-template-columns: 1fr;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    .title{
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: center;
    }

    .inputs{
        display: grid;
        grid-template-columns: 1fr ;
        gap: 1rem;
        input{
            padding: 0.5rem 1rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            font-size: 1rem;

        }
    }

    .DesiredInstallDiv{
        h3{
            font-size: 1.3rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }

        .OptionsDiv{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            @media screen and (max-width: 660px){
                grid-template-columns: 1fr 1fr;
            }
            @media screen and (max-width: 500px){
                grid-template-columns: 1fr;
                
            }
            gap: 1rem;
            div{
                display: flex;
                align-items: center;
                gap: 0.5rem;
                border-bottom: 1px solid #ccc;
                padding-bottom: 1rem;
                input[type="checkbox"]{
                    margin-right: 0.5rem;
                    width: 1rem;
                    height: 1rem;
                }
            }
        }
    }

    select{
        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        font-size: 1rem;
        width: 100%;
    }

    .inputDiv{
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
        label{
            font-size: 1.2rem;
            // font-weight: 600;
        }

        input{
            padding: 0.5rem 1rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            font-size: 1rem;
        }
        input[type="checkbox"]{
            width: 1rem;
            height: 1rem;
        }
    }

    .CreateButton{
        background-color: #007bff;
        color: #fff;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1.4rem;
        // margin: auto;
        width: 100%;
        place-self: center;
    }
}

.map_customer_form{
    .inputDiv{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        div{
            display: flex;
            align-items: center;
            gap: 1rem;
        }
        p{
            color: #858585;
        }
                
    }
}
.become_customer{
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}